import React, { useEffect, useState } from "react";
import { faAdd, faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col } from "react-bootstrap";
import CrudApi from "@serie3/common/api/crudApi";
import DocumentImage from "./DocumentImage";
import downloadHandler from "@serie3/freelancesplaceapi/Domain/Shared/downloadHandler";
import DocumentsConfig from "@serie3/freelancesplaceapi/Config/Documents/DocumentsConfig";

const DocumentCard = ({ document, setShow, CardButtons, deleteFunction }) => {
  const [file, setFile] = useState();
  const [preview, setPreview] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      let previewUrl = DocumentsConfig.endpoints.preview + document.id;
      const response = await CrudApi.getBinary(previewUrl);
      if (response.status === 200 || response.status === 204) {
        let file = {
          header: response.headers,
          data: response.data,
        };
        setFile(file);
        if (response.status === 204) {
          setPreview(false);
        }
      } else {
        setFile(null);
      }
    };
    loadData();
  }, []);

  const DocumentCardContent = () => {
    if (typeof document !== "undefined" && typeof file !== "undefined") {
      return (
        <>
          <div
            className="card-header text-muted border-bottom-0"
            style={{
              overflow: "hidden",
              overflowWrap: "normal",
              textOverflow: "ellipsis",
            }}
          >
            <b>{document.name}</b>
          </div>
          <div className="card-body pt-0 pb-0">
            {file && preview ? (
              <DocumentImage file={file} />
            ) : (
              <div
                style={{
                  verticalAlign: "middle",
                  textAlign: "center",
                  lineHeight: "150px",
                }}
              >
                No preview possible
              </div>
            )}
          </div>
          <div className="card-footer">
            <div className="text-end">
              <Buttons document={document} file={file} />
            </div>
          </div>
        </>
      );
    }
    return (
      <div className="card-body centerButtonCard">
        <Button className="btn bg-gradient-success btn-circle">
          <FontAwesomeIcon icon={faAdd} />
        </Button>
      </div>
    );
  };

  const downloadUrl = DocumentsConfig.endpoints.preview + document.id;

  const Buttons = ({ document, file }) => {
    return CardButtons ? (
      <CardButtons />
    ) : (
      <>
        {file ? (
          <Button
            variant="primary"
            size="sm"
            className="mr-1"
            onClick={() => {
              downloadHandler(downloadUrl, document.name);
            }}
          >
            <FontAwesomeIcon icon={faDownload} />
          </Button>
        ) : null}

        <Button
          variant="danger"
          size="sm"
          className="mr-1"
          onClick={() => {
            deleteFunction(document);
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </>
    );
  };

  return (
    <div
      style={{
        flex: "1", // Ensures equal width for each card in a row
        minWidth: "calc(33.33% - 10px)", // Sets the maximum width for each card (adjust the percentage and padding as needed)
        maxWidth: "calc(33.33% - 10px)",
        padding: "10px", // Adds padding to create gaps between the cards
        boxSizing: "border-box", // Ensures padding is included in the total width
        display: "flex", // Enables flexbox layout
        flexDirection: "column", // Stacks the content vertically within each div
        overflow: "hidden", // Hide any content that overflows the max height
      }}
    >
      <div className="card bg-light d-flex flex-fill">
        <DocumentCardContent />
      </div>
    </div>
  );
};

export default DocumentCard;
