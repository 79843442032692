import { useState, useEffect } from "react";
import MainSection from "./Components/MainSection";
import Header from "./Components/Header";
import Features from "./Components/Features";
import icon1 from "@serie3/freelancesplaceapi/Assets/img/Landingpages/icon1.png";
import icon2 from "@serie3/freelancesplaceapi/Assets/img/Landingpages/icon2.png";
import icon3 from "@serie3/freelancesplaceapi/Assets/img/Landingpages/icon3.png";
import icon4 from "@serie3/freelancesplaceapi/Assets/img/Landingpages/icon4.png";
import Feature from "./Components/Feature";
import { Row } from "react-bootstrap";
import Join from "./Components/Join";
import Video from "./Components/Video";
import Signup from "./Components/Signup";
import LandingpageFooter from "./Components/LandingpageFooter";
import RegisterModal from "../../signup/RegisterModal";

const WhySignUp = () => {
  const [showRegister, setShowRegister] = useState(false);

  useEffect(() => {
    const location = window.location.hostname;
    if (location === "demo.freelancersplace.net") {
      window.location.href = "https://www.javascripttutorial.net/";
    }
  }, []);

  const headerTitle =
    "We aim to be the only tool you will ever need to organize your feelancing business - we are not a job platform!";
  const headerText =
    "There are no projects here. But once you found a job, we will assist you in securing it with our customized CVs and application tracking, mange your workload with our project tools, and complete it smoothly with our billing solution!";
  const headerInsurance = "";

  const mainTitle = "Is this for you?";

  const MainFirstfeature = () => {
    return (
      <>
        <h4 className="landingpage blueColorText">Sign up </h4>
        <p>
          If you are a single freelancer looking to make your daily tasks easier
          and keep track of your business needs
        </p>
        <p style={{ fontWeight: "bold" }}>
          This tool was made by freelaners for freelancers and will help you
          organize yourself
        </p>
      </>
    );
  };

  const MainSecondFeature = () => {
    return (
      <>
        <h4 className="landingpage blueColorText">Don't sign up</h4>
        <p>
          If you are looking for a colaboration tool to manage a team project or
          if you only need one specific feature
        </p>
        <p style={{ fontWeight: "bold" }}>
          In this case you should look more for specialised tools that fit your
          needs better
        </p>
      </>
    );
  };

  const videoTitle = "Screencast";
  const videoMainTitle = "See the application tracker in action";
  const videoText =
    "Discover how our Application Tracker can streamline your workflow. Watch our detailed screencast for an in-depth look";
  const videoLink =
    "https://www.youtube.com/embed/kK_zTULDhgQ?si=RIpy47t8z8gyLW0-";

  return (
    <>
      <RegisterModal
        show={showRegister}
        setShow={setShowRegister}
        showLoginForm={false}
      />
      <Header
        setShowRegister={setShowRegister}
        title={headerTitle}
        text={headerText}
        insurance={headerInsurance}
      />
      <MainSection
        title={mainTitle}
        FirstFeature={MainFirstfeature}
        SecondFeature={MainSecondFeature}
      />
      <Features>
        <Row>
          <Feature
            icon={icon1}
            title={"Different application states"}
            text={
              "Always know what the next step is or whose turn it is in the recruiting process. Get reminded when it's time to act (coming soon)"
            }
          />
          <Feature
            icon={icon2}
            title={"Import different types (coming soon)"}
            text={
              "Import from different sources like E-Mails or create your own complete custom application "
            }
          />
        </Row>
        <Row>
          <Feature
            icon={icon3}
            title={"Manage CV & Job opportunities (coming soon)"}
            text={
              "Apply with job specified CVs to maximize your chances, track applications and get reminders if needed."
            }
          />
          <Feature
            icon={icon4}
            title={"Convert applications into projects (coming soon)"}
            text={
              "Once you have been accepted for a job, you can easily convert it into a project and manage it"
            }
          />
        </Row>
      </Features>
      <Join />
      <Video
        title={videoTitle}
        mainTitle={videoMainTitle}
        mainText={videoText}
        videoLink={videoLink}
      />
      <Signup />
      <LandingpageFooter />
    </>
  );
};

export default WhySignUp;
