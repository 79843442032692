import ProjectTask from "../../../Entity/Project/ProjectTask";
import * as Yup from "yup";
import CrudApi from "@serie3/common/api/crudApi";
import ProjectConfig from "../../../Config/Projects/ProjectConfig";
import timeingStuff from "@serie3/freelancesplaceapi/Domain/Shared/timeingStuff";

const createProjectTaskForm = (addTimetracking, projectId, setShowPayModal) => {

  const onSubmit = async (values) => {
    values.project = {'id': projectId}
    values.task.pause_time = timeingStuff.convertToMinutes(values.task.pause_time);
    let payload = JSON.stringify(values, null, 2);
    let response;
    if (values.id > 0) {
      response = await CrudApi.edit(
        ProjectConfig.endpoints.tasks.edit,
        payload
      );
    } else {
      response = await CrudApi.createNew(
        ProjectConfig.endpoints.tasks.new,
        payload,
        setShowPayModal
      );
    }
    if (response.status !== 200) {

    } else {
      console.log(response.data);
      addTimetracking(response.data);
    }
  };

  return {
    initialValues: ProjectTask,
    onSubmit: (values, actions) => {
      onSubmit(values, actions);
    },
  };
};

const setEditData = (editData) => {
  const date = new Date();
  let Project = {
    id: editData.id,
    task: editData.task,
  };

  return Project;
};

export default { createProjectTaskForm, setEditData };
