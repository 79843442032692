import crudApi from "../../api/crudApi";

const createRequestValidationForm = (setSuccess) => {
  const onSubmit = async (values, actions) => {
    console.log(values);
    let payload = JSON.stringify(values, null, 2);
    const response = await crudApi.createNew(
      "/register/requestemailvalidation",
      payload
    );
    if (response.status === 200) {
      setSuccess(true);
    } else {
      setSuccess(false);
    }
  };

  return {
    initialValues: { email: "" },
    onSubmit: (values, actions) => {
      onSubmit(values, actions);
    },
  };
};


export default createRequestValidationForm;