const updateList = (
  response,
  payload,
  dataList,
  setDataFunction,
  showErrorFunction
) => {
  let editedItem = JSON.parse(payload);
  if (response.ok) {
    const tmpItem = editedItem;
    const tmp = [...dataList];
    const itemIndex = dataList.findIndex((obj) => obj.id === editedItem.id);
    tmp[itemIndex] = tmpItem;
    setDataFunction(tmp);
  } else {
    showErrorFunction(true);
  }
};

const deleteFromList = async (
  index,
  apiCall,
  setDataFunction,
  dataList,
  showErrorFunction
) => {
  if (apiCall) {
    const response = await apiCall;
    if (response.status == 200) {
      dataList.splice(index, 1);
      let tmpList = [...dataList];
      setDataFunction(tmpList);
    } else {
      if (typeof showErrorFunction !== "undefined") {
        showErrorFunction(true);
      }
    }
  } else {
    dataList.splice(index, 1);
    let tmpList = [...dataList];
    setDataFunction(tmpList);
  }
};

// Add the new/create function instead of the endpoint
const newToList = (
  response,
  dataList,
  setDataFunction,
  showErrorFunction,
  addToFront = false
) => {
  if (response.ok) {
    if (addToFront) {
      dataList.unshift(response.data);
    } else {
      dataList.push(response.data);
    }
    setDataFunction([...dataList]);
  } else {
    if (typeof showErrorFunction === "function") {
      showErrorFunction(true);
    }
  }
};

const replaceItemInList = (replaceItem, dataList, setDataFunction) => {
  const itemIndex = dataList.findIndex((obj) => obj.id === replaceItem.id);
  dataList.splice(itemIndex, 1, replaceItem);
  let tmpList = [...dataList];
  setDataFunction(tmpList);
};

const deleteWithoutCall = (deleteItem, dataList) => {
  const itemIndex = dataList.findIndex((obj) => obj.id === deleteItem.id);
  dataList.splice(itemIndex, 1);
  return dataList;
};

const deleteWithoutCallAndId = (deleteItem, dataList) => {
  const itemIndex = dataList.indexOf(deleteItem);
  dataList.splice(itemIndex, 1);
  return dataList;
};

export default {
  updateList,
  newToList,
  deleteFromList,
  replaceItemInList,
  deleteWithoutCall,
  deleteWithoutCallAndId,
};
