import { React, useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import FileDropzone from "../../../../documents/modals/FileDropzone";
import NewModal from "../../../../documents/modals/NewModal";
import Spinner from "@serie3/freelancesplaceapi/Assets/img/spinner.gif";
import CrudApi from "@serie3/common/api/crudApi";
import DocumentImage from "../../../../documents/DocumentImage";

const BillDropzone = ({ bill, uploadUrl, previewUrl }) => {
  const [showBillUpload, setShowBillUpload] = useState(false);
  const [file, setFile] = useState();

  const loadData = async () => {
    const response = await CrudApi.getBinary(previewUrl);
    let file = {
      header: response.headers,
      data: response.data,
    };
    setFile(file);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <NewModal show={showBillUpload} setShow={setShowBillUpload}>
        <FileDropzone
          uploadUrl={uploadUrl}
          additionalData={{ id: bill.id }}
          edit={true}
          setName={false}
        />
      </NewModal>
      <Col
        style={{ backgroundColor: "lightgrey", borderRadius: "5px", overflow: "scroll" }}
        onClick={() => {
          setShowBillUpload(true);
        }}
      >
        {file ? <DocumentImage file={file} /> : <img src={Spinner} />}
      </Col>
    </>
  );
};

export default BillDropzone;
