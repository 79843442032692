import ToastMessages from "../Shared/ToastMessages";
import {
  faPlayCircle,
  faPlay,
  faHourglass,
} from "@fortawesome/free-solid-svg-icons";

const handleResponse = (response, tasks, setTasks) => {
  if (response.status === 200) {
    ToastMessages.SuccessfulSave();
    tasks.push(response.data.task);
    setTasks([...tasks]);
  } else if (response.status === 402) {
    setShowPayModal(true);
  } else {
    ToastMessages.Error();
  }
};

const setIcon = (status, setIsRunning) => {
  let icon = faPlay;
  switch (status) {
    case "running":
      icon = faHourglass;
      setIsRunning(true);
      break;
    case "paused":
      icon = faPlayCircle;
      break;
    default:
      icon = faPlay;
      break;
  }

  return icon;
};

const setStatus = (task) => {
  if (typeof task.pauseStart !== "undefined" && task.ende === "undefined" ) {
    return "pauses";
  }
  if(typeof task.ende === "undefined"){
    return "running";
  }
  return "not started";
};

const checkTaskStatus = (task, setIsRunning) => {
  const status = setStatus(task);
  const icon = setIcon(status, setIsRunning);
  return {icon, status};
};

export default { checkTaskStatus, handleResponse };
