import { Routes, Route } from "react-router-dom";
import Index from "./Index";

const ActionRoutes = () => {
  return (
    <Routes>
         <Route path="/" element={<Index />} />
    </Routes>
  );
};

export default ActionRoutes;
