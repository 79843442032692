import { Row, Col, Container } from "react-bootstrap";

const Demosystem = () => {
  return (
    <Container fluid>
      <Row
        className="opacity-bg"
        style={{
          textAlign: "left",
  
        }}
      >
        <Row style={{marginBottom: "0.5rem"}}>
          <Col style={{ paddingLeft: "3rem", paddingTop: "1.5rem",     alignContent: "center", alignItems: "center"}}>
            <span
              style={{
                color: "var(--01, #FFF)",
                fontFamily: "Space Grotesk",
                fontSize: "2rem",
                fontStyle: "normal",
                fontWeight: "700",
                lineHeight: "120%",
                letterSpacing: "-0.0625rem",
              }}
            >
              Not convinced yet? Check out our free Demo System
            </span>
          </Col>
          <Col></Col>
        </Row>
        <Row className="opacity-text" style={{marginBottom: "0.5rem"}}>
          <Col
            style={{
              fontFamily: "Inter",
              fontSize: "1rem",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "1.75rem",
              opacity: "0.7",
              paddingLeft: "3rem",
            }}
          >
            Our <a href="https://demo.freelancersplace.net" target="_blank" style={{textDecoration: "none"}}>Demo system </a> resets the Data every hour and is filled with
            testing data you can use to play
          </Col>
          <Col></Col>
        </Row>
        <br />
      </Row>
    </Container>
  );
};

export default Demosystem;
