import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMicroscope,
  faUserGraduate,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "react-bootstrap";
import EditCardButtons from "../../../layout/Buttons/EditCardButtons";

import ProfileConfig from "@serie3/freelancesplaceapi/Config/Profiles/ProfileConfig";
import ProfilePicture from "./ProfilePicture";

const ProfileCard = ({
  index,
  profile,
  deleteData,
  setShowDetails,
  setProfile,
}) => {
  const skills = profile.skills;
  const lookingFor = profile._searching;

  return (
    <div className="col-12 col-sm-6 d-flex align-items-stretch flex-column">
      <div className="card bg-light d-flex flex-fill">
        <div
          className="card-header border-bottom-0"
          style={{
            minHeight: "64px",
            paddingTop: "15px",
            paddingBottom: "10px",
            paddingLeft: "17px",
            backgroundColor: "#007bff",
            color: "white",
            textAlign: "center",
          }}
        >
          <ProfilePicture profileId={profile.id} pictureSize="80px" />
          &nbsp;
          <h4 style={{marginTop: "15px"}}>{profile.profiletitle}</h4>
        </div>
        <br />
        <div className="card-body pt-0">
          <Row>
            <Col>
              <p
                className="text-muted"
                style={{ maxHeight: "128px", overflow: "clip", fontWeight: "bold" }}
              >
                <i>{profile.mantra}</i>
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <ul className="ml-4 mb-0 fa-ul text-muted">
                <li className="small">
                  <span className="fa-li">
                    <FontAwesomeIcon icon={faUserGraduate} />
                  </span>
                  {skills.map((skill, index) => {
                    return skill.name + ", ";
                  })}
                </li>
                <li className="small">
                  <span className="fa-li">
                    <FontAwesomeIcon icon={faMicroscope} />
                  </span>
                  {lookingFor.map((looking, index) => {
                    return looking.name + ", ";
                  })}
                </li>
                <li className="small">
                  <span className="fa-li">
                    <FontAwesomeIcon icon={faMoneyBill} />
                  </span>
                    {profile.hourly_rate_on_site} € / {profile.hourly_rate_remote} € /{profile.daily_rate_remote} € / {profile.daily_rate_on_site} € 
                </li>
              </ul>
            </Col>
          </Row>
        </div>
        <div className="card-footer">
          <div className="text-right">
            <EditCardButtons
              index={index}
              data={profile}
              setShowDetails={setShowDetails}
              setViewData={setProfile}
              deleteData={deleteData}
              editUrl={ProfileConfig.endpoints.edit + "/"}
              downloadUrl={ProfileConfig.endpoints.download + profile.id}
              downloadname={profile.title + ".pdf"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
