import { useState } from "react";
import { useFormik } from "formik";
import CreateLoginForm from "@serie3/common/Form/Login/CreateLoginForm";
import { Form, Col, Row, Collapse, Modal } from "react-bootstrap";

const LoginModal = ({
  show,
  setShow,
  setShowRegister,
  setToken,
  setShowForgot,
  title,
}) => {
  const [showLoginError, setShowLoginError] = useState(false);

  const closeModal = () => {
    setShow(false);
  };

  const loginFormiForm = CreateLoginForm(
    setToken,
    setShowLoginError,
    closeModal
  );
  const formik = useFormik(loginFormiForm);

  const showSignupForm = () => {
    setShow(false);
    setShowRegister(true);
  };

  const setShowForgotModal = () => {
    setShow(false);
    setShowForgot(true);
  };

  return (
    <Modal
      size="lg"
      show={show}
      onShow={() => {
        setShowLoginError(false);
      }}
      onHide={() => setShow(false)}
      dialogClassName="modal-80w"
      style={{
        content: {
          position: "absolute",
          top: "40px",
          left: "40px",
          right: "40px",
          bottom: "40px",
          border: "10px solid #ccc",
          background: "#fff",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          borderRadius: "4px",
          outline: "none",
          padding: "20px",
        },
      }}
    >
      <Modal.Header closeButton={true} style={{ border: "0px" }} />
      <Modal.Body
        style={{ textAlign: "center", padding: "2.5rem 3.5rem 3.5rem 4.5rem" }}
      >
        <h1 style={{ marginBottom: "2rem" }}>
          {!title ? <b>Login</b> : <b>{title}</b>}
        </h1>

        <Form onSubmit={formik.handleSubmit}>
          <Form.Group className="input-group mb-3" controlId="username">
            <Form.Control
              type="email"
              name="username"
              placeholder="email"
              onChange={formik.handleChange}
              value={formik.values.username}
              isInvalid={!!formik.errors.username}
              className="form-control"
              style={{
                borderRadius: "0.5rem",
                border: "1px solid var(--gray-300, #D0D5DD)",
              }}
            />

            <Form.Control.Feedback type="invalid">
              {formik.errors.email}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="input-group mb-3" controlId="password">
            <Form.Control
              type="password"
              name="password"
              placeholder="password"
              onChange={formik.handleChange}
              value={formik.values.password}
              isInvalid={!!formik.errors.password}
              className="form-control"
              style={{
                borderRadius: "0.5rem",
                border: "1px solid var(--gray-300, #D0D5DD)",
              }}
            />

            <Form.Control.Feedback type="invalid">
              {formik.errors.password}
            </Form.Control.Feedback>
          </Form.Group>

          <Row>
            <Col>
              <button
                type="submit"
                className="btn btn-primary btn-block"
                onClick={() => setShowLoginError(false)}
              >
                Sign In
              </button>
            </Col>
          </Row>
          <Collapse in={showLoginError}>
            <Row>
              <Col style={{ color: "red", textAlign: "center" }}>
                <br />
                Something went wrong, while trying to login!
              </Col>
            </Row>
          </Collapse>
          <br />

          {window.location.hostname !== "demo.freelancersplace.net" ? (
            <>
              <Row>
                <Col
                  className="d-flex justify-content-center"
                  style={{
                    color: "var(--Blue, #007BFF)",
                    cursor: "pointer",
                    fontFamily: "Inter",
                    fontWeight: "400",
                    fontStyle: "normal",
                  }}
                >
                  <span
                    onClick={() => {
                      setShowForgotModal();
                    }}
                  >
                    Forgot password?
                  </span>
                </Col>
              </Row>
              <br />
              <Row>
                <Col
                  className="d-flex justify-content-center"
                  style={{
                    color: "var(--Grey, #4D4D4D)",
                    fontFamily: "Inter",
                    fontWeight: "400",
                    fontStyle: "normal",
                  }}
                >
                  <span>
                    Don't have an account? &nbsp;
                    <span
                      style={{
                        color: "var(--Blue, #007BFF)",
                        cursor: "pointer",
                        fontWeight: "700",
                      }}
                      onClick={showSignupForm}
                    >
                      Sign up.
                    </span>
                  </span>
                </Col>
              </Row>
            </>
          ) : (
            <Row>
              <Col style={{color: "red"}}>
                
                  The data on this system is getting reset every 30 Minutes.
                  Don't use any real data on this system!
                
              </Col>
            </Row>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default LoginModal;
