import KanbanBoard from "../../../../../../layout/Kanban/KanbanBoard";
import { useCallback, useState, useRef, useEffect } from "react";
import { Button, Row, Col, Collapse } from "react-bootstrap";
import { faAdd, faFilter, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NewModal from "../../../../documents/modals/NewModal";
import TicketView from "../../../../../../layout/Kanban/TicketView";
import ActionsFormWrapper from "../../../../crm/Actions/Form/ActionsFormWrapper";
import { useFormik } from "formik";
import ProjectActionForm from "@serie3/freelancesplaceapi/Form/Project/Project/ProjectActionForm";
import ProjectConfig from "@serie3/freelancesplaceapi/Config/Projects/ProjectConfig";
import ReferencedInputAndSubmit from "../../../../../../layout/Form/Fields/ReferencedInputAndSubmit";
import Tag from "../../../../../../layout/Buttons/Tag";
import getSettings from "@serie3/common/config/settings";
import PaymentModal from "../../../../../../layout/Payment/PaymentModal";


const Tickets = ({ tickets, setTickets, projectId, addTicket }) => {
  const [showForm, setShowForm] = useState(false);
  const [editAction, setEditAction] = useState();
  const [viewTicket, setViewTicket] = useState(false);
  const [showView, setShowView] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [searchWords, setSearchWords] = useState([]);
  const [showPayModal, setShowPayModal] = useState(false);
  const [settings, setSettings] = useState();

  const tagRef = useRef(null);

  useEffect(() => {
    if (searchWords.length > 0) {
      handleSearch();
    } else {
      setSearchResults([]);
    }
    setSettings(getSettings());
  }, [searchWords]);

  const addElement = (refName) => {
    searchWords.push(refName.current.value);
    refName.current.value = "";
    setSearchWords([...searchWords]);
    handleSearch();
  };

  const deleteElement = (indexToDelete) => {
    const tmpSearchWords = searchWords.filter(
      (tag, index) => index !== indexToDelete
    );

    setSearchWords(tmpSearchWords);
  };

  const updateEditAction = (newEditAction) => {
    setEditAction(newEditAction);
  }

  const ticketForm = ProjectActionForm.createProjectActionForm(
    addTicket,
    projectId,
    setShowPayModal,
    updateEditAction
  );
  const formik = useFormik(ticketForm);

  const showTicket = useCallback((ticket) => {
    setViewTicket(ticket);
    setShowView(true);
  }, []);

  const AddEduButton = () => {
    return (
      <Button
        className="success"
        onClick={() => {
          setEditAction(null);
          setShowForm(true);
        }}
      >
        Add &nbsp;
        <FontAwesomeIcon icon={faAdd} />
      </Button>
    );
  };

  const SearchButton = () => {
    return (
      <Button
        className="success"
        onClick={() => {
          setShowSearch(!showSearch);
        }}
      >
        {showSearch ? <FontAwesomeIcon icon={faX} /> : <FontAwesomeIcon icon={faFilter} />}
      </Button>
    );
  };

  const ResetButton = () => {
    return (
      <Button
        className="success"
        onClick={() => {
          handleResetSearch();
        }}
      >
        <FontAwesomeIcon icon={faX} />
      </Button>
    );
  };

  const handleSearch = () => {
    const lowercaseKeywords = searchWords.map((keyword) =>
      keyword.toLowerCase()
    );

    const filteredTickets = tickets.filter((obj) => {
      const title = obj.title.toLowerCase();
      const beschreibung = obj.beschreibung.toLowerCase();
      const tags = obj.tags.map((tag) => tag.name.toLowerCase()).join(" ");

      return lowercaseKeywords.some(
        (keyword) =>
          title.includes(keyword) ||
          beschreibung.includes(keyword) ||
          tags.includes(keyword)
      );
    });

    setSearchResults([...filteredTickets]);
  };

  const handleResetSearch = () => {
    setSearchWords([]);
  };

  const viewTitle = viewTicket ? viewTicket.title : "";
  const ticketList =
    searchResults.length > 0 ? [...searchResults] : [...tickets];

  return (
    <>
      {settings && <PaymentModal show={showPayModal} setShow={setShowPayModal} priceTableId={settings.pricingTables.projects}/>}
      <ActionsFormWrapper
        setShowForm={setShowForm}
        showForm={showForm}
        editAction={editAction}
        setEditAction={setEditAction}
        tickets={tickets}
        setTickets={setTickets}
        projectFormik={formik}
        projectId={projectId}
      />
      <NewModal show={showView} setShow={setShowView} title={viewTitle}>
        <TicketView action={viewTicket} />
      </NewModal>
      <Row>
        <Col className="text-end">
          <AddEduButton />
          &nbsp;
          <SearchButton />
        </Col>
      </Row>
      <br />

      <Collapse in={showSearch} style={{ marginLeft: "1px" }}>
        <Row>
          <Row>
            <Col>
              <ReferencedInputAndSubmit
                namedReference={tagRef}
                onClick={() => {
                  addElement(tagRef, "tags");
                }}
              />
            </Col>
          </Row>
          {searchWords.length > 0 ? (
            <Row >
              <Col>
                {searchWords.map((tag, index) => {
                  return (
                    <Tag
                      key={index}
                      item={tag}
                      content={tag}
                      deleteFunction={() => {
                        deleteElement(index);
                      }}
                    />
                  );
                })}
              </Col>
              <Col md={1}>
                <ResetButton />
              </Col>
            </Row>
          ) : null}
        </Row>
      </Collapse>
      <KanbanBoard
        showForm={showForm}
        setShowForm={setShowForm}
        actions={ticketList}
        setActions={setTickets}
        editAction={editAction}
        setEditAction={setEditAction}
        setViewAction={showTicket}
        deleteUrl={ProjectConfig.endpoints.actions.delete}
      />
    </>
  );
};

export default Tickets;
