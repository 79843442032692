import React from "react";
import FinanceSideNav from "../modules/finance/SideNav";
import CrmSideNav from "../modules/crm/SideNav";
import ProfilesSideNav from "../modules/profiles/SideNav";
import TimetrackingSideNav from "../modules/timetracking/SideNav";
import DocumentsSideNav from "../modules/documents/SideNav";
import Logo from "@serie3/freelancesplaceapi/Assets/img/logo.png";
import ProjectsSideNav from "../modules/projects/SideNav";
import SystemProfileContext from "@serie3/common/user/SystemProfileContext";
import { useContext } from "react";

const Sidebar = () => {
  const { systemProfile } = useContext(SystemProfileContext);
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      {/* Brand Logo */}
      <img
        src={Logo}
        alt="Freelancersplace"
        className="brand-image mt-3 ml-2 mr-2"
        style={{ maxWidth: "90%", border: "none" }}
      />
      <span className="brand-text font-weight-light">&nbsp;</span>

      {/* Sidebar */}
      <div className="sidebar">
        {/* Sidebar user panel (optional) */}
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <img
              src="/dist/img/avatar.png"
              className="img-circle elevation-2"
              alt="prifilepic"
            />
          </div>
          {
            <div className="info" style={{ color: "white", fontWeight: "bolder" }}>
              Hello {systemProfile && systemProfile.vorname ? systemProfile.vorname : null}
            </div>
          }
        </div>
        {/* Sidebar Menu */}
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            {/* Add icons to the links using the .nav-icon class
     with font-awesome or any other icon font library */}
            <li className="nav-item">
              <a href="/" className="nav-link active">
                <i className="nav-icon fas fa-tachometer-alt" />
                <p>Dashboard</p>
              </a>
            </li>
            <FinanceSideNav />
            <CrmSideNav />
            <ProfilesSideNav />
            <TimetrackingSideNav />
            <ProjectsSideNav />
            <DocumentsSideNav />
          </ul>
        </nav>
        {/* /.sidebar-menu */}
      </div>
      {/* /.sidebar */}
    </aside>
  );
};

export default Sidebar;
