const handlePositionFormChange = (e, formik) => {
  var position = formik.values;

  const { name, value } = e.currentTarget;

  if (name === "steuersatz") {
    var taxabsolute = ((position.preis * position.multiplikator) / 100) * value;
    var gesamt_preis = position.preis * position.multiplikator;
  }
  if (name === "preis") {
    var taxabsolute =
      ((value * position.multiplikator) / 100) * position.steuersatz;
    var gesamt_preis = value * position.multiplikator;
  }

  if (name === "multiplikator") {
    var taxabsolute = ((position.preis * value) / 100) * position.steuersatz;
    var gesamt_preis = position.preis * value ;
  }

  if (name !== "steuersatz" && name !== "preis" && name !== "multiplikator") {
    taxabsolute = position.taxabsolute;
    gesamt_preis = position.gesamt_preis;
  }

  formik.setFieldValue("taxabsolute", Math.round(taxabsolute * 100) / 100);
  formik.setFieldValue("gesamt_preis", Math.round(gesamt_preis * 100) / 100);
  formik.setFieldValue("gross_price", Math.round(gesamt_preis * 100) / 100 + Math.round(taxabsolute * 100) / 100);
};

const calculatePositionTax = (position) => {
  let nett = position.preis * position.multiplikator;
  let taxtotal = (nett / 100) * position.steuersatz;
  return taxtotal.toFixed(2);
};

const calculatePositionNett = (position) => {
  let nett = position.preis * position.multiplikator;
  return nett.toFixed(2);
};

const calculatePositionGross = (position) => {
  let nett = position.preis * position.multiplikator;
  let taxtotal = (nett / 100) * position.steuersatz;
  let subtotal = taxtotal + nett;
  return subtotal.toFixed(2);
};

export default {
  calculatePositionTax,
  calculatePositionNett,
  calculatePositionGross,
  handlePositionFormChange,
};
