import Task from "../../Entity/Timetracking/Task";
import * as Yup from "yup";
import CrudApi from "@serie3/common/api/crudApi";
import TimetrackingConfig from "../../Config/Timetracking/TimetrackingConfig";
import listHandler from "@serie3/common/Domain/listHandler";
import timeingStuff from "@serie3/freelancesplaceapi/Domain/Shared/timeingStuff";

const createTimetrackingForm = (
  dataList,
  setDataFunction,
  showErrorFunction,
  setShowEdit,
  setShowPayModal
) => {

  const onSubmit = async (values, actions) => {
    if (typeof values.pause_time !== "undefined") {
      const pausedMinutes = timeingStuff.convertToMinutes(values.pause_time);
      values.pause_time = pausedMinutes;
    }

    if (typeof values.start !== "undefined") {
      const startDate = new Date(values.start.$d);
      if (typeof startDate === "Date") {
        values.start = startDate.toISOString();
      }
    }
    if (typeof values.end !== "undefined") {
      const endDate = new Date(values.end.$d);
      if (typeof endDate === "Date") {
        values.end = endDate.toISOString();
      }
    }

    if (values.id > 0) {
      let payload = JSON.stringify(values, null, 2);
      const response = await CrudApi.edit(
        TimetrackingConfig.endpoints.edit,
        payload
      );
      listHandler.updateList(
        response,
        payload,
        dataList,
        setDataFunction,
        showErrorFunction
      );
    } else {
      let payload = JSON.stringify(values, null, 2);
      const response = await CrudApi.createNew(
        TimetrackingConfig.endpoints.new,
        payload,
        setShowPayModal
      );
      listHandler.newToList(
        response,
        dataList,
        setDataFunction,
        showErrorFunction
      );
    }

    setShowEdit(false);
    actions.resetForm(Task);
  };

  return {
    initialValues: Task,
    onSubmit: (values, actions) => {
      onSubmit(values, actions);
    },
  };
};

const setEditData = (editData) => {
  const date = new Date();
  let Task = {
    id: editData.id,
    date: editData.date,
    start: editData.start,
    ende: editData.ende,
    pause_start: editData.pause_start,
    pause_time: timeingStuff.convertToHHmm(editData.pause_time),
    title: editData.title,
    total_time: editData.total_time,
    beschreibung: editData.beschreibung,
    tags: editData.tags,
    deleted: editData.deleted,
  };

  return Task;
};

export default { createTimetrackingForm, setEditData };
