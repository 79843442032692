const Finance = {
    beschreibung: '',
    post_text: '',
    betreff: '',
    datum: '',
    aborechnung: false,
    privat: false,
    start: '',
    ende: '',
    rechnungsinterval: 0,
    versandinterval: 0,
    deadline: '',
    wiedervorlage_datum: '',
    status: '',
    geldeingang: false,
    payment_deadline: '',
    payment_due_days: '',
    typ: '',
    draft: true,
    autopay: false,
    autopayDate: '',
    eingang: false,
    kunde: '',
    person: '',
    rechnungs_nummer:'',
    final_billing_number:'',
    tags: [],
  }

export default Finance;