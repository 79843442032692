import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";

const Address = ({ addressJson = "{}", showStreet = true, style }) => {
  const [address, setAddress] = useState();

  useEffect(() => {
    try {
      let addressObject = JSON.parse(addressJson);
      setAddress(addressObject);
    } catch (error) {}
  }, [addressJson]);

  const PersonAddress = () => {
    return Object.keys(address).length !== 0 ? (
      <>
        {address.salutation ? address.salutation : ""}{" "}
        {address.firstname ? address.firstname : ""}{" "}
        {address.name ? address.name : ""}
      </>
    ) : null;
  };

  const CompanyAddress = () => {
    return Object.keys(address).length !== 0 ? (
      (address.companyName ? address.companyName : "") +
        " " +
        (address.legal_form ? address.legal_form : "")
    ) : (
      <br />
    );
  };

  return (
    address && (
      <address style={{ ...style }}>
        <strong>
          {address.hasOwnProperty("salutation") ? (
            <PersonAddress />
          ) : (
            <CompanyAddress />
          )}
        </strong>
        {showStreet ? (
          <Row>
            <Col>
              {address.street} &nbsp;{address.number}
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col>
            {address.postcode}&nbsp;{address.city}
          </Col>
        </Row>
      </address>
    )
  );
};

export default Address;
