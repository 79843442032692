import Content from "../../../../layout/Content";
import JobForm from "@serie3/freelancesplaceapi/Form/Project/Job/JobForm";
import { useFormik } from "formik";
import { useEffect, useState, useRef } from "react";
import { Col, Row, Table, Button } from "react-bootstrap";
import TextField from "../../../../layout/Form/Fields/TextField";
import TextArea from "../../../../layout/Form/Fields/TextArea";
import ReferencedInputAndSubmit from "../../../../layout/Form/Fields/ReferencedInputAndSubmit";
import Checkbox from "../../../../layout/Form/Fields/Checkbox";
import Card from "../../../../layout/Cards/Card";
import { useParams } from "react-router-dom";
import crudApi from "@serie3/common/api/crudApi";
import JobConfig from "@serie3/freelancesplaceapi/Config/Projects/JobConfig";
import DateField from "../../../../layout/Form/Fields/DateField";
import SaveButton from "../../../../layout/Buttons/SaveButton";
import TagLikeHandler from "@serie3/freelancesplaceapi/Form/shared/TagLikeHandler";
import Tag from "../../../../layout/Buttons/Tag";
import LanguageForm from "../../../../layout/Form/LanguageForm";
import AddLanguageButton from "../../../../layout/Buttons/AddLanguageButton";
import CompleteDropdown from "../../../../layout/Dropdowns/CompleteDropdown";
import JobHeader from "./JobHeader";
import Freelancers from "./Freelancers";
import NumberField from "../../../../layout/Form/Fields/NumberField";
import Job from "@serie3/freelancesplaceapi/Domain/Projects/Job";
import CustomerModal from "../../crm/Shared/CustomerModal";
import Address from "../../../../layout/Common/Address";
import ContactData from "../../../../layout/Common/ContactData";

const Edit = () => {
  const [editJob, setEditJob] = useState();
  const [keySkills, setKeySkills] = useState([]);
  const [niceToHaveSkills, setNiceToHaveSkills] = useState([]);
  const [tasks, setTaks] = useState([]);
  const [weOffer, setWeOffer] = useState([]);
  const [showLanguageForm, setShowLanguageForm] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [editLanguage, setEditLanguage] = useState();
  const [updateFreelancers, setUpdateFreelancers] = useState(false);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [contactData, setContactData] = useState();
  const [customerData, setCustomerData] = useState();
  const [contactFunction, setContactFunction] = useState();

  const jobForm = JobForm.createJobForm(setLanguages);
  const formik = useFormik(jobForm);
  const { id } = useParams();
  const skillsRef = useRef(null);
  const niceSkillsRef = useRef(null);
  const tasksRef = useRef(null);
  const weOfferRef = useRef(null);

  useEffect(() => {
    const loadData = async (id) => {
      const job = await crudApi.show(JobConfig.endpoints.show, id);
      setEditJob(job);
      setKeySkills(job.key_skills);
      setNiceToHaveSkills(job.other_skills);
      setTaks(job.to_do);
      setWeOffer(job.we_offer);
      setLanguages([...job.languages]);
      formik.setValues(JobForm.setEditData(job));

      let customer = job.person
        ? job.person
        : job.customer
        ? job.customer
        : null;
      setContactData(customer);

      let contactData = job.client_person
        ? job.client_person
        : job.client_customer
        ? job.client_customer
        : null;
      setCustomerData(contactData);

    };
    loadData(id);
  }, [id]);

  const saveAsDraft = (e) => {
    formik.setFieldValue("public", false);
    formik.setFieldValue("draft", true);
    formik.handleSubmit(e);
  };

  const save = (e) => {
    formik.setFieldValue("public", true);
    formik.setFieldValue("draft", false);
    formik.handleSubmit(e);
  };

  const addElement = (refName, valueName) => {
    TagLikeHandler.addElement(formik, refName, valueName);
    setUpdateFreelancers(!updateFreelancers);
  };
  const deleteElement = (element, valueName, setData) => {
    TagLikeHandler.deleteElement(element, valueName, setData, formik);
    setUpdateFreelancers(!updateFreelancers);
  };

  const deleteLanguage = (language) => {
    TagLikeHandler.deleteElement(language, "languages", setLanguages, formik);
  };

  const updateContact = async (contact) => {
    const response = await Job.updateContact(formik.values, contact);
    if (response.status === 200) {
      const job = response.data;
      let contactData = job.person
        ? job.person
        : job.customer
        ? job.customer
        : null;
      setContactData(contactData);
    }
  };

  const updateCustomer = async (contact) => {
    const response = await Job.updateCustomer(formik.values, contact);
    if (response.status === 200) {
      const job = response.data;
      let contactData = job.client_person
        ? job.client_person
        : job.client_customer
        ? job.client_customer
        : null;
      setCustomerData(contactData);
    }
  };

  const PickContactData = () => {
    return (
      <Button
        onClick={(e) => {
          setContactFunction("contact");
          setShowCustomerModal(true);
        }}
      >
        Pick Contact Data
      </Button>
    );
  };

  const PickCustomerButton = () => {
    return (
      <Button
        onClick={(e) => {
          setContactFunction("customer");
          setShowCustomerModal(true);
        }}
      >
        Pick Customer Data
      </Button>
    );
  };

  const SaveButtons = () => {
    return (
      <>
        <SaveButton
          text={"Save as Draft"}
          handleClick={(e) => {
            saveAsDraft(e);
          }}
        />
        &nbsp;
        <SaveButton
          text={"Publish"}
          handleClick={(e) => {
            save(e);
          }}
        />
      </>
    );
  };

  const LanguageButton = () => {
    return (
      <AddLanguageButton
        formik={formik}
        setEditLanguage={setEditLanguage}
        setShowLanguageForm={setShowLanguageForm}
      />
    );
  };

  const contactUpdateWrapper = (contact) => {
    if (typeof contactFunction !== "undefined") {
      if (contactFunction === "contact") {
        updateContact(contact);
      }
      if (contactFunction === "customer") {
        updateCustomer(contact);
      }
    }
  };

  return editJob ? (
    <>
      <CustomerModal
        show={showCustomerModal}
        setShow={setShowCustomerModal}
        updateContact={contactUpdateWrapper}
      />
      <LanguageForm
        showEdit={showLanguageForm}
        setShowEdit={setShowLanguageForm}
        dataList={formik.values.languages}
        editLanguage={editLanguage}
        formik={formik}
        setDataList={setLanguages}
      />
      <JobHeader />
      <Content AdditionalButtons={SaveButtons}>
        <Row>
          <Col>
            <Card title={"Basics"}>
              <Row>
                <Col>
                  <TextField
                    formik={formik}
                    label={"Job title"}
                    valueName={"jobtitle"}
                    style={{ marginBottom: "10px" }}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <TextField
                    formik={formik}
                    label={"Contract Type"}
                    valueName={"contract_type"}
                    style={{ marginBottom: "10px" }}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Checkbox
                    formik={formik}
                    label={"On Site"}
                    valueName={"on_site"}
                    style={{ marginBottom: "10px" }}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <TextArea
                    formik={formik}
                    label={"Description"}
                    valueName={"description"}
                    style={{ marginBottom: "10px" }}
                  />
                </Col>
              </Row>
            </Card>
            <Card title={"Contact Data"} CardTools={PickContactData}>
              <Row>
                <Col>{contactData && <Address contact={contactData} />}</Col>
                <Col>
                  {contactData && <ContactData contact={contactData} />}
                </Col>
              </Row>
            </Card>
            <Card title={"Customer"} CardTools={PickCustomerButton}>
              <Row>
                <Col>{customerData && <Address contact={customerData} />}</Col>
                <Col>
                  {customerData && <ContactData contact={customerData} />}
                </Col>
              </Row>
            </Card>
            <Card title={"Dates"}>
              <Row>
                <Col>
                  <DateField
                    formik={formik}
                    label={"Start"}
                    valueName={"start"}
                    style={{ marginBottom: "10px" }}
                  />
                </Col>
                <Col>
                  <DateField
                    formik={formik}
                    label={"End"}
                    valueName={"end"}
                    style={{ marginBottom: "10px" }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <DateField
                    formik={formik}
                    label={"Publish from"}
                    valueName={"publish_from"}
                    style={{ marginBottom: "10px" }}
                  />
                </Col>
                <Col>
                  <DateField
                    formik={formik}
                    label={"Publish to"}
                    valueName={"publish_to"}
                    style={{ marginBottom: "10px" }}
                  />
                </Col>
              </Row>
            </Card>
            <Card title={"Rates and Times"}>
              <Row>
                <Col>
                  <NumberField
                    formik={formik}
                    label={"Total Hours"}
                    valueName={"max_hours"}
                    style={{ marginBottom: "10px" }}
                  />
                </Col>
                <Col>
                  <NumberField
                    formik={formik}
                    label={"Weekly Hours"}
                    valueName={"max_hours_per_week"}
                    style={{ marginBottom: "10px" }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <NumberField
                    formik={formik}
                    label={"Max Hourly Rate"}
                    valueName={"max_hourly_rate"}
                    style={{ marginBottom: "10px" }}
                  />
                </Col>
                <Col>
                  <NumberField
                    formik={formik}
                    label={"Max Daily Rate"}
                    valueName={"max_daily_rate"}
                    style={{ marginBottom: "10px" }}
                  />
                </Col>
              </Row>
            </Card>
            <Card title={"Must have Skills"}>
              <Row>
                <Col>
                  <ReferencedInputAndSubmit
                    namedReference={skillsRef}
                    onClick={() => {
                      addElement(skillsRef, "key_skills");
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  {keySkills.map((keySkill, index) => {
                    return (
                      <Tag
                        key={index}
                        keySkill={keySkill}
                        content={keySkill.name}
                        deleteFunction={() => {
                          deleteElement(keySkill, "key_skills", setKeySkills);
                        }}
                      />
                    );
                  })}
                </Col>
              </Row>
            </Card>
            <Card title={"Languages needed"} CardTools={LanguageButton}>
              <Table className="table table-striped projects">
                <tbody>
                  {languages
                    ? languages.map((language, index) => {
                        return (
                          <tr key={index}>
                            <td>{language.name}</td>
                            <td>{language.level} %</td>
                            <td>
                              <CompleteDropdown
                                deleteAction={() => {
                                  deleteLanguage(language);
                                }}
                                editAction={() => {
                                  setEditLanguage(language);
                                  setShowLanguageForm(true);
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </Table>
            </Card>
            <Card title={"Nice to have Skills"}>
              <Row>
                <Col>
                  <ReferencedInputAndSubmit
                    namedReference={niceSkillsRef}
                    onClick={() => {
                      addElement(niceSkillsRef, "other_skills");
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  {niceToHaveSkills.map((niceSkill, index) => {
                    return (
                      <Tag
                        key={index}
                        item={niceSkill}
                        content={niceSkill.name}
                        deleteFunction={() => {
                          deleteElement(
                            niceSkill,
                            "other_skills",
                            setNiceToHaveSkills
                          );
                        }}
                      />
                    );
                  })}
                </Col>
              </Row>
            </Card>
            <Card title={"Your Job"}>
              <Row>
                <Col>
                  <ReferencedInputAndSubmit
                    namedReference={tasksRef}
                    onClick={() => {
                      addElement(tasksRef, "to_do");
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  {tasks.map((task, index) => {
                    return (
                      <Tag
                        key={index}
                        item={task}
                        content={task.name}
                        deleteFunction={() => {
                          deleteElement(task, "to_do", setTaks);
                        }}
                      />
                    );
                  })}
                </Col>
              </Row>
            </Card>
            <Card title={"We offer"}>
              <Row>
                <Col>
                  <ReferencedInputAndSubmit
                    namedReference={weOfferRef}
                    onClick={() => {
                      addElement(weOfferRef, "we_offer");
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  {weOffer.map((offer, index) => {
                    return (
                      <Tag
                        key={index}
                        item={offer}
                        content={offer.name}
                        deleteFunction={() => {
                          deleteElement(offer, "we_offer", setWeOffer);
                        }}
                      />
                    );
                  })}
                </Col>
              </Row>
            </Card>
          </Col>
          <Col style={{ maxWidth: "30%" }}>
            <Freelancers
              jobId={editJob.id}
              updateFreelancers={updateFreelancers}
            />
          </Col>
        </Row>
      </Content>
    </>
  ) : null;
};

export default Edit;
