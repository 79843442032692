import { useState } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faAdd } from "@fortawesome/free-solid-svg-icons";
import Address from "./Address";
import CustomerModal from "../../../../crm/Shared/CustomerModal";
import Finance from "@serie3/freelancesplaceapi/Domain/Billing/Outgoing/Finance";
import FinanceForm from "@serie3/freelancesplaceapi/Form/Billing/FinanceForm";

const BillingHeaderLeft = ({
  bill,
  contact,
  setContact,
  setAddress,
  addressJson = "{}",
  formik
}) => {
  const [showCustomers, setShowCustomers] = useState(false);
  const AddDeleteCustomerButtons = () => {
    if (typeof contact !== "undefined" && typeof contact.id !== "undefined") {
      return (
        <>
          <Button onClick={() => setShowCustomers(true)}>
            Change &nbsp;
            <strong>
              <FontAwesomeIcon icon={faAdd} />
            </strong>
          </Button>
          &nbsp;
          <Button variant="danger" onClick={() => setContact()}>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </>
      );
    }

    return (
      <Button onClick={() => setShowCustomers(true)}>
        Add &nbsp;
        <strong>
          <FontAwesomeIcon icon={faAdd} />
        </strong>
      </Button>
    );
  };

  const updateContact = async (contact) => {
    const response = await Finance.updateContact(bill, contact, setAddress);
    if(response.status === 200 && typeof formik !== "undefined"){
      formik.setValues(FinanceForm.setEditData(response.data));
    }
  };

  return (
    <>
      <CustomerModal
        show={showCustomers}
        setShow={setShowCustomers}
        updateContact={updateContact}
      />
      <Row>
        <Col>
          <Form.Group className="form-inline" controlId="rechnungs_nummer">
            <Form.Label>Billingnumber: &nbsp;</Form.Label>
            <Form.Control
              type="text"
              name="rechnungs_nummer"
              placeholder="rechnungs_nummer"
              htmlSize={5}
              value={formik.values.rechnungs_nummer}
              onChange={formik.handleChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <Address addressJson={addressJson} />
          <Row>
            <Col>
              <AddDeleteCustomerButtons />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default BillingHeaderLeft;
