import crudApi from "@serie3/common/api/crudApi";
import JobConfig from "../../Config/Projects/JobConfig";

const updateContact = async (job, contact, setContact) => {
  let payload = { id: job["id"],  jobtitle: job["jobtitle"] };

  if (
    contact.hasOwnProperty("anrede") &&
    contact.hasOwnProperty("geschlecht")
  ) {
    payload.person = {
      id: contact.id,
    };
    payload.customer = null;
  } else {
    payload.customer = {
      id: contact.id,
    };
    payload.person = null;
  }

  payload = JSON.stringify(payload, null, 2);
  return await crudApi.edit(JobConfig.endpoints.edit, payload);
};

const updateCustomer = async (job, contact, setContact) => {
  let payload = { id: job["id"],  jobtitle: job["jobtitle"] };

  if (
    contact.hasOwnProperty("anrede") &&
    contact.hasOwnProperty("geschlecht")
  ) {
    payload.client_person = {
      id: contact.id,
    };
    payload.client_customer = null;
  } else {
    payload.client_customer = {
      id: contact.id,
    };
    payload.client_person = null;
  }

  payload = JSON.stringify(payload, null, 2);
  return await crudApi.edit(JobConfig.endpoints.edit, payload);
};

export default { updateContact, updateCustomer };
