import BillingSettingsForm from "@serie3/freelancesplaceapi/Form/Billing/BillingSettingsForm";
import FinanceConfig from "@serie3/freelancesplaceapi/Config/Finance/FinanceConfig";
import { useEffect, useState } from "react";
import crudApi from "@serie3/common/api/crudApi";
import { Button, Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import TextField from "../../../../../layout/Form/Fields/TextField";
import TextArea from "../../../../../layout/Form/Fields/TextArea";
import Card from "../../../../../layout/Cards/Card";
import NumberField from "../../../../../layout/Form/Fields/NumberField";
import SaveButton from "../../../../../layout/Buttons/SaveButton";

const BillingSettings = () => {
  const [billingSettings, setBillingSettings] = useState();

  const billingSettingsForm = BillingSettingsForm.createBillingSettingsForm();
  const formik = useFormik(billingSettingsForm);

  const loadSettings = async () => {
    const settings = await crudApi.show(
      FinanceConfig.endpoints.settings.show,
      "/"
    );

    if (Object.keys(settings).length > 0) {
      setBillingSettings(BillingSettingsForm.setEditData(settings));
      formik.setValues(BillingSettingsForm.setEditData(settings));
    }
  };

  const createNewSettings = async () => {
    const response = await crudApi.createNew(
      FinanceConfig.endpoints.settings.new,
      {}
    );
    if (response.status === 200) {
      loadSettings();
    }
  };

  useEffect(() => {
    loadSettings();
  }, []);

  const NewButton = () => {
    return (
      <Button onClick={createNewSettings}>Setup your billing settings</Button>
    );
  };

  const Save = () => {
    return (
      <SaveButton
        handleClick={(e) => {
          formik.handleSubmit(e);
          e.preventDefault();
        }}
      />
    );
  };

  return billingSettings ? (
    <Card CardTools={Save}>
      <Row>
        <Col>
          <TextArea
            formik={formik}
            label={"Comapany Address"}
            valueName={"company_address"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextArea
            formik={formik}
            label={"Pre Text"}
            valueName={"pre_text"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextArea
            formik={formik}
            label={"Post Text"}
            valueName={"post_text"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <NumberField
            formik={formik}
            label={"Payment Deadline in Days"}
            valueName={"payment_deadline"}
            style={{ marginBottom: "10px" }}
            step={1}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            formik={formik}
            label={"IBAN"}
            valueName={"iban"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            formik={formik}
            label={"Bic"}
            valueName={"bic"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            formik={formik}
            label={"Bank Name"}
            valueName={"bank_name"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            formik={formik}
            label={"Email"}
            valueName={"email"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            formik={formik}
            label={"Website"}
            valueName={"website"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            formik={formik}
            label={"Telefon"}
            valueName={"phone"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            formik={formik}
            label={"Tax number"}
            valueName={"taxnumber"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            formik={formik}
            label={"Tax ID"}
            valueName={"tax_id"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
      </Row>
    </Card>
  ) : (
    <NewButton />
  );
};

export default BillingSettings;
