import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { Button, Row, Col } from "react-bootstrap";

const Timeline = ({ addFunction, children, style, showAddButton = true }) => {
  const AddEduButton = () => {
    return (
      <Button className="success" onClick={addFunction}>
        Add &nbsp;
        <FontAwesomeIcon icon={faAdd} />
      </Button>
    );
  };

  const dummyfunction = () => {};

  return (
    <>
      {showAddButton ? (
        <>
          <Row>
            <Col className="text-end">
              <AddEduButton />
            </Col>
          </Row>
          <br />
        </>
      ) : null}
      <Row>
        <Col>
          <div className="timeline timeline-inverse" style={{ ...style }}>
            {children}
            <div>
              <i className="fas fa-clock bg-gray"></i>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Timeline;
