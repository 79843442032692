
import Index from "./content/Index"
import Edit from "./content/Edit";

import { Routes, Route } from "react-router-dom";

const DocumentsRoutes = () => {
  return (
    <Routes>
         <Route path="/" element={<Index />} />
         <Route path="/edit/:id" element={<Edit />} />
    </Routes>
  );
};

export default DocumentsRoutes;
