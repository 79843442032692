const LandingpageFooter = () => {
  return (
    <footer className="bg-dark text-center text-white py-3">
      <div className="container">
        <small>
          <a href="/imprint" className="text-white">
            Imprint
          </a>          |
          <a href="/privacy" className="text-white">
            Privacy policy
          </a>
        </small>
      </div>
    </footer>
  );
};

export default LandingpageFooter;
