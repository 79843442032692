import React from "react";
import ContentHeader from "../../../layout/ContenteHeader";
import { Row, Col } from "react-bootstrap";

const DocumentsHeader = () => {
  return (
    <ContentHeader>
    </ContentHeader>
  );
};

export default DocumentsHeader;
