import { Form } from "react-bootstrap";

const NumberField = ({ formik, formClass, label, valueName, style, step="0.01" }) => {
  return (
    <Form.Group
      className={formClass ? formClass : "form-inline"}
      controlId={valueName}
      style={style}
    >
      <Form.Label>{label}:</Form.Label>
      <Form.Control
        type="number"
        name={valueName}
        step={step}
        placeholder={0}
        value={formik.values[valueName]}
        onChange={formik.handleChange}
        style={{ width: "100%" }}
      />
    </Form.Group>
  );
};

export default NumberField;
