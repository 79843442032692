import { Routes, Route } from "react-router-dom";
import Index from "../Shared/Index";
import Edit from "./Edit";


const PeoplesRoutes = () => {
  return (
    <Routes>
         <Route path="/" element={<Index />} />
         <Route path="/edit/:id" element={<Edit />} />
    </Routes>
  );
};

export default PeoplesRoutes;
