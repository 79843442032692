import Education from "../../Entity/Profile/Education";
import * as Yup from "yup";
import CrudApi from "@serie3/common/api/crudApi";
import ProfileConfig from "../../Config/Profiles/ProfileConfig";
import listHandler from "@serie3/common/Domain/listHandler";

const createEducationForm = (
  dataList,
  setDataFunction,
  showErrorFunction,
  setShowEdit
) => {
  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    vorname: Yup.string().required("Required"),
    sperrgrund: Yup.string().when("gesperrt", {
      is: true,
      then: Yup.string().required(
        "You must add a reason why this company is blocked"
      ),
    }),
  });

  const onSubmit = async (values, actions) => {
    if (values.id > 0) {
      values.start = values.start.split("T")[0];
      values.ende = values.ende ? values.ende.split("T")[0] : null;
      let payload = JSON.stringify(values, null, 2);
      const response = await CrudApi.edit(
        ProfileConfig.endpoints.education.edit,
        payload
      );
      listHandler.updateList(
        response,
        payload,
        dataList,
        setDataFunction,
        showErrorFunction
      );
    } else {
      values.start = values.start.split("T")[0];
      values.ende = values.ende ? values.ende.split("T")[0]: null;
      let payload = JSON.stringify(values, null, 2);
      const response = await CrudApi.createNew(
        ProfileConfig.endpoints.education.new,
        payload
      );
      listHandler.newToList(
        response,
        dataList,
        setDataFunction,
        showErrorFunction
      );
    }

    setShowEdit(false);
    actions.resetForm(Address);
  };

  return {
    initialValues: Education,
    onSubmit: (values, actions) => {
      onSubmit(values, actions);
    },
  };
};

const setEditData = (editData) => {
  const date = new Date();
  let Profile = {
    id: editData.id,
    name: editData.name,
    subject_area: editData.subject_area,
    graduation: editData.graduation,
    start: editData.start
      ? editData.start.split("T")[0]
      : date.toISOString().split("T")[0],
    ende: editData.ende
      ? editData.ende.split("T")[0]
      : date.toISOString().split("T")[0],
    description: editData.description,
    profile: editData.profile,
  };

  return Profile;
};

export default { createEducationForm, setEditData };
