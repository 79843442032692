import ReferencedInputAndSubmit from "../../../../../layout/Form/Fields/ReferencedInputAndSubmit";
import TagLikeHandler from "@serie3/freelancesplaceapi/Form/shared/TagLikeHandler";
import Tag from "../../../../../layout/Buttons/Tag";
import { useRef } from "react";
import { Col, Row } from "react-bootstrap";

const ActionTags = ({ formik, allTags, setAllTags }) => {
  const tagRef = useRef(null);

  const addElement = (refName, valueName) => {
    TagLikeHandler.addElement(formik, refName, valueName, true);
    const tmpArray = [...formik.values.tags];
    setAllTags(tmpArray);
  };

  const deleteElement = (element, valueName, setData) => {
    TagLikeHandler.deleteElement(element, valueName, setData, formik, true);
    const tmpArray = [...formik.values.tags];
    setAllTags(tmpArray);
  };

  const setTags = () => {};
  
  return (
    <>
      <Row>
        <ReferencedInputAndSubmit
          namedReference={tagRef}
          onClick={() => {
            addElement(tagRef, "tags");
          }}
        />
      </Row>
      <Row>
        <Col>
          {allTags.length > 0
            ? allTags.map((tag, index) => {
                return (
                  <Tag
                    key={index}
                    item={tag}
                    content={tag.name}
                    deleteFunction={() => {
                      deleteElement(tag, "tags", setTags);
                    }}
                  />
                );
              })
            : null}
        </Col>
      </Row>
    </>
  );
};

export default ActionTags;
