import ErrorPopup from "../../../../layout/ErrorPopup";
import NewModal from "../../documents/modals/NewModal";
import { Col, Row } from "react-bootstrap";
import TextField from "../../../../layout/Form/Fields/TextField";
import TextArea from "../../../../layout/Form/Fields/TextArea";
import { useFormik } from "formik";
import { useState } from "react";
import DateField from "../../../../layout/Form/Fields/DateField";
import SaveButton from "../../../../layout/Buttons/SaveButton";
import WorkexperienceForm from "@serie3/freelancesplaceapi/Form/Profile/WorkexperienceForm";
import Workexperience from "@serie3/freelancesplaceapi/Entity/Profile/Workexperience";

const WorkexperienceFormView = ({
  showEdit,
  setShowEdit,
  editData,
  profileId,
  dataList,
  setDataList,
  showErrorFunction,
}) => {
  const [showError, setShowError] = useState(false);

  const editWorkexperienceForm = WorkexperienceForm.createWorkexperienceForm(
    dataList,
    setDataList,
    showErrorFunction,
    setShowEdit
  );
  const formik = useFormik(editWorkexperienceForm);

  const Save = () => {
    return (
      <SaveButton
        handleClick={(e) => {
          formik.handleSubmit(e);
          e.preventDefault();
        }}
      />
    );
  };

  return (
    <>
      <ErrorPopup setShowError={setShowError} showError={showError}>
        ... Sorry! Something went wrong
        <br />
        <br />
        <strong>
          If you think this is a bug and we screwed up plz contact support!
        </strong>
      </ErrorPopup>

      <NewModal
        show={showEdit}
        setShow={setShowEdit}
        ExtraButton={Save}
        onShow={() => {
          editData
            ? formik.setValues(WorkexperienceForm.setEditData(editData))
            : formik.setValues(Workexperience);
         
          formik.setFieldValue("profile.id", profileId);
        }}
      >
        <Row>
          <Col>
            <TextField
              formik={formik}
              label={"Position name"}
              valueName={"position_name"}
              style={{ marginBottom: "10px" }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <DateField
              formik={formik}
              label={"start"}
              valueName={"start"}
              style={{ marginBottom: "10px" }}
            />
          </Col>
          <Col>
            <DateField
              formik={formik}
              label={"ende"}
              valueName={"ende"}
              style={{ marginBottom: "10px" }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              formik={formik}
              label={"Position Type (e.g. freelance/contractor)"}
              valueName={"position_type"}
              style={{ marginBottom: "10px" }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              formik={formik}
              label={"Company"}
              valueName={"company"}
              style={{ marginBottom: "10px" }}
            />
          </Col>
          <Col>
            <TextField
              formik={formik}
              label={"Headcount"}
              valueName={"headcount"}
              style={{ marginBottom: "10px" }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              formik={formik}
              label={"Business"}
              valueName={"business"}
              style={{ marginBottom: "10px" }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextArea
              formik={formik}
              label={"Description"}
              valueName={"description"}
              style={{ marginBottom: "10px" }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextArea
              formik={formik}
              label={"Used technologies"}
              valueName={"type_of_business"}
              style={{ marginBottom: "10px" }}
            />
          </Col>
        </Row>

      </NewModal>
    </>
  );
};

export default WorkexperienceFormView;
