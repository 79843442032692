const TabGroupLeftVerticalBody = ({ children }) => {
  return (
    <div className="col-7 col-sm-9">
      <div className="tab-content" id="vert-tabs-tabContent">
        {children}
      </div>
    </div>
  );
};

export default TabGroupLeftVerticalBody;
