const JobConfig = {
  endpoints: {
    getAll: "/jobs/",
    new: "/jobs/",
    edit: "/jobs/edit",
    show: "/jobs/show/",
    delete: "/jobs/delete/",
    matchingProfiles: "/jobs/profiles/",

  },
};
export default JobConfig;
