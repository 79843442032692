import crudApi from "@serie3/common/api/crudApi";
import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import VerifyEmailConfig from "@serie3/freelancesplaceapi/Config/User/VerifyEmailConfig";
import FreelancerserplaceNavbar from "./SubComponents/FreelancerserplaceNavbar";

const VerifyEmail = ({setToken}) => {
  const [registerMessage, setRegisterMessage] = useState(null);

  useEffect(() => {
    const verifyData = async () => {
        const endpoint = VerifyEmailConfig.endpoints.verify + window.location.search;
        const response = await crudApi.justGet(endpoint);
        if(response.status === 200){
            setRegisterMessage("Your account has been enabled!");
        }else{
            setRegisterMessage("There was a Problem, please contact support@freelancersplace.net!");
        }
    }
    verifyData();
  }, []);

  return (
    <>
      <FreelancerserplaceNavbar setToken={setToken} />
      <br />
      <Row className="justify-content-md-center">
        <br />
        <Col md={12} style={{textAlign: "center"}}>
            {registerMessage}
        </Col>
      </Row>
    </>
  );
};

export default VerifyEmail;
