import { Routes, Route } from "react-router-dom";
import Index from "./content/Index";

const TimetrackingRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Index />} />
    </Routes>
  );
};

export default TimetrackingRoutes;
