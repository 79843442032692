const Project = {
    finance: [],
    project_addresses: [],
    tags: [],
    title: '',
    description: '',
    start: '',
    end: '',
    max_hours: 0,
    daily_hours: 0,
    documents: [],
    done: 0,
    original_id: '',
    description: '',
    original_link: '',
    last_update: new Date(),
    quicklinkList: false, 
    show_in_dashboard: false
}
export default Project;