import React from "react";
import ListElement from "../../navbars/elements/ListElement";
import ListHeader from "../../navbars/elements/ListHeader";
import SubList from "../../navbars/elements/SubList";
import {
  faBuilding,
  faUsers,
  faPerson,
  faTasks,
} from "@fortawesome/free-solid-svg-icons";

const CrmSideNav = () => {
  return (
    <>
      <ListHeader title="Crm" link="/crm/settings"/>
      <ListElement name="Contacts" icon={faUsers} topElement={true}>
        <SubList>
          <ListElement
            name="Companies"
            linkStyle="far nav-icon"
            targetUrl="/crm/companies"
            icon={faBuilding}
          />
          <ListElement
            name="People"
            linkStyle="far nav-icon"
            targetUrl="/crm/people"
            icon={faPerson}
          />
        </SubList>
      </ListElement>
      <ListElement name="Tasks" targetUrl="/crm/actions" icon={faTasks} />
    </>
  );
};

export default CrmSideNav;
