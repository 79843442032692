import { useLocation } from "react-router-dom";
import ContentHeader from "../../../../../layout/ContenteHeader";

const ProjectsHeader = ({project}) => {

    const url = useLocation();

    return (url.pathname === '/project/') ? (
        <ContentHeader >
            <b>Projects:  </b>
        </ContentHeader>
    ): (
        <ContentHeader >
            <b>Project: {project.title ? project.title: null} </b>
        </ContentHeader>
    );

}

export default ProjectsHeader