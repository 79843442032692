import React from "react";
import ListElement from "../../navbars/elements/ListElement";
import { faUser, faBullseye } from "@fortawesome/free-solid-svg-icons";

const ProfilesSideNav = () => {
  return (
    <>
      <li className="nav-header">Yours</li>
      <ListElement
        name="Profiles"
        linkStyle="far nav-icon"
        targetUrl="/profile/"
        icon={faUser}
      />
      <ListElement
        name="Applications"
        linkStyle="far nav-icon"
        targetUrl="/profile/applications"
        icon={faBullseye}
      />
    </>
  );
};

export default ProfilesSideNav;
