import CompanyView from "./Companies/CompanyView";
import PeopleView from "./People/PeopleView";
import { useLocation } from "react-router-dom";

const ViewWrapper = ({ contact }) => {
  const url = useLocation();

  return url.pathname === "/crm/people" ? (
    <PeopleView contact={contact} />
  ) : (
    <CompanyView contact={contact} />
  );
};

export default ViewWrapper;
