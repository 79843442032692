import { initializeApp } from "firebase/app";
import firebaseConfig from "@serie3/freelancesplaceapi/Config/Firebase/firebase";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import ReferencedInputAndSubmit from "../layout/Form/Fields/ReferencedInputAndSubmit";
import { useRef, useState } from "react";
import { Modal } from "react-bootstrap";

const Forgot = ({ show, setShow }) => {
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const emailFieldRef = useRef(null);
  const [showForm, setShowForm] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const resetPassword = () => {
    const email = emailFieldRef.current.value;
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setShowForm(true);
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setShowError(true);
      });
  };
  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => setShow(false)}
      dialogClassName="modal-80w"
      style={{
        content: {
          position: "absolute",
          top: "40px",
          left: "40px",
          right: "40px",
          bottom: "40px",
          border: "10px solid #ccc",
          background: "#fff",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          borderRadius: "4px",
          outline: "none",
          padding: "20px",
        },
      }}
    >
      <Modal.Header closeButton={true} style={{ border: "0px" }} />
      <Modal.Body
        style={{ textAlign: "center", padding: "2.5rem 3.5rem 3.5rem 4.5rem" }}
      >
        <h3 style={{ marginBottom: "2rem" }}>
          <b>Forgot password?</b>
        </h3>

        {showForm ? (
          "Please check your email"
        ) : (
          <>
            Enter your E-Mailadress:
            <br />
            <br />
            <ReferencedInputAndSubmit
              namedReference={emailFieldRef}
              onClick={resetPassword}
              label={"Request new password"}
            />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};
export default Forgot;
