import { Col, Row } from "react-bootstrap";
import NewModal from "../../../documents/modals/NewModal";
import KeyValueForm from "@serie3/freelancesplaceapi/Form/shared/KeyValueForm";
import KeyValue from "@serie3/freelancesplaceapi/Entity/shared/KeyValue";
import { useFormik } from "formik";
import SaveButton from "../../../../../layout/Buttons/SaveButton";
import CrmConfig from "@serie3/freelancesplaceapi/Config/Crm/CrmConfig";
import TextArea from "../../../../../layout/Form/Fields/TextArea";

const CommentsForm = ({
  showEdit,
  setShowEdit,
  editComments,
  companyId,
  personId,
  dataList,
  setCommentsData,
  showErrorFunction,
}) => {
  const editMediaForm = KeyValueForm.createKeyValueForm(
    dataList,
    setCommentsData,
    showErrorFunction,
    setShowEdit,
    CrmConfig.comments.endpoints.new,
    CrmConfig.comments.endpoints.edit
  );
  const formik = useFormik(editMediaForm);

  const Save = () => {
    return (
      <SaveButton
        handleClick={(e) => {
          formik.handleSubmit(e);
          e.preventDefault();
        }}
      />
    );
  };

  return (
    <NewModal
      show={showEdit}
      setShow={setShowEdit}
      ExtraButton={Save}
      onShow={() => {
        editComments
          ? formik.setValues(KeyValueForm.setEditData(editComments))
          : formik.setValues(KeyValue);
        companyId && formik.setFieldValue("kunde.id", companyId);
        personId && formik.setFieldValue("person.id", personId);
        formik.setFieldValue("key_name", "Comment");
      }}
    >
      <Row>
        <Col>
          <TextArea
            formik={formik}
            label={"Comment"}
            valueName={"value"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
      </Row>
    </NewModal>
  );
};

export default CommentsForm;
