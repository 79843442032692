import { Form, Row, Col, Table, Button } from "react-bootstrap";
import TextField from "../../../../layout/Form/Fields/TextField";
import TextArea from "../../../../layout/Form/Fields/TextArea";
import Checkbox from "../../../../layout/Form/Fields/Checkbox";
import DateField from "../../../../layout/Form/Fields/DateField";
import Card from "../../../../layout/Cards/Card";
import Tag from "../../../../layout/Buttons/Tag";
import TagLikeHandler from "@serie3/freelancesplaceapi/Form/shared/TagLikeHandler";
import { useRef, useState } from "react";
import ReferencedInputAndSubmit from "../../../../layout/Form/Fields/ReferencedInputAndSubmit";
import LanguageForm from "../../../../layout/Form/LanguageForm";
import CompleteDropdown from "../../../../layout/Dropdowns/CompleteDropdown";
import AddLanguageButton from "../../../../layout/Buttons/AddLanguageButton";

const ProfileBasics = ({
  formik,
  SaveButton,
  profile,
  searchingFor,
  setSearchingFor,
  languages,
  setLanguages,
}) => {
  const searchingInputRef = useRef(null);
  const [showLanguageForm, setShowLanguageForm] = useState(false);
  const [editLanguage, setEditLanguage] = useState();

  const addSearchingFor = () => {
    TagLikeHandler.addElement(formik, searchingInputRef, "_searching");
  };

  const deleteLookingFor = (searching) => {
    TagLikeHandler.deleteElement(
      searching,
      "_searching",
      setSearchingFor,
      formik
    );
  };

  const deleteLanguage = (language) => {
    TagLikeHandler.deleteElement(language, "languages", setLanguages, formik);
  };

  const LanguageButton = () => {
    return (
      <AddLanguageButton
        formik={formik}
        setEditLanguage={setEditLanguage}
        setShowLanguageForm={setShowLanguageForm}
      />
    );
  };

  return (
    <>
      <LanguageForm
        formik={formik}
        dataList={formik.values.languages}
        setDataList={setLanguages}
        editLanguage={editLanguage}
        showEdit={showLanguageForm}
        setShowEdit={setShowLanguageForm}
      />
      <Form>
        {!profile.system_profile ? (
          <Card title={"Basics"} CardTools={SaveButton}>
            <Row>
              <Col>
                <TextField
                  formik={formik}
                  label={"Profile title"}
                  valueName={"profiletitle"}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <TextField
                  formik={formik}
                  label={"Downloadname"}
                  valueName={"title"}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <TextArea
                  formik={formik}
                  label={"Mantra"}
                  valueName={"mantra"}
                />
              </Col>
            </Row>
          </Card>
        ) : null}
        <Card title={"Personal"} CardTools={SaveButton}>
          <Row>
            <Col>
              <TextField
                formik={formik}
                label={"First Name"}
                valueName={"vorname"}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <TextField formik={formik} label={"Name"} valueName={"name"} />
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col>
              <DateField
                formik={formik}
                label={"Birthdate"}
                valueName={"birthdate"}
              />
            </Col>
            <Col className="align-bottom">
              <Checkbox
                formik={formik}
                label={"Publish Birthdate"}
                valueName={"birthdate_public"}
              />
            </Col>
          </Row>
        </Card>
        <br />
        <Card title={"Address"} CardTools={SaveButton}>
          <Row>
            <Col>
              <TextField
                formik={formik}
                label={"Street"}
                valueName={"street"}
              />
            </Col>
            <Col>
              <TextField
                formik={formik}
                label={"Number"}
                valueName={"housenumber"}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <TextField
                formik={formik}
                label={"Postcode"}
                valueName={"postcode"}
              />
            </Col>
            <Col>
              <TextField formik={formik} label={"City"} valueName={"city"} />
            </Col>
          </Row>
        </Card>
      </Form>
      {setLanguages ? (
        <Card title={"Languages"} CardTools={LanguageButton}>
          <Table className="table table-striped projects">
            <tbody>
              {languages
                ? languages.map((language, index) => {
                    return (
                      <tr key={index}>
                        <td>{language.name}</td>
                        <td>{language.level} %</td>
                        <td>
                          <CompleteDropdown
                            deleteAction={() => {
                              deleteLanguage(language);
                            }}
                            editAction={() => {
                              setEditLanguage(language);
                              setShowLanguageForm(true);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </Table>
        </Card>
      ) : null}
      {setSearchingFor ? (
        <Card title={"Looking for"}>
          <Row>
            <Col>
              <ReferencedInputAndSubmit
                namedReference={searchingInputRef}
                onClick={addSearchingFor}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              {profile._searching
                ? profile._searching.map((searching, index) => {
                    return (
                      <Tag
                        key={index}
                        index={index}
                        item={searching}
                        content={searching.name}
                        deleteFunction={deleteLookingFor}
                      />
                    );
                  })
                : null}
            </Col>
          </Row>
        </Card>
      ) : null}
    </>
  );
};

export default ProfileBasics;
