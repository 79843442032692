import NewModal from "../../documents/modals/NewModal";
import { Row, Col } from "react-bootstrap";
import ApplicationForm from "@serie3/freelancesplaceapi/Form/Profile/ApplicationForm";
import TextField from "../../../../layout/Form/Fields/TextField";
import Application from "@serie3/freelancesplaceapi/Entity/Profile/Application";  

const ApplicationFormModal = ({ application, showView, setShowView, formik, SaveButton }) => {

  return application ? (
    <NewModal
      show={showView}
      setShow={setShowView}
      title={formik.values["job_title"]}
      ExtraButton ={SaveButton}
      onShow={() => {
        application
          ? formik.setValues(ApplicationForm.setEditData(application))
          : formik.setValues(Application);
      }}
    >
      <Row>
        <Col>
          <TextField
            formik={formik}
            label={"Job Title"}
            valueName={"job_title"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
        <Col>
          <TextField
            formik={formik}
            label={"Hireing company"}
            valueName={"hireing_company"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            formik={formik}
            label={"page"}
            valueName={"page"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            formik={formik}
            label={"link"}
            valueName={"link"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            formik={formik}
            label={"location"}
            valueName={"location"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
      </Row>
    </NewModal>
  ) : null;
};

export default ApplicationFormModal;
