import apiClient from "@serie3/common/api/client";
import ToastMessages from "@serie3/freelancesplaceapi/Domain/Shared/ToastMessages";


const showToasts = (response) => {
  switch(response.status){
    case 200:
      ToastMessages.SuccessfulSave();
      break;
    case 401:
      ToastMessages.Error('Session timed out');
      break;
    case 403:
      ToastMessages.NotAllowed('The bill has already been sent - no more changes allowed');
      break;
    default:
      ToastMessages.Error();
  }
}

const createNew = async (data) => {
  const response = await apiClient.post("finance/positions/", data);
  showToasts(response);
  return response;
};

const edit = async (data) => {
  const response = await apiClient.put("finance/positions/edit", data);
  showToasts(response);
  return response;
};

const del = async (id) => {
  const response = await apiClient.delete("finance/positions/delete/" + id);
  showToasts(response);
  return response;
};

export default { edit, del, createNew };
