import { React, useEffect, useState } from "react";
import Content from "../../../../../layout/Content";
import { Button, Row, Col, Form } from "react-bootstrap";
import billingApi from "@serie3/freelancesplaceapi/api/finance/out";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import PositionRow from "./PositionRow";
import PositionModal from "../../modals/PositionEdit";
import BillingHeaderRight from "./SubComponents/BillHeaderRight";
import BillingHeaderLeft from "./SubComponents/BillHeaderLeft";
import { useParams } from "react-router-dom";
import FinanceHeader from "../../FinanceHeader";
import listHandler from "@serie3/common/Domain/listHandler";
import PositionView from "../../modals/PositionView";
import PositionRowHeader from "./RowHeader";
import BillSummary from "./BillSummary";
import { useFormik } from "formik";
import FinanceForm from "@serie3/freelancesplaceapi/Form/Billing/FinanceForm";
import Finance from "@serie3/freelancesplaceapi/Domain/Billing/Outgoing/Finance";
import IndexButtons from "./SubComponents/IndexButtons";
import BillView from "../../modals/BillView";
import BillOut from "../../BillOut";
import { useNavigate } from "react-router-dom";
import FinanceApi from "@serie3/freelancesplaceapi/api/finance/out";
import FileDropzone from "../../../documents/modals/FileDropzone";
import NewModal from "../../../documents/modals/NewModal";
import DocumentConnectionButtons from "../../../../../layout/Documents/DocumentConnectionButtons";
import ConnectedFiles from "../../../../../layout/Documents/ConnectedFiles";
import CheckboxFields from "../shared/Form/FormFields/Checkboxes";
import Fields from "../shared/Form/FormFields/Fields";
import SubmitButtons from "../shared/Form/FormFields/Submitbuttons";
import getSettings from "@serie3/common/config/settings";
import PaymentModal from "../../../../../layout/Payment/PaymentModal";

const Edit = ({ type, direction, titleText }) => {
  const [showRowEdit, setShowRowEdit] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [contact, setContact] = useState();
  const [positionen, setPositionen] = useState();
  const [editPosition, setEditPosition] = useState();
  const [showRowError, setShowRowError] = useState(false);
  const [showPosition, setShowPosition] = useState(false);
  let { id } = useParams();
  const [bill, setBill] = useState();
  const [address, setAddress] = useState();
  const [openDebitDate, setOpenDebitDate] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [paymentDeadline, setPaymentDeadline] = useState(new Date());
  const [showBill, setShowBill] = useState(false);
  const [reloadConnectedFileView, setReloadConnectedFileView] = useState(0);
  const [showPayModal, setShowPayModal] = useState(false);
  const [settings, setSettings] = useState();

  const editOutgoingBillForm = FinanceForm.createFinanceForm(type, direction);
  const formik = useFormik(editOutgoingBillForm);
  const navigate = useNavigate();

  useEffect(() => {
    const loadData = async (id) => {
      if (typeof id !== "undefined") {
        const bill = await billingApi.showBill(type, direction, id);
        setBill(bill);
        setPositionen(bill.positionen);
        setAddress(bill.fixed_address);
        formik.setValues(FinanceForm.setEditData(bill));
        let paymentDueDate = Finance.calculatePaymentDeadline(
          bill.datum,
          bill.payment_due_days
        );
        setPaymentDeadline(paymentDueDate);
        setIsLoaded(true);
      }
    };
    loadData(id);
    setSettings(getSettings());
  }, [id]);

  const viewBill = () => {
    setShowBill(true);
  };

  const deleteFinance = async () => {
    const response = await FinanceApi.del(bill.id);
    if (response.status === 200) {
      navigate("/finance/" + direction + "/" + type);
    } else {
      alert("Error while deleting");
    }
  };

  const Buttons = (draft) => {
    return (
      <>
        <DocumentConnectionButtons
          showUpload={setShowNew}
          showLink={setShowNew}
        />
        <IndexButtons
          setShow={setShowBill}
          viewBill={viewBill}
          bill={bill}
          direction={direction}
          type={type}
          deleteFunction={deleteFinance}
          dropDownType="button"
          style={{ float: "right" }}
        />
      </>
    );
  };

  const Title = () => {
    return (
      <>
        <b>Finance: &nbsp; </b> {titleText}
      </>
    );
  };

  const deleteFromList = async (index, apiCall) => {
    listHandler.deleteFromList(
      index,
      apiCall,
      setPositionen,
      positionen,
      setShowRowError
    );
  };

  const editRow = (position) => {
    setEditPosition(position);
    setShowRowEdit(true);
  };

  const viewPosition = (position) => {
    setEditPosition(position);
    setShowPosition(true);
  };

  return !isLoaded ? (
    <></>
  ) : (
    <>
      {settings && (
        <PaymentModal
          show={showPayModal}
          setShow={setShowPayModal}
          priceTableId={settings.pricingTables.document}
        />
      )}
      <BillView show={showBill} bill={bill} setShow={setShowBill}>
        <BillOut bill={bill} />
      </BillView>
      <PositionModal
        show={showRowEdit}
        setShow={setShowRowEdit}
        financeId={id}
        position={editPosition}
        dataList={positionen}
        setDataFunction={setPositionen}
        showErrorFunction={setShowRowError}
      />

      <NewModal show={showNew} setShow={setShowNew}>
        <FileDropzone
          uploadUrl={"/document/finance/" + bill.id}
          setData={() => {
            setReloadConnectedFileView(Math.random());
          }}
          setShowPayModal={setShowPayModal}
        />
      </NewModal>

      <PositionView
        show={showPosition}
        setShow={setShowPosition}
        position={editPosition}
      />

      <FinanceHeader />
      <Content Title={Title} AdditionalButtons={Buttons} setShow={setShowBill}>
        <Form>
          <br />
          <Row>
            <Col>
              <BillingHeaderLeft
                bill={bill}
                contact={contact}
                setContact={setContact}
                setAddress={setAddress}
                addressJson={address}
                formik={formik}
              />
            </Col>
            <Col>
              <BillingHeaderRight formik={formik} />
            </Col>
          </Row>
          <br />
          <br />
          <Row className="centeredRow mb-3" style={{ fontWeight: "bolder" }}>
            <Col xs={12} sm={3}>
              <CheckboxFields.Draft formik={formik} />
            </Col>
            <Col xs={12} sm={3}>
              <CheckboxFields.Payed formik={formik} />
            </Col>
            <Col xs={12} sm={3}>
              <CheckboxFields.Debited
                formik={formik}
                setOpenDebitDate={setOpenDebitDate}
                openDebitDate={openDebitDate}
              />
            </Col>
            <Col xs={12} sm={3}>
              <CheckboxFields.Private formik={formik} />
            </Col>
          </Row>
          <Fields.DebitedAt openDebitDate={openDebitDate} />
          <Row>
            <Col className="mb-3 mt-3">
              <Fields.Subject formik={formik} />
            </Col>
          </Row>
          <Row>
            <Col>
              <Fields.Description formik={formik} />
            </Col>
          </Row>
          <Row>
            <Col style={{ overflowX: "auto" }}>
              <table className="table table-striped">
                <PositionRowHeader />
                <tbody>
                  {positionen
                    ? positionen.map((position, index) => (
                        <PositionRow
                          key={index}
                          index={index}
                          position={position}
                          setShowRowEdit={editRow}
                          deleteFunction={deleteFromList}
                          setShowPosition={viewPosition}
                        />
                      ))
                    : null}
                </tbody>
              </table>
            </Col>
          </Row>
          <Row>
            <Col className="col-sm-12 text-end mb-4 mt-2">
              <Button
                onClick={() => {
                  setEditPosition(null);
                  setShowRowEdit(true);
                }}
              >
                Add Position &nbsp;
                <FontAwesomeIcon icon={faAdd} />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
              <Form.Group className="form-inline" controlId="post_text">
                <Form.Control
                  placeholder="more text"
                  as="textarea"
                  rows={3}
                  name="post_text"
                  value={formik.values.post_text}
                  onChange={formik.handleChange}
                  style={{ width: "100%" }}
                />
              </Form.Group>
            </Col>
            <Col className="col-xl-3 col-lg-5 col-md-6 col-sm-6 offset-xl-3 offset-lg-1">
              <div className="table-responsive">
                <BillSummary positionen={positionen} />
              </div>
            </Col>
          </Row>
          <br />
          <br />
          <Row>
            <Col className="col-sm-6">
              <Fields.Payable
                formik={formik}
                setPaymentDeadline={setPaymentDeadline}
              />
            </Col>
            <Col
              className="col-sm-6"
              style={{ display: "flex", alignItems: "center" }}
            >
              <strong>
                Payment Due: {paymentDeadline.toISOString().split("T")[0]}
              </strong>
            </Col>
          </Row>
          <br />
          <SubmitButtons formik={formik} bill={bill} />
          <Row>
            <Col>
              <ConnectedFiles
                show={reloadConnectedFileView}
                entity={bill}
                endpointAll={"/document/finance/"}
                endpointUnlink={"/document/finance/"}
              />
            </Col>
          </Row>
        </Form>
      </Content>
    </>
  );
};

export default Edit;
