
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";

const SearchTag = ({ item, content, deleteFunction }) => {

    return (
        <Button
          className="btn-sm btn-outline-primary btn-light"
          style={{
            marginRight: "5px",
            marginBottom: "5px",
            float: "left",
            verticalAlign: "middle",
            padding: "0",
          }}
        >
          <span
            onClick={() => {
              alert("edit");
            }}
            style={{ float: "left", padding: "12px 0px 12px 16px" }}
          >
            {content}
          </span>

          <FontAwesomeIcon
            icon={faTrash}
            style={{
              float: "right",
              paddingLeft: "15px",
              paddingRight: "10px",
              marginTop: "15px",
              color: "red",
            }}
            onClick={() => {
              deleteFunction(item);
            }}
          /> 
        </Button>
      );
}

export default SearchTag