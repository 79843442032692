import { Routes, Route } from "react-router-dom";
import Index from "./Index";
import Edit from "./Edit";

const JobRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path="/edit/:id" element={<Edit />} />
    </Routes>
  );
};

export default JobRoutes;
