const FinanceConfig = {
  endpoints: {
    billingPayment: "/finance/buy",
    settings: {
      list: "/finance/settings/",
      new: "/finance/settings/",
      edit: "/finance/settings/edit",
      show: "/finance/settings",
    },
  },
};

export default FinanceConfig;
