import { Dropdown } from "react-bootstrap";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faPrint,
  faTrash,
  faPen,
  faClone,
  faBan,
  faListDots,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import EllipsisVerticalToggle from "../../../../../../layout/Dropdowns/EllipsisVerticalToggle";
import FinanceApi from "@serie3/freelancesplaceapi/api/finance/out";
import { useNavigate } from "react-router-dom";
import ErrorPopup from "../../../../../../layout/ErrorPopup";
import downloadHandler from "@serie3/freelancesplaceapi/Domain/Shared/downloadHandler";
import PDFModal from "../../../../../../layout/Popups/PDFModal";

const IndexButtons = ({
  index,
  bill,
  setShow,
  setBill,
  viewBill,
  editTarget,
  dropDownType,
  direction,
  type,
  deleteFunction,
  draft,
  setDraft,
  payed,
  setPayed,
}) => {
  const navigate = useNavigate();
  const [showCancelError, setShowCancelError] = useState(false);
  const [showCloneError, setShowCloneError] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const clone = async () => {
    const response = await FinanceApi.clone(bill.id);
    if (response.status !== 200) {
      setShowCloneError(true);
    } else {
      navigate("/finance/" + direction + "/" + type + "/edit/" + response.data);
    }
  };

  const cancelBill = async () => {
    const response = await FinanceApi.cancelBill(bill.id);
    if (response.status !== 200) {
      setShowCancelError(true);
    } else {
      //Hardcoded path because only bills can be canceled
      navigate("/finance/out/bills/edit/" + response.data);
    }
  };

  const markPayed = async () => {
    await FinanceApi.markPayed(bill.id, setPayed, setDraft);
    //   setDraft(false);
    //   setPayed(true);
  };

  const DropDownStyle = ({ children }) => {
    return dropDownType === "button" ? (
      <Dropdown style={{ float: "right", marginLeft: "5px" }}>
        <Dropdown.Toggle id="dropdown-basic">
          <FontAwesomeIcon icon={faListDots} />
        </Dropdown.Toggle>
        {children}
      </Dropdown>
    ) : (
      <Dropdown style={{ maxHeight: "16px" }}>
        <EllipsisVerticalToggle />
        {children}
      </Dropdown>
    );
  };

  const CancelError = () => {
    return (
      <ErrorPopup setShowError={setShowCancelError} showError={showCancelError}>
        ... while trying to create a cancel bill
        <br />
        <br />
        <strong>
          If you think this is a bug and we screwed up plz contact support!
        </strong>
      </ErrorPopup>
    );
  };

  const CloneError = () => {
    return (
      <ErrorPopup setShowError={setShowCloneError} showError={showCloneError}>
        ... while trying to clone the entity
        <br />
        <br />
        <strong>
          If you think this is a bug and we screwed up plz contact support!
        </strong>
      </ErrorPopup>
    );
  };

  return (
    <>
      <PDFModal showSpinner={showSpinner} setShowSpinner={setShowSpinner} />
      <CancelError />
      <CloneError />
      <DropDownStyle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={() => viewBill(setShow, setBill, bill)}>
            <small>
              <FontAwesomeIcon icon={faEye} />
              &nbsp; View
            </small>
          </Dropdown.Item>

          {draft ? (
            <>
              <div className="dropdown-divider"></div>
              <Dropdown.Item href={editTarget}>
                <small>
                  <FontAwesomeIcon icon={faPen} />
                  &nbsp; Edit
                </small>
              </Dropdown.Item>
            </>
          ) : null}
          {draft ? null : (
            <>
              <div className="dropdown-divider"></div>
              <Dropdown.Item onClick={cancelBill}>
                <small>
                  <FontAwesomeIcon icon={faBan} />
                  &nbsp; Cancel
                </small>
              </Dropdown.Item>
            </>
          )}
          <div className="dropdown-divider"></div>
          <Dropdown.Item onClick={clone}>
            <small>
              <FontAwesomeIcon icon={faClone} />
              &nbsp; Clone
            </small>
          </Dropdown.Item>
          {!payed ? (
            <>
              <div className="dropdown-divider"></div>
              <Dropdown.Item onClick={markPayed}>
                <small>
                  <FontAwesomeIcon icon={faDollarSign} />
                  &nbsp; Mark payed
                </small>
              </Dropdown.Item>
            </>
          ) : null}

          {draft ? (
            <>
              <div className="dropdown-divider"></div>
              <Dropdown.Item
                onClick={() => {
                  deleteFunction(index, FinanceApi.del(bill.id));
                }}
              >
                <small>
                  <FontAwesomeIcon icon={faTrash} />
                  &nbsp; Delete
                </small>
              </Dropdown.Item>
            </>
          ) : null}

          <div className="dropdown-divider"></div>
          <Dropdown.Item
            onClick={() => {
              setShowSpinner(true);
              downloadHandler(
                "finance/print/" + bill.id,
                "Bill-" + bill.id + ".pdf",
                null,
                setShowSpinner
              );
            }}
          >
            <small>
              <FontAwesomeIcon icon={faPrint} />
              &nbsp; Print
            </small>
          </Dropdown.Item>
        </Dropdown.Menu>
      </DropDownStyle>
    </>
  );
};

export default IndexButtons;
