import { Doughnut } from "react-chartjs-2";

const JobRowDashboard = ({ job, maxScore, showDetails }) => {
  const percentage = parseInt((job._score/maxScore) * 100); 
  const leftover = parseInt(100-percentage);
  const data = {
    datasets: [
      {
        label: "Fit",
        data: [percentage, leftover],
        backgroundColor: ["green", "rgba(54, 162, 235)"],
        borderWidth: 0,
      },
    ],
  };

  return (
    <div
      className="info-box mb-3"
      style={{ backgroundColor: "#bdbdbd", color: "#fff" }}
      onClick={() => {showDetails(job)}}
    >
      <span className="info-box-icon">
        <Doughnut data={data} />
      </span>
      <div className="info-box-content">
        <span className="info-box-text">{job._source.jobtitle}</span>
        <span className="info-box-number">{percentage === 100 ? ('BEST FIT'): percentage+"% Fit"}</span>
      </div>
    </div>
  );
};

export default JobRowDashboard;
