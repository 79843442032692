function secondsToTimeString(seconds, showNegativeSign = false) {
  const isNegative = seconds < 0;
  seconds = Math.abs(seconds);

  // Calculate hours, minutes, and remaining seconds
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  // Format the time string with or without the negative sign
  let timeString;
  if (showNegativeSign && !isNegative && seconds > 0) {
    timeString = `- ${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  } else {
    timeString = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  }

  return timeString;
}

function convertToHHmm(minutes) {
  if (typeof minutes === "number" && minutes >= 0) {
    minutes = Math.round(minutes); // Round to the nearest whole number
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;

    const HH = String(hours).padStart(2, "0");
    const mm = String(mins).padStart(2, "0");

    return `${HH}:${mm}`;
  }
}

function convertToMinutes(timeString) {
  const timeRegex = /^(?:[01]\d|2[0-3]):(?:[0-5]\d|00)$/;
  if (timeRegex.test(timeString)) {
    const [hours, minutes] = timeString.split(":").map(Number);
    const totalMinutes = hours * 60 + minutes;

    return totalMinutes;
  }
}

function calculateTimeDifference(startTime, endTime) {
  if (!endTime) {
    return "End time is not set!";
  }

  // Parse the start and end times
  var start = startTime.split(":");
  var end = endTime.split(":");

  // Create Date objects for both times, using today's date
  var startDate = new Date();
  startDate.setHours(start[0], start[1], 0, 0);

  var endDate = new Date();
  endDate.setHours(end[0], end[1], 0, 0);

  // If the end time is earlier than the start time, assume it's the next day
  if (endDate < startDate) {
    endDate.setDate(endDate.getDate() + 1);
  }

  // Calculate the difference in milliseconds
  var differenceInMilliseconds = endDate - startDate;

  // Convert the difference to hours and minutes
  var hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
  var minutes = Math.floor(
    (differenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
  );

  // Convert the minutes to a fraction of an hour
  var fractionalHours = minutes / 60;

  // Return the result as a float number
  return (hours + fractionalHours).toFixed(2);
}

const calculateAge = (statdate) => {
  const todoDate = new Date(statdate);
  const today = new Date();
  const msPerDay = 1000 * 60 * 60 * 24;
  const date1UTC = Date.UTC(
    todoDate.getFullYear(),
    todoDate.getMonth(),
    todoDate.getDate()
  );
  const date2UTC = Date.UTC(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  const ageInDays = Math.floor((date2UTC - date1UTC) / msPerDay);
  return ageInDays;
};

export default {
  secondsToTimeString,
  convertToHHmm,
  convertToMinutes,
  calculateTimeDifference,
  calculateAge,
};
