import NewModal from "../../../../../../documents/modals/NewModal";
import TabBody from "../../../../../../../../layout/Tabs/TabBody";
import TabHeader from "../../../../../../../../layout/Tabs/TabHeader";
import TabContent from "../../../../../../../../layout/Tabs/TabContent";
import TabLink from "../../../../../../../../layout/Tabs/TabLink";
import {
  faFileInvoice,
  faHandshake,
  faShoppingCart,
  faAdd,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import billingApi from "@serie3/freelancesplaceapi/api/finance/out";
import { Button } from "react-bootstrap";
import FinanceCardRow from "./FinanceCardRow";

const FinancePicker = ({ show, setShow, formik, direction }) => {
  const [bills, setBills] = useState([]);
  const [offers, setOffers] = useState([]);
  const [orders, setOrders] = useState([]);

  const prefix = "project-finance-outgoing";

  const loadDataFunction = async (type, direction) => {
    let response;
    switch (type) {
      case "bills":
        if (bills.length > 0) {
          return;
        }
        response = await billingApi.getAll(type, direction);
        setBills(response.data.finanzvorgang);
        break;
      case "offers":
        if (offers.length > 0) {
          return;
        }
        response = await billingApi.getAll(type, direction);
        setOffers(response.data.finanzvorgang);
        break;
      case "orders":
        if (orders.length > 0) {
          return;
        }
        response = await billingApi.getAll(type, direction);
        setOrders(response.data.finanzvorgang);
        break;
    }
  };

  const onHide = () => {
    setBills([]);
  };

  const AddButton = ({ financeId }) => {
    return (
      <Button
        className="success"
        onClick={() => {
          const newFinance = { id: financeId };
          formik.values.finance.push(newFinance);
          formik.handleSubmit();
        }}
      >
        Add &nbsp;
        <FontAwesomeIcon icon={faAdd} />
      </Button>
    );
  };

  return (
    <NewModal show={show} setShow={setShow} onHide={onHide}>
      <TabHeader style={{ width: "100%" }}>
        <TabLink
          prefix={prefix}
          name={"Bills"}
          icon={faFileInvoice}
          ignoreWith={true}
          style={{ minWidth: "33%" }}
          onClick={() => {
            loadDataFunction("bills", direction);
          }}
        />
        <TabLink
          prefix={prefix}
          name={"Offers"}
          icon={faHandshake}
          ignoreWith={true}
          style={{ minWidth: "33%" }}
          onClick={() => {
            loadDataFunction("offers", direction);
          }}
        />
        <TabLink
          prefix={prefix}
          name={"Orders"}
          icon={faShoppingCart}
          ignoreWith={true}
          style={{ minWidth: "33%" }}
          onClick={() => {
            loadDataFunction("orders", direction);
          }}
        />
      </TabHeader>
      <TabBody>
        <TabContent prefix={prefix} name={"Bills"}>
          <FinanceCardRow financeArray={bills} AddButton={AddButton} />
        </TabContent>
        <TabContent prefix={prefix} name={"Offers"}>
          <FinanceCardRow financeArray={offers} AddButton={AddButton} />
        </TabContent>
        <TabContent prefix={prefix} name={"Orders"}>
          <FinanceCardRow financeArray={orders} AddButton={AddButton} />
        </TabContent>
      </TabBody>
    </NewModal>
  );
};

export default FinancePicker;
