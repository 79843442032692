const DocumentDetails = ({ Document }) => {
  return (
    <>
      <div className="card card-primary card-outline">
        <div className="card-body box-profile">
          <div className="text-center"></div>
          <h3 className="profile-username text-center">{Document.name}</h3>
          <ul className="list-group list-group-unbordered mb-3">
            <li className="list-group-item">
              <b>File size:</b>
              <span className="float-right">{Document.file_size}</span>
            </li>
            <li className="list-group-item">
              <b>Last changed:</b> <span className="float-right"></span>
            </li>
            <li className="list-group-item">
              <b>Uploaded at:</b> <span className="float-right">{Document.date}</span>
            </li>
            <li className="list-group-item">
              <b>Tags:</b> <span className="float-right">

              </span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default DocumentDetails;
