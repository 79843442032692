import apiClient from "@serie3/common/api/client";
import ToastMessages from "@serie3/freelancesplaceapi/Domain/Shared/ToastMessages";

const getAll = (type, direction) => {
  return apiClient.get("finance/" + direction + "/" + type + "/");
};

const showBill = async (type, direction, id) => {
  const response = await apiClient.get(
    "finance/" + direction + "/" + type + "/show/" + id
  );
  return response.data;
};

const createNew = async (type, direction, setShowPayModal) => {
  const response = await apiClient.post(
    "finance/" + direction + "/" + type + "/",
    {}
  );
  if (response.status === 200) {
    console.log(response.data.id);
    return response.data.id;
  }

  if (response.status === 402) {
    setShowPayModal(true);
  }
};

const edit = async (type, direction, data) => {
  const response = await apiClient.put(
    "finance/" + direction + "/" + type + "/edit",
    data
  );
  if (response.status === 200) {
    ToastMessages.SuccessfulSave();
  } else {
    ToastMessages.Error();
  }
  return response;
};

const clone = async (id) => {
  const response = await apiClient.post("finance/clone/" + id);
  return response;
};

const del = async (id) => {
  const response = await apiClient.delete("finance/delete/" + id);
  return response;
};

const cancelBill = async (id) => {
  const response = await apiClient.put("finance/out/bills/cancel/" + id);
  return response;
};

const markPayed = async (id, setPayed, setDraft) => {
  const response = await apiClient.put("finance/in/bills/markPayed/" + id);
  if(response.status === 200){
    setPayed(true);
    setDraft(false);
  }
  return response;
};

export default { getAll, showBill, createNew, edit, clone, del, cancelBill, markPayed };
