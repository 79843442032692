import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareFacebook,
  faTiktok,
  faInstagramSquare,
  faLinkedinIn,
  faSquareYoutube,
} from "@fortawesome/free-brands-svg-icons";

const Social = ({
  size,
  liststyle = false,
  textColor = "white",
  showText = false,
}) => {
  return (
    <>
      <a
        href="https://www.facebook.com/groups/1042928496984673/"
        target="_blank"
        style={{ color: textColor, textDecoration: "none" }}
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faSquareFacebook} size={size} className="mx-2" />
        &nbsp;
        {showText ? "Facebook" : null}
      </a>
      {liststyle ? <br /> : null}
      <a
        href="https://www.tiktok.com/@freelancersplace.net"
        target="_blank"
        style={{ color: textColor, textDecoration: "none" }}
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faTiktok} size={size} className="mx-2" />
        &nbsp;
        {showText ? "TikTok" : null}
      </a>
      {liststyle ? <br /> : null}
      <a
        href="https://www.instagram.com/freelancersplace/"
        target="_blank"
        style={{ color: textColor, textDecoration: "none" }}
        rel="noreferrer"
      >
        <FontAwesomeIcon
          icon={faInstagramSquare}
          size={size}
          className="mx-2"
        />
                &nbsp;
        {showText ? "Instagram" : null}
      </a>
      {liststyle ? <br /> : null}
      <a
        href="https://www.linkedin.com/company/freelancersplace-net/"
        target="_blank"
        style={{ color: textColor, textDecoration: "none" }}
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faLinkedinIn} size={size} className="mx-2" />
        &nbsp;
        {showText ? "LinkedIn" : null}
      </a>
      {liststyle ? <br /> : null}
      <a
        href="https://www.youtube.com/channel/UC0nsjp980Cb8qmcNqm2ayxw"
        target="_blank"
        style={{ color: textColor, textDecoration: "none" }}
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faSquareYoutube} size={size} className="mx-2" />
        &nbsp;
        {showText ? "Youtube" : null}
      </a>
    </>
  );
};

export default Social;
