import IndexButtons from "./content/outgoing/SubComponents/IndexButtons";
import StatusIcons from "./content/outgoing/SubComponents/StatusIcons";
import Address from "./content/outgoing/SubComponents/Address";
import { useState } from "react";

const TableRowBills = ({ index, bill, setShow, setBill, clone, direction, type, deleteFunction }) => {
  const editTarget = "/finance/"+direction +"/bills/edit/" + bill.id;
  const [payed, setPayed] = useState(bill.geldeingang);
  const [draft, setDraft] = useState(bill.draft);

  const viewBill = (setShow, setBill, bill) => {
    setShow(true);
    setBill(bill);
  };

  return (
    <tr className="billingRow">
      <td>
        <b># {bill.id} </b>
      </td>
      <td>
        <Address
          addressJson={bill.fixed_address}
          showStreet={false}
          style={{ marginBottom: "0px" }}
        />
      </td>
      <td>
        {bill.rechnungsnummer ? (
          <>
            {bill.rechnungsnummer}
            <br />
            <StatusIcons
              payed={payed}
              draft={draft}
              subscription={bill.aborechnung}
            />
          </>
        ) : (
          <StatusIcons
            payed={payed}
            draft={draft}
            subscription={bill.aborechnung}
            privateButton={bill.privat}
          />
        )}
      </td>
      <td>{bill.datum}</td>
      <td>
        <b>{bill.netto} €</b>
      </td>
      <td>
        <b>{bill.brutto} € </b>
      </td>
      <td style={{ verticalAlign: "middle" }}>
        <IndexButtons
          index={index}
          viewBill={viewBill}
          setShow={setShow}
          setBill={setBill}
          bill={bill}
          editTarget={editTarget}
          clone={clone}
          direction={direction}
          type={type}
          deleteFunction={deleteFunction}
          payed={payed}
          setPayed={setPayed}
          draft={draft}
          setDraft={setDraft}
        />
      </td>
    </tr>
  );
};

export default TableRowBills;
