const addComma = (number) => {
  var numberStr = number.toString(); // Convert number to string
  if (numberStr.length < 2) {
    // If the number has less than 2 digits, return it as is
    return numberStr;
  } else {
    var lastTwoDigits = numberStr.slice(-2); // Get the last two digits
    var remainingDigits = numberStr.slice(0, -2); // Get the remaining digits
    return remainingDigits + "," + lastTwoDigits;
  }
};

export default addComma;
