import { Fragment } from "react";
import { useState, useEffect } from "react";
import Timeline from "../../../../layout/Timeline/Timeline";
import EducationTimelineItem from "../EducationTimelineItem";
import Timelinelabel from "../../../../layout/Timeline/Timelinelabel";
import EducationFormView from "../Forms/EducationFormView";
import listHandler from "@serie3/common/Domain/listHandler";
import crudApi from "@serie3/common/api/crudApi";
import ProfileConfig from "@serie3/freelancesplaceapi/Config/Profiles/ProfileConfig";
import ErrorPopup from "../../../../layout/ErrorPopup";

const Education = ({ profile }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [educations, setEducations] = useState([]);
  const [showError, setShowError] = useState(false);
  const [editEducation, setEditEducation] = useState();

  const addFunction = () => {
    setEditEducation(null);
    setShowEdit(true);
  };

  const editAction = (education) => {
    setEditEducation(education);
    setShowEdit(true);
  };

  const deleteAction = (index, educationId) => {
    const deleteUrl = ProfileConfig.endpoints.education.delete;
    listHandler.deleteFromList(
      index,
      crudApi.del(deleteUrl, educationId),
      setEducations,
      educations,
      setShowError
    );
  };

  useEffect(() => {
    setEducations(profile.education);
  }, [profile]);

  return (
    <>
      <ErrorPopup setShowError={setShowError} showError={showError}>
        ... while trying to delete the document. Sorry!
        <br />
        <br />
        <strong>
          If you think this is a bug and we screwed up plz contact support!
        </strong>
      </ErrorPopup>

      <EducationFormView
        showEdit={showEdit}
        setShowEdit={setShowEdit}
        profileId={profile.id}
        dataList={educations}
        setDataList={setEducations}
        editEducation={editEducation}
      />
      <Timeline addFunction={addFunction}>
        {educations
          ? educations.map((education, index) => {
              const labelText = education.start + " - " + education.ende;
              return (
                <Fragment key={index}>
                  <Timelinelabel labeltext={labelText} />
                  <EducationTimelineItem
                    education={education}
                    editAction={(() => {editAction(education)})}
                    deleteAction={() => {
                      deleteAction(index, education.id);
                    }}
                  />
                  <br />
                </Fragment>
              );
            })
          : null}
      </Timeline>
    </>
  );
};

export default Education;
