import Action from "../../Entity/Crm/Action";
import actionValidationSchema from "./Yup/ActionValidation";
import CrudApi from "@serie3/common/api/crudApi";
import ListHandler from "@serie3/common/Domain/listHandler";

const createActionForm = (
  dataList,
  setDataFunction,
  showErrorFunction,
  setShowPayModal,
  setEditAction
) => {
  const onSubmit = async (values, actions) => {
    let payload = JSON.stringify(values, null, 2);
    if (typeof values.id === "undefined") {
      const response = await CrudApi.createNew(
        "/crm/tickets/",
        payload,
        setShowPayModal
      );
      if (response.status === 200) {
        setEditAction(response.data);
        const tmpDataList = [...dataList];
        ListHandler.newToList(
          response,
          tmpDataList,
          setDataFunction,
          showErrorFunction
        );
      }
    } else {
      const response = await CrudApi.edit("/crm/tickets/edit", payload);
      const tmpDataList = [...dataList];
      ListHandler.updateList(
        response,
        payload,
        tmpDataList,
        setDataFunction,
        showErrorFunction
      );
    }
  };

  return {
    initialValues: Action,
    validationSchema: actionValidationSchema,
    onSubmit: (values, actions) => {
      onSubmit(values, actions);
    },
  };
};

const setEditData = (editData) => {
  let Action = {
    id: editData.id,
    start: editData.start,
    ende: editData.ende,
    wiedervorlage: editData.wiedervorlage,
    deadline: editData.deadline,
    title: editData.title,
    beschreibung: editData.beschreibung,
    abgeschlossen: editData.abgeschlossen,
    kunde: "",
    person: editData.person,
    priority: editData.priority,
    status: editData.status,
    project_pos: editData.project_pos,
    total_pos: editData.total_pos,
    company_pos: editData.company_pos,
    person_pos: editData.person_pos,
    tags: editData.tags,
  };

  if (editData.kunde && editData.kunde.id) {
    Action.kunde = {
      id: editData.kunde.id,
    };
  } else {
    Action.kunde = null;
  }

  if (editData.person && editData.person.id) {
    Action.person = {
      id: editData.person.id,
    };
  } else {
    Action.person = null;
  }

  return Action;
};

export default { createActionForm, setEditData };
