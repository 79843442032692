const PublicProfilesConfig = {
    Profiles: {
      endpoints: {
        publicProfiles: "/publicprofiles/",
        profileDetails: "/publicprofiles/showpublicprofiles/",
        matchingJobs: "/publicprofiles/profiles/",
        search: "/publicprofiles/search",
      },
    },
  };
  
  export default PublicProfilesConfig;
  