import { Row, Col, Button, Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import CustomModal from "../../../layout/CustomModal";

const Welcome = ({ setShowRegister }) => {
  const [location, setLocation] = useState();
  const [showImage, setShowImage] = useState(false);
  const imagePath = "/dist/img/mainDashboard.jpg";
  const altText = "Welcome to freelancersplace.net";

  useEffect(() => {
    const tmpLocation = window.location.hostname;
    setLocation(tmpLocation);
  }, []);

  return (
    <>
      <CustomModal
        show={showImage}
        setShow={setShowImage}
        size="xl"
        title={"Your personal dashboard"}
      >
        <img src={imagePath} className="landingpageImageModal" alt={altText} />
      </CustomModal>
      <Container>
        <Row
          style={{
            position: "relative",
            border: "0px solid #000000",
            paddingTop: "4.5rem",
            paddingBottom: "5.5rem",
          }}
        >
          <Col className="yellowdotWelcome" />
          <Col className="greendotWelcome" />
          <Row>
            <Col md={7} sm={12} style={{ marginBottom: "0.5rem" }}>
              <span
                style={{
                  display: "inline-block",
                  maxWidth: "75%",
                  color: "var(--Black, #000)",
                  fontFamily: "Space Grotesk",
                  fontSize: "3.625rem",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "110%",
                  marginBottom: "1.5rem",
                }}
              >
                Organize your
                <span style={{ color: "#007BFF" }}> freelancing </span> easily
                and efficiently
              </span>
              <p>
              You won't find any job listings here! However, we provide all the necessary tools for you to apply, manage, and bill the projects you already have.              </p>
              <p style={{ fontWeight: "bolder" }}>
                We're here for you and your projects - from Start to End.
              </p>
              {location !== "demo.freelancersplace.net" ? (
                <Button
                  style={{
                    padding: "0.6125rem 2.25rem",
                    fontSize: "1.125rem",
                    gap: "0.625",
                    borderRadius: "0.5rem",
                  }}
                  onClick={() => {
                    setShowRegister(true);
                  }}
                >
                  Try now for free
                </Button>
              ) : null}
            </Col>

            <Col md={5} sm={12}>
              <Col className="reddotWelcome" />
              <div className="landingpageImageWrapper">
                <img
                  alt={altText}
                  src={imagePath}
                  className="landingpageImage"
                  onClick={() => {
                    setShowImage(true);
                  }}
                />
              </div>
            </Col>
          </Row>
        </Row>
      </Container>
    </>
  );
};

export default Welcome;
