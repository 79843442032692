import { create } from "apisauce";
import getSettings from "../config/settings";

const settings = getSettings();

const blogClient = create({
  baseURL: settings.blogApiUrl,
});

export default blogClient;
