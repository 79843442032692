import { Row, Col } from "react-bootstrap";


const ContactData = ({contact, style}) => {
    const contactData = contact.kontaktdaten ? contact.kontaktdaten : null;


    return (
        contactData && contactData.map((contact, index) => {
            return (
                <Row key={index}>
                    <Col md={4}><b>{contact.key_name}: </b></Col>
                    <Col>{contact.value}</Col>
                </Row>
            )
        

        })
    )


}

export default ContactData;