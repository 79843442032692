import { useEffect, useState } from "react";
import { Button, Table, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import Content from "../../../../../layout/Content";
import ProjectsHeader from "./ProjectsHeader";
import ProjectRow from "./ProjectRow";
import crudApi from "@serie3/common/api/crudApi";
import { useNavigate } from "react-router-dom";
import ProjectConfig from "@serie3/freelancesplaceapi/Config/Projects/ProjectConfig";
import listHandler from "@serie3/common/Domain/listHandler";
import ProjectView from "./ProjectView";
import getSettings from "@serie3/common/config/settings";
import PaymentModal from "../../../../../layout/Payment/PaymentModal";

const Index = () => {
  const [showRowError, setShowRowError] = useState(false);
  const [viewProject, setVieProject] = useState();
  const [showView, setShowView] = useState(false);
  const [projects, setProjects] = useState([]);
  const [showPayModal, setShowPayModal] = useState(false);
  const [settings, setSettings] = useState();

  const navigate = useNavigate();

  const createNew = async () => {
    const response = await crudApi.createNew(
      ProjectConfig.endpoints.new,
      {
        title: " ",
        done: 0,
      },
      setShowPayModal
    );
    if (response.status === 200) {
      navigate(ProjectConfig.endpoints.edit + "/" + response.data.id);
    } else {
      setShowRowError(true);
    }
  };

  const NewButton = () => {
    return (
      <>
        <Button
          className="success"
          onClick={createNew}
          style={{ float: "left", marginRight: "0.375rem" }}
        >
          New <FontAwesomeIcon icon={faPlus} />
        </Button>
        <Dropdown
          style={{ float: "right", marginRight: "15px", lineHeight: "100%" }}
        >
          <Dropdown.Toggle style={{ paddingLeft: ".375rem" }}>
            <FontAwesomeIcon icon={faSearch} />
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ marginTop: "0.75rem" }}>
            <Dropdown.Item onClick={() => {crudApi.getAll(ProjectConfig.endpoints.getDone, setProjects, "project");}}>Show Done</Dropdown.Item>
            <div className="dropdown-divider" />
            <Dropdown.Item onClick={() => {crudApi.getAll(ProjectConfig.endpoints.getOngoing, setProjects, "project");}}>Show other</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  useEffect(() => {
    const endpoint = ProjectConfig.endpoints.getOngoing;
    crudApi.getAll(endpoint, setProjects, "project");
    setSettings(getSettings());
  }, []);

  const title = () => {
    return (
      <>
        <b>Projects &nbsp; </b>
      </>
    );
  };

  const editAction = (id) => {
    navigate(ProjectConfig.endpoints.edit + "/" + id);
  };

  const deleteAction = async (index, project) => {
    listHandler.deleteFromList(
      index,
      crudApi.del(ProjectConfig.endpoints.delete, project.id),
      setProjects,
      projects,
      setShowRowError
    );
  };

  const viewAction = (project) => {
    setVieProject(project);
    setShowView(true);
  };

  return projects ? (
    <>
      {viewProject ? (
        <ProjectView
          show={showView}
          setShow={setShowView}
          projectId={viewProject.id}
        />
      ) : null}
      {settings && (
        <PaymentModal
          show={showPayModal}
          setShow={setShowPayModal}
          priceTableId={settings.pricingTables.projects}
        />
      )}
      <ProjectsHeader />
      <Content Title={title} AdditionalButtons={NewButton}>
        <Table striped>
          <thead>
            <tr>
              <th style={{ width: "1%" }}>#</th>
              <th style={{ width: "25%" }}>Project Name</th>
              <th>Project Progress</th>
              <th style={{ width: "8%" }} className="text-center">
                Status
              </th>
              <th style={{ width: "20%" }}></th>
            </tr>
          </thead>
          <tbody>
            {projects.map((project, index) => {
              return (
                <ProjectRow
                  key={index}
                  editAction={() => editAction(project.id)}
                  deleteAction={() => deleteAction(index, project)}
                  viewAction={() => {
                    viewAction(project);
                  }}
                  project={project}
                />
              );
            })}
          </tbody>
        </Table>
      </Content>
    </>
  ) : null;
};

export default Index;
