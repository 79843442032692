import { Row, Col, Table } from "react-bootstrap";
import { faUnlink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Address from "../../../../../../finance/content/outgoing/SubComponents/Address";
import StatusIcons from "../../../../../../finance/content/outgoing/SubComponents/StatusIcons";

const FinanceCard = ({ bill, AddButton = null, unlinkFunction }) => {
  return (
    <Col className="col-sm-6">
      <div className="card bg-light d-flex flex-fill">
        <div
          className="card-header text-muted border-bottom-0"
          style={{ flexDirection: "row" }}
        >
          <b># {bill.id}</b>: {bill.betreff}
          <span style={{ float: "right" }}>
            {unlinkFunction ? (
              <FontAwesomeIcon
                icon={faUnlink}
                style={{ color: "red" }}
                onClick={unlinkFunction}
              />
            ) : null}
          </span>
        </div>
        <div className="card-body pt-0">
          <Row>
            <Col sm={6}>
              <Address addressJson={bill.fixed_address} />
            </Col>
            <Col sm={6}>
              <StatusIcons
                payed={bill.geldeingang}
                draft={bill.draft}
                subscription={bill.aborechnung}
                privateButton={bill.privat}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Table>
              <tbody>
                <tr>
                  <th>Date:</th>
                  <td>{bill.datum}</td>
                </tr>
                <tr>
                  <th>Nett:</th>
                  <td>{bill.netto}</td>
                </tr>
                <tr>
                  <th>Gross:</th>
                  <td>{bill.brutto}</td>
                </tr>
              </tbody>
            </Table>
          </Row>
        </div>
        <div className="card-footer">
          <div className="text-right">
            {AddButton ? (
              <AddButton financeId={bill.id} AddButton={AddButton} />
            ) : null}
          </div>
        </div>
      </div>
    </Col>
  );
};

export default FinanceCard;
