import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Collapse } from "react-bootstrap";
import { useState } from "react";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

const ListElement = (props) => {
  const [openCollapse, setOpenCollapse] = useState(false);
  let hasChildren = true;
  if (typeof props.children === "undefined") {
    hasChildren = false;
  }

  let targetUrl = "#";
  if (typeof props.targetUrl !== "undefined") {
    targetUrl = props.targetUrl;
  }

  const openCloseCollapse = () => {
    if(props.topElement === true) {setOpenCollapse(!openCollapse)};
  };

  let linkStyle = "nav-icon " + props.linkStyle;
  return (
    <li className={"nav-item"}>
      <Link to={targetUrl} className="nav-link" onClick={openCloseCollapse}>
        <i className={linkStyle} style={{ width: "0.6rem" }} />
        {props.icon ? <FontAwesomeIcon icon={props.icon} /> : null}
        &nbsp;&nbsp;&nbsp;
        <p>{props.name}
        {hasChildren && <FontAwesomeIcon icon={faAngleDown} className="right" />}
        </p>
      </Link>

      {hasChildren ? (
        <Collapse in={openCollapse}>
          <Row>
            <Col>{props.children} </Col>
          </Row>
        </Collapse>
      ) : null}
    </li>
  );
};

export default ListElement;
