import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";

const ErrorPopup = ({ title, children, showError, setShowError }) => {
  return (
    <Modal
      size="lg"
      show={showError}
      onHide={() => setShowError(false)}
      dialogClassName="modal-90w alert-modal"
    >
      <Modal.Header
        closeButton={true}
        className="alert alert-danger"
        style={{ color: "white", backgroundColor: "#dc3545" }}
      >
        <Modal.Title id="Register">
          {title ? (
            title
          ) : (
            <>
              <FontAwesomeIcon icon={faBan} className="mr-2"/>
              Something went wrong... 
            </>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="alert alert-danger">{children}</Modal.Body>
      <Modal.Footer className="alert alert-danger">
        <Button onClick={() => setShowError(false)}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ErrorPopup;
