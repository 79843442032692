import ListHeader from "../../navbars/elements/ListHeader";
import ListElement from "../../navbars/elements/ListElement";
import SubList from "../../navbars/elements/SubList";
import {
  faArrowAltCircleRight,
  faArrowAltCircleLeft,
  faHandshake,
  faShoppingCart,
  faPlus,
  faCogs,
  faFileInvoice
} from "@fortawesome/free-solid-svg-icons";

const FinanceSideNav = () => {
  return (
    <>
      <ListHeader title="Finance" link="/finance/settings" />
      <ListElement name="Outgoing" icon={faArrowAltCircleRight} topElement={true}>
        <SubList>
          <ListElement
            name="Bills"
            targetUrl="/finance/out/bills"
            linkStyle="far nav-icon"
            icon={faFileInvoice}
          />
          <ListElement
            name="Offers"
            targetUrl="/finance/out/offers"
            linkStyle="far nav-icon"
            icon={faHandshake}
          />
          <ListElement
            name="Orders"
            targetUrl="/finance/out/orders"
            linkStyle="far nav-icon"
            icon={faShoppingCart}
          />
        </SubList>
      </ListElement>
      <ListElement name="Incoming" icon={faArrowAltCircleLeft} topElement={true}>
        <SubList>
          <ListElement
            name="Bills"
            targetUrl="/finance/in/bills"
            linkStyle="far nav-icon"
            icon={faFileInvoice}
          />
          <ListElement
            name="Offers"
            targetUrl="/finance/in/offers"
            linkStyle="far nav-icon"
            icon={faHandshake}
          />
          <ListElement
            name="Orders"
            targetUrl="/finance/in/orders"
            linkStyle="far nav-icon"
            icon={faShoppingCart}
          />
        </SubList>
      </ListElement>
    </>
  );
};

export default FinanceSideNav;
