import React from "react";

const Table = ({ children }) => {
  return (
    <table className="table table-striped projects">
      {children}
    </table>
  );
};


export default Table;