import { useEffect, useState } from "react";
import ContentHeader from "../../../layout/ContenteHeader";
import Content from "../../../layout/Content";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import crudApi from "@serie3/common/api/crudApi";
import ProfileForm from "@serie3/freelancesplaceapi/Form/Profile/ProfileForm";
import TabContent from "../../../layout/Tabs/TabContent";
import TabHeader from "../../../layout/Tabs/TabHeader";
import {
  faMoneyBill,
  faUser,
  faGraduationCap,
  faBookOpenReader,
  faHandshake,
  faCog,
  faContactCard,
} from "@fortawesome/free-solid-svg-icons";
import TabLink from "../../../layout/Tabs/TabLink";
import TabBody from "../../../layout/Tabs/TabBody";
import ProfileConfig from "@serie3/freelancesplaceapi/Config/Profiles/ProfileConfig";
import ProfileBasics from "./Tabs/ProfileBasics";
import ContactData from "./Tabs/ContactData";
import PricesAndAvailability from "./Tabs/PricesAndAvailability";
import Skills from "./Tabs/Skills";
import ProfileLeft from "./ProfileLeft";
import Education from "./Tabs/Education";
import Workexperience from "./Tabs/WorkExperience";
import ProfileSettings from "./Tabs/ProfileSettings";

const Edit = () => {
  const [editProfile, setEditProfile] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [originalSkillList, setOriginalSkillList] = useState([]);
  const [showError, setShowError] = useState(false);
  const [qualifications, setQualifications] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [searchingFor, setSearchingFor] = useState([]);
  const [updateJobs, setUpdateJobs] = useState([]);
  let { id } = useParams();

  const prefix = "profile";

  const handleSave = (e) => {
    formik.handleSubmit(e);
    e.preventDefault();
  };

  const editProfileForm = ProfileForm.createProfileForm(
    setShowError,
    setOriginalSkillList,
    setQualifications,
    setLanguages
  );

  const formik = useFormik(editProfileForm);

  const title = () => {
    return (
      <>
        <b>Profile: &nbsp; </b> Person
      </>
    );
  };

  useEffect(() => {
    const loadData = async (id) => {
      const profile = await crudApi.show(ProfileConfig.endpoints.show, id);
      setEditProfile(profile);
      setOriginalSkillList(profile.skills);
      setLanguages([...profile.languages]);
      formik.setValues(ProfileForm.setEditData(profile));
      setIsLoaded(true);
    };
    loadData(id);
  }, [id]);

  useEffect(() => {
    setUpdateJobs(!updateJobs);
  }, [originalSkillList, qualifications, languages, searchingFor]);

  const SaveButton = () => {
    return (
      <Button
        onClick={(e) => {
          formik.handleSubmit(e);
          e.preventDefault();
        }}
      >
        Save
      </Button>
    );
  };

  return isLoaded ? (
    <>
      <ContentHeader />
      <Content Title={title}>
        <Row>
          <ProfileLeft formik={formik} profile={editProfile} updateJobs={updateJobs}/>
          <Col className="col-md-9">
            <div className="card">
              <TabHeader prefix={prefix}>
                <TabLink prefix={prefix} name={"Basics"} icon={faUser} />
                <TabLink
                  prefix={prefix}
                  name={"Contacts"}
                  icon={faContactCard}
                />
                <TabLink prefix={prefix} name={"Prices"} icon={faMoneyBill} />
                <TabLink
                  prefix={prefix}
                  name={"Skills"}
                  icon={faBookOpenReader}
                />
                <TabLink
                  prefix={prefix}
                  name={"Education"}
                  icon={faGraduationCap}
                />
                <TabLink
                  prefix={prefix}
                  name={"Workhistory"}
                  icon={faHandshake}
                />
                <TabLink prefix={prefix} name={"Settings"} icon={faCog} />
              </TabHeader>

              <TabBody>
                <TabContent prefix={prefix} name={"Basics"} show={true}>
                  <ProfileBasics
                    formik={formik}
                    SaveButton={SaveButton}
                    profile={editProfile}
                    searchingFor={searchingFor}
                    setSearchingFor={setSearchingFor}
                    languages={languages}
                    setLanguages={setLanguages}
                  />
                </TabContent>
                <TabContent prefix={prefix} name={"Contacts"}>
                  <ContactData
                    formik={formik}
                    profile={editProfile}
                    SaveButton={SaveButton}
                  />
                </TabContent>
                <TabContent prefix={prefix} name={"Prices"}>
                  <PricesAndAvailability
                    formik={formik}
                    SaveButton={SaveButton}
                  />
                </TabContent>
                <TabContent prefix={prefix} name={"Skills"}>
                  <Skills
                    formik={formik}
                    profile={editProfile}
                    SaveButton={SaveButton}
                    originalSkillList={originalSkillList}
                    setOriginalSkillList={setOriginalSkillList}
                    qualifications={qualifications}
                    setQualifications={setQualifications}
                  />
                </TabContent>
                <TabContent prefix={prefix} name={"Education"}>
                  <Education formik={formik} profile={editProfile} />
                </TabContent>
                <TabContent prefix={prefix} name={"Workhistory"}>
                  <Workexperience formik={formik} profile={editProfile} />
                </TabContent>
                <TabContent prefix={prefix} name={"Settings"}>
                  <ProfileSettings formik={formik} />
                </TabContent>
              </TabBody>
            </div>
          </Col>
        </Row>
      </Content>
    </>
  ) : null;
};

export default Edit;
