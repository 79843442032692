import Workexperience from "../../Entity/Profile/Workexperience";
import * as Yup from "yup";
import CrudApi from "@serie3/common/api/crudApi";
import ProfileConfig from "../../Config/Profiles/ProfileConfig";
import listHandler from "@serie3/common/Domain/listHandler";

const createWorkexperienceForm = (
  dataList,
  setDataFunction,
  showErrorFunction,
  setShowEdit
) => {
  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    vorname: Yup.string().required("Required"),
    sperrgrund: Yup.string().when("gesperrt", {
      is: true,
      then: Yup.string().required(
        "You must add a reason why this company is blocked"
      ),
    }),
  });

  const onSubmit = async (values, actions) => {
    if (values.id > 0) {
      values.start = values.start.split("T")[0];
      values.ende = values.ende ? values.ende.split("T")[0] : null;
      let payload = JSON.stringify(values, null, 2);
      const response = await CrudApi.edit(
        ProfileConfig.endpoints.workexperience.edit,
        payload
      );
      listHandler.updateList(
        response,
        payload,
        dataList,
        setDataFunction,
        showErrorFunction
      );
    } else {
      values.start = values.start.split("T")[0];
      try {
        values.ende = values.ende ? values.ende.split("T")[0] : null;
      } catch (Error) {
        values.ende = null;
      }
      let payload = JSON.stringify(values, null, 2);
      const response = await CrudApi.createNew(
        ProfileConfig.endpoints.workexperience.new,
        payload
      );
      listHandler.newToList(
        response,
        dataList,
        setDataFunction,
        showErrorFunction
      );
    }

    setShowEdit(false);
    actions.resetForm(Address);
  };

  return {
    initialValues: Workexperience,
    onSubmit: (values, actions) => {
      onSubmit(values, actions);
    },
  };
};

const setEditData = (editData) => {
  const date = new Date();
  let Workexperience = {
    id: editData.id,
    position_name: editData.position_name,
    position_type: editData.position_type,
    company: editData.company,
    description: editData.description,
    business: editData.business,
    type_of_business: editData.type_of_business,
    start: editData.start
      ? editData.start.split("T")[0]
      : date.toISOString().split("T")[0],
    ende: editData.ende
      ? editData.ende.split("T")[0]
      : date.toISOString().split("T")[0],
    current: editData.current,
    use_for_card: editData.use_for_card,
    headcount: editData.headcount,
    deleted: editData.deleted,
  };

  return Workexperience;
};

export default { createWorkexperienceForm, setEditData };
