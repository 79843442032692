import TextField from "./Fields/TextField";
import { Col, Row } from "react-bootstrap";
import KeyValueForm from "@serie3/freelancesplaceapi/Form/shared/KeyValueForm";
import KeyValue from "@serie3/freelancesplaceapi/Entity/shared/KeyValue";
import { useFormik } from "formik";
import SaveButton from "../Buttons/SaveButton";
import NewModal from "../../LoggedIn/modules/documents/modals/NewModal";
import { useState } from "react";
import BigErrorPopup from "../Popups/BigErrorPopup";

const KeyValueFormView = ({
  showEdit,
  setShowEdit,
  editKeyValueData,
  dataList,
  setDataList,
  onShow,
  newEndpoint,
  editEndpoint,
}) => {
  const [showError, setShowError] = useState(false);

  const editKeyValueForm = KeyValueForm.createKeyValueForm(
    dataList,
    setDataList,
    setShowError,
    setShowEdit,
    newEndpoint,
    editEndpoint
  );
  const formik = useFormik(editKeyValueForm);

  const Save = () => {
    return (
      <SaveButton
        handleClick={(e) => {
          formik.handleSubmit(e);
          e.preventDefault();
        }}
      />
    );
  };

  return (
    <>
      <BigErrorPopup setShowError={setShowError} showError={showError} />
      <NewModal
        show={showEdit}
        setShow={setShowEdit}
        ExtraButton={Save}
        onShow={() => {
          editKeyValueData
            ? formik.setValues(KeyValueForm.setEditData(editKeyValueData))
            : formik.setValues(KeyValue);
          onShow(formik);
        }}
      >
        <Row>
          <Col>
            <TextField
              formik={formik}
              label={"Name"}
              valueName={"key_name"}
              style={{ marginBottom: "10px" }}
            />
          </Col>
          <Col>
            <TextField
              formik={formik}
              label={"Url"}
              valueName={"value"}
              style={{ marginBottom: "10px" }}
            />
          </Col>
        </Row>
      </NewModal>
    </>
  );
};

export default KeyValueFormView;
