import { useState } from 'react';

export default function useAccount() {
  const getAccount = () => {
    const accountString = sessionStorage.getItem('account');
    const userAccount = JSON.parse(accountString);
    return userAccount?.account
  };

  const [account, setAccount] = useState(getAccount());

  const saveAccount = userAccount => {
    sessionStorage.setItem('account', JSON.stringify(userAccount));
    sessionStorage.setItem('loginTime', Date.now());
    setAccount(userAccount.account);
  };
  
  if((Math.abs(Date.now() - sessionStorage.getItem('loginTime'))/ 60000) >= 60){
    sessionStorage.removeItem('account');
    sessionStorage.removeItem('loginTime');
  }

  return {
    setAccount: saveAccount,
    account
  }
}
 
