import Card from "../Cards/Card";

const CardRow = ({ children, title, type, CardTools, count }) => {
  let cardTitle = title;
  if (typeof count !== "undefined" && typeof count === "number") {
    cardTitle = title + " (" + count + ")";
  }

  return (
    <Card
      title={cardTitle}
      className={type}
      CardTools={CardTools}
      style={{ paddingBottom: "150px" }}
    >
      {children}
    </Card>
  );
};

export default CardRow;
