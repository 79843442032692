import Card from "../../../../../../layout/Cards/Card";
import TextField from "../../../../../../layout/Form/Fields/TextField";
import TextArea from "../../../../../../layout/Form/Fields/TextArea";
import Checkbox from "../../../../../../layout/Form/Fields/Checkbox";
import DateField from "../../../../../../layout/Form/Fields/DateField";
import SaveButton from "../../../../../../layout/Buttons/SaveButton";
import ReferencedInputAndSubmit from "../../../../../../layout/Form/Fields/ReferencedInputAndSubmit";
import { useRef, useState, useEffect } from "react";
import TagLikeHandler from "@serie3/freelancesplaceapi/Form/shared/TagLikeHandler";
import CustomerModal from "../../../../crm/Shared/CustomerModal";
import Project from "@serie3/freelancesplaceapi/Domain/Projects/Project";
import { Row, Col, Button } from "react-bootstrap";
import Tag from "../../../../../../layout/Buttons/Tag";
import NumberField from "../../../../../../layout/Form/Fields/NumberField";
import Address from "../../../../../../layout/Common/Address";

const BasicsForm = ({ formik, project, tags, setTags }) => {
  const tagRef = useRef(null);
  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [customer, setCustomer] = useState();

  useEffect(() => {
   let customer = project.person ? project.person : project.customer ? project.customer : null;  
   setCustomer(customer);
  }, [project]);

  const SaveBasics = () => {
    return (
      <SaveButton
        handleClick={(e) => {
          formik.handleSubmit();
          e.preventDefault();
        }}
      />
    );
  };

  const PickCustomerButton = () => {
    return (
      <Button
        onClick={(e) => {
          setShowCustomerModal(true);
        }}
      >
        Pick Customer
      </Button>
    );
  };

  const addElement = (refName, valueName) => {
    TagLikeHandler.addElement(formik, refName, valueName);
  };
  const deleteElement = (element, valueName, setData) => {
    TagLikeHandler.deleteElement(element, valueName, setData, formik);
  };

  const updateContact = async (contact) => {
    const response = await Project.updateContact(formik.values, contact);
    if(response.status === 200){
      const project = response.data;
      let customer = project.person ? project.person : project.customer ? project.customer : null;  
      setCustomer(customer);
    }
  };

  return (
    <>
      <CustomerModal
        show={showCustomerModal}
        setShow={setShowCustomerModal}
        updateContact={updateContact}
      />
      <Card title={"Customer"} CardTools={PickCustomerButton}>
        <Row>
          <Col>
            {customer && <Address contact={customer}/> }
          </Col>
        </Row>
      </Card>
      <Card title={"Project Basics"} CardTools={SaveBasics}>
        <Row>
          <Col>
            <TextField formik={formik} label={"Title"} valueName={"title"} />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <TextArea
              formik={formik}
              label={"Description"}
              valueName={"description"}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <Checkbox formik={formik} label={"Done"} valueName={"done"} />
          </Col>
        </Row>
      </Card>
      <Card title={"Project Times"} CardTools={SaveBasics}>
        <Row>
          <Col>
            <DateField formik={formik} label={"Start"} valueName={"start"} />
          </Col>
          <Col>
            <DateField formik={formik} label={"End"} valueName={"end"} />
          </Col>
        </Row>
        <Row>
          <Col>
            <NumberField
              formik={formik}
              label={"Max Hours"}
              valueName={"max_hours"}
            />
          </Col>
          <Col>
            <NumberField
              formik={formik}
              label={"Daily Hours"}
              valueName={"daily_hours"}
            />
          </Col>
        </Row>
      </Card>
      <Card title={"Tags"}>
        <Row>
          <Col>
            <ReferencedInputAndSubmit
              namedReference={tagRef}
              onClick={() => {
                addElement(tagRef, "tags");
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            {tags.map((tag, index) => {
              return (
                <Tag
                  key={index}
                  item={tag}
                  content={tag.name}
                  deleteFunction={() => {
                    deleteElement(tag, "tags", setTags);
                  }}
                />
              );
            })}
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default BasicsForm;
