import { React, useEffect, useState } from "react";
import Content from "../../../../../layout/Content";
import billingApi from "@serie3/freelancesplaceapi/api/finance/out";
import NewModal from "../../../documents/modals/NewModal";
import FileDropzone from "../../../documents/modals/FileDropzone";
import FinanceHeader from "../../FinanceHeader";
import BillDropzone from "./SubComponents/BillDropzone";
import BillingData from "./SubComponents/BillingData";
import { Row, Form } from "react-bootstrap";
import { useFormik } from "formik";
import FinanceForm from "@serie3/freelancesplaceapi/Form/Billing/FinanceForm";
import Finance from "@serie3/freelancesplaceapi/Domain/Billing/Outgoing/Finance";
import { useParams } from "react-router-dom";
import ConnectedFiles from "../../../../../layout/Documents/ConnectedFiles";
import getSettings from "@serie3/common/config/settings";
import PaymentModal from "../../../../../layout/Payment/PaymentModal";

const EditIn = ({ type, direction, titleText }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [paymentDeadline, setPaymentDeadline] = useState(new Date());
  const [previewUrl, setPreviewUrl] = useState();
  const [address, setAddress] = useState();
  const [reloadConnectedFileView, setReloadConnectedFileView] = useState(0);
  const [showPayModal, setShowPayModal] = useState(false);
  const [settings, setSettings] = useState();

  const [bill, setBill] = useState();
  let { id } = useParams();

  const editOutgoingBillForm = FinanceForm.createFinanceForm(type, direction);
  const formik = useFormik(editOutgoingBillForm);
  const uploadUrl = "/finance/" + direction + "/" + type + "/edit";

  const Title = () => {
    return (
      <>
        <b>Finance: &nbsp; </b> {titleText}
      </>
    );
  };

  const loadData = async (id) => {
    const bill = await billingApi.showBill(type, direction, id);
    setBill(bill);
    setAddress(bill.fixed_address);
    formik.setValues(FinanceForm.setEditData(bill));
    let paymentDueDate = Finance.calculatePaymentDeadline(
      bill.datum,
      bill.payment_due_days
    );
    setPaymentDeadline(paymentDueDate);
    setIsLoaded(true);
    setSettings(getSettings());
  };

  useEffect(() => {
    loadData(id);
  }, [id]);

  return !isLoaded ? (
    <></>
  ) : (
    <>
      {settings && (
        <PaymentModal
          show={showPayModal}
          setShow={setShowPayModal}
          priceTableId={settings.pricingTables.document}
        />
      )}
      <NewModal show={showNew} setShow={setShowNew}>
        <FileDropzone
          uploadUrl={"/document/finance/" + id}
          setData={() => {
            setReloadConnectedFileView(Math.random());
          }}
          setShowPayModal={setShowPayModal}
        />
      </NewModal>
      <FinanceHeader />
      <Content Title={Title}>
        <Form>
          <Row>
            <BillDropzone
              bill={bill}
              uploadUrl={uploadUrl}
              previewUrl={
                "/finance/" + direction + "/" + type + "/filePreview/" + bill.id
              }
            />
            <BillingData
              formik={formik}
              bill={bill}
              setShowNew={setShowNew}
              address={address}
              setAddress={setAddress}
            />
          </Row>
        </Form>
        <Row>
          <ConnectedFiles
            show={reloadConnectedFileView}
            entity={bill}
            endpointAll={"/document/finance/"}
            endpointUnlink={"/document/finance/"}
          />
        </Row>
      </Content>
    </>
  );
};

export default EditIn;
