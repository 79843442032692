import { Button, Table, Row, Col } from "react-bootstrap";
import { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import ProfileDomain from "@serie3/freelancesplaceapi/Domain/Profile/Profile";
import Skillswrapper from "../Tables/Skillswrapper";
import Card from "../../../../layout/Cards/Card";
import SkillsForm from "../Forms/SkillsForm";
import Skill from "@serie3/freelancesplaceapi/Domain/Profile/Skill";
import Tag from "../../../../layout/Buttons/Tag";
import TagLikeHandler from "@serie3/freelancesplaceapi/Form/shared/TagLikeHandler";
import ReferencedInputAndSubmit from "../../../../layout/Form/Fields/ReferencedInputAndSubmit";

const Skills = ({
  formik,
  originalSkillList,
  setOriginalSkillList,
  qualifications,
  setQualifications,
}) => {
  const [showSkillsForm, setshowSkillsForm] = useState(false);
  const [skillList, setSkillList] = useState();
  const [editSkill, setEditSkill] = useState(null);
  const [showError, setShowError] = useState(false);
  const softSkillInputRef = useRef(null);

  const AddSkillButton = () => {
    return (
      <div className="card-tools ml-auto">
        <Button
          className="success"
          //TODO extract to reusable function
          onClick={() => {
            const lastSkill =
              formik.values.skills[formik.values.skills.length - 1];
            if (
              (typeof lastSkill !== "undefined" &&
              lastSkill.name &&
              lastSkill.category) || formik.values.skills.length == 0
            ) {
              formik.values.skills.push(Skill);
            }


            setEditSkill(undefined);
            setshowSkillsForm(true);
          }}
        >
          Add &nbsp;
          <FontAwesomeIcon icon={faAdd} />
        </Button>
      </div>
    );
  };

  const addSoftSkill = () => {
    TagLikeHandler.addElement(formik, softSkillInputRef, "qualifications");
  };

  const deleteLookingFor = (tag) => {
    TagLikeHandler.deleteElement(
      tag,
      "qualifications",
      setQualifications,
      formik
    );
  };

  useEffect(() => {
    const modifiedSkillArray =
      ProfileDomain.handleSkillSplitup(originalSkillList);
    setSkillList(modifiedSkillArray);
    setQualifications(formik.values.qualifications);
  }, [originalSkillList]);

  return (
    <>
      <SkillsForm
        showEdit={showSkillsForm}
        setShowEdit={setshowSkillsForm}
        dataList={originalSkillList}
        setDataList={setOriginalSkillList}
        formik={formik}
        editSkill={editSkill}
        setEditSkill={setEditSkill}
      />
      <Card title={"Hard skills"} CardTools={AddSkillButton}>
        <Table className="table table-striped projects">
          <Skillswrapper
            skillList={skillList}
            setEditFunction={setEditSkill}
            setShowEdit={setshowSkillsForm}
            setDataList={setOriginalSkillList}
            dataList={originalSkillList}
            editItem={editSkill}
            showErrorFunction={setShowError}
            formik={formik}
          />
        </Table>
      </Card>
      <Card title={"Soft/Secondary skills"}>
        <Row>
          <Col>
            <ReferencedInputAndSubmit
              namedReference={softSkillInputRef}
              onClick={addSoftSkill}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            {qualifications
              ? qualifications.map((softSkill, index) => {
                  return (
                    <Tag
                      key={index}
                      index={index}
                      item={softSkill}
                      content={softSkill.name}
                      deleteFunction={deleteLookingFor}
                    />
                  );
                })
              : null}
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default Skills;
