import { Container, Row, Col } from "react-bootstrap";
import dashboard from "@serie3/freelancesplaceapi/Assets/img/Landingpages/dashboardapplicationtracker.png";

const MainSection = ({ title, FirstFeature, SecondFeature }) => {
  return (
    <Container className="my-5">
      <Row>
        <Col md={6}>
          <img src={dashboard} className="img-fluid" alt="dashboard" />
        </Col>
        <Col>
          <h2 className="landingpage">{title}</h2>
          <br />
          <Row>
            <Col md={6}>
              <FirstFeature />
            </Col>
            <Col md={6}>
              <SecondFeature />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
export default MainSection;
