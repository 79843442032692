import FinanceConfig from "../../Config/Finance/FinanceConfig";
import BillingSettings from "../../Entity/Billing/BillingSettings";
import crudApi from "@serie3/common/api/crudApi";
import ToastMessages from "@serie3/freelancesplaceapi/Domain/Shared/ToastMessages";

const createBillingSettingsForm = () => {
  const onSubmit = async (values) => {
    let payload = JSON.stringify(values, null, 2);
    const response = await crudApi.edit(
      FinanceConfig.endpoints.settings.edit,
      payload
    );
    if (response.status === 200) {
      ToastMessages.SuccessfulSave();
    } else {
      ToastMessages.Error();
    }
  };

  return {
    initialValues: BillingSettings,
    onSubmit: (values, actions) => {
      onSubmit(values, actions);
    },
  };
};

const setEditData = (editData) => {
  let BillingSettings = {
    id: editData.id,
    company_address: editData.company_address,
    pre_text: editData.pre_text,
    post_text: editData.post_text,
    payment_deadline: editData.payment_deadline,
    iban: editData.iban,
    bic: editData.bic,
    bank_name: editData.bank_name,
    email: editData.email,
    website: editData.website,
    phone: editData.phone,
    taxnumber: editData.taxnumber,
    tax_id: editData.tax_id,
    billing_number_prefix: editData.billing_number_prefix,
    billing_number_offset: editData.billing_number_offset,
    deleted: editData.deleted,
  };
  return BillingSettings;
};

export default { createBillingSettingsForm, setEditData };
