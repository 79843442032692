const ApplicationConfig = {
    endpoints: {
        getAll: "/profile/applications/",
        new: "/profile/applications/",
        edit: "/profile/applications/edit",
        delete: "/profile/applications/delete/"
    },
    datafield: "applications"
}

export default ApplicationConfig;