import { Row, Col, Container } from "react-bootstrap";
import CustomModal from "../../../layout/CustomModal";
import { useState } from "react";

const Billing = () => {
  const [showImage, setShowImage] = useState(false);
  const imagePath = "/dist/img/billings.jpg";
  return (
    <>
      <CustomModal
        show={showImage}
        setShow={setShowImage}
        size="xl"
        title={"Bills, offers, orders, incoming and outgoing you name it "}
      >
        <img src={imagePath} className="landingpageImageModal" />
      </CustomModal>
      <Container>
        <Row
          style={{
            position: "relative",
            border: "0px solid #000000",
            paddingTop: "4.5rem",
            paddingBottom: "5.5rem",
          }}
        >
          <Row>
            <Col md={6} sm={12} style={{ marginBottom: "0.5rem" }}>
              <Col className="reddot1" />
              <div className="landingpageImageWrapperFullGreen">
                <div className="landingpageImageWrapper">
                  <img
                    src={imagePath}
                    className="landingpageImage"
                    onClick={() => {
                      setShowImage(true);
                    }}
                    style={{ display: "block" }}
                  />
                </div>
              </div>
            </Col>
            <Col md={1} />
            <Col md={5} sm={12}>
              <div
                className="landingpageHeader"
                style={{
                  fontWeight: "400",
                  fontStyle: "normal",
                  lineHeight: "110%",
                }}
              >
                Elevate your Business with our Billing Solution
              </div>
              <span
                style={{
                  color: "var(--Grey, #4D4D4D)",
                  fontFamily: "Inter",
                  fontSize: "1rem",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "1.5rem",
                }}
              >
                Simplify your incoming and outgoing bills with our automated
                billing system. Keep track of payments, due dates, and financial
                summaries without the hassle.
                <br />
                <br />
                <i>
                  Comming soon: Our App with AI Features for automated Data
                  extraction from your bills. Automatically grow your contact
                  list
                </i>
              </span>
              <br />
              <br />
              <Row style={{ marginBottom: "1rem" }}>
                <Col>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="none"
                    style={{ marginRight: "0.5rem" }}
                  >
                    <circle cx="7" cy="7.5" r="7" fill="#007BFF" />
                  </svg>
                  <span
                    style={{
                      color: "#000",
                      fontFamily: "Sofia Sans",
                      fontSize: "1.25rem",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "160%",
                    }}
                  >
                    Incoming Billing
                  </span>
                </Col>
                <Col className="yellowdotWelcome" />
                <Col className="greendotWelcome" />
              </Row>
              <Row>
                <Col>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="none"
                    style={{ marginRight: "0.5rem" }}
                  >
                    <circle cx="7" cy="7.5" r="7" fill="#007BFF" />
                  </svg>
                  <span
                    style={{
                      color: "#000",
                      fontFamily: "Sofia Sans",
                      fontSize: "1.25rem",
                      fontStyle: "normal",
                      fontWeight: "400",
                      lineHeight: "160%",
                    }}
                  >
                    Outgoing Billing
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>
      </Container>
    </>
  );
};

export default Billing;
