import React from "react";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOut, faPen } from "@fortawesome/free-solid-svg-icons";
import useToken from "../../../../business/security/useToken";
import { getAuth, signOut } from "firebase/auth";
import firebaseConfig from "@serie3/freelancesplaceapi/Config/Firebase/firebase";
import { initializeApp } from "firebase/app";
import { useNavigate } from "react-router-dom";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="#"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

const ProfileDropdown = () => {
  const { token, setToken } = useToken();
  const navigate = useNavigate();

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  return (
    <Dropdown
      className="d-flex"
      style={{ flexDirection: "row", alignItems: "center" }}
    >
      <Dropdown.Toggle id="dropdown-basic" as={CustomToggle}>
        <div className="image">
          <img
            src="/dist/img/avatar.png"
            className="img-circle elevation-2"
            alt="prifilepic"
            style={{ maxHeight: "30px" }}
          />
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => {navigate('/userprofile')}}>
            <small>
              <FontAwesomeIcon icon={faPen} />
              &nbsp; Edit Profile
            </small>
        </Dropdown.Item>
        <div className="dropdown-divider"></div>
        <Dropdown.Item
          onClick={() => {
            setToken(null, null);
            window.location.reload();
            signOut(auth);
          }}
        >
          <small>
            <FontAwesomeIcon icon={faSignOut} />
            &nbsp; Logout
          </small>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
