import { Col, Row } from "react-bootstrap";
import DateField from "../../../../../layout/Form/Fields/DateField";
import SelectField from "../../../../../layout/Form/Fields/SelectField";
import TextField from "../../../../../layout/Form/Fields/TextField";
import TextArea from "../../../../../layout/Form/Fields/TextArea";
import ActionTags from "./ActionTags";

const ActionBasics = ({ formik, allTags, setAllTags }) => {
  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col>
              <TextField
                formik={formik}
                label={"Title"}
                valueName={"title"}
                style={{ marginBottom: "10px" }}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <DateField
                formik={formik}
                label={"Start"}
                valueName={"start"}
                style={{ marginBottom: "10px" }}
              />
            </Col>
            <Col>
              <DateField
                formik={formik}
                label={"End"}
                valueName={"ende"}
                style={{ marginBottom: "10px" }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <DateField
                formik={formik}
                label={"Deadline"}
                valueName={"deadline"}
                style={{ marginBottom: "10px" }}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <SelectField
                formik={formik}
                label={"Priority"}
                valueName={"priority"}
                style={{ marginBottom: "10px" }}
              >
                <option></option>
                <option value="Highest">Highest</option>
                <option value="High">High</option>
                <option value="Normal">Normal</option>
                <option value="Low">Low</option>
                <option value="Nicetohave">Nice to have</option>
              </SelectField>
            </Col>
            <Col>
              <SelectField
                formik={formik}
                label={"Status"}
                valueName={"status"}
                style={{ marginBottom: "10px" }}
              >
                <option></option>
                <option value="Created">Created</option>
                <option value="In_Progress">In Progress</option>
                <option value="Done">Done</option>
                <option value="On_Hold">On Hold</option>
                <option value="Canceled">Canceled</option>
              </SelectField>
            </Col>
          </Row>
        </Col>
      </Row>
      <ActionTags allTags={allTags} setAllTags={setAllTags} formik={formik} />
      <br />
      <Row>
        <Col>
          <TextArea
            formik={formik}
            label={"Description"}
            valueName={"beschreibung"}
            style={{ marginBottom: "10px" }}
            rows={10}
          />
        </Col>
      </Row>
    </>
  );
};

export default ActionBasics;
