import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";

const RoundNewButton = ({createNew}) => {
    return (
        <div className="card-body centerButtonCard">
          <Button className="btn bg-gradient-success btn-circle" onClick={createNew}>
            <FontAwesomeIcon icon={faAdd} />
          </Button>
        </div>
      );
}

export default RoundNewButton;