import TodoRow from "./TodoRow";
import ReferencedInputAndSubmit from "../../../layout/Form/Fields/ReferencedInputAndSubmit";
import listHandler from "@serie3/common/Domain/listHandler";
import crudApi from "@serie3/common/api/crudApi";
import TodoConfig from "@serie3/freelancesplaceapi/Config/Todo/TodoConfig";
import { Form } from "react-bootstrap";
import NewModal from "../../../layout/Popups/NewModal";
import { useState } from "react";

const TodoList = ({
  todos,
  setTodos,
  dailyTodos,
  setDailyTodos,
  addTodo,
  deleteTodo,
  todoRef,
  dailyList = false,
  setShowMaxReached,
}) => {
  const [alreadySet, setAlreadySet] = useState(false);

  const markAsDaily = async (todoItem) => {
    const itemIndex = dailyTodos.findIndex((obj) => obj.id === todoItem.id);

    if (itemIndex === -1) {
      var response = await crudApi.edit(
        TodoConfig.endpoints.markAsDaily + todoItem.id,
        null
      );
      switch (response.status) {
        case 200:
          var dataList = listHandler.deleteWithoutCall(todoItem, todos);
          setTodos([...dataList]);
          dailyTodos.push(todoItem);
          setDailyTodos([...dailyTodos]);
          break;
        case 403:
          setShowMaxReached(true);
          break;
      }
    } else {
      setAlreadySet(true);
    }
  };

  return (
    <>
      <NewModal
        show={alreadySet}
        setShow={setAlreadySet}
        title={"Already on the List"}
      >
        The item you're trying to add is already on your daily list
      </NewModal>

      <Form>
        <ReferencedInputAndSubmit namedReference={todoRef} onClick={addTodo} />
      </Form>
      <div
        style={{ height: "300px", overflowY: "scroll", paddingBottom: "20px" }}
      >
        <ul className="todo-list" data-widget="todo-list">
          {todos.length > 0
            ? todos.map((todo) => {
                return (
                  <TodoRow
                    dailyList={dailyList}
                    key={todo.id}
                    todo={todo}
                    deleteFunction={() => {
                      deleteTodo(todo);
                    }}
                    markAsDaily={() => {
                      markAsDaily(todo);
                    }}
                  />
                );
              })
            : "Nothing to do, Have a nice day :)"}
        </ul>
      </div>
    </>
  );
};

export default TodoList;
