import Project from "../../../Entity/Project/Project";
import * as Yup from "yup";
import CrudApi from "@serie3/common/api/crudApi";
import ProjectConfig from "../../../Config/Projects/ProjectConfig";

const createProjectForm = (
  setSubmitError,
  setTags,
  setTickets,
  setFinance,
  setTasks,
  setDocuments,
  setProjectAdresses
) => {
  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    vorname: Yup.string().required("Required"),
    sperrgrund: Yup.string().when("gesperrt", {
      is: true,
      then: Yup.string().required(
        "You must add a reason why this company is blocked"
      ),
    }),
  });

  const onSubmit = async (values) => {

    let modifiedFinance = values.finance.map((finance) => {
      return { id: finance.id }; // Modify the element here if needed
    });

    values.finance = modifiedFinance;

    let modifiedDocuments = values.documents.map((document) => {
      return { id: document.id }; // Modify the element here if needed
    });
    values.documents = modifiedDocuments;
    
    let payload = JSON.stringify(values, null, 2);
    const response = await CrudApi.edit(ProjectConfig.endpoints.edit, payload);
    if (response.status !== 200) {
      setSubmitError(true);
    } else {
      setTags([...response.data.tags]);
      setFinance([...response.data.finance]);
      setDocuments([...response.data.documents]);
      setSubmitError(false);
    }
    return response.status;
  };

  return {
    initialValues: Project,
    onSubmit: (values, actions) => {
      onSubmit(values, actions);
    },
  };
};

const setEditData = (editData) => {
  const date = new Date();
  let Project = {
    id: editData.id,
    finance: editData.finance,
    project_addresses: editData.project_addresses,
    tags: editData.tags,
    title: editData.title,
    description: editData.description,
    start: editData.start,
    end: editData.end,
    max_hours: editData.max_hours,
    daily_hours: editData.daily_hours,
    documents: editData.documents,
    done: editData.done,
    original_id: editData.original_id,
    description: editData.description,
    original_link: editData.original_link,
    last_update: editData.last_update,
    quicklinkList: editData.quicklinkList, 
    show_in_dashboard: editData.show_in_dashboard
  };

  return Project;
};

export default { createProjectForm, setEditData };
