import { Row, Col, Container } from "react-bootstrap";
import CustomModal from "../../../layout/CustomModal";
import { useState } from "react";

const Profiles = () => {
  const [showImage, setShowImage] = useState(false);
  const imagePath = "/dist/img/profiles.jpg";

  return (
    <>
      <CustomModal
        show={showImage}
        setShow={setShowImage}
        size="xl"
        title={"Better chances of getting hired"}
      >
        <img src={imagePath} className="landingpageImageModal" />
      </CustomModal>
      <Container>
        <Row
          style={{
            position: "relative",
            border: "0px solid #000000",
            paddingTop: "4.5rem",
            paddingBottom: "5.5rem",
          }}
        >
          <Col className="bluedotProfile">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="700"
              height="953"
              viewBox="0 0 700 953"
              fill="none"
            >
              <g opacity="0.8" filter="url(#filter0_f_254_9150)">
                <circle cx="224" cy="476.486" r="76" fill="#45C546" />
              </g>
              <defs>
                <filter
                  id="filter0_f_254_9150"
                  x="-252"
                  y="0.486084"
                  width="952"
                  height="952"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feGaussianBlur
                    stdDeviation="200"
                    result="effect1_foregroundBlur_254_9150"
                  />
                </filter>
              </defs>
            </svg>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <span
              style={{
                color: "var(--Blue, #007BFF)",
                textAlign: "center",
                fontFamily: "Sofia Sans",
                fontSize: "1.125rem",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "140%",
              }}
            >
              Create Multiple Profiles
            </span>
            <br />
            <div
              className="landingpageHeader"
              style={{
                fontWeight: "400",
                fontStyle: "normal",
                lineHeight: "110%",
              }}
            >
              More Success in Project Applications with multiple Profiles
            </div>
            <span
              style={{
                color: "var(--Grey, #4D4D4D)",
                fontFamily: "Inter",
                fontSize: "1rem",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "1.5rem",
              }}
            >
              Applying with a profile tailored to a specific job description
              significantly increases your chances of success! Customized
              profiles demonstrate a deep understanding and alignment with a
              company's needs, highlighting your direct relevance and value.
              Stand out by personalizing your approach and showcasing how you're
              the right fit for that specific role and choose from multiple
              user-friendly templates
            </span>
            <br />
            <br />
            <b>
              <i>
                Cooming soon: Application tracking and personalized jobsearch
              </i>
            </b>
            <br />
            <br />
            <div
              className="landingpageImageWrapper"
              style={{ maxWidth: "50%", height: "auto" }}
            >
              <img
                src={imagePath}
                className="landingpageImage"
                onClick={() => {
                  setShowImage(true);
                }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profiles;
