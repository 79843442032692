const ProfileConfig = {
  endpoints: {
    getAll: "/profile/",
    new: "/profile/",
    edit: "/profile/edit",
    show: "/profile/show/",
    delete: "/profile/delete/",
    download: "/profile/download/",
    profilePicture: "/profile/profilePicture/",
    matchingJobs: "/profile/jobs/",
    systemProfile: "/profile/systemprofile",
    editSystemProfile: "/profile/systemprofile/edit",
    socialMedia: {
      getAll: "/profile/socialmedia/",
      new: "/profile/socialmedia/",
      edit: "/profile/socialmedia/edit",
      show: "/profile/socialmedia/show/",
      delete: "/profile/socialmedia/delete/",
    },
    education: {
      getAll: "/profile/education/",
      new: "/profile/education/",
      edit: "/profile/education/edit",
      show: "/profile/education/show/",
      delete: "/profile/education/delete/",
    },
    workexperience: {
      getAll: "/profile/workexperience/",
      new: "/profile/workexperience/",
      edit: "/profile/workexperience/edit",
      show: "/profile/workexperience/show/",
      delete: "/profile/workexperience/delete/",
    },
    skills: {
      new: "/profile/skill/",
      edit: "/profile/skill/edit",
      show: "/profile/skill/show/",
      delete: "/profile/skill/delete/",
    },
  },
  datafield: "profile",
};

export default ProfileConfig;
