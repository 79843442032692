import React from "react";
import { Button, Modal } from "react-bootstrap";


const NewModal = ({ show, setShow, children, ExtraButton, onShow, title, onHide }) => {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => {setShow(false); typeof onHide !== "undefined" && onHide()} }
      dialogClassName="modal-90w"
      onShow={() => {(onShow) && onShow()}}
    >
      <Modal.Header closeButton={true}>
        <Modal.Title id="Billview">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        {ExtraButton ? <ExtraButton /> : null}
        <Button onClick={() => setShow(false)}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewModal;
