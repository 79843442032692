import Finance from "../../Entity/Billing/Finance";
import * as Yup from "yup";
import FinanceApi from "../../api/finance/out";

const createFinanceForm = (type, direction) => {
  const onSubmit = async (values) => {
    let payload = JSON.stringify(values, null, 2);
    const response = await FinanceApi.edit(type, direction, payload);
  };

  return {
    initialValues: Finance,
    onSubmit: (values, actions) => {
      onSubmit(values, actions);
    },
  };
};

const setEditData = (editData) => {
  let Finance = {
    id: editData.id,
    beschreibung: editData.beschreibung,
    post_text: editData.post_text,
    betreff: editData.betreff,
    datum: editData.datum,
    aborechnung: editData.aborechnung,
    privat: editData.privat,
    start: editData.start,
    ende: editData.ende,
    rechnungsinterval: editData.rechnungsinterval,
    versandinterval: editData.versandinterval,
    deadline: editData.deadline,
    wiedervorlage_datum: editData.wiedervorlage_datum,
    status: editData.status,
    geldeingang: editData.geldeingang,
    payment_deadline: editData.payment_deadline,
    payment_due_days: editData.payment_due_days,
    typ: editData.typ,
    autopay: editData.autopay,
    autopay_date: editData.autopay_date,
    eingang: editData.eingang,
    kunde: editData.kunde,
    person: editData.person,
    rechnungs_nummer:editData.rechnungs_nummer,
    final_billing_number:editData.final_billing_number,
    tags: editData.tags,
    draft: editData.draft,
    brutto: editData.brutto,
    netto: editData.netto,
  };
  return Finance;
};

export default { createFinanceForm, setEditData };
