import FreelancerserplaceNavbar from "../signup/SubComponents/FreelancerserplaceNavbar";
import { Col, Row, Container } from "react-bootstrap";
import Footer from "../signup/SubComponents/Footer";

const Imprint = ({setToken}) => {
  return (
    <>
      <FreelancerserplaceNavbar setToken={setToken} />
      <Container>
        <Row>
          <Col>
            <h1>Impressum</h1>

            <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
            <p>
              Felix Dziekan
              <br />
              Geleitsgasse 2
              <br />
              90762 F&uuml;rth
            </p>

            <h2>Kontakt</h2>
            <p>
              Telefon: +49 (0) 17622658978
              <br />
              E-Mail: felix@serie3.de
            </p>

            <h2>Umsatzsteuer-ID</h2>
            <p>
              Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a
              Umsatzsteuergesetz:
              <br />
              218 / 212 / 91485
            </p>

            <h2>
              Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
            </h2>
            <p>
              Wir sind nicht bereit oder verpflichtet, an
              Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
              teilzunehmen.
            </p>

            <p>
              Quelle: <a href="https://www.e-recht24.de">e-recht24.de</a>
            </p>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Imprint;
