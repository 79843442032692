import crudApi from "@serie3/common/api/crudApi";
import { Button } from "react-bootstrap";
import { useState } from "react";
import Card from "../../../../../../layout/Cards/Card";
import ProjectConfig from "@serie3/freelancesplaceapi/Config/Projects/ProjectConfig";
import NewModal from "../../../../documents/modals/NewModal";

const ProjectSettings = ({
  formik,
  project,
  projectId,
  publicId,
  setPublicId,
  submitError,
}) => {
  const [showPassword, setShowPassword] = useState();
  const [password, setPassword] = useState();
  const [hasQuicklink, setHasQuicklink] = useState(project.quicklinklist);
  const [isInDashboard, setShowIsInDashboard] = useState(
    project.show_in_dashboard
  );

  const shareLInk =
    window.location.protocol +
    "//" +
    window.location.host +
    "/publicdashboard/" +
    publicId;

  const addToQuickLinkList = async () => {
    formik.setFieldValue("quicklinklist", true);
    await formik.handleSubmit();
    if (submitError === false) {
      setHasQuicklink(true);
    }
  };

  const removeFromQuickLinkList = async () => {
    formik.setFieldValue("quicklinklist", false);
    await formik.handleSubmit();
    if (submitError === false) {
      setHasQuicklink(false);
    }
  };

  const addToDashboard = async () => {
    formik.setFieldValue("show_in_dashboard", true);
    await formik.handleSubmit();
    if (submitError === false) {
      setShowIsInDashboard(true);
    }
  };

  const removeFromDashboard = async () => {
    formik.setFieldValue("show_in_dashboard", false);
    await formik.handleSubmit();
    if (submitError === false) {
      setShowIsInDashboard(false);
    }
  };

  const setLink = async () => {
    const response = await crudApi.justGet(
      ProjectConfig.endpoints.share + projectId
    );
    if (response.status === 200) {
      setPublicId(response.data.uniqueId);
      setPassword(response.data.password);
      setShowPassword(true);
    }
  };

  const PasswordModal = () => {
    return (
      <NewModal
        setShow={setShowPassword}
        show={showPassword}
        title={"Password"}
      >
        This is the Password for the customer. Send it to your customer on a
        different channel than the link. You will never see this password again,
        but you can generate a new any time!
        <br />
        <br />
        Password: <b> {password} </b>
      </NewModal>
    );
  };

  return (
    <>
      <PasswordModal />
      <Card title={"Quicklink"}>
        {hasQuicklink === false ? (
          <Button onClick={addToQuickLinkList}>Add to Quicklinks</Button>
        ) : (
          <Button onClick={removeFromQuickLinkList}>
            Remove from Quicklinks
          </Button>
        )}
      </Card>
      <Card title={"Dashboard"}>
        {isInDashboard === false ? (
          <Button onClick={addToDashboard}>Add to Dashboard</Button>
        ) : (
          <Button onClick={removeFromDashboard}>Remove from Dashboard</Button>
        )}
      </Card>
      <br />
      <Card>
        {publicId ? (
          <>
            <a href={shareLInk}> {shareLInk} </a>
            <br />
            <br />
            <Button onClick={setLink}>Change Password</Button>
          </>
        ) : (
          <Button onClick={setLink}>Share</Button>
        )}
        <br />
        <br />
        <i>
          <b>
            Remember: This is not a secure connection. Don't share sensible
            data!
          </b>
        </i>
      </Card>
    </>
  );
};

export default ProjectSettings;
