import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import DocumentsHeader from "../DocumentsHeader";
import Content from "../../../../layout/Content";
import { Col, Row } from "react-bootstrap";
import DocumentDetails from "./SubComponents/DocumentDetails";
import CrudApi from "@serie3/common/api/crudApi";

const Edit = () => {
  let { id } = useParams();
  const [document, setDocument] = useState(null);

  const loadData = async () => {
    const response = await CrudApi.show("/documents/show/", id);
    setDocument(response);
  };

  useEffect(() => {
    loadData();
  }, []);

  const Title = () => {
    return (
      <>
        <b>Document: &nbsp; </b>
      </>
    );
  };

  return (
    <>
      <DocumentsHeader />
      <Content Title={Title}>
        <Row>
          <Col sm={3}>
            {document ? <DocumentDetails Document={document} /> : null}
          </Col>
        </Row>
      </Content>
    </>
  );
};

export default Edit;
