import Content from "../../../../layout/Content";
import ContentHeader from "../../../../layout/ContenteHeader";
import { Button, Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import JobConfig from "@serie3/freelancesplaceapi/Config/Projects/JobConfig";
import crudApi from "@serie3/common/api/crudApi";
import { useNavigate } from "react-router-dom";
import CompleteDropdown from "../../../../layout/Dropdowns/CompleteDropdown";
import listHandler from "@serie3/common/Domain/listHandler";
import JobView from "./JobView";
import getSettings from "@serie3/common/config/settings";
import PaymentModal from "../../../../layout/Payment/PaymentModal";


const Index = () => {
  const [jobs, setJobs] = useState([]);
  const [showRowError, setShowRowError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showView, setShowView] = useState(false);
  const [viewJob, setViewJob] = useState();
  const [showPayModal, setShowPayModal] = useState(false);
  const [settings, setSettings] = useState();

  const navigate = useNavigate();

  const title = () => {
    return (
      <>
        <b>Outsource Jobs &nbsp; </b>
      </>
    );
  };

  const createNew = async () => {
    const response = await crudApi.createNew(JobConfig.endpoints.new, {
      jobtitle: " ",
    }, setShowPayModal);
    if (response.status === 200) {
      navigate(JobConfig.endpoints.edit + "/" + response.data.id);
    } else {
      setShowRowError(true);
    }
  };

  const NewButton = () => {
    return (
      <Button className="success" onClick={createNew}>
        New <FontAwesomeIcon icon={faPlus} />
      </Button> 
    );
  };

  const editAction = (id) => {
    navigate(JobConfig.endpoints.edit + "/" + id);
  };

  const deleteAction = async (index, job) => {
    listHandler.deleteFromList(
      index,
      crudApi.del(JobConfig.endpoints.delete, job.id),
      setJobs,
      jobs,
      setShowRowError
    );
  };

  const viewAction = (job) => {
    setViewJob(job);
    setShowView(true);
  };

  useEffect(() => {
    const endpoint = JobConfig.endpoints.getAll;
    crudApi.getAll(endpoint, setJobs, "job");
    setSettings(getSettings());
    setIsLoaded(true);
  }, []);

  const Marker = ({ index }) => {
    if (index === 0) {
      return (
        <span style={{ color: "red" }}>
          <b>!!</b>
        </span>
      );
    }
    return <b>#</b>;
  };

  return isLoaded ? (
    <>
      {settings && <PaymentModal show={showPayModal} setShow={setShowPayModal} priceTableId={settings.pricingTables.jobs}/>}
      <JobView job={viewJob} showView={showView} setShowView={setShowView} />
      <ContentHeader />
      <Content Title={title} AdditionalButtons={NewButton}>
        <Table hover className={"table table-striped projects"}>
          <thead>
            <tr>
              <th>#</th>
              <th style={{ width: "20%" }}>Job Title</th>
              <th style={{ width: "20%" }}>Freelancers available</th>
              <th>Needed Skills </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {jobs
              ? jobs.map((job, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <Marker index={index} />
                      </td>
                      <td>
                        <a>
                          <b>{job.jobtitle}</b>
                        </a>
                        &nbsp;
                        <br />
                        <small style={{ fontSize: "80%" }}>
                          Starting: {job.start}
                        </small>
                      </td>
                      <td className={"project_progress"}>
                        <div
                          className={"progress progress-sm"}
                          style={{ height: "10px" }}
                        >
                          <div
                            className={"progress-bar bg-green"}
                            role="progressbar"
                            aria-valuenow="57"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: "57%" }}
                          ></div>
                        </div>
                        <small style={{ fontSize: "80%" }}>57% Complete</small>
                      </td>
                      <td>
                        {job.languages.map((language) => {
                          return (
                            <>
                              <span className={"badge badge-success"}>
                                {language.name}
                              </span>
                              &nbsp;
                            </>
                          );
                        })}
                      </td>
                      <td>
                        <CompleteDropdown
                          deleteAction={() => {
                            deleteAction(index, job);
                          }}
                          viewAction={() => {
                            viewAction(job);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })
              : null}
          </tbody>
        </Table>
      </Content>
    </>
  ) : null;
};

export default Index;
