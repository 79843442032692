import { useState, useEffect } from "react";
import useToken from "../../../../business/security/useToken";
import { Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import LoginModal from "../../../signup/LoginModal";

const Timer = () => {
  const countdown = 59;
  const [remainingTime, setRemainingTime] = useState(countdown * 60); // Convert minutes to seconds
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [restart, setRestart] = useState(false);
  const { token, setToken } = useToken();

  useEffect(() => {
    const loginTime = sessionStorage.getItem("loginTime"); // Retrieve the start time from session storage

    if (loginTime) {
      const startTime = parseInt(loginTime, 10);
      const targetTime = startTime + countdown * 60000;

      const interval = setInterval(() => {
        const currentTime = new Date().getTime();
        const remainingSeconds = Math.floor((targetTime - currentTime) / 1000);

        setRemainingTime(remainingSeconds);

        if (remainingSeconds <= 0) {
          clearInterval(interval);
          setShowLoginForm(true);
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [countdown, restart]);

  const minutes = Math.floor(remainingTime / 60);
  const seconds = remainingTime % 60;

  const timerTip = (
    <Tooltip id="draft">
      <small>
        Your session will time out in &nbsp;
        {minutes < 10 ? `0${minutes}` : minutes}:
        {seconds < 10 ? `0${seconds}` : seconds}
        &nbsp;Minutes
      </small>
    </Tooltip>
  );

  const timertitle =
    remainingTime <= 0
      ? "Your Session has timed out - Relogin now"
      : "Your Session has not timed out yet but you can renew it";
  const updateTimerAndToken = (token, localId) => {
    setToken(token, localId);
    setRemainingTime(countdown * 60);
    setRestart(!restart);
  };

  return (
    <>
      <LoginModal
        show={showLoginForm}
        setShow={setShowLoginForm}
        setToken={updateTimerAndToken}
        title={"Renew Session"}
      />

      <OverlayTrigger placement="bottom" overlay={timerTip}>
        <Button
          className="nav-item btn-light"
          onClick={() => {
            setShowLoginForm(true);
          }}
        >
          {minutes < 10 ? `0${minutes}` : minutes}:
          {seconds < 10 ? `0${seconds}` : seconds}
        </Button>
      
      </OverlayTrigger>
    </>
  );
};

export default Timer;
