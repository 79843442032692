import { create } from "apisauce";
import getSettings from "../config/settings";


const settings = getSettings();

const binaryClient = create({
  baseURL: settings.apiUrl,
  responseType: "blob",
});

binaryClient.addAsyncRequestTransform(async (request) => {
  let authToken;
  let userId;
  if (typeof authStorage !== "undefined") {
    authToken = await authStorage.getToken();
  } else {
    const tokenString = sessionStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    userId = JSON.parse(sessionStorage.getItem("localId"));
    authToken = userToken;
  }
  if (!authToken) return;
  request.headers["x-auth-token"] = authToken;
  request.headers["x-auth-id"] = userId;
});

export default binaryClient;
