import Person from "../../Entity/Crm/Person";
import * as Yup from "yup";
import CrudApi from "@serie3/common/api/crudApi";

const createPersonForm = (showErrorFunction) => {
  const validationSchema = Yup.object().shape({
    anrede: Yup.string().required("Required"),
    geschlecht: Yup.string().required("Required"),
  });

  const onSubmit = async (values) => {
    let payload = JSON.stringify(values, null, 2);
    const response = await CrudApi.edit("/crm/person/edit", payload);
  };

  return {
    initialValues: Person,
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      onSubmit(values, actions);
    },
  };
};

const setEditData = (editData) => {
  let Person = {
    id: editData.id,
    geschlecht: editData.geschlecht,
    anrede: editData.anrede,
    name: editData.name,
    vorname: editData.vorname,
    position: editData.position,
    bemerkung: editData.bemerkung,
    gesperrt: editData.gesperrt,
    sperrgrund: editData.sperrgrund,
    geburtstag: editData.geburtstag,
    original_id: editData.original_id,
    source: editData.source,   
    tax_id: editData.tax_id,
    tax_number: editData.tax_number,
  };

  return Person;
};

export default { createPersonForm, setEditData };

