import ProfileDropdown from "./elements/ProfileDropdown";
import ProjectQuicklink from "./elements/ProjectQuicklink";
import Timer from "./elements/Timer";
const HeaderNav = () => {
  return (
    <nav className="main-header navbar navbar-expand navbar-white navbar-light">
      {/* Left navbar links */}
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link" data-widget="pushmenu" href="#" role="button">
            <i className="fas fa-bars" />
          </a>
        </li>
      </ul>
      {/* Right navbar links */}
      <ul className="navbar-nav ml-auto">
        <Timer/>
        &nbsp;
        &nbsp;
        <ProjectQuicklink />
        {/* Messages Dropdown Menu */}
        <ProfileDropdown />
      </ul>
    </nav>
  );
};

export default HeaderNav;
