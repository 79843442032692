import React from "react";
import ListElement from "../../navbars/elements/ListElement";
import { faClock } from "@fortawesome/free-solid-svg-icons";

const TimetrackingSideNav = () => {
  return (
    <>
      <li className="nav-header">Timetracking</li>
      <ListElement
        name="Timetracking"
        targetUrl="/timetracking/"
        linkStyle="far nav-icon"
        icon={faClock}
      />
    </>
  );
};

export default TimetrackingSideNav;
