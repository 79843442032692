import { useState, useEffect, useRef } from "react";
import crudApi from "@serie3/common/api/crudApi";
import TodoConfig from "@serie3/freelancesplaceapi/Config/Todo/TodoConfig";
import listHandler from "@serie3/common/Domain/listHandler";
import TabBody from "../../../layout/Tabs/TabBody";
import TabHeader from "../../../layout/Tabs/TabHeader";
import TabContent from "../../../layout/Tabs/TabContent";
import TabLink from "../../../layout/Tabs/TabLink";
import { faCalendarDay, faList } from "@fortawesome/free-solid-svg-icons";
import TodoList from "./TodoList";
import NewModal from "../../../layout/Popups/NewModal";

const Index = () => {
  const [dailyTodos, setDailyTodos] = useState([]);
  const [todos, setTodos] = useState([]);
  const [showError, setShowError] = useState(false);
  const [showMaxReached, setShowMaxReached] = useState(false);

  const prefix = "todoList";
  const todoRef = useRef(null);
  const dailyTodoRef = useRef(null);

  useEffect(() => {
    const fetchData = async (endpoint, todoListFunction) => {
      try {
        const response = await crudApi.getAll(endpoint);
        if (response.status === 200) {
          todoListFunction(response.data["todo"]);
          todoRef.current.value = "";
          dailyTodoRef.current.value = "";
        } else {
          setShowError(true);
        }
      } catch (error) {
        setShowError(true);
      }
    };

    fetchData(TodoConfig.endpoints.getAll, setTodos);
    fetchData(TodoConfig.endpoints.getDaily, setDailyTodos);
  }, []);

  const addTodo = async (
    todoList,
    todoListFunction,
    dailyTodo = false,
    currentTodoRef
  ) => {
    const response = await crudApi.createNew(TodoConfig.endpoints.new, {
      value: currentTodoRef.current.value,
      key_name: "todoElement",
      done: false,
      daily: dailyTodo,
      date: new Date().toISOString().split("T")[0],
    });
    if (response.status === 200) {
      listHandler.newToList(response, todoList, todoListFunction, setShowError);
      currentTodoRef.current.value = "";
    } else {
      if (response.status === 403) {
        setShowMaxReached(true);
      }
    }
  };

  const deleteTodo = async (deleteItem) => {
    const itemIndexDaily = dailyTodos.findIndex(
      (obj) => obj.id === deleteItem.id
    );
    let deleted = false;
    if (itemIndexDaily > -1) {
      listHandler.deleteFromList(
        itemIndexDaily,
        crudApi.del(TodoConfig.endpoints.delete, deleteItem.id),
        setDailyTodos,
        dailyTodos,
        showError
      );
      deleted = true;
    }

    const itemIndexTodo = todos.findIndex((obj) => obj.id === deleteItem.id);
    if (itemIndexTodo > -1) {
      if (deleted === false) {
        listHandler.deleteFromList(
          itemIndexTodo,
          crudApi.del(TodoConfig.endpoints.delete, deleteItem.id),
          setTodos,
          todos,
          showError
        );
      } else {
        var dataList = listHandler.deleteWithoutCall(deleteItem, todos);
        setTodos([...dataList]);
      }
    }
  };

  return (
    <>
      <NewModal
        show={showMaxReached}
        setShow={setShowMaxReached}
        title={"Keep it simple"}
      >
        Only 5 daily todo items per day. You only got 24 hours... If you have
        more create a project!
      </NewModal>
      <TabHeader prefix={prefix}>
        <TabLink
          prefix={prefix}
          name={"daily"}
          text={"Todays To-Dos"}
          icon={faCalendarDay}
        />
        <TabLink
          prefix={prefix}
          name={"all"}
          text={"General To-Do List"}
          icon={faList}
        />
      </TabHeader>
      <TabBody>
        <TabContent prefix={prefix} name={"daily"} show={true}>
          <TodoList
            todos={dailyTodos}
            addTodo={() => {
              addTodo(dailyTodos, setDailyTodos, true, dailyTodoRef);
            }}
            deleteTodo={(todo) => {
              deleteTodo(todo);
            }}
            todoRef={dailyTodoRef}
            dailyList={true}
          />
        </TabContent>
        <TabContent prefix={prefix} name={"all"} show={false}>
          <TodoList
            todos={todos}
            dailyTodos={dailyTodos}
            addTodo={() => {
              addTodo(todos, setTodos, false, todoRef);
            }}
            deleteTodo={(todo) => {
              deleteTodo(todo);
            }}
            todoRef={todoRef}
            setShowMaxReached={setShowMaxReached}
            setTodos={setTodos}
            setDailyTodos={setDailyTodos}
          />
        </TabContent>
      </TabBody>
    </>
  );
};

export default Index;
