

const TabHeader = ({ ExtraButtons, prefix, children, style }) => {
  return (
    <div className="card-header p-2 border-bottom-0">
      <div className="card-title" style={{...style}}>
        <ul className="nav nav-pills" id={prefix + "-tab"} role="tablist">
          {children}
        </ul>
      </div>
      <div className="card-tools ml-auto">
        {ExtraButtons ? <ExtraButtons /> : null}
      </div>
    </div>
  );
};

export default TabHeader;
