import { useEffect } from "react";
import getSettings from "@serie3/common/config/settings";
import { useState } from "react";
import SettingsContext from "@serie3/common/auth/SettingsContext";
import { useContext } from "react";


const StripePricingTable = ({ priceTableId }) => {
  const [systemSettings, setSystemSettings] = useState(null);
  const {settings, setSettings} = useContext(SettingsContext);

  useEffect(() => {
    const localSettings = getSettings();
    setSystemSettings(localSettings);
  }, []);
  
  return (systemSettings && systemSettings.stripePubKey) && (settings && settings.payment_id) ? (
    <stripe-pricing-table
      pricing-table-id={priceTableId}
      publishable-key={systemSettings.stripePubKey}
      client-reference-id={settings.payment_id}
    />
  ) : null;
};

export default StripePricingTable;
