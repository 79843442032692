import { Row, Col } from "react-bootstrap";
import Card from "../../../layout/Cards/Card";
import ContentHeader from "../../../layout/ContenteHeader";
import InfoBox from "../../../layout/Boxes/InfoBox";
import {
  faMoneyBill,
  faList,
  faHourglass,
  faBusinessTime,
} from "@fortawesome/free-solid-svg-icons";
import TodoIndex from "../todo/Index";
import ProjectDashboard from "./ProjectDashboard.jsx";
import JobRowDashboard from "./JobRowDashboard";
import DashboardConfig from "@serie3/freelancesplaceapi/Config/Dashboard/DashboardConfig";
import { useEffect, useState } from "react";
import crudApi from "@serie3/common/api/crudApi";
import PublicJobView from "../profiles/PublicJobView";
import TimetrackingConfig from "@serie3/freelancesplaceapi/Config/Timetracking/TimetrackingConfig";
import ProjectConfig from "@serie3/freelancesplaceapi/Config/Projects/ProjectConfig.js";

const MainDashboard = () => {
  const [matchingJobs, setMatchingJobs] = useState([]);
  const [maxScore, setMaxScore] = useState(100);
  const [viewJob, setViewJob] = useState();
  const [showView, setShowView] = useState(false);
  const [totalIncome, setTotalIncome] = useState(0);
  const [doneTickets, setDoneTickets] = useState(0);
  const [totalHours, setTotalHours] = useState(0);
  const [dashboardProjects, setDashboardProjects] = useState([]);

  useEffect(() => {
    const loadJobs = async () => {
      const allJobs = await crudApi.getAll(
        DashboardConfig.enpoints.dashboardJobs
      );
      if (allJobs.status === 200) {
        setMatchingJobs(allJobs.data.hits.hits);
        setMaxScore(allJobs.data.hits.max_score);
      }
      const totalIncomeResponse = await crudApi.justGet(
        "/finance/simplebalance/"
      );
      if (totalIncomeResponse.status === 200) {
        setTotalIncome(parseFloat(totalIncomeResponse.data).toFixed(2));
      }
      const totalHoursResponse = await crudApi.justGet(
        TimetrackingConfig.endpoints.totalMonthlyHours
      );
      if (totalHoursResponse.status === 200) {
        setTotalHours(totalHoursResponse.data);
      }
    };

    const loadProjects = async () => {
      const allJobs = await crudApi.getAll(
        ProjectConfig.endpoints.showindashboard,
        setDashboardProjects,
        'project'
      );
    };
    loadJobs();
    loadProjects();
  }, []);

  const showJobDetails = (job) => {
    setViewJob(job);
    setShowView(true);
  };

  return (
    <>
      {viewJob ? (
        <PublicJobView
          jobId={viewJob._source.id}
          showView={showView}
          setShowView={setShowView}
        />
      ) : null}
      <ContentHeader>
        <h1 className="m-0">Dashboard</h1>
      </ContentHeader>
      <section className="content">
        <Row>
          <Col className="col-sm-6 col-md-3 col-12">
            <InfoBox
              color={"bg-info"}
              icon={faMoneyBill}
              title={"Income"}
              message={totalIncome + " €"}
              textStyle={{ color: totalIncome > 0 ? "black" : "darkred" }}
            />
          </Col>
          <Col className="col-sm-6 col-md-3 col-12">
            <InfoBox
              color={"bg-success"}
              icon={faList}
              title={"Done"}
              message={doneTickets + " %"}
            />
          </Col>
          <Col className="col-sm-6 col-md-3 col-12">
            <InfoBox
              color={"bg-warning"}
              icon={faHourglass}
              title={"Total Hours"}
              message={totalHours + " h"}
            />
          </Col>
          <Col className="col-sm-6 col-md-3 col-12">
            <InfoBox
              color={"bg-secondary"}
              icon={faBusinessTime}
              title={"Jobs"}
              message={matchingJobs.length}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={8} md={8} style={{ height: "450px" }}>
            <TodoIndex show />
          </Col>
          <Col sm={4} md={4} style={{ height: "450px" }}>
            <Card title={"Jobs"} style={{ height: "450px" }}>
              {matchingJobs.length > 0
                ? matchingJobs.map((job, index) => {
                    return (
                      <JobRowDashboard
                        job={job}
                        key={index}
                        maxScore={maxScore}
                        showDetails={showJobDetails}
                      />
                    );
                  })
                : null}
            </Card>
          </Col>
        </Row>
        <br />
        {dashboardProjects.map((project, index) => {
          return (
            <ProjectDashboard
              key={project.id}
              setDoneTickets={setDoneTickets}
              projectId={project.id}
            />
          );
        })}
      </section>
    </>
  );
};

export default MainDashboard;
