import ContentHeader from "../../../layout/ContenteHeader";
import TabContent from "../../../layout/Tabs/TabContent";
import TabBody from "../../../layout/Tabs/TabBody";
import TabLink from "../../../layout/Tabs/TabLink";
import TabHeader from "../../../layout/Tabs/TabHeader";
import {
  faCogs,
  faMoneyBill,
  faUser,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import SettingsContext from "@serie3/common/auth/SettingsContext";
import { useContext, useEffect, useState } from "react";
import Subscriptions from "./Subscriptions";
import SystemProfile from "./Systemprofile";
import DeleteAccount from "./DeleteAccount";
import crudApi from "@serie3/common/api/crudApi";
import UserConfig from "@serie3/freelancesplaceapi/Config/User/UserConfig";
import NewModal from "../documents/modals/NewModal";

const UserProfile = () => {
  const prefix = "user-settings";
  const settings = useContext(SettingsContext);
  const [showConfirm, setShowConfirm] = useState(false);
  const [error, setError] = useState(false);
  const [deleteDate, setDeleteDate] = useState();
  const [showCancel, setShowCancel] = useState(false);

  useEffect(() => {
    const makeCalls = async () => {
      const pathname = window.location.pathname;
      if (pathname.includes("confirmdelete")) {
        const searchParamsString = window.location.search;
        const response = await crudApi.justGet(
          UserConfig.endpoints.confirmDeleteAccount + searchParamsString
        );


        if (response.status === 200) {
          if(typeof response.data.date === "undefined"){
            console.log('blubb');
            setError(true);
          }
          setDeleteDate(response.data.date);
          setShowConfirm(true);
        } else {
          setError(true);
          showConfirm(true);
        }
      }

      if(pathname.includes("confirmcanceldelete")) {
        const searchParamsString = window.location.search;
        const response = await crudApi.justGet(
          UserConfig.endpoints.confirmCancelDelete + searchParamsString
        )
        if(response.status === 200){
          setShowCancel(true);
        }else{
          setError(true);
          setShowCancel(true);
        }
      }
    };
    makeCalls();
  }, []);

  const ConfirmDelete = () => {
    return (
      <NewModal show={showConfirm} setShow={setShowConfirm}>
        {(error || typeof deleteDate === "undefined")
          ? "There was an error confirming the request. Plz contact support"
          : ("Your account will be deleted on " + deleteDate.split(" ")[0]  + ". You cancel that any time!")}
      </NewModal>
    );
  };


  const ConfirmCancel = () => {
    return (
      <NewModal show={showCancel} setShow={setShowCancel}>
        {error
          ? "There was an error confirming the request. Plz contact support"
          : "Your account will no longer be deleted, all your subscriptions will continue"}
      </NewModal>
    );
  };

  return (
    <>
      <ConfirmCancel />
      <ConfirmDelete />
      <ContentHeader>User Profile</ContentHeader>
      <section className="content" style={{ height: "unset" }}>
        <div className="card" id="userprofile">
          <TabHeader prefix={prefix}>
            <TabLink
              prefix={prefix}
              name={"Profile"}
              icon={faUser}
              show={true}
            />
            <TabLink prefix={prefix} name={"Settings"} icon={faCogs} />
            <TabLink
              prefix={prefix}
              name={"Subscriptions"}
              icon={faMoneyBill}
            />
            <TabLink prefix={prefix} name={"Delete"} icon={faTrash} />
          </TabHeader>

          <TabBody prefix={prefix} name={"billing_settings"}>
            <TabContent prefix={prefix} name={"Profile"} show={true}>
              <SystemProfile />
            </TabContent>
            <TabContent prefix={prefix} name={"Settings"}></TabContent>
            <TabContent prefix={prefix} name={"Subscriptions"}>
              <Subscriptions subscriptions={settings.settings.subscriptions} />
            </TabContent>
            <TabContent prefix={prefix} name={"Delete"}>
              <DeleteAccount />
            </TabContent>
          </TabBody>
        </div>
      </section>
    </>
  );
};

export default UserProfile;
