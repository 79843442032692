import { Col } from "react-bootstrap";

const Feature = ({ icon, title, text }) => {
  return (
    <Col md={6} sm={12} className="mb-3">
      <div className="d-flex">
        <img src={icon} alt="icon3" className="me-2" />
        <div>
          <h4 className="landingpage">{title}</h4>
          <p>{text}</p>
        </div>
      </div>
    </Col>
  );
};
export default Feature;
