import { Modal } from "react-bootstrap";

const PDFModal = ({ showSpinner, setShowSpinner }) => {
  return (
    <Modal show={showSpinner} setShow={setShowSpinner}>
      <Modal.Body
        style={{ textAlign: "center", backgroundColor: "transparent" }}
      >
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div>Generating PDF...(might take a bit)</div>
      </Modal.Body>
    </Modal>
  );
};

export default PDFModal;