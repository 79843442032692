import { Form } from "react-bootstrap";

const Draft = ({ formik, formClass, checkboxClass }) => {
  return (
    <Form.Group className={checkboxClass ? formClass : null} controlId="draft">
      <Form.Check
        className={checkboxClass ? checkboxClass : null}
        type="checkbox"
        name="draft"
        label="Draft"
        checked={formik.values.draft}
        onChange={(e) => {
          formik.handleChange(e)
          formik.setFieldValue('draft', e.target.checked);
        }}
      />
    </Form.Group>
  );
};

const Payed = ({ formik, formClass, checkboxClass }) => {
  return (
    <Form.Group
      className={checkboxClass ? formClass : null}
      controlId="geldeingang"
    >
      <Form.Check
        className={checkboxClass ? checkboxClass : null}
        type="checkbox"
        name="geldeingang"
        label="Payed"
        checked={formik.values.geldeingang}
        onChange={(e) => {
          formik.handleChange(e)
          formik.setFieldValue('geldeingang', e.target.checked);
        }}
      />
    </Form.Group>
  );
};

const Debited = ({
  formik,
  setOpenDebitDate,
  openDebitDate,
  formClass,
  checkboxClass,
}) => {
  return (
    <Form.Group
      className={checkboxClass ? formClass : null}
      controlId="autopay"
    >
      <Form.Check
        className={checkboxClass ? checkboxClass : null}
        type="checkbox"
        name="autopay"
        label="will be debited"
        checked={formik.values.autopay}
        onChange={(e) => {
          formik.handleChange(e);
          formik.setFieldValue('autopay', e.target.checked);
          setOpenDebitDate(!openDebitDate);
        }}
      />
    </Form.Group>
  );
};

const Private = ({ formik, formClass, checkboxClass }) => {
  return (
    <Form.Group className={checkboxClass ? formClass : null} controlId="privat">
      <Form.Check
        className={checkboxClass ? checkboxClass : null}
        type="checkbox"
        name="privat"
        label="Private"
        checked={formik.values.privat}
        onChange={(e) => {
          formik.handleChange(e);
          formik.setFieldValue('privat', e.target.checked);
        }}
      />
    </Form.Group>
  );
};

export default { Debited, Private, Payed, Draft };
