import { Pie } from "react-chartjs-2";
import { useEffect, useState } from "react";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";

Chart.register(CategoryScale);

const PieChart = ({ Data = [] }) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const tmpChartData = {
      labels: Data.map((Data) => Data.label),
      datasets: [
        {
          data: Data.map((Data) => Data.number),
          backgroundColor: [
            "#007bff",
            "#17a2b8",
            "#6c757d",
            "#28a745",
            "#dc3545",
          ],
          borderColor: "black",
          borderWidth: 1.5,
        },
      ],
    };
    setChartData(tmpChartData);
  }, [Data]);

  return chartData ? (
    <div className="chart-container">
      <Pie data={chartData} style={{ height: "355px", maxHeight: "355px" }} />
    </div>
  ) : null;
};

export default PieChart;
