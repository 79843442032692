import PieChart from "../../../../../layout/Charts/Pie";
import { useState, useEffect } from "react";

const TicketChart = ({ tickets = [], setDoneTickets }) => {
  const [dataSet, setDataSet] = useState([]);
  useEffect(() => {
    if (tickets.length > 0) {
      let todoCount = 0;
      let inProgressCount = 0;
      let doneCount = 0;
      let onHoldCount = 0;
      let canceledCount = 0;

      tickets.map((ticket, index) => {
        switch (ticket.action.status) {
          case "Created":
            todoCount++;
            break;
          case "In_Progress":
            inProgressCount++;
            break;
          case "Done":
            doneCount++;
            break;
          case "Canceled":
            canceledCount++;
            break;
          case "On_Hold":
            onHoldCount++;
            break;
          default:
            todoCount++;
            break;
        }
      });

      setDataSet([
        {
          label: "To do",
          number: todoCount,
        },
        {
          label: "In Progress",
          number: inProgressCount,
        },
        {
          label: "On Hold",
          number: onHoldCount,
        },
        {
          label: "Done",
          number: doneCount,
        },

        {
          label: "Canceled",
          number: canceledCount,
        },
      ]);

      setDoneTickets && setDoneTickets(parseInt((doneCount / tickets.length)*100));
    }
  }, [tickets]);

  return dataSet ? <PieChart Data={dataSet} /> : null;
};

export default TicketChart;
