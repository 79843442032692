import Content from "../../../layout/Content";
import crudApi from "@serie3/common/api/crudApi";
import { Row, Col, Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import ContentHeader from "../../../layout/ContenteHeader";
import ApplicationConfig from "@serie3/freelancesplaceapi/Config/Profiles/ApplicationConfig";
import ApplicationRow from "./ApplicationRow";
import ApplicationFormModal from "./Forms/ApplicationFormModal";
import SaveButton from "../../../layout/Buttons/SaveButton";
import { useFormik } from "formik";
import ApplicationForm from "@serie3/freelancesplaceapi/Form/Profile/ApplicationForm";
import listHandler from "@serie3/common/Domain/listHandler";


const ApplicationIndex = () => {
  const [applications, setApplications] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [editApplication, setEditApplication] = useState(null);

  const editApplicationForm = ApplicationForm.createApplicationForm(applications, setApplications, setShowForm);
  const formik = useFormik(editApplicationForm);

  const Save = () => {
    return (
      <SaveButton
        handleClick={(e) => {
          formik.handleSubmit(e);
          e.preventDefault();
        }}
      />
    );
  };

  const title = () => {
    return "Applications";
  };

  const deleteAction = (applicationId, index) => {
    const deleteUrl = ApplicationConfig.endpoints.delete;
    listHandler.deleteFromList(
      index,
      crudApi.del(deleteUrl, applicationId),
      setApplications,
      applications
    );
  };

  useEffect(() => {
    const getAll = async () => {
      const endpoint = ApplicationConfig.endpoints.getAll;
      const allApplicationsResponse = await crudApi.getAll(endpoint);
      let allApplications =
        allApplicationsResponse.data[ApplicationConfig.datafield];

      if (window.location.search.length > 0) {
        let applicationData = window.location.search;
        if (applicationData.startsWith("?")) {
          applicationData = applicationData.slice(1);
        }

        let pairs = applicationData.split("&");
        let result = {};

        pairs.forEach((pair) => {
          let [key, value] = pair.split("=");
          result[decodeURIComponent(key)] = decodeURIComponent(value);
        });

        if (
          'job_title' in result &&
          result["job_title"] !== "undefined" &&
          result["job_title"].length > 0
        ) {
          const payload = JSON.stringify(result);
          const applicationResponse = await crudApi.createNew(
            ApplicationConfig.endpoints.new,
            payload
          );

          if (applicationResponse.status === 200) {
            allApplications.unshift(applicationResponse.data);
          }
        } else{
          setEditApplication(result);
          setShowForm(true);
        }
      }

      setApplications(allApplications);
    };

    getAll();
  }, []);

  const editAction = (application) => {
    setEditApplication(application);
    setShowForm(true);
  };


  return (
    <>
      <ApplicationFormModal
        showView={showForm}
        setShowView={setShowForm}
        application={editApplication}
        SaveButton={Save}
        formik={formik}
      />
      <ContentHeader />
      <Content Title={title}>
        <Row>
          <Col>
            <Table striped>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Job title</th>
                  <th>Job page</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {applications
                  ? applications.map((application, index) => (
                      <ApplicationRow
                        key={application.id}
                        application={application}
                        editAction={editAction}
                        deleteAction={() => {deleteAction(application.id, index)}}
                      />
                    ))
                  : null}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Content>
    </>
  );
};

export default ApplicationIndex;
