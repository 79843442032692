import { Container } from "react-bootstrap";

const Features = ({children}) => {

    return (

        <Container className="my-5" id="feature">
            <h2 class="landingpage" style={{marginLeft: "50px"}}>What we do for you!</h2>
            <br />
            {children}
        </Container>
    );
}

export default Features;