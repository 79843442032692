import { useState, useEffect } from "react";
import { useFormik } from "formik";
import createRegisterForm from "@serie3/common/Form/Register/RegisterForm";
import { Form, Collapse, Modal } from "react-bootstrap";

/**
 * showLoginForm can be true or false. If false the Login-link will redirect to the startpage. if true your component will need to also handle the loginform
 */
const RegisterModal = ({
  show,
  setShow,
  setShowLogin,
  showLoginForm = true,
}) => {
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showProcess, setShowProcess] = useState(false);

  const registerForm = createRegisterForm(
    setShowSuccess,
    setShowError,
    setShowProcess
  );
  const formik = useFormik(registerForm);

  useEffect(() => {
    setShowSuccess(false);
    setShowError(false);
  }, [show]);

  const showLogin = () => {
    setShow(false);
    setShowLogin(true);
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => setShow(false)}
      dialogClassName="modal-80w"
      style={{
        content: {
          position: "absolute",
          top: "40px",
          left: "40px",
          right: "40px",
          bottom: "40px",
          border: "10px solid #ccc",
          background: "#fff",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          borderRadius: "4px",
          outline: "none",
          padding: "20px",
        },
      }}
    >
      <Modal.Header closeButton={true} style={{ border: "0px" }} />
      <Modal.Body
        style={{ textAlign: "center", padding: "2.5rem 3.5rem 3.5rem 4.5rem" }}
      >
        <h1 style={{ marginBottom: "2rem" }}>
          <b>Sign&nbsp;Up</b>
        </h1>

        <Form onSubmit={formik.handleSubmit}>
          <Form.Group
            className="input-group mb-3"
            controlId="username"
            style={{ marginBottom: "2rem" }}
          >
            <Form.Control
              type="text"
              name="username"
              placeholder="Full name"
              onChange={formik.handleChange}
              value={formik.values.username}
              isInvalid={!!formik.errors.username}
              className="form-control"
              style={{
                borderRadius: "0.5rem",
                border: "1px solid var(--gray-300, #D0D5DD)",
              }}
            />
          </Form.Group>

          <Form.Group
            className="input-group mb-3"
            controlId="email"
            style={{ marginBottom: "2rem" }}
          >
            <Form.Control
              type="email"
              name="email"
              placeholder="Email"
              onChange={formik.handleChange}
              value={formik.values.email}
              isInvalid={!!formik.errors.email}
              className="form-control"
              style={{
                borderRadius: "0.5rem",
                border: "1px solid var(--gray-300, #D0D5DD)",
              }}
            />
          </Form.Group>

          <Form.Group
            className="input-group mb-3"
            controlId="password"
            style={{ marginBottom: "2rem" }}
          >
            <Form.Control
              type="password"
              name="password"
              placeholder="Password"
              onChange={formik.handleChange}
              value={formik.values.password}
              isInvalid={!!formik.errors.password}
              className="form-control"
              style={{
                borderRadius: "0.5rem",
                border: "1px solid var(--gray-300, #D0D5DD)",
              }}
            />
          </Form.Group>

          <Form.Group
            className="input-group mb-3"
            controlId="passwordconfirmation"
            style={{ marginBottom: "2rem" }}
          >
            <Form.Control
              type="password"
              name="passwordconfirmation"
              placeholder="Password confirmation"
              onChange={formik.handleChange}
              value={formik.values.passwordconfirmation}
              isInvalid={!!formik.errors.passwordconfirmation}
              className="form-control"
              style={{
                borderRadius: "0.5rem",
                border: "1px solid var(--gray-300, #D0D5DD)",
              }}
            />
          </Form.Group>
          <div className="row">
            <div className="col-12">
              <button type="submit" className="btn btn-primary btn-block">
                Register
              </button>
            </div>
          </div>
          <br />
        </Form>
        <div
          style={{
            color: "var(--Grey, #4D4D4D)",
            fontFamily: "Inter",
            fontWeight: "400",
            fontStyle: "normal",
            fontSize: "0.875rem",
          }}
        >
          Already have an account?&nbsp;
          {showLoginForm ? (
            <span
              style={{ color: "var(--Blue, #007BFF)", cursor: "pointer" }}
              onClick={showLogin}
            >
              Login in{" "}
            </span>
          ) : (
            <a href="/">Login</a>
          )}
        </div>
        <div>
          <br />
          <Collapse in={showSuccess}>
            <div
              style={{
                textAlign: "center",
                color: "green",
                border: "1px solid green",
                padding: "10px",
                fontWeight: "bolder",
                borderRadius: "0.5rem",
              }}
            >
              Success!
              <br />
              Your Account has been Successfully created.
              <br />
              <small>
                And E-Mail was sent for you to verify your mailaccount. Plz
                check your Spamfolder too.
              </small>
            </div>
          </Collapse>
          <Collapse in={showProcess}>
            <div
              style={{
                textAlign: "center",
                border: "1px solid #000000",
                padding: "10px",
                fontWeight: "bolder",
                borderRadius: "0.5rem",
              }}
            >
              We are processing your signup.
            </div>
          </Collapse>
          <Collapse in={showError}>
            <div
              style={{
                textAlign: "center",
                color: "red",
                border: "1px solid red",
                padding: "10px",
                fontWeight: "bolder",
                borderRadius: "0.5rem",
              }}
            >
              Oh Oh!
              <br />
              Something went wrong.
              <br />
              <small>
                Maybe try a different E-Mail address or a different password
                (password has to be at least 8 characters).
                <br />
                If you still can't sign up please. contact us for support.
              </small>
            </div>
          </Collapse>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default RegisterModal;
