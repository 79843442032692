import simpleCv from "@serie3/freelancesplaceapi/Assets/img/CV/simple.jpg";
import { Row, Col, Button } from "react-bootstrap";
import Card from "../../../../layout/Cards/Card";
import { useState } from "react";

const ProfileSettings = ({ formik }) => {
  const [templateName, setTemplateName] = useState("simple");
  const setCvTemplate = (name) => {
    formik.setFieldValue("cv_template", name);
    formik.handleSubmit();
    setTemplateName(name);
  };

  const CardButtons = ({ name }) => {
    return (templateName !== name) ? (
      <Button
        onClick={() => {
          setCvTemplate(name);
        }}
      >
        Use
      </Button>
    ) : null;
  };

  return (
    <Row>
      <h3>CV Template</h3>
      <hr style={{ maxWidth: "95%" }} />
      <Col className="col-sm-6 col-md-6">
        <Card
          title="Simple"
          style={{
            maxWidth: "100%",
            maxHeight: "321px",
            border: "1px solid #28a745",
          }}
          CardTools={() => {
            return <CardButtons name={"simple"} />;
          }}
        >
          <img
            src={simpleCv}
            style={{ maxWidth: "100%", maxHeight: "321px" }}
          />
        </Card>
      </Col>
      <Col className="col-sm-6 col-md-6">
        <Card
          title="Simple English"
          style={{ maxWidth: "100%", maxHeight: "321px" }}
          CardTools={() => {
            return <CardButtons name={"simple_english"} />;
          }}
        >
          <img
            src={simpleCv}
            style={{ maxWidth: "100%", maxHeight: "321px" }}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default ProfileSettings;
