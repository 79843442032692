import ErrorPopup from "../ErrorPopup";

const BigErrorPopup = ({ setShowError, showError }) => {
  return (
    <ErrorPopup setShowError={setShowError} showError={showError}>
      ... Sorry! Something went wrong
      <br />
      <br />
      <strong>
        If you think this is a bug and we screwed up plz contact support!
      </strong>
    </ErrorPopup>
  );
};

export default BigErrorPopup;
