import { Container, Row, Col } from "react-bootstrap";
import logo from "@serie3/freelancesplaceapi/Assets/img/Landingpages/logo.png";
import headerimage from "@serie3/freelancesplaceapi/Assets/img/Landingpages/headerimg.png";

const Header = ({setShowRegister, title, text, insurance}) => {
  return (
    <header
      className="landingpage"
      style={{ backgroundColor: "#343A3F", color: "#FFF" }}
    >
      <Container className="py-4">
        <Row className="align-items-center">
          <Col md={6} className=" mb-3">
            <img src={logo} className="img-fluid mb-3 logo" alt="logo" />
            <h1 className="landingpage">
            {title}
            </h1>
            <p className="herotext">
              {text}
            </p>
            <a onClick={() => {setShowRegister(true)}} className="btn btn-primary mt-3 signupButton" style={{width: "100%"}}>
              Sign Up!
            </a>
            <span style={{fontSize: "0.75rem", width: "100%", textAlign: "center", display: "inline-block"}}>
             {insurance}
            </span>
          </Col>
          <Col md={6}>
            <img
              src={headerimage}
              className="img-fluid mb-3"
              alt="logo"
              style={{ height: "auto", maxWidth: "100%" }}
            />
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Header;
