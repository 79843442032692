import { toast } from "react-toastify";
import { Flip } from 'react-toastify';

const SuccessfulSave = () => {
  toast.success("Changes saved", {transition: Flip});
};

const NotFound = () => {
  toast.error("Not found", {transition: Flip});
};

const Error = (message) => {
  if(typeof message === "undefined") {
      message = "Error: Changes not saved";
  }
  toast.error(message, {transition: Flip});
};

const NotAllowed = (message) => {
  if(typeof message === "undefined") {
      message = "Error: This Action is not allowed";
  }
  toast.error(message, {transition: Flip});
};

export default {SuccessfulSave, NotFound, Error, NotAllowed}