import addComma from "@serie3/freelancesplaceapi/Domain/Payment/payment";
import { Table } from "react-bootstrap";

const Subscriptions = ({ subscriptions = [] }) => {
  return (
    <Table striped>
      <thead>
        <tr>
          <th>Date</th>
          <th>Type</th>
          <th>Amount</th>
          <th>Link</th>
        </tr>
      </thead>
      <tbody>
        {subscriptions
          ? subscriptions.map((subcription, index) => {
              return (
                <tr>
                  <td>{subcription.date}</td>
                  <td>{subcription.product_name}</td>
                  <td>{addComma(subcription.price)} €</td>
                  <td>{subcription.invoice_link && (<a href={subcription.invoice_link} target="_blank">Klick to see Invoice</a>)}</td>
                </tr>
              );
            })
          : null}
      </tbody>
    </Table>
  );
};

export default Subscriptions;
