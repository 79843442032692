import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import ProjectsHeader from "./ProjectsHeader";
import crudApi from "@serie3/common/api/crudApi";
import ProjectForm from "@serie3/freelancesplaceapi/Form/Project/Project/ProjectForm";
import ProjectConfig from "@serie3/freelancesplaceapi/Config/Projects/ProjectConfig";
import {
  faMoneyBill,
  faComment,
  faTasks,
  faClock,
  faFile,
  faDotCircle,
  faTachometerAlt,
  faCogs,
} from "@fortawesome/free-solid-svg-icons";
import TabHeader from "../../../../../layout/Tabs/TabHeader";
import TabBody from "../../../../../layout/Tabs/TabBody";
import TabLink from "../../../../../layout/Tabs/TabLink";
import TabContent from "../../../../../layout/Tabs/TabContent";
import BasicsForm from "./Forms/BasicsForm";
import Comments from "./Tabs/Comments";
import Tickets from "./Tabs/Tickets";
import Finance from "./Tabs/Finance";
import Timetracking from "./Tabs/Timetracking";
import Files from "./Tabs/Files";
import Dashboard from "./Tabs/Dashboard";
import ProjectSettings from "./Tabs/ProjectSettings";
import TimetrackingButton from "../../../../../layout/Buttons/TimetrackingButton";

const Edit = ({}) => {
  const [project, setProject] = useState();
  const [tags, setTags] = useState([]);
  const [comments, setComments] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [finance, setFinance] = useState([]);
  //Tasks is the internal name for timetracking
  const [tasks, setTasks] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [runningTask, setRunningTask] = useState();
  const [publicId, setPublicId] = useState();
  const [submitError ,setSubmitError] = useState(false);

  const projectForm = ProjectForm.createProjectForm(
    setSubmitError,
    setTags,
    setTickets,
    setFinance,
    setTasks,
    setDocuments
  );
  const prefix = "project-form";

  const formik = useFormik(projectForm);
  const { id } = useParams();

  const addTicket = (ticket) => {
    project.actions.push(ticket);
    let modifiedActions = project.actions.map((action) => {
      return action.action;
    });
    setTickets([...modifiedActions]);
  };

  const addTimetracking = (task) => {
    project.tasks.push(task);
    let modifiedTasks = project.tasks.map((task) => {
      return task.task;
    });
    setTasks([...modifiedTasks]);
    setRunningTask(task.task);
  };

  useEffect(() => {
    const loadData = async (id) => {
      const project = await crudApi.show(ProjectConfig.endpoints.show, id);
      setProject(project);
      formik.setValues(ProjectForm.setEditData(project));
      let modifiedActions = project.actions.map((action) => {
        return action.action;
      });
      let modifiedTasks = project.tasks.map((task) => {
        return task.task;
      });
      setTags([...project.tags]);
      setComments([...project.comments]);
      setTickets([...modifiedActions]);
      setFinance([...project.finance]);
      setTasks([...modifiedTasks]);
      setDocuments([...project.documents]);
      setPublicId(project.public_id);

      const runningTask = await crudApi.show(
        ProjectConfig.endpoints.tasks.getRunning,
        id
      );

      if (typeof runningTask === "object") {
        setRunningTask(runningTask);
      }
    };
    loadData(id);
  }, [id]);

  const updateTasks = (newTasks) => {
    setTasks(newTasks);
    setRunningTask(tasks.pop());
  };

  const Startbutton = () => {
    return (
      <TimetrackingButton
        startUrl={ProjectConfig.endpoints.tasks.start + project.id}
        task={runningTask}
        tasks={tasks}
        setTasks={updateTasks}
      />
    );
  };

  return project ? (
    <>
      <ProjectsHeader project={project} />
      <section className="content" style={{ height: "unset" }}>
        <div className="card" id="projectEdit">
          <TabHeader prefix={prefix} ExtraButtons={Startbutton}>
            <TabLink
              prefix={prefix}
              name={"Dashboard"}
              icon={faTachometerAlt}
            />
            <TabLink prefix={prefix} name={"Basics"} icon={faDotCircle} />
            <TabLink prefix={prefix} name={"Tickets"} icon={faTasks} />
            <TabLink prefix={prefix} name={"Commets"} icon={faComment} />
            <TabLink prefix={prefix} name={"Finance"} icon={faMoneyBill} />
            <TabLink prefix={prefix} name={"Timetracking"} icon={faClock} />
            <TabLink prefix={prefix} name={"Files"} icon={faFile} />
            <TabLink prefix={prefix} name={"Settings"} icon={faCogs} />
          </TabHeader>

          <TabBody prefix={prefix} name={"project"}>
            <TabContent prefix={prefix} name={"Dashboard"}>
              <Dashboard project={project} comments={comments} />
            </TabContent>
            <TabContent prefix={prefix} name={"Basics"}>
              <BasicsForm
                formik={formik}
                project={project}
                tags={tags}
                setTags={setTags}
              />
            </TabContent>
            <TabContent prefix={prefix} name={"Tickets"} show={true}>
              <Tickets
                tickets={tickets}
                setTickets={setTickets}
                projectId={id}
                addTicket={addTicket}
              />
            </TabContent>
            <TabContent prefix={prefix} name={"Commets"}>
              <Comments
                project={project}
                comments={comments}
                setComments={setComments}
              />
            </TabContent>
            <TabContent prefix={prefix} name={"Finance"}>
              <Finance
                formik={formik}
                finance={finance}
                setFinance={setFinance}
              />
            </TabContent>
            <TabContent prefix={prefix} name={"Timetracking"}>
              <Timetracking
                tasks={tasks}
                setTasks={setTasks}
                projectId={id}
                addTimetracking={addTimetracking}
                projectName={project.title}
              />
            </TabContent>
            <TabContent prefix={prefix} name={"Files"}>
              <Files
                projectFormik={formik}
                documents={documents}
                setDocuments={setDocuments}
              />
            </TabContent>
            <TabContent prefix={prefix} name={"Settings"}>
              <ProjectSettings
                formik={formik}
                project={project}
                projectId={id}
                publicId={publicId}
                setPublicId={setPublicId}
                submitError={submitError}
              />
            </TabContent>
          </TabBody>
        </div>
      </section>
    </>
  ) : null;
};

export default Edit;
