import { React, useState } from "react";
import { Row, Col, Form, Collapse } from "react-bootstrap";

const BillingHeaderRight = ({ formik, className}) => {
  const [openSubscription, setOpenSubscription] = useState(
    formik.values.aborechnung
  );

  return (
    <>
      <Row>
        <Col>
          <Form.Group className="form-inline" controlId="billingdate">
            <Form.Label className={className ? className : "col-sm-4 ml-auto rightText"}>
              Billing date:
            </Form.Label>
            <Form.Control
              type="date"
              name="datum"
              placeholder="BillingDate"
              value={formik.values.datum}
              onChange={formik.handleChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Group className="form-inline" controlId="wiedervorlage_datum">
          <Form.Label className={className ? className: "col-sm-4 ml-auto rightText"}>
              Follow up:
            </Form.Label>
            <Form.Control
              type="date"
              name="wiedervorlage_datum"
              placeholder="Followup"
              value={formik.values.wiedervorlage_datum}
              onChange={formik.handleChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <br />
      <Row style={{ marginBottom: "15px" }}>
        <Col className={className ? className: "col-sm-8 subscriptionbutton"}>
          <Form.Group controlId="subscription">
            <Form.Check type="switch">
              <Form.Check.Label style={{ marginRight: "10px" }}>
                <strong>Subscription:</strong>
              </Form.Check.Label>
              <Form.Check.Input
                type="checkbox"
                onChange={() => {
                  formik.setFieldValue("aborechnung", !openSubscription);
                  setOpenSubscription(!openSubscription);
                }}
                style={{ marginLeft: "auto" }}
                checked={formik.values.aborechnung}
              />
            </Form.Check>
          </Form.Group>
        </Col>
      </Row>
      <Collapse in={openSubscription}>
        <Row>
          <Col>
            <Row>
              <Col>
                <Form.Group className="form-inline" controlId="start">
                  <Form.Label className={className ? className: "col-sm-4 ml-auto rightText"}>
                    Start:
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="start"
                    placeholder="start"
                    value={formik.values.start}
                    onChange={formik.handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="form-inline" controlId="ende">
                  <Form.Label className={className ? className: "col-sm-4 ml-auto rightText"}>
                    End:
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="ende"
                    placeholder="ende"
                    value={formik.values.ende}
                    onChange={formik.handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="form-inline" controlId="rechnungsinterval">
                  <Form.Label className={className ? className: "col-sm-6 ml-auto rightText"}>
                    Payment interval: &nbsp;
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="rechnungsinterval"
                    placeholder="paymentinterval"
                    htmlSize={3}
                    value={formik.values.rechnungsinterval}
                    onChange={formik.handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="form-inline" controlId="versandinterval">
                  <Form.Label className={className ? className: "col-sm-6 ml-auto rightText"}>
                    Sending interval: &nbsp;
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="versandinterval"
                    placeholder="sendinginterval"
                    htmlSize={3}
                    value={formik.values.versandinterval}
                    onChange={formik.handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Col>
        </Row>
      </Collapse>
    </>
  );
};

export default BillingHeaderRight;
