import { Fragment, useRef } from "react";
import Timeline from "../../../../../layout/Timeline/Timeline";
import Timelinelabel from "../../../../../layout/Timeline/Timelinelabel";
import ProjectCommentTimelineItem from "../../../projects/Projects/content/ProjectCommentTimelineItem";
import KeyValueForm from "@serie3/freelancesplaceapi/Form/shared/KeyValueForm";
import { useFormik } from "formik";
import CrmConfig from "@serie3/freelancesplaceapi/Config/Crm/CrmConfig";
import listHandler from "@serie3/common/Domain/listHandler";
import crudApi from "@serie3/common/api/crudApi";
import { Col, Form, Row } from "react-bootstrap";

const ActionsComments = ({ editAction, allComments, setAllComments }) => {
  const commentRef = useRef(null);
  const editEndpoint = "";
  //These dummys are needed so the correct order for the createKeyValue Form can be maintained.
  const errrorFunction = () => {};
  const setShowEditFunction = () => {};

  if(typeof editAction.comments === "undefined"){
    editAction.comments = [];
  }

  const editKeyValueForm = KeyValueForm.createKeyValueForm(
    allComments,
    setAllComments,
    errrorFunction,
    setShowEditFunction,
    CrmConfig.tickets.endpoints.comments.new,
    editEndpoint,
    editAction.comments
  );
  const commentFormik = useFormik(editKeyValueForm);

  const addComment = (e) => {
    commentRef.current.value.length > 1 &&
      editAction &&
      typeof editAction.id !== "undefined" &&
      (() => {
        commentFormik.setFieldValue("key_name", "ticketComment");
        commentFormik.setFieldValue("value", commentRef.current.value);
        commentFormik.setFieldValue("aktion", { id: editAction.id });
        commentFormik.handleSubmit();
        commentRef.current.value = "";
      })();
    e.preventDefault();
  };

  const deleteAction = (index, comment) => {
    const deleteUrl = CrmConfig.tickets.endpoints.comments.delete;
    listHandler.deleteFromList(
      index,
      crudApi.del(deleteUrl, comment.id),
      setAllComments,
      allComments,
      errrorFunction
    );
  };

  return (
    <>
      <Row>
        <Col>
          <Form.Group className={"form-inline"} controlId={"commentForm"}>
            <Form.Label>Comment</Form.Label>
            <Form.Control
              ref={commentRef}
              placeholder={"Comment"}
              as="textarea"
              rows={3}
              name={"Actioncomment"}
              style={{ width: "100%" }}
            />
          </Form.Group>
        </Col>
      </Row>
      <br />
      <Timeline addFunction={addComment} style={{ marginBottom: "150px" }}>
        {allComments.map((comment, index) => {
          return (
            <Fragment key={index}>
              <Timelinelabel labeltext={comment.date} />
              <ProjectCommentTimelineItem
                comment={comment}
                deleteAction={() => {
                  deleteAction(index, comment);
                }}
              />
            </Fragment>
          );
        })}
      </Timeline>
    </>
  );
};

export default ActionsComments;
