import Content from "../../../layout/Content";
import StripePricingTable from "../../../layout/Payment/StripePricingTable";
import TabHeader from "../../../layout/Tabs/TabHeader";
import TabLink from "../../../layout/Tabs/TabLink";
import TabBody from "../../../layout/Tabs/TabBody";
import TabContent from "../../../layout/Tabs/TabContent";
import BillingSettings from "./content/settings/BillingSettings";
import getSettings from "@serie3/common/config/settings";
import { useEffect } from "react";
import { faCogs, faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const FinanceSettings = () => {
  const prefix = "billing-settings";
  const [settings, setSettings] = useState();

  useEffect(() => {
    const localSettings = getSettings();
    setSettings(localSettings);
  }, []);

  const Title = () => {
    return (
      <>
        <b>Finance: &nbsp; </b> Settings
      </>
    );
  };

  return (
    settings && (
      <>
        <br />
        <Content Title={Title}>
          <TabHeader prefix={prefix}>
            <TabLink prefix={prefix} name={"Buy"} icon={faMoneyBill} />
            <TabLink prefix={prefix} name={"Settings"} icon={faCogs} />
          </TabHeader>
          <TabBody prefix={prefix} name={"billing_settings"}>
            <TabContent prefix={prefix} name={"Buy"} show={true}>
              <StripePricingTable
                priceTableId={settings.pricingTables.billing}
              />
            </TabContent>
            <TabContent prefix={prefix} name={"Settings"}>
              <BillingSettings />
            </TabContent>
          </TabBody>
        </Content>
      </>
    )
  );
};

export default FinanceSettings;
