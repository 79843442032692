const PositionRowHeader = ({showDropdowns = true}) => {
  return (
    <thead>
      <tr>
        <th style={{ paddingLeft: "5px", paddingRight: "5px" }}>#</th>
        <th>Description</th>
        <th style={{ textAlign: "center" }}>Price</th>
        <th style={{ textAlign: "center" }}>Amount</th>
        <th style={{ textAlign: "center" }}>Tax(%)</th>
        <th style={{ textAlign: "center" }}>Nett</th>
        <th style={{ textAlign: "center" }}>Tax(€)</th>
        <th style={{ textAlign: "center" }}>Gross</th>
        {showDropdowns === true ? (<th></th>) : null}
      </tr>
    </thead>
  );
};

export default PositionRowHeader;
