import TextField from "../../../../layout/Form/Fields/TextField";
import { Form } from "react-bootstrap";
import TextArea from "../../../../layout/Form/Fields/TextArea";
import Checkbox from "../../../../layout/Form/Fields/Checkbox";
import DateField from "../../../../layout/Form/Fields/DateField";
import SelectField from "../../../../layout/Form/Fields/SelectField";

const PersonBasicsForm = ({ formik }) => {
  return (
    <Form>
      <SelectField
        formik={formik}
        label={"Salutation"}
        valueName={"anrede"}
        style={{ marginBottom: "10px" }}
      >
        <option value=""></option>
        <option value="Mr">Mr</option>
        <option value="Ms">Ms</option>
      </SelectField>
      <br />

      <SelectField
        formik={formik}
        label={"Gender"}
        valueName={"geschlecht"}
        style={{ marginBottom: "10px" }}
      >
        <option value=""></option>
        <option value="Sir">Male</option>
        <option value="Madam">Female</option>
        <option value="Other">Other</option>
      </SelectField>

      <br />
      <TextField
        formik={formik}
        label={"First name"}
        valueName={"vorname"}
        style={{ marginBottom: "10px" }}
      />
      <br />
      <TextField
        formik={formik}
        label={"Name"}
        valueName={"name"}
        style={{ marginBottom: "10px" }}
      />
      <br />
      <TextField
        formik={formik}
        label={"Job Title"}
        valueName={"position"}
        style={{ marginBottom: "10px" }}
      />
      <br />
      <DateField
        formik={formik}
        label={"Birthday"}
        valueName={"geburtstag"}
        style={{ marginBottom: "10px" }}
      />
      <br />
      <TextField
        formik={formik}
        label={"Tax Id"}
        valueName={"tax_id"}
        style={{ marginBottom: "10px" }}
      />
      <br />
      <TextField
        formik={formik}
        label={"Taxnumber"}
        valueName={"tax_number"}
        style={{ marginBottom: "10px" }}
      />
      <br />
      <TextArea
        formik={formik}
        label={"Comment"}
        valueName={"bemerkung"}
        style={{ marginBottom: "10px" }}
      />
      <br />
      <Checkbox
        formik={formik}
        label={"Blocked "}
        valueName={"gesperrt"}
        style={{ marginBottom: "10px" }}
      />
      <br />
      <TextField
        formik={formik}
        label={"Reason for blocking "}
        valueName={"sperrgrund"}
        style={{ marginBottom: "10px" }}
      />
    </Form>
  );
};

export default PersonBasicsForm;
