import Profile from "../../Entity/Profile/Profile";
import * as Yup from "yup";
import CrudApi from "@serie3/common/api/crudApi";
import ProfileConfig from "../../Config/Profiles/ProfileConfig";

const createProfileForm = (showErrorFunction, setSkillslist, setQualifications, setLanguages) => {
  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    vorname: Yup.string().required("Required"),
    sperrgrund: Yup.string().when("gesperrt", {
      is: true,
      then: Yup.string().required(
        "You must add a reason why this company is blocked"
      ),
    }),
  });

  const onSubmit = async (values) => {
    let payload = JSON.stringify(values, null, 2);
    const response = await CrudApi.edit(ProfileConfig.endpoints.edit, payload);
    if(response.status === 200 ){
      const skills = [...response.data.skills];
      setSkillslist(skills);
      values.skills = skills;
      const qualifications = [...response.data.qualifications];
      values.qualifications = qualifications;
      values._searching = response.data._searching;
      values.languages = response.data.languages;
      setLanguages(response.data.languages)
      setQualifications(qualifications);
    }
  };

  return {
    initialValues: Profile,
    onSubmit: (values, actions) => {
      onSubmit(values, actions);
    },
  };
};

const setEditData = (editData) => {
  let Profile = {
    id: editData.id,
    profiletitle: editData.profiletitle,
    title: editData.title,
    vorname: editData.vorname,
    name: editData.name,
    email: editData.email,
    email_public: editData.email_public,
    telefon: editData.telefon,
    telefon_public: editData.telefon_public,
    remote_only: editData.remote_only,
    available: editData.available,
    available_from: editData.available_from,
    available_to: editData.available_to,
    max_hours: editData.max_hours,
    fulltime_only: editData.fulltime_only,
    mobile: editData.mobile,
    mobile_public: editData.mobile_public,
    birthdate: editData.birthdate,
    birthdate_public: editData.birthdate_public,
    birthname: editData.birthname,
    birthname_public: editData.birthname_public,
    public: editData.public,
    mantra: editData.mantra,
    domain: editData.domain,
    domain_template: editData.domain_template,
    cv_template: editData.cv_template,
    website: editData.website,
    postcode: editData.postcode,
    street: editData.street,
    housenumber: editData.housenumber,
    city: editData.city,
    einsatz_orte: editData.einsatz_orte,
    aktive: editData.aktive,
    hourly_rate_on_site: editData.hourly_rate_on_site,
    hourly_rate_remote: editData.hourly_rate_remote,
    daily_rate_on_site: editData.daily_rate_on_site,
    daily_rate_remote: editData.daily_rate_remote,
    hourly_rate_on_site_public: editData.hourly_rate_on_site_public,
    hourly_rate_remote_public: editData.hourly_rate_remote_public,
    daily_rate_on_site_public: editData.daily_rate_on_site_public,
    daily_rate_remote_public: editData.daily_rate_remote_public,
    skills: editData.skills,
    qualifications: editData.qualifications,
    _searching: editData._searching,
    languages: editData.languages,
  };

  return Profile;
};

export default { createProfileForm, setEditData };
