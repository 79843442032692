import { Button, Col, Form, Row } from "react-bootstrap";
import crudApi from "@serie3/common/api/crudApi";
import NewModal from "../documents/modals/NewModal";
import { useEffect, useRef, useState } from "react";
import ReferencedInputAndSubmit from "../../../layout/Form/Fields/ReferencedInputAndSubmit";
import UserConfig from "@serie3/freelancesplaceapi/Config/User/UserConfig";

const DeleteAccount = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteString, setDeleteString] = useState();
  const [checkEmail, setCheckEmail] = useState(false);
  const [deleteDate, setDeleteDate] = useState();
  const [showCancel, setShowCancel] = useState(false);

  const confirmfieldRef = useRef(null);

  const generateRandomString = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  useEffect(() => {
    const checkDeletion = async () => {
      const response = await crudApi.justGet(
        UserConfig.endpoints.checkDeletion
      );
      if (response.status === 200) {
        const date = response.data.date.split(" ")[0];
        setDeleteDate(date);
      }
    };

    setDeleteString(generateRandomString(10));
    checkDeletion();
  }, []);

  const sendDeleteRequest = async () => {
    if (confirmfieldRef.current.value === deleteString) {
      const response = await crudApi.justGet(
        UserConfig.endpoints.deleteAccount
      );
      if (response.status === 200) {
        setCheckEmail(true);
      }
    }
  };

  const DeletePasswordModal = () => {
    return (
      <NewModal
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        title={"Confirm deletion of your account"}
      >
        {checkEmail ? (
          <Row>
            <Col>Check your emails to confirm your request.</Col>
          </Row>
        ) : (
          <>
            <Row>
              <Col style={{ backgroundColor: "lightGrey", padding: "10px" }}>
                Enter the following String to confirm: <b>{deleteString}</b>
              </Col>
            </Row>
            <br />
            <Row>
              <Form>
                <ReferencedInputAndSubmit
                  namedReference={confirmfieldRef}
                  label={"Delete Account"}
                  onClick={sendDeleteRequest}
                />
              </Form>
            </Row>
            <Row>
              <Col>
                Your account will be deleted in 7 days. You can cancel this any
                time.
              </Col>
            </Row>
          </>
        )}
      </NewModal>
    );
  };

  const getDeletePassword = () => {
    setShowDeleteModal(true);
  };

  const cancelDelete = async () => {
    const response = await crudApi.justGet(UserConfig.endpoints.cancelDelete);
    if (response.status === 200) {
      setShowCancel(true);
      setCheckEmail(true);
    }
  };

  const CancelDeleteModal = () => {
    return (
      <NewModal show={showCancel} setShow={setShowCancel}>
        {checkEmail ? (
          <Row>
            <Col>Check your emails to confirm your request.</Col>
          </Row>
        ) : (
          "An Error occured, plz contact support"
        )}
      </NewModal>
    );
  };

  const CancelDeletionButton = () => {
    return (
      <>
        <Row>
          <Col>
            <Button onClick={cancelDelete}> Cancel Deletion </Button>
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col>
            Your account will be deleted on: <b>{deleteDate}</b>{" "}
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      <CancelDeleteModal />
      <DeletePasswordModal />
      <Row>
        <Col>
          {deleteDate ? (
            <CancelDeletionButton />
          ) : (
            <Button onClick={getDeletePassword}> Delete my account </Button>
          )}
        </Col>
      </Row>
    </>
  );
};

export default DeleteAccount;
