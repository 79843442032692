import Content from "../../../layout/Content";
import StripePricingTable from "../../../layout/Payment/StripePricingTable";
import TabHeader from "../../../layout/Tabs/TabHeader";
import TabLink from "../../../layout/Tabs/TabLink";
import TabBody from "../../../layout/Tabs/TabBody";
import TabContent from "../../../layout/Tabs/TabContent";
import {
  faCogs,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";

const CrmSettings = ({}) => {
    const prefix = "crm-settings";

    const Title = () => {
      return (
        <>
          <b>Crm: &nbsp; </b> Settings
        </>
      );
    };
  
    return (
      <>
        <br />
        <Content Title={Title}>
          <TabHeader prefix={prefix}>
            <TabLink prefix={prefix} name={"Buy"} icon={faMoneyBill} />
            <TabLink prefix={prefix} name={"Settings"} icon={faCogs} />
          </TabHeader>
          <TabBody prefix={prefix} name={"billing_settings"}>
            <TabContent prefix={prefix} name={"Buy"} show={true}>
              <StripePricingTable priceTableId={"prctbl_1NPoBqCJGCgzEfQX6e0xkXtY"}/>
            </TabContent>
            <TabContent prefix={prefix} name={"Settings"}>
              Settings
            </TabContent>
          </TabBody>
        </Content>
      </>
    );
}

export default CrmSettings;

