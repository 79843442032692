import { Container } from "react-bootstrap";
import Social from "../../Public/Components/Social";
const Footer = () => {
  return (
    <Container>
      <footer className="row row-cols-1 row-cols-sm-2 row-cols-md-5 py-5 my-5 border-top">
        <div className="col mb-3"></div>

        <div className="col mb-3">
          <h5>Legal</h5>
          <ul className="nav flex-column">
            <li className="nav-item mb-2">
              <a href="/" className="nav-link p-0 text-muted">
                Home
              </a>
            </li>
            <li className="nav-item mb-2">
              <a href="/privacy" className="nav-link p-0 text-muted">
                Privacy/Datenschutz
              </a>
            </li>
            <li className="nav-item mb-2">
              <a href="/imprint" className="nav-link p-0 text-muted">
                Imprint/Impressum
              </a>
            </li>
          </ul>
        </div>

        <div className="col mb-3">
          <h5>Help and support</h5>
          <ul className="nav flex-column">
            <li className="nav-item mb-2">
              <a
                href="https://freelancersplace.net/blog"
                className="nav-link p-0 text-muted"
              >
                Blog
              </a>
            </li>
          </ul>
        </div>
        <div className="col mb-3">
          <h5>Social</h5>
          <Social
            size={"1x"}
            liststyle={true}
            textColor={"#6c757d"}
            showText={true}
          />
        </div>
      </footer>
    </Container>
  );
};

export default Footer;
