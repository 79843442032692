const Company = {
    bemerkung: '',
    kundenklasse: '',
    gesperrt: false,
    sperrgrund: '',
    firmenname: '',
    rechtsform: '',
    geloescht: false,
    deleted: false,
    tax_id: '',
    tax_number: '',
};

export default Company;
