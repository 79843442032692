import NewModal from "../documents/modals/NewModal";
import { useEffect, useState } from "react";
import loadPublicJobDetails from "@serie3/freelancesplaceapi/Domain/Projects/LoadPublicJobDetails";
import JobDetails from "../../../Public/Components/JobDetails";

const PublicJobView = ({ jobId, showView, setShowView }) => {
  const [publicJob, setPublicJob] = useState();
  
  useEffect(() => {
    loadPublicJobDetails(setPublicJob, jobId);
  }, [jobId]);
  
  return publicJob ?(
    <NewModal
      show={showView}
      setShow={setShowView}
      title={publicJob.jobtitle}
    >
      <JobDetails job={publicJob} />
    </NewModal>
  ): null;
};

export default PublicJobView;
