import CompleteDropdown from "../../../../../layout/Dropdowns/CompleteDropdown";

const ProjectCommentTimelineItem = ({
  comment,
  deleteAction,
  viewAction,
  editAction,
  showDropdown = true,
}) => {
  return (
    <div>
      <i className="far fa-clock bg-primary"></i>
      <div className="timeline-item">
        {showDropdown ? (
          <span className="time">
            <CompleteDropdown
              deleteAction={deleteAction}
              editAction={editAction}
              viewAction={viewAction}
            />
          </span>
        ) : null}
        <div className="timeline-body">{comment.value}</div>
      </div>
    </div>
  );
};

export default ProjectCommentTimelineItem;
