import { Col } from "react-bootstrap";

const ProfileDetails = ({ profile }) => {
  return Object.keys(profile).length > 0 ? (
    <>
      {profile.mantra}
      <br />
      <b>Skills</b>
      <ul>
        {profile.skills
          ? profile.skills.map((skill, skillIndex) => {
              return <li key={skillIndex}>{skill.name}</li>;
            })
          : null}
      </ul>
    </>
  ) : null;
};

export default ProfileDetails;
