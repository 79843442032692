import { Table } from "react-bootstrap";

const TicketTable = ({ tickets, label }) => {
  return (
    <Table striped>
      <thead>
        <tr>
          <th colSpan={2} style={{ borderBottom: "2px solid #0075e9" }}>
            {label}
          </th>
        </tr>
      </thead>
      <tbody>
        {typeof tickets !== "undfined" && tickets.length > 0
          ? tickets.map((ticket) => {
              return (
                <tr key={ticket.action.id}>
                  <td style={{ padding: "0.5rem" }}>
                    <small>
                      <b>{ticket.action.title}</b>
                    </small>
                  </td>
                  <td style={{ padding: "0.5rem" }}>{ticket.action.priority}</td>
                </tr>
              );
            })
          : null}
      </tbody>
    </Table>
  );
};
export default TicketTable;
