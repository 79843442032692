import { Button, Row, Col } from "react-bootstrap";
import CompleteDropdown from "../../../layout/Dropdowns/CompleteDropdown";

const EducationTimelineItem = ({ education, deleteAction, editAction }) => {
  return (
    <div>
      <i className="far fa-clock bg-primary"></i>
      <div className="timeline-item">
        <span className="time">
          <CompleteDropdown
            deleteAction={deleteAction}
            editAction={editAction}
          />
        </span>
        <h3 className="timeline-header">
          <a href="#">{education.name}</a>
        </h3>
        <div className="timeline-body">
          <br />
          <Row>
            <Col>
              <b>
                Graduated: <i> {education.graduation}</i>
              </b>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
            {education.description} 
            </Col>
          </Row>
        </div>
        <div className="timeline-footer">
          <b>
            Company: <i>  {education.subject_area} </i>
          </b>
        </div>
      </div>
    </div>
  );
};

export default EducationTimelineItem;
