import { Routes, Route } from "react-router-dom";
import Index from "./Index";
import Edit from "./Edit";
import ApplicationIndex from "./ApplicationIndex";

const ProfilesRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path="/edit/:id" element={<Edit />} />
      <Route path="/applications" element={<ApplicationIndex />} />
    </Routes>
  );
};

export default ProfilesRoutes;
