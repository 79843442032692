const Datareset = () => {
    const errorPageStyles = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        margin: 0,
        backgroundColor: '#303d32',
      };
      
      const errorContainerStyles = {
        textAlign: 'center',
      };
      
      const headingStyles = {
        color: '#fff',
        fontSize: '4em',
        marginBottom: '20px',
      };
      
      const paragraphStyles = {
        color: '#fff',
        fontSize: '1.5em',
      };

    return (
        <div style={errorPageStyles}>
          <div style={errorContainerStyles}>
            <h1 style={headingStyles}>The demo data is getting reset </h1>
            <p style={paragraphStyles}> We do reset the testing data every 30 Minutes. Please wait 5 minutes and <a href="https://demo.freelancersplace.net/" > click here </a> </p>
            <p style={paragraphStyles}>If this error continues plz contact support! </p>
          </div>
        </div>
      );
}

export default Datareset;