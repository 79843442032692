import crudApi from "@serie3/common/api/crudApi";
import PublicJobsConfig from "../../Config/Public/Jobs/JobsConfig";

const loadPublicJobDetails = async (setJobDetails, id) => {
    const response = await crudApi.show(
        PublicJobsConfig.Jobs.endpoints.jobDetails,
        id
      );
      if (response !== null) {
        response.hits.hits[0]
          ? setJobDetails(response.hits.hits[0]._source)
          : console.log("no data");
      } else {
        setJobDetails(null);
      }
}

export default loadPublicJobDetails;