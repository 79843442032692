import PositionDomain from "@serie3/freelancesplaceapi/Domain/Billing/Outgoing/Position";

const BillSummary = ({positionen = []}) => {
 
  let nett = 0;
  let tax = 0;
  let total = 0;

  positionen.map((position) => {
    nett += parseFloat(PositionDomain.calculatePositionNett(position));
    tax += parseFloat(PositionDomain.calculatePositionTax(position));
    total += parseFloat(PositionDomain.calculatePositionGross(position));
  });
    
  return (
    <table className="table">
      <thead style={{fontWeight: "700"}}>
        <tr>
          <td>Subtotal:</td>
          <td>€ {nett.toFixed(2)}</td>
        </tr>
        <tr>
          <td>Tax: </td>
          <td>€ {tax.toFixed(2)}</td>
        </tr>
        <tr>
          <td>Total:</td>
          <td>€ {total.toFixed(2)}</td>
        </tr>
      </thead>
    </table>
  );
};

export default BillSummary;
