import * as Yup from "yup";
import Register from "../../Entity/Register/Register";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import firebaseConfig from "@serie3/freelancesplaceapi/Config/Firebase/firebase";
import { initializeApp } from "firebase/app";
import crudApi from "../../api/crudApi";

const validationSchema = Yup.object().shape({
  username: Yup.string().required().label("Username"),
  password: Yup.string().required().min(8).label("Password"),
  passwordconfirmation: Yup.string()
    .required()
    .min(8)
    .label("Password confirmation")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  email: Yup.string().required().email().label("Email"),
});

const createRegisterForm = (setSuccess, setError, setShowProcess) => {
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  return {
    initialValues: Register,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setShowProcess(true);
      setError(false);
      setSuccess(false);
      await createUserWithEmailAndPassword(auth, values.email, values.password)
        .then((userCredential) => {
          const token = userCredential._tokenResponse;
          const data = {
            username: values.username,
            token: token.idToken,
            email: token.email,
          };
          crudApi
            .edit("/register/signup", data)
            .then((response) => {
              if (response.ok) {
                setSuccess(true);
                setShowProcess(false);
              } else {
                setError(true);
                setShowProcess(false);
              }
            })
            .catch((error) => {
              setError(true);
              setShowProcess(false);
            });
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          setError(true);
          setShowProcess(false);
        });
    },
  };
};

export default createRegisterForm;
