import TextField from "../../../../../layout/Form/Fields/TextField";
import { Col, Row } from "react-bootstrap";
import NewModal from "../../../documents/modals/NewModal";
import { useFormik } from "formik";
import SaveButton from "../../../../../layout/Buttons/SaveButton";
import CrmConfig from "@serie3/freelancesplaceapi/Config/Crm/CrmConfig";
import AddressForm from "@serie3/freelancesplaceapi/Form/Crm/AddressForm";
import Address from "@serie3/freelancesplaceapi/Entity/Crm/Address";

const AddressesForm = ({
  showEdit,
  setShowEdit,
  editAddressData,
  companyId,
  personId,
  dataList,
  setAddressData,
  showErrorFunction,
}) => {
  const editMediaForm = AddressForm.createKeyValueForm(
    dataList,
    setAddressData,
    showErrorFunction,
    setShowEdit,
    CrmConfig.address.endpoints.new,
    CrmConfig.address.endpoints.edit,
  );
  const formik = useFormik(editMediaForm);
  
  const Save = () => {
    return (
      <SaveButton handleClick={(e) => {
        formik.handleSubmit(e);
        e.preventDefault()}} />
    );
  };
  return (
    <NewModal
      show={showEdit}
      setShow={setShowEdit}
      ExtraButton={Save}
      onShow={() => {
        editAddressData
          ? formik.setValues(AddressForm.setEditData(editAddressData))
          : formik.setValues(Address);
        (companyId) && formik.setFieldValue("kunde.id", companyId);
        (personId) && formik.setFieldValue("person.id", personId);
      }}
    >
      <Row>
        <Col>
          <TextField
            formik={formik}
            label={"Street"}
            valueName={"strasse"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
        <Col>
          <TextField
            formik={formik}
            label={"Number"}
            valueName={"hausnummer"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
        <Col>
          <TextField
            formik={formik}
            label={"Postal"}
            valueName={"plz"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
        <Col>
          <TextField
            formik={formik}
            label={"City"}
            valueName={"ort"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
      </Row>
    </NewModal>
  );
};

export default AddressesForm;
