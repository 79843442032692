import ReferencedInputAndSubmit from "../../layout/Form/Fields/ReferencedInputAndSubmit";
import Card from "../../layout/Cards/Card";
import SearchTag from "../../layout/Buttons/SearchTag";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useRef } from "react";
import listHandler from "@serie3/common/Domain/listHandler";
import CreateGeneralSearchForm from "@serie3/common/Form/Search/GeneralSearchForm";
import { useFormik } from "formik";

const Search = ({ title, endpoint, resultData, setResultData, loadData }) => {
  const [searchWords, setSearchWords] = useState([]);
  const searchRef = useRef(null);

  const searchForm = CreateGeneralSearchForm.createGeneralSearchForm(
    resultData,
    setResultData,
    endpoint
  );
  const formik = useFormik(searchForm);

  const Label = (
    <>
      Search <FontAwesomeIcon icon={faSearch} />
    </>
  );

  const deleteFunction = (word) => {
    const tmpList = [...listHandler.deleteWithoutCallAndId(word, searchWords)];
    if (tmpList.length > 0) {
      setSearchWords(tmpList);
      formik.setFieldValue("keywords", tmpList);

      formik.submitForm();
    } else {
      loadData();
    }
  };

  const searchClick = () => {
    if (searchRef.current.value.length > 0) {
      searchWords.push(searchRef.current.value);
      let tmpArray = [...searchWords];
      setSearchWords(tmpArray);
      formik.setFieldValue("keywords", tmpArray);
      searchRef.current.value = "";
    }
    if (searchWords.length === 0) {
      loadData();
    }
    formik.submitForm();
  };

  return (
    <Card title={title}>
      <div className="form-inline" style={{ width: "100%" }}>
        <ReferencedInputAndSubmit
          style={{ width: "100%" }}
          label={Label}
          namedReference={searchRef}
          onClick={searchClick}
        />
      </div>
      {searchWords.length > 0
        ? searchWords.map((word, wordIndex) => {
            return (
              <SearchTag
                content={word}
                key={wordIndex}
                deleteFunction={() => {
                  deleteFunction(word);
                }}
              />
            );
          })
        : null}
    </Card>
  );
};

export default Search;
