import { useEffect, useState } from "react";
import timeingStuff from "@serie3/freelancesplaceapi/Domain/Shared/timeingStuff";
import AgeInDaysBatch from "../../../layout/Common/AgeInDaysBatch";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip, OverlayTrigger, Button } from "react-bootstrap";
const TodoRow = ({ todo, deleteFunction, dailyList = false, markAsDaily }) => {
  const [ageInDays, setAgeInDays] = useState(0);

  useEffect(() => {
    setAgeInDays(timeingStuff.calculateAge(todo.date));
  }, [todo]);

  const moveDailyTip = (
    <Tooltip id="movedaily">
      <small>Move item to daily todo list</small>
    </Tooltip>
  );

  return todo ? (
    <li className="">
      <div className="check-primary d-inline ml-2">
        <input type="checkbox" value="" onClick={deleteFunction} />
        <label for="todoCheck1"></label>
      </div>
      <span className="text">{todo.value}</span>
      <AgeInDaysBatch ageInDays={ageInDays} />
      {!dailyList && typeof markAsDaily === "function" ? (
        <OverlayTrigger overlay={moveDailyTip}>
          <a
            href="#"
            onClick={markAsDaily}
            style={{
              float: "right",
              textDecoration: "none",
              color: "inherit",
              marginRight: "20px",
            }}
          >
            <FontAwesomeIcon icon={faCalendarDay} />
          </a>
        </OverlayTrigger>
      ) : null}
    </li>
  ) : null;
};

export default TodoRow;
