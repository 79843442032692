import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";

const AgeInDaysBatch = ({ageInDays, showZero = false}) => {

    return (ageInDays > 0 || showZero ? (
        <small className="badge badge-danger">
          <FontAwesomeIcon icon={faClock} />
          &nbsp;
          {ageInDays} Days
        </small>
      ) : null)
}

export default AgeInDaysBatch;