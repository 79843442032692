import { useState } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CommentsForm from "../Form/CommentsForm";
import ErrorPopup from "../../../../../layout/ErrorPopup";
import CrmConfig from "@serie3/freelancesplaceapi/Config/Crm/CrmConfig";
import CommentView from "../../../../../layout/CommentView";

const Comments = ({ commentsData, companyId, setCommentsData, personId }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [editComment, setEditComment] = useState(null);
  const [showError, setShowError] = useState(false);

  const showErrorFunction = () => {
    setShowError(true);
  };

  return (
    <>
      <ErrorPopup setShowError={setShowError} showError={showError}>
        ... Sorry! Something went wrong
        <br />
        <br />
        <strong>
          If you think this is a bug and we screwed up plz contact support!
        </strong>
      </ErrorPopup>
      <Row>
        <Col>
          <Form>
            <CommentsForm
              showEdit={showEdit}
              setShowEdit={setShowEdit}
              editComments={editComment}
              companyId={companyId}
              personId={personId}
              dataList={commentsData}
              setCommentsData={setCommentsData}
              showErrorFunction={showErrorFunction}
            />
          </Form>

          {commentsData
            ? commentsData.map((contact, index) => {
                return (
                  <CommentView
                    index={index}
                    key={index}
                    keyValueObject={contact}
                    setShowEdit={setShowEdit}
                    setEditFunction={setEditComment}
                    setKeyValueData={setCommentsData}
                    keyValueDataList={commentsData}
                    showErrorFunction={showErrorFunction}
                    deleteUrl={CrmConfig.comments.endpoints.delete}
                  />
                );
              })
            : null}
        </Col>
      </Row>
      <Row>
        <Col className="col-sm-12 text-end mb-4 mt-2">
          <Button
            onClick={() => {
              setEditComment(null);
              setShowEdit(true);
            }}
          >
            Add Comment &nbsp;
            <FontAwesomeIcon icon={faAdd} />
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Comments;
