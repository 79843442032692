const BillingSettings = {
  company_address: "",
  pre_text: "",
  post_text: "",
  payment_deadline: 14,
  iban: "",
  bic: "",
  bank_name: "",
  email: "",
  website: "",
  phone: "",
  taxnumber: "",
  tax_id: "",
  billing_number_prefix: "",
  billing_number_offset: "",
};

export default BillingSettings;
