import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const InfoBox = ({ color, icon, title, message, textStyle }) => {

  const classname = "info-box-icon "  + color;
  return (
    <div className="info-box">
      <span className={classname}>
            <FontAwesomeIcon icon={icon} />
      </span>
      <div className="info-box-content"  style={textStyle}>
        <span className="info-box-text">{title}</span>
        <span className="info-box-number">{message}</span>
      </div>
    </div>
  );
};

export default InfoBox;
