import NewModal from "../../documents/modals/NewModal";
import { Table, Row, Col } from "react-bootstrap";
import Card from "../../../../layout/Cards/Card";

const JobView = ({ job, showView, setShowView }) => {
  const viewTitle = job ? job.jobtitle + " Start: (" + job.start + ")" : null;
  return job ? (
    <NewModal show={showView} setShow={setShowView} title={viewTitle}>
      <Row>
        <Col xs={8}>
          <Table striped>
            <tbody>
              <tr>
                <td style={{ width: "30%" }}>
                  <b>Contract type:</b>
                </td>
                <td>{job.contract_type}</td>
              </tr>
              <tr>
                <td style={{ width: "30%" }}>
                  <b>On Site</b>
                </td>
                <td>{job.on_site ? "yes" : "no"}</td>
              </tr>
              <tr>
                <td style={{ width: "30%" }}>
                  <b>Description</b>
                </td>
                <td>{job.description}</td>
              </tr>
              <tr>
                <td style={{ width: "30%" }}>
                  <b>Skills:</b>
                </td>
                <td>
                  {job.key_skills ? job.key_skills.map((language) => {
                    return (
                      <>
                        <span className={"badge badge-success"}>
                          {language.name}
                        </span>{" "}
                        &nbsp;
                      </>
                    );
                  }): null}
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
        <Col>
        <Card title={"Found Freelancers"}>
            -- no matches found --
        </Card>
        </Col>
      </Row>
    </NewModal>
  ) : null;
};

export default JobView;
