import Index from "./content/shared/Index";
import Edit from "./content/outgoing/Edit";
import EditIn from "./content/incoming/EditIn";
import FinanceSettings from "./FinanceSettings";
import { Routes, Route } from "react-router-dom";

const FinanceRoutes = () => {
  return (
    <Routes>
      <Route
        path="/out/bills"
        element={
          <Index
            type={"bills"}
            direction={"out"}
            titleText={"Bills Outgoing"}
          />
        }
      />
      <Route
        path="/out/bills/edit/:id"
        element={
          <Edit type={"bills"} direction={"out"} titleText={"Bills Outgoing"} />
        }
      />
      <Route
        path="/out/offers"
        element={
          <Index
            type={"offers"}
            direction={"out"}
            titleText={"Offers Outgoing"}
          />
        }
      />
      <Route
        path="/out/offers/edit/:id"
        element={
          <Edit
            type={"offers"}
            direction={"out"}
            titleText={"Offers Outgoing"}
          />
        }
      />
      <Route
        path="/out/orders"
        element={
          <Index
            type={"orders"}
            direction={"out"}
            titleText={"Orders Outgoing"}
          />
        }
      />
      <Route
        path="/out/orders/edit/:id"
        element={
          <Edit
            type={"orders"}
            direction={"out"}
            titleText={"Orders Outgoing"}
          />
        }
      />
      {/* Incomming routes */}
      <Route
        path="/in/bills"
        element={
          <Index type={"bills"} direction={"in"} titleText={"Bills Incoming"} />
        }
      />
      <Route
        path="/in/bills/edit/:id"
        element={
          <EditIn type={"bills"} direction={"in"} titleText={"Bills Incoming"} />
        }
      />
      <Route
        path="/in/offers"
        element={
          <Index
            type={"offers"}
            direction={"in"}
            titleText={"Offers Incoming"}
          />
        }
      />
      <Route
        path="/in/offers/edit/:id"
        element={
          <Edit
            type={"offers"}
            direction={"in"}
            titleText={"Offers Incoming"}
          />
        }
      />
      <Route
        path="/in/orders"
        element={
          <Index
            type={"orders"}
            direction={"in"}
            titleText={"Orders Incoming"}
          />
        }
      />
      <Route
        path="/in/orders/edit/:id"
        element={
          <Edit
            type={"orders"}
            direction={"in"}
            titleText={"Orders Incoming"}
          />
        }
      />
      <Route path="/settings" element={<FinanceSettings />} />
    </Routes>
  );
};

export default FinanceRoutes;
