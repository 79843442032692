import NewModal from "../../LoggedIn/modules/documents/modals/NewModal";
import StripePricingTable from "./StripePricingTable";

const PaymentModal = ({ show, setShow, priceTableId }) => {
  const title = "Your current Plan has expired";
  return (
    <NewModal show={show} setShow={setShow} title={title}>
      <StripePricingTable priceTableId={priceTableId} />
    </NewModal>
  );
};
export default PaymentModal;
