import { Row, Col, Button } from "react-bootstrap";
import Card from "../layout/Cards/Card";
import crudApi from "@serie3/common/api/crudApi";
import { useEffect, useState } from "react";
import PublicJobsConfig from "@serie3/freelancesplaceapi/Config/Public/Jobs/JobsConfig";
import JobDetails from "./Components/JobDetails";
import Search from "./Components/Search";
import loadPublicJobDetails from "@serie3/freelancesplaceapi/Domain/Projects/LoadPublicJobDetails";
import FreelancerserplaceNavbar from "../signup/SubComponents/FreelancerserplaceNavbar";
import Footer from "../signup/SubComponents/Footer";

const PublicJobs = ({ setToken }) => {
  const [publicJobs, setPublicJobs] = useState();
  const [jobDetails, setJobDetails] = useState();
  const [count, setCount] = useState(0);

  const loadData = async () => {
    const response = await crudApi.search(
      PublicJobsConfig.Jobs.endpoints.publicJobs,
      {},
      true
    );

    if (response.status === 200) {
      setCount(response.data.hits.total.value);
      setPublicJobs(response.data.hits.hits);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  const CardTools = ({ jobId }) => {
    return (
      <Button
        onClick={() => {
          loadPublicJobDetails(setJobDetails, jobId);
        }}
      >
        More
      </Button>
    );
  };

  return (
    <>
      <FreelancerserplaceNavbar setToken={setToken} />
      <br />
      <Row className="justify-content-md-center">
        <br />
        <Col md={8}>
          <Search
            title={"Find your next Job here"}
            endpoint={PublicJobsConfig.Jobs.endpoints.search}
            resultData={publicJobs}
            setResultData={setPublicJobs}
            loadData={loadData}
          />
        </Col>
      </Row>
      <br />
      <div
        style={{
          marginLeft: "0px",
          paddingRight: "1%",
          paddingLeft: "1%",
          maxWidth: "99%",
        }}
      >
        <Row className="justify-content-md-center">
          <Col md={8}>
            <h3 style={{ width: "100%" }}>
              We have {count} jobs and projects listed{" "}
            </h3>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={7}>
            {publicJobs
              ? publicJobs.map((job, index) => {
                  return (
                    <Card
                      title={job._source.jobtitle}
                      key={index}
                      CardTools={() => {
                        return <CardTools jobId={job._source.id} />;
                      }}
                    >
                      {job._source.description}
                      <hr />
                      <b> Key Skills: </b>
                      {job._source.key_skills &&
                      job._source.key_skills.length > 0
                        ? job._source.key_skills.map((skill, skillIndex) => {
                            return skill.name + ", ";
                          })
                        : null}
                    </Card>
                  );
                })
              : "loading Jobs"}
          </Col>
          <Col md={5}>
            {jobDetails ? (
              <Card title={jobDetails.jobtitle}>
                <JobDetails job={jobDetails} />
              </Card>
            ) : null}
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default PublicJobs;
