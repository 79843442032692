import ActionsForm from "./ActionsForm";
import ActionForm from "@serie3/freelancesplaceapi/Form/Crm/ActionForm";
import NewModal from "../../../documents/modals/NewModal";
import { useFormik } from "formik";
import SaveButton from "../../../../../layout/Buttons/SaveButton";
import { useEffect, useState } from "react";
import Action from "@serie3/freelancesplaceapi/Entity/Crm/Action";
import getSettings from "@serie3/common/config/settings";
import PaymentModal from "../../../../../layout/Payment/PaymentModal";

const ActionsFormWrapper = ({
  showForm,
  setShowForm,
  editAction,
  setEditAction,
  tickets,
  setTickets,
  projectFormik,
  projectId,
}) => {
  const [showError, setShowError] = useState(false);
  const [showPayModal, setShowPayModal] = useState(false);
  const [settings, setSettings] = useState();

  const editActionForm = ActionForm.createActionForm(
    tickets,
    setTickets,
    setShowError,
    setShowPayModal,
    setEditAction,
  );

  useEffect(() => {
    setSettings(getSettings());
  }, []);


  const formik = useFormik(editActionForm);

  const handleClick = (e) => {
    if (editAction === null) {
      if (Object.keys(formik.errors).length === 0) {
        projectFormik.setFieldValue("action", formik.values);
        projectFormik.setFieldValue("project", { id: projectId });
        projectFormik.handleSubmit();
      }
    } else {
      formik.setFieldValue("id", editAction.id);
      formik.handleSubmit();
    }

    e.preventDefault();
  };

  const SaveActionButton = () => {
    return (
      <>
        <SaveButton
          handleClick={(e) => {
            handleClick(e);
          }}
        />
      </>
    );
  };

  const editTitle = editAction ? editAction.title : "Create Ticket";

  const setFormikNewValues = () => {
    formik.setValues(Action);
    formik.setFieldValue("tags", []);
  };

  return (
    <>
      {settings && (
        <PaymentModal
          show={showPayModal}
          setShow={setShowPayModal}
          priceTableId={settings.pricingTables.document}
        />
      )}

      <NewModal
        show={showForm}
        setShow={setShowForm}
        ExtraButton={SaveActionButton}
        title={editTitle}
        onShow={() => {
          editAction
            ? formik.setValues(ActionForm.setEditData(editAction))
            : setFormikNewValues();
        }}
      >
        <ActionsForm
          formik={formik}
          editAction={editAction}
          setShowPayModal={setShowPayModal}
        />
      </NewModal>
    </>
  );
};

export default ActionsFormWrapper;
