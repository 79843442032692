import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlay,
  faStop,
  faHourglass,
  faPause,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import crudApi from "@serie3/common/api/crudApi";
import TimetrackingConfig from "@serie3/freelancesplaceapi/Config/Timetracking/TimetrackingConfig";

const TimetrackingButton = ({ task, startUrl, tasks, setTasks }) => {
  const buttonStyling = {
    width: "30px",
    height: "30px",
    fontSize: "15px",
    lineHeight: "1.33",
    paddingLeft: "10px",
    border: "0",
  };

  const [status, setStatus] = useState("");
  const [localTask, setLocalTask] = useState();

  useEffect(() => {
    if (typeof task === "object" && task !== null) {
      const currentDate = new Date();
      const inputDate = new Date(task.date);
      if (inputDate.toDateString() === currentDate.toDateString()) {
        if (typeof task.pause_start !== "undefined") {
          setStatus("paused");
        } else {
          setStatus("running");
        }
        setLocalTask(task);
      }
    }
  }, [task]);

  const start = async () => {
    if (typeof startUrl === "undefined") {
      startUrl = TimetrackingConfig.endpoints.start;
    }
    const response = await crudApi.justGet(startUrl);
    if (response.status === 200) {
      const newTask = response.data;
      tasks.push(newTask);
      setTasks([...tasks]);
      setStatus("running");
      setLocalTask(newTask);
    }
  };

  const pause = async () => {
    const response = await crudApi.justGet(
      TimetrackingConfig.endpoints.pause + localTask.id
    );
    if (response.status === 200) {
      setStatus("paused");
    }
  };

  const resume = async () => {
    const response = await crudApi.justGet(
      TimetrackingConfig.endpoints.resume + localTask.id
    );
    if (response.status === 200) {
      setStatus("running");
    }
  };

  const stop = async () => {
    const response = await crudApi.justGet(
      TimetrackingConfig.endpoints.stop + localTask.id
    );
    if (response.status === 200) {
      setLocalTask();
    }
  };

  const HourglasButton = () => {
    return (
      <Button
        className="btn bg-gradient-success"
        style={{ borderRadius: "25px", ...buttonStyling }}
      >
        <FontAwesomeIcon
          icon={faHourglass}
          className={status === "running" ? "fa-spin" : ""}
        />
      </Button>
    );
  };

  const PauseButton = () => {
    return (
      <Button
        className="btn bg-gradient-success"
        style={{ borderRadius: "25px", ...buttonStyling }}
        onClick={pause}
      >
        <FontAwesomeIcon icon={faPause} />
      </Button>
    );
  };

  const StopButton = () => {
    return (
      <Button
        className="btn bg-gradient-success"
        style={{ borderRadius: "5px", ...buttonStyling }}
        onClick={stop}
      >
        <FontAwesomeIcon icon={faStop} />
      </Button>
    );
  };

  const RunningButtons = () => {
    switch (status) {
      case "running":
        return (
          <>
            <HourglasButton />
            &nbsp;
            <PauseButton />
            &nbsp;
            <StopButton />
          </>
        );
      case "paused":
        return (
          <>
            <StartButton />
            &nbsp;
            <StopButton />
          </>
        );
        break;
    }
  };

  const StartButton = () => {
    return (
      <Button
        className="btn bg-gradient-success"
        style={{ borderRadius: "25px", ...buttonStyling }}
        onClick={localTask ? resume : start}
      >
        <FontAwesomeIcon icon={faPlay} />
      </Button>
    );
  };

  return (
    <div className="card-body centerButtonCard">
      {typeof localTask !== "undefined" ? <RunningButtons /> : <StartButton />}
    </div>
  );
};

export default TimetrackingButton;
