import React from "react";


const SubList = ({children}) => {
    return (
        <ul className="nav nav-pills nav-sidebar flex-column">
            {children}
        </ul>
    );
}

export default SubList;