const Person = {
    geschlecht: '',
    anrede: '',
    name: '',
    vorname: '',
    position: '',
    bemerkung: '',
    gesperrt: 0,
    sperrgrund: '',
    geburtstag: '',
    original_id: '',
    source: '',
    tax_id: '',
    tax_number: '',
}

export default Person;