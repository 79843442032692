const PublicJobsConfig = {
  Jobs: {
    endpoints: {
      publicJobs: "/publicjobs/",
      jobDetails: "/publicjobs/showpublicjob/",
      matchingProfiles: "/publicjobs/profiles/",
      search: "/publicjobs/search",
    },
  },
};

export default PublicJobsConfig;
