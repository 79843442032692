const DocumentsConfig = {
    endpoints: {
      getAll: "/documents/",
      new: "/documents/",
      edit: "/documents/edit",
      show: "/documents/show/",
      delete: "/documents/delete/",
      preview: "/documents/preview/",
    },
  };
  export default DocumentsConfig;
  