import NewModal from "../documents/modals/NewModal";
import { useEffect, useState } from "react";
import loadPublicProfileDetails from "@serie3/freelancesplaceapi/Domain/Profile/LoadPublicProfileDetails";
import ProfileDetails from "../../../Public/Components/ProfileDetails";

const PublicProfileView = ({ profileId, showView, setShowView }) => {
  const [publicProfile, setPublicProfile] = useState();

  useEffect(() => {
    loadPublicProfileDetails(setPublicProfile, profileId);
  }, [profileId]);
  
  return publicProfile ? (
    <NewModal
      show={showView}
      setShow={setShowView}
      title={publicProfile.title}
    >
      <ProfileDetails profile={publicProfile} />
    </NewModal>
  ): null;
};

export default PublicProfileView;
