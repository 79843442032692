import Footer from "../signup/SubComponents/Footer";
import FreelancerserplaceNavbar from "../signup/SubComponents/FreelancerserplaceNavbar";
import { Container } from "react-bootstrap";

const Privacy = ({ setToken }) => {
  return (
    <>
      <FreelancerserplaceNavbar setToken={setToken} />
      <Container>
        <div className="header">
          <h1>Datenschutzerklärung</h1>
          <ul>
            <li className="lhl" style={{ marginBottom: "18px" }}>
              <span> </span>
              <p>
                <strong> </strong>
                <strong>Allgemeine Hinweise</strong>
              </p>
            </li>
            <li style={{ marginBottom: "18px" }} value="1">
              <span>
                Diese Datenschutzerklärung enthält ausführliche Informationen
                darüber, was mit Ihren persönlichen Daten passiert, wenn Sie
                unsere Website https://freelancersplace.net/ besuchen.
                Personenbezogene Daten sind alle Daten, mit denen Sie sich
                persönlich identifizieren können. Wir halten uns bei der
                Verarbeitung Ihrer Daten streng an die gesetzlichen
                Bestimmungen, insbesondere die Datenschutzgrundverordnung
                („DSGVO“), und legen großen Wert darauf, dass Ihr Besuch auf
                unserer Website absolut sicher ist.
              </span>
              <br />
            </li>
            <li className="lhl" style={{ marginBottom: "18px" }}>
              <span> </span>
              <p>
                <strong> </strong>
                <strong>Verantwortliche Stelle</strong>
              </p>
            </li>
            <li style={{ marginBottom: "18px" }} value="2">
              <span>
                Datenschutzrechtlich verantwortlich für die Erhebung und
                Verarbeitung von personenbezogenen Daten auf dieser Website ist:
              </span>
              <br />
              <br />
              <strong>Vorname, Name: </strong>Felix Dziekan
              <br />
              <strong>Straße, Hausnummer: </strong>Geleitsgasse 2<br />
              <strong>Postleitzahl, Ort: </strong>90762, Fürth
              <br />
              <strong>Land: </strong>Deutschland
              <br />
              <strong>E-Mail: </strong>felix@serie3.de
              <br />
              <strong>Tel.: </strong>017622658978
              <br />
            </li>
            <li className="lhl" style={{ marginBottom: "18px" }}>
              <span> </span>
              <p>
                <strong> </strong>
                <strong>Cookies</strong>
              </p>
            </li>
            <li style={{ marginBottom: "18px" }} value="3">
              <span>
                Um den Besuch unserer Website attraktiv zu gestalten und die
                Nutzung bestimmter Funktionen zu ermöglichen, verwenden wir
                sogenannte Cookies. Hierbei handelt es sich um kleine
                Textdateien, die auf Ihrem Endgerät abgelegt werden. Cookies
                können keine Programme ausführen oder Viren auf Ihr
                Computersystem übertragen.
              </span>
              <br />
            </li>
            <li style={{ marginBottom: "18px" }} value="4">
              <span>
                Cookies, die zur Durchführung des elektronischen
                Kommunikationsvorgangs oder zur Bereitstellung bestimmter, von
                Ihnen erwünschter Funktionen erforderlich sind, werden auf
                Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Wir haben
                ein berechtigtes Interesse an der Speicherung von Cookies zur
                technisch fehlerfreien und optimierten Bereitstellung unserer
                Dienste. Soweit andere Cookies (z.B. Cookies zur Analyse Ihres
                Surfverhaltens) gespeichert werden, werden diese in dieser
                Datenschutzerklärung gesondert behandelt.
              </span>
              <br />
            </li>
            <li style={{ marginBottom: "18px" }} value="5">
              <span>
                Die meisten der von uns verwendeten Cookies sind so genannte
                „Session-Cookies”. Sie werden nach Ende Ihres Besuchs
                automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät
                gespeichert bis Sie diese löschen. Diese Cookies ermöglichen es
                uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.
              </span>
              <br />
            </li>
            <li style={{ marginBottom: "18px" }} value="6">
              <span>
                Sie können Ihren Browser so einstellen, dass Sie über das Setzen
                von Cookies informiert werden und Cookies nur im Einzelfall
                erlauben, die Annahme von Cookies für bestimmte Fälle oder
                generell ausschließen sowie das automatische Löschen der Cookies
                beim Schließen des Browsers aktivieren. Bei der Deaktivierung
                von Cookies kann die Funktionalität dieser Website eingeschränkt
                sein.
              </span>
              <br />
            </li>
            <li className="lh" style={{ marginBottom: "18px" }}>
              <span> </span>
              <p style={{ marginLeft: "33pt" }}>
                <strong> </strong>
                <strong>Webanalyse Tools und Werbung</strong>
              </p>
            </li>
            <li style={{ marginBottom: "18px" }} value="7">
              <ol start="1" className="subnumberedList">
                <li style={{ marginBottom: "0pt" }} value="1">
                  <span> </span>
                  <strong>Google Analytics/Google Tag Manager</strong>
                  <br />
                  <br />
                  <p style={{ textAlign: "left" }}>
                    Wir setzen den Google Tag Manager ein. Anbieter ist die
                    Google Ireland Limited, Gordon House, Barrow Street, Dublin
                    4, Irland. Der Google Tag Manager ist ein Tool, mit dessen
                    Hilfe wir Tracking- oder Statistik-Tools und andere
                    Technologien auf unserer Website einbinden können. Der
                    Google Tag Manager selbst erstellt keine Nutzerprofile,
                    speichert keine Cookies und nimmt keine eigenständigen
                    Analysen vor. Er dient lediglich der Verwaltung und
                    Ausspielung der über ihn eingebundenen Tools. Der Google Tag
                    Manager erfasst jedoch Ihre IP-Adresse, die auch an das
                    Mutterunternehmen von Google in die Vereinigten Staaten
                    übertragen werden kann. Der Einsatz des Google Tag Managers
                    erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der
                    Websitebetreiber hat ein berechtigtes Interesse an einer
                    schnellen und unkomplizierten Einbindung und Verwaltung
                    verschiedener Tools auf seiner Website. Sofern eine
                    entsprechende Einwilligung abgefragt wurde, erfolgt die
                    Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1
                    lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
                  </p>
                  <p style={{ textAlign: "left" }}>
                    Unsere Website benutzt den Webanalysedienst Google
                    Analytics. Anbieter ist die Google Ireland Ltd., Gordon
                    House, Barrow Street, Dublin 4, Ireland („Google“).
                  </p>
                  <p style={{ textAlign: "left" }}>
                    Google Analytics verwendet sogenannte „Cookies“. Das sind
                    Textdateien, die auf Ihrem Computer gespeichert werden und
                    die eine Analyse der Benutzung unserer Website durch Sie
                    ermöglichen. Die durch Cookies erzeugten Informationen über
                    Ihre Benutzung unserer Website werden in der Regel an einen
                    Server von Google in den USA übertragen und dort
                    gespeichert.
                  </p>
                  <p style={{ textAlign: "left" }}>
                    Rechtsgrundlage für die Verarbeitung Ihrer Daten ist die von
                    Ihnen über das Cookie-Consent-Tool erteilte Einwilligung
                    gemäß Art. 6 Abs. 1 Satz 1 lit. a) DSGVO.
                  </p>
                  <ol start="1" className="recital">
                    <li style={{ marginBottom: "18px" }} value="1">
                      <span> </span>
                      <strong>IP-Anonymisierung</strong>
                      <br />
                      <br />
                      <p style={{ textAlign: "left" }}>
                        Wir haben auf dieser Website die Funktion
                        IP-Anonymisierung aktiviert. Dadurch wird Ihre
                        IP-Adresse von Google innerhalb von Mitgliedstaaten der
                        Europäischen Union oder in anderen Vertragsstaaten des
                        Abkommens über den Europäischen Wirtschaftsraum vor der
                        Übermittlung in die USA gekürzt. Nur in Ausnahmefällen
                        wird die volle IP-Adresse an einen Server von Google in
                        den USA übertragen und dort gekürzt. In unserem Auftrag
                        wird Google diese Informationen benutzen, um Ihre
                        Nutzung unserer Website auszuwerten, um Reports über die
                        Websiteaktivitäten zusammenzustellen und um weitere mit
                        der Websitenutzung und der Internetnutzung verbundene
                        Dienstleistungen gegenüber uns zu erbringen. Die im
                        Rahmen von Google Analytics von Ihrem Browser
                        übermittelte IP-Adresse wird nicht mit anderen Daten von
                        Google zusammengeführt.
                      </p>
                    </li>
                    <li style={{ marginBottom: "18px" }} value="2">
                      <span> </span>
                      <p>
                        <strong>Auftragsverarbeitung</strong>
                        <br />
                      </p>
                      <p style={{ textAlign: "left" }}>
                        Wir haben mit Google einen Vertrag zur
                        Auftragsdatenverarbeitung abgeschlossen und setzen die
                        strengen Vorgaben der deutschen Datenschutzbehörden bei
                        der Nutzung von Google Analytics vollständig um.
                      </p>
                    </li>
                    <li style={{ marginBottom: "18px" }} value="3">
                      <span> </span>
                      <strong>Speicherdauer</strong>
                      <br />
                      <br />
                      <p style={{ textAlign: "left" }}>
                        Bei Google gespeicherte Daten auf Nutzer- und
                        Ereignisebene, die mit Cookies, Nutzerkennungen (z.B.
                        User ID) oder Werbe-IDs (z.B. DoubleClick-Cookies,
                        Android-Werbe-ID) verknüpft sind, werden nach 14 Monaten
                        anonymisiert bzw. gelöscht. Details hierzu ersehen Sie
                        unter folgendem Link:
                        https://support.google.com/analytics/answer/7667196?hl=de
                      </p>
                    </li>
                    <li style={{ marginBottom: "0pt" }} value="4">
                      <span> </span>
                      <p>
                        <strong>Widerspruch gegen Datenerfassung</strong>
                        <br />
                      </p>
                      <p style={{ marginBottom: "18px" }}>
                        Sie können die Speicherung der Cookies durch eine
                        entsprechende Einstellung Ihrer Browser-Software
                        verhindern; wir weisen Sie jedoch darauf hin, dass Sie
                        in diesem Fall gegebenenfalls nicht sämtliche Funktionen
                        dieser Website vollumfänglich werden nutzen können.
                      </p>
                      <p>
                        Sie können darüber hinaus die Erfassung der durch die
                        Cookies erzeugten und auf Ihre Nutzung der Website
                        bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie
                        die Verarbeitung dieser Daten durch Google verhindern,
                        indem Sie das unter dem folgenden Link verfügbare
                        Browser-Add-on zur Deaktivierung von Google Analytics
                        herunterladen und installieren:
                        https://tools.google.com/dlpage/gaoptout?hl=de. Wenn Sie
                        die Cookies auf Ihrem Rechner löschen, müssen Sie den
                        Opt-Out-Cookie erneut setzen.
                      </p>
                    </li>
                  </ol>
                  <p style={{ textAlign: "left" }}>
                    Mehr Informationen zum Umgang mit Nutzerdaten bei Google
                    Analytics finden Sie in der Datenschutzerklärung von Google:
                    https://support.google.com/analytics/answer/6004245?hl=de.
                  </p>
                  <p style={{ textAlign: "left" }}>
                    Weitere Informationen zum Datenschutz können Sie der
                    Datenschutzerklärung von Google entnehmen:
                    https://policies.google.com/privacy?hl=de&amp;gl=de
                  </p>
                </li>
              </ol>
            </li>
            <li className="lhl" style={{ marginBottom: "18px" }}>
              <span> </span>
              <p>
                <strong> </strong>
                <strong>Kontaktformular</strong>
              </p>
            </li>
            <li style={{ marginBottom: "18px" }} value="8">
              <span>
                Sofern Sie mit uns Kontakt per E-Mail oder über ein
                Kontaktformular aufnehmen, werden übermittelte Daten
                einschließlich Ihrer Kontaktdaten gespeichert, um Ihre Anfrage
                bearbeiten zu können oder um für Anschlussfragen bereitzustehen.
                Eine Weitergabe dieser Daten findet ohne Ihre Einwilligung nicht
                statt.
              </span>
              <br />
            </li>
            <li style={{ marginBottom: "18px" }} value="9">
              <span>
                Die Verarbeitung der in das Kontaktformular eingegebenen Daten
                erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6
                Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten
                Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine
                formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum
                Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom
                Widerruf unberührt.
              </span>
              <br />
            </li>
            <li style={{ marginBottom: "18px" }} value="10">
              <span>
                Über das Kontaktformular übermittelte Daten verbleiben bei uns,
                bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur
                Speicherung widerrufen oder keine Notwendigkeit der
                Datenspeicherung mehr besteht. Zwingende gesetzliche
                Bestimmungen - insbesondere Aufbewahrungsfristen - bleiben
                unberührt.
              </span>
              <br />
            </li>
            <li className="lhl" style={{ marginBottom: "18px" }}>
              <span> </span>
              <p>
                <strong> </strong>
                <strong>Nutzerkonto</strong>
              </p>
            </li>
            <li style={{ marginBottom: "18px" }} value="11">
              <span>
                Wenn Sie ein Nutzerkonto eröffnen, willigen Sie damit ein, dass
                Ihre Bestandsdaten wie Name, Adresse und E-Mail-Adresse sowie
                Ihre Nutzungsdaten (Benutzername, Passwort) gespeichert werden.
                Dadurch haben Sie die Möglichkeit, sich mit Ihrer E-Mail-Adresse
                sowie Ihrem persönlichen Passwort bei uns einzuloggen.
              </span>
              <br />
            </li>
            <li className="lhl" style={{ marginBottom: "18px" }}>
              <span> </span>
              <p>
                <strong> </strong>
                <strong>Datenverwendung und -weitergabe</strong>
              </p>
            </li>
            <li style={{ marginBottom: "18px" }} value="12">
              <span>
                Die personenbezogenen Daten, die Sie uns z.B. per E-Mail
                mitteilen (z.B. Ihr Name und Ihre Adresse oder Ihre
                E-Mail-Adresse), werden wir weder an Dritte verkaufen noch
                anderweitig vermarkten. Ihre personenbezogenen Daten werden nur
                zur Korrespondenz mit Ihnen und nur für den Zweck verarbeitet,
                zu dem Sie uns die Daten zur Verfügung gestellt haben. Zur
                Abwicklung von Zahlungen geben wir Ihre Zahlungsdaten an das mit
                der Zahlung beauftragte Kreditinstitut weiter.
              </span>
              <br />
              <p style={{ textAlign: "left" }}>
                <br />
                Die Verwendung von Daten, die bei Ihrem Besuch unserer Website
                automatisch erhoben werden, erfolgt nur zu den vorstehend
                genannten Zwecken. Eine anderweitige Verwendung der Daten findet
                nicht statt.
              </p>
              <p style={{ textAlign: "left" }}>
                Wir versichern, dass wir Ihre personenbezogenen Daten im Übrigen
                nicht an Dritte weitergeben, es sei denn, dass wir dazu
                gesetzlich verpflichtet wären oder Sie uns vorher Ihre
                Zustimmung gegeben haben.
              </p>
            </li>
            <li className="lhl" style={{ marginBottom: "18px" }}>
              <span> </span>
              <p>
                <strong> </strong>
                <strong>SSL- bzw. TLS-Verschlüsselung</strong>
              </p>
            </li>
            <li style={{ marginBottom: "18px" }} value="13">
              <span>
                Unsere Website benutzt aus Sicherheitsgründen und zum Schutz der
                Übertragung vertraulicher Inhalte, wie zum Beispiel Anfragen,
                die Sie an uns als Seitenbetreiber senden, eine SSL-bzw.
                TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie
                daran, dass die Adresszeile des Browsers von „http://” auf
                „https://” wechselt und an dem Schloss-Symbol in Ihrer
                Browserzeile.
              </span>
              <br />
            </li>
            <li style={{ marginBottom: "18px" }} value="14">
              <span>
                Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
                Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
                werden.
              </span>
              <br />
            </li>
            <li className="lh" style={{ marginBottom: "18px" }}>
              <span> </span>
              <p style={{ marginLeft: "33pt" }}>
                <strong> </strong>
                <strong>Speicherdauer</strong>
              </p>
            </li>
            <li style={{ marginBottom: "18px" }} value="15">
              <span>
                Personenbezogene Daten, die uns über unsere Website mitgeteilt
                worden sind, werden nur so lange gespeichert, bis der Zweck
                erfüllt ist, zu dem sie uns anvertraut wurden. Soweit handels-
                und steuerrechtliche Aufbewahrungsfristen zu beachten sind, kann
                die Speicherdauer zu bestimmten Daten bis zu 10 Jahre betragen.
              </span>
              <br />
            </li>
            <li className="lh" style={{ marginBottom: "18px" }}>
              <span> </span>
              <p style={{ marginLeft: "33pt" }}>
                <strong> </strong>
                <strong>Betroffenenrechte</strong>
              </p>
            </li>
            <li style={{ marginBottom: "18px" }} value="16">
              <span> </span>
              <p>
                Hinsichtlich der Sie betreffenden personenbezogenen Daten haben
                als Betroffener der Datenverarbeitung nach Maßgabe der
                gesetzlichen Bestimmungen folgende Rechte gegenüber dem
                Verantwortlichen:
                <br />
              </p>
              <ol start="1" className="subnumberedList">
                <li style={{ marginBottom: "18px" }} value="1">
                  <span> </span>
                  <p>
                    <strong>Widerrufsrecht</strong>
                    <br />
                  </p>
                  <p style={{ textAlign: "left" }}>
                    Viele Datenverarbeitungsvorgänge sind nur mit Ihrer
                    ausdrücklichen Einwilligung möglich. Sofern die Verarbeitung
                    Ihrer Daten auf Ihrer Einwilligung beruht, haben Sie das
                    Recht, eine einmal erteilte Einwilligung in die Verarbeitung
                    von Daten gemäß Art. 7 Abs. 3 DSGVO jederzeit mit Wirkung
                    für die Zukunft zu widerrufen. Durch den Widerruf der
                    Einwilligung wird die Rechtmäßigkeit der aufgrund der
                    Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht
                    berührt. Speicherung der Daten für Abrechnungs- und
                    buchhalterische Zwecke bleibt von einem Widerruf nicht
                    berührt.
                  </p>
                </li>
                <li style={{ marginBottom: "18px" }} value="2">
                  <span> </span>
                  <p>
                    <strong>Auskunftsrecht</strong>
                    <br />
                  </p>
                  <p>
                    Sie haben das Recht, gemäß Art. 15 DSGVO von uns eine
                    Bestätigung darüber zu verlangen, ob wir personenbezogene
                    Daten verarbeiten, die Sie betreffen. Liegt eine solche
                    Verarbeitung vor, haben Sie das Recht auf Auskunft über Ihre
                    von uns verarbeiteten personenbezogenen Daten, die
                    Verarbeitungszwecke, die Kategorien der verarbeiteten
                    personenbezogenen Daten, die Empfänger oder Kategorien von
                    Empfängern, gegenüber denen Ihre Daten offengelegt wurden
                    oder werden, die geplante Speicherdauer bzw. die Kriterien
                    für die Festlegung der Speicherdauer, das Bestehen eines
                    Rechts auf Berichtigung, Löschung, Einschränkung der
                    Verarbeitung, Widerspruch gegen die Verarbeitung, Beschwerde
                    bei einer Aufsichtsbehörde, die Herkunft Ihrer Daten, wenn
                    diese nicht durch uns bei Ihnen erhoben wurden, das Bestehen
                    einer automatisierten Entscheidungsfindung einschließlich
                    Profiling und ggf. aussagekräftige Informationen über die
                    involvierte Logik und die Sie betreffende Tragweite und die
                    angestrebten Auswirkungen einer solchen Verarbeitung, sowie
                    Ihr Recht auf Unterrichtung, welche Garantien gemäß Art. 46
                    DSGVO bei Weiterleitung Ihrer Daten in Drittländer bestehen.
                  </p>
                </li>
                <li style={{ marginBottom: "18px" }} value="3">
                  <span> </span>
                  <p>
                    <strong>Recht auf Berichtigung</strong>
                    <br />
                  </p>
                  <p style={{ textAlign: "left" }}>
                    Sie haben das Recht, gemäß Art. 16 DSGVO jederzeit
                    unverzügliche Berichtigung Sie betreffender unrichtiger
                    personenbezogener Daten und/oder die Vervollständigung Ihrer
                    unvollständigen Daten zu verlangen.
                  </p>
                </li>
                <li style={{ marginBottom: "18px" }} value="4">
                  <span> </span>
                  <p>
                    <strong>Recht auf Löschung</strong>
                    <br />
                  </p>
                  <p style={{ textAlign: "left" }}>
                    Sie haben das Recht, gemäß Art. 17 DSGVO die Löschung Ihrer
                    personenbezogenen Daten zu verlangen, sofern einer der
                    folgenden Gründe zutrifft:
                  </p>
                  <ol start="1" className="recital">
                    <li style={{ marginBottom: "18px" }} value="1">
                      <span> </span>
                      <p>
                        Ihre personenbezogenen Daten sind für die Zwecke, für
                        die sie erhoben oder auf sonstige Weise verarbeitet
                        wurden, nicht mehr notwendig;
                      </p>
                    </li>
                    <li style={{ marginBottom: "18px" }} value="2">
                      <span> </span>
                      <p>
                        Sie widerrufen Ihre Einwilligung, auf die sich die
                        Verarbeitung gemäß Art. 6 Abs. 1 lit. a oder Art. 9 Abs.
                        2 lit. a DSGVO stützte, und es fehlt an einer
                        anderweitigen Rechtsgrundlage für die Verarbeitung;
                      </p>
                    </li>
                    <li style={{ marginBottom: "18px" }} value="3">
                      <span> </span>
                      <p>
                        Sie legen gemäß Art. 21 Abs. 1 DSGVO Widerspruch gegen
                        die Verarbeitung ein und es liegen keine vorrangigen
                        berechtigten Gründe für die Verarbeitung vor, oder Sie
                        legen gemäß Art. 21 Abs. 2 DSGVO Widerspruch gegen die
                        Verarbeitung ein;
                      </p>
                    </li>
                    <li style={{ marginBottom: "18px" }} value="4">
                      <span> </span>
                      <p>
                        Die personenbezogenen Daten wurden unrechtmäßig
                        verarbeitet;
                      </p>
                    </li>
                    <li style={{ marginBottom: "18px" }} value="5">
                      <span> </span>
                      <p>
                        Die Löschung der personenbezogenen Daten ist zur
                        Erfüllung einer rechtlichen Verpflichtung nach dem
                        Unionsrecht oder dem Recht des Mitgliedstaates
                        erforderlich, dem wir unterliegen;
                      </p>
                    </li>
                    <li style={{ marginBottom: "0pt" }} value="6">
                      <span> </span>
                      <p>
                        Die personenbezogenen Daten wurden in Bezug auf
                        angebotene Dienste der Informationsgesellschaft gemäß
                        Art. 8 Abs. 1 DSGVO erhoben;
                      </p>
                    </li>
                  </ol>
                  <p>
                    Dieses Recht besteht jedoch nicht, soweit die Verarbeitung
                    erforderlich ist:
                  </p>
                  <ol start="1">
                    <li style={{ marginBottom: "18px" }} value="1">
                      <span> </span>
                      <p>
                        zur Ausübung des Rechts auf freie Meinungsäußerung und
                        Information;
                      </p>
                    </li>
                    <li style={{ marginBottom: "18px" }} value="2">
                      <span> </span>
                      <p>
                        zur Erfüllung einer rechtlichen Verpflichtung, die die
                        Verarbeitung nach dem Recht der Union oder des
                        Mitgliedstaates, dem wir unterliegen, erfordert, oder
                        zur Wahrnehmung einer Aufgabe, die im öffentlichen
                        Interesse liegt oder in Ausübung öffentlicher Gewalt
                        erfolgt, die uns übertragen wurde;
                      </p>
                    </li>
                    <li style={{ marginBottom: "18px" }} value="3">
                      <span> </span>
                      <p>
                        aus Gründen des öffentlichen Interesses im Bereich der
                        öffentlichen Gesundheit gemäß Art. 9 Abs. 2 lit. h und i
                        sowie Art. 9 Abs. 3 DSGVO;
                      </p>
                    </li>
                    <li style={{ marginBottom: "0pt" }} value="4">
                      <span> </span>
                      <p>
                        für im öffentlichen Interesse liegende Archivzwecke,
                        wissenschaftliche oder historische Forschungszwecke oder
                        für statistische Zwecke gemäß Art. 89 Abs. 1 DSGVO,
                        soweit das Betroffenenrecht voraussichtlich die
                        Verwirklichung der Ziele dieser Verarbeitung unmöglich
                        macht oder ernsthaft beeinträchtigt, oder
                        <br />
                        zur Geltendmachung, Ausübung oder Verteidigung von
                        Rechtsansprüchen.
                      </p>
                    </li>
                  </ol>
                  <p style={{ textAlign: "left" }}>
                    Haben wir Ihre personenbezogenen Daten öffentlich gemacht
                    und sind wir nach Vorstehendem zu deren Löschung
                    verpflichtet, so treffen wir unter Berücksichtigung der
                    verfügbaren Technologie und der Implementierungskosten
                    angemessene Maßnahmen, auch technischer Art, um für die
                    Datenverarbeitung Verantwortliche, die die personenbezogenen
                    Daten verarbeiten, darüber zu informieren, dass Sie als
                    betroffene Person von ihnen die Löschung aller Links zu
                    Ihren personenbezogenen Daten oder von Kopien oder
                    Replikationen dieser personenbezogenen Daten verlangt haben.
                  </p>
                </li>
                <li style={{ marginBottom: "18px" }} value="5">
                  <span> </span>
                  <p>
                    <strong>Recht auf Einschränkung der Verarbeitung</strong>
                    <br />
                  </p>
                  <p style={{ textAlign: "left" }}>
                    Sie haben das Recht, gemäß Art. 18 DSGVO die Einschränkung
                    der Verarbeitung (Sperrung) Ihrer personenbezogenen Daten zu
                    verlangen. Hierzu können Sie sich jederzeit unter der im
                    Impressum angegebenen Adresse an uns wenden. Das Recht auf
                    Einschränkung der Verarbeitung besteht in folgenden Fällen:
                    <br />
                  </p>
                  <ol start="1" className="recital">
                    <li style={{ marginBottom: "18px" }} value="1">
                      <span>
                        Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
                        personenbezogenen Daten bestreiten, benötigen wir in der
                        Regel Zeit, um dies zu überprüfen. Für die Dauer der
                        Prüfung haben Sie das Recht, die Einschränkung der
                        Verarbeitung Ihrer personenbezogenen Daten zu verlangen.{" "}
                      </span>
                      <br />
                    </li>
                    <li style={{ marginBottom: "18px" }} value="2">
                      <span>
                        Wenn die Verarbeitung Ihrer personenbezogenen Daten
                        unrechtmäßig geschah / geschieht, können Sie statt der
                        Löschung die Einschränkung der Datenverarbeitung
                        verlangen.{" "}
                      </span>
                      <br />
                    </li>
                    <li style={{ marginBottom: "18px" }} value="3">
                      <span>
                        Wenn wir Ihre personenbezogenen Daten nicht mehr
                        benötigen, Sie sie jedoch zur Ausübung, Verteidigung
                        oder Geltendmachung von Rechtsansprüchen benötigen,
                        haben Sie das Recht, statt der Löschung die
                        Einschränkung der Verarbeitung Ihrer personenbezogenen
                        Daten zu verlangen.{" "}
                      </span>
                      <br />
                    </li>
                    <li style={{ marginBottom: "0pt" }} value="4">
                      <span>
                        Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO
                        eingelegt haben, muss eine Abwägung zwischen Ihren und
                        unseren Interessen vorgenommen werden. Solange noch
                        nicht feststeht, wessen Interessen überwiegen, haben Sie
                        das Recht, die Einschränkung der Verarbeitung Ihrer
                        personenbezogenen Daten zu verlangen.
                      </span>
                      <br />
                    </li>
                  </ol>
                  <p style={{ textAlign: "left" }}>
                    Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
                    eingeschränkt haben, dürfen diese Daten – von ihrer
                    Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur
                    Geltendmachung, Ausübung oder Verteidigung von
                    Rechtsansprüchen oder zum Schutz der Rechte einer anderen
                    natürlichen oder juristischen Person oder aus Gründen eines
                    wichtigen öffentlichen Interesses der Europäischen Union
                    oder eines Mitgliedstaats verarbeitet werden.
                  </p>
                </li>
                <li style={{ marginBottom: "18px" }} value="6">
                  <span> </span>
                  <p>
                    <strong>Recht auf Unterrichtung</strong>
                    <br />
                  </p>
                  <p style={{ textAlign: "left" }}>
                    Haben Sie das Recht auf Berichtigung, Löschung oder
                    Einschränkung der Verarbeitung gegenüber uns geltend
                    gemacht, sind wir verpflichtet, allen Empfängern, denen Ihre
                    personenbezogenen Daten offengelegt wurden, diese
                    Berichtigung oder Löschung der Daten oder Einschränkung der
                    Verarbeitung mitzuteilen, es sei denn, dies erweist sich als
                    unmöglich oder ist mit einem unverhältnismäßigen Aufwand
                    verbunden. Ihnen steht gemäß Art. 19 DSGVO das Recht zu,
                    nach Verlangen über diese Empfänger unterrichtet zu werden.
                  </p>
                </li>
                <li style={{ marginBottom: "18px" }} value="7">
                  <span> </span>
                  <p>
                    <strong>
                      Recht, nicht einer ausschließlich auf einer
                      automatisierten Verarbeitung – einschließlich Profiling –
                      beruhenden Entscheidung unterworfen zu werden
                    </strong>
                    <br />
                  </p>
                  <p style={{ textAlign: "left" }}>
                    Sie haben das Recht, gemäß Art. 22 DSGVO nicht einer
                    ausschließlich auf einer automatisierten Verarbeitung –
                    einschließlich Profiling – beruhenden Entscheidung
                    unterworfen zu werden, die Ihnen gegenüber rechtliche
                    Wirkung entfaltet oder Sie in ähnlicher Weise erheblich
                    beeinträchtigt.
                  </p>
                  <p style={{ textAlign: "left" }}>
                    Dies gilt nicht, wenn die Entscheidung
                  </p>
                  <ol start="1" className="recital">
                    <li style={{ marginBottom: "18px" }} value="1">
                      <span>
                        für den Abschluss oder die Erfüllung eines Vertrags
                        zwischen Ihnen und uns erforderlich ist,
                      </span>
                      <br />
                    </li>
                    <li style={{ marginBottom: "18px" }} value="2">
                      <span>
                        aufgrund von Rechtsvorschriften der Union oder der
                        Mitgliedstaaten, denen der Verantwortliche unterliegt,
                        zulässig ist und diese Rechtsvorschriften angemessene
                        Maßnahmen zur Wahrung Ihrer Rechte und Freiheiten sowie
                        Ihrer berechtigten Interessen enthalten oder
                      </span>
                      <br />
                    </li>
                    <li style={{ marginBottom: "0pt" }} value="3">
                      <span>
                        mit Ihrer ausdrücklichen Einwilligung erfolgt.
                      </span>
                      <br />
                    </li>
                  </ol>
                  <p style={{ textAlign: "left" }}>
                    Allerdings dürfen die Entscheidungen in den in (a) bis (c)
                    genannten Fällen nicht auf besonderen Kategorien
                    personenbezogener Daten nach Art. 9 Abs. 1 DSGVO beruhen,
                    sofern nicht Art. 9 Abs. 2 lit. a oder lit. g gilt und
                    angemessene Maßnahmen zum Schutz der Rechte und Freiheiten
                    sowie Ihrer berechtigten Interessen getroffen wurden.
                  </p>
                  <p style={{ textAlign: "left" }}>
                    In den in (a) und (c) genannten Fällen treffen wir
                    angemessene Maßnahmen, um Ihre Rechte und Freiheiten sowie
                    Ihre berechtigten Interessen zu wahren, wozu mindestens das
                    Recht auf Erwirkung des Eingreifens einer Person seitens des
                    Verantwortlichen, auf Darlegung des eigenen Standpunkts und
                    auf Anfechtung der Entscheidung gehört.
                  </p>
                </li>
                <li style={{ marginBottom: "18px" }} value="8">
                  <span> </span>
                  <p>
                    <strong>Recht auf Datenübertragbarkeit</strong>
                    <br />
                  </p>
                  <p style={{ textAlign: "left" }}>
                    Sofern die Verarbeitung auf Ihrer Einwilligung gem. Art. 6
                    Abs. 1 lit. a DSGVO oder Art. 9 Abs. 2 lit. a DSGVO oder auf
                    einem Vertrag gemäß Art. 6 Abs. 1 lit. b DSGVO beruht und
                    mithilfe automatisierter Verfahren erfolgt, haben Sie das
                    Recht, gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die
                    Sie uns bereitgestellt haben, in einem strukturierten,
                    gängigen und maschinenlesebaren Format zu erhalten und einem
                    anderen Verantwortlichen zu übermitteln oder die
                    Übermittlung an einen anderen Verantwortlichen zu verlangen,
                    soweit dies technisch machbar ist.
                  </p>
                </li>
                <li style={{ marginBottom: "18px" }} value="9">
                  <span> </span>
                  <p>
                    <strong>Widerspruchsrecht</strong>
                    <br />
                  </p>
                  <p style={{ textAlign: "left" }}>
                    Soweit wir die Verarbeitung Ihrer personenbezogenen Daten
                    auf die Interessenabwägung nach Art. 6 Abs. 1 lit. f DSGVO
                    stützen, haben Sie jederzeit das Recht, aus Gründen, die
                    sich aus Ihrer besonderen Situation ergeben, gegen die
                    Verarbeitung Ihrer personenbezogenen Daten Widerspruch
                    einzulegen; dies gilt auch für ein auf diese Bestimmung
                    gestütztes Profiling. Die jeweilige Rechtsgrundlage, auf der
                    eine Verarbeitung beruht, entnehmen Sie dieser
                    Datenschutzerklärung. Wenn Sie Widerspruch einlegen, werden
                    wir Ihre betroffenen personenbezogenen Daten nicht mehr
                    verarbeiten, es sei denn, wir können zwingende schutzwürdige
                    Gründe für die Verarbeitung nachweisen, die Ihre Interessen,
                    Rechte und Freiheiten überwiegen oder die Verarbeitung dient
                    der Geltendmachung, Ausübung oder Verteidigung von
                    Rechtsansprüchen (Widerspruch nach Art. 21 Abs. 1 DSGVO).
                  </p>
                  <p style={{ textAlign: "left" }}>
                    Werden Ihre personenbezogenen Daten verarbeitet, um
                    Direktwerbung zu betreiben, so haben Sie das Recht,
                    jederzeit Widerspruch gegen die Verarbeitung Sie
                    betreffender personenbezogener Daten zum Zwecke derartiger
                    Werbung einzulegen; dies gilt auch für das Profiling, soweit
                    es mit solcher Direktwerbung in Verbindung steht. Wenn Sie
                    widersprechen, werden Ihre personenbezogenen Daten
                    anschließend nicht mehr zum Zwecke der Direktwerbung
                    verwendet (Widerspruch nach Art. 21 Abs. 2 DSGVO).
                  </p>
                  <p style={{ textAlign: "left" }}>
                    Sie haben die Möglichkeit, im Zusammenhang mit der Nutzung
                    von Diensten der Informationsgesellschaft – ungeachtet der
                    Richtlinie 2002/58/EG - Ihr Widerspruchsrecht mittels
                    automatisierter Verfahren auszuüben, bei denen technische
                    Spezifikationen verwendet werden.
                  </p>
                </li>
                <li style={{ marginBottom: "0pt" }} value="10">
                  <span> </span>
                  <p>
                    <strong>
                      Beschwerderecht bei der zuständigen Aufsichtsbehörde gemäß
                      Art. 77 DSGVO
                      <br />
                    </strong>
                  </p>
                  <p style={{ textAlign: "left" }}>
                    Im Falle von Verstößen gegen die DSGVO steht den Betroffenen
                    ein Beschwerderecht bei einer Aufsichtsbehörde, insbesondere
                    in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres
                    Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu.
                    Das Beschwerderecht besteht unbeschadet anderweitiger
                    verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
                  </p>
                  <p style={{ textAlign: "left" }}>
                    Die für uns zuständige Aufsichtsbehörde ist:
                    <br />
                  </p>
                  <p>
                    Bayerisches Landesamt für Datenschutzaufsicht
                    <br />
                    <br />
                    Promenade 18
                    <br />
                    91522 Ansbach
                    <br />
                    <br />
                    Postanschrift: <br />
                    Postfach 1349, 91504 Ansbach
                    <br />
                    <br />
                    Telefon: 0981/180093-0
                    <br />
                    E-Mail: poststelle@lda.bayern.de
                    <br />
                    Internet: https://www.lda.bayern.de
                  </p>
                </li>
              </ol>
            </li>
            <li className="lhl" style={{ marginBottom: "18px" }}>
              <span> </span>
              <p>
                <strong> </strong>
                <strong>
                  Gültigkeit und Änderung dieser Datenschutzerklärung
                </strong>
              </p>
            </li>
            <li style={{ marginBottom: "18px" }} value="17">
              <span>
                Diese Datenschutzerklärung gilt ab dem 30. August 2023. Wir
                behalten uns das Recht vor, diese Datenschutzerklärung jederzeit
                unter Beachtung der geltenden Datenschutzvorschriften zu ändern.
                Dies kann z.B. zur Einhaltung neuer Gesetzesbestimmungen oder
                zur Berücksichtigung der Änderungen unserer Website bzw. neuer
                Dienstleistungen auf unserer Website erforderlich sein. Es gilt
                die zum Zeitpunkt Ihres Besuchs abrufbare Fassung.
              </span>
              <br />
            </li>
            <li style={{ marginBottom: "0pt" }} value="18">
              <span>
                Sollte diese Datenschutzerklärung geändert werden, beabsichtigen
                wir, Änderungen an unserer Datenschutzerklärung auf dieser Seite
                bekannt zu geben, sodass Sie umfassend darüber informiert sind,
                welche personenbezogene Daten wir sammeln, wie wir sie
                verarbeiten und unter welchen Umständen sie weitergegeben werden
                können.
              </span>
              <br />
            </li>
          </ul>
          <div className="header">
            <span className="content">Datenschutzerklärung</span>
          </div>
          <div className="footer">
            <span className="pageNumbers">
              Seite <span className="currentPageNum"></span> von
              <span className="totalPageNum"></span>
            </span>
          </div>
          <span className="copyright">
            &copy;2002-2023 RECHTSDOKUMENTE (Sequiter Inc.)
          </span>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default Privacy;
