import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TabLink = ({ prefix, name, icon, ignoreWith = false, style, onClick = null, text }) => {
  return (
    <li className="nav-item" style={{...style}}>
      <a
        className="nav-link"
        id={prefix + "-" + name + "-tab"}
        data-toggle="pill"
        href={"#" + prefix + "-" + name}
        role="tab"
        aria-controls={prefix + "-" + name}
        aria-selected="false"
        style={{textAlign: "center"}}
        onClick={onClick}
      >
        {(icon) ? <FontAwesomeIcon icon={icon} />: null}
        &nbsp;<br />
        {(window.innerWidth > 1117 || ignoreWith === true) ? (<small>{(typeof text === "undefined" ? name : text)}</small>) : null }
      </a>
    </li>
  );
};

export default TabLink;
