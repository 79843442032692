const TabBody = ({ children, prefix, name }) => {
  return (
    <div className="card-body h-100">
      <div className="tab-content" style={{height: "100%", paddingBottom: "50px"}} id={prefix + "-" + name + "-tabContent"}>
        {children}
      </div>
    </div>
  );
};

export default TabBody;
