const TodoConfig = {
  endpoints: {
    getAll: "/todo/",
    getDaily: "/todo/indexdaily",
    markAsDaily: "/todo/markdaily/",
    new: "/todo/",
    edit: "/todo/edit",
    show: "/todo/show/",
    delete: "/todo/delete/",
  },
};

export default TodoConfig;
