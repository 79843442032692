import { Form, Row, Col, Collapse } from "react-bootstrap";
import Finance from "@serie3/freelancesplaceapi/Domain/Billing/Outgoing/Finance";

const DebitedAt = ({ openDebitDate }) => {
  return (
    <Collapse in={openDebitDate}>
      <Row style={{ border: "1px solid #000" }}>
        <Col xs={12}>
          <Form.Group className="form-inline" controlId="debitedat">
            <Form.Label>debit at:</Form.Label>
            <Form.Control
              type="date"
              name="debitedat"
              placeholder="debitedat"
            />
          </Form.Group>
        </Col>
      </Row>
    </Collapse>
  );
};

const Subject = ({ formik, formClass }) => {
  return (
    <Form.Group
      className={formClass ? formClass : "form-inline"}
      controlId="betreff"
    >
      <Form.Control
        placeholder="Subject"
        type="text"
        name="betreff"
        value={formik.values.betreff}
        onChange={formik.handleChange}
        style={{ width: "100%" }}
      />
    </Form.Group>
  );
};

const Description = ({ formik, formClass }) => {
  return (
    <Form.Group
      className={formClass ? formClass : "form-inline"}
      controlId="pretext"
    >
      <Form.Control
        placeholder="Description"
        as="textarea"
        name="beschreibung"
        value={formik.values.beschreibung}
        onChange={formik.handleChange}
        rows={3}
        style={{ width: "100%" }}
      />
    </Form.Group>
  );
};

const Payable = ({ formik, setPaymentDeadline }) => {
  return (
    <Form.Group className="form-inline" controlId="payment_due_days">
      <Form.Label>Payable within Days: &nbsp;</Form.Label>
      <Form.Control
        type="number"
        name="payment_due_days"
        placeholder="15"
        htmlSize={5}
        style={{ maxWidth: "50%" }}
        value={formik.values.payment_due_days}
        onChange={(e) => {
          let paymentDueDate = Finance.calculatePaymentDeadline(
            formik.values.datum,
            e.target.value
          );
          setPaymentDeadline(paymentDueDate);
          formik.setFieldValue(
            "payment_deadline",
            paymentDueDate.toISOString().split("T")[0]
          );
          formik.setFieldValue("payment_due_days", e.target.value);
        }}
      />
    </Form.Group>
  );
};

const Gross = ({ formik, formClass, className }) => {
  return (
    <Form.Group
      className={formClass ? formClass : "form-inline"}
      controlId="brutto"
    >
      <Form.Label className={className ? className : "mr-auto rightText"}>
        Gross:
      </Form.Label>
      <Form.Control
        placeholder="Gross"
        type="text"
        name="brutto"
        value={formik.values.brutto}
        onChange={formik.handleChange}
      />
    </Form.Group>
  );
};

const Net = ({ formik, formClass }) => {
  return (
    <Form.Group
      className={formClass ? formClass : "form-inline"}
      controlId="netto"
    >
      <Form.Label>Net:</Form.Label>
      <Form.Control
        placeholder="Net"
        type="text"
        name="netto"
        value={formik.values.netto}
        onChange={formik.handleChange}
        style={{ width: "100%" }}
      />
    </Form.Group>
  );
};

const Fields = { DebitedAt, Subject, Description, Payable, Gross, Net };
export default Fields;
