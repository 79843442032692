const Profile = {
  profiletitle: "",
  title: "",
  vorname: "",
  name: "",
  email: "",
  email_public: 0,
  telefon: "",
  telefon_public: 0,
  remote_only: 0,
  available: 1,
  available_from: null,
  available_to: null,
  max_hours: 0,
  fulltime_only: false,
  mobile: "",
  mobile_public: 0,
  birthdate: null,
  birthdate_public: 0,
  birthname: "",
  birthname_public: 0,
  public: 0,
  mantra: "",
  domain: "",
  domain_template: "",
  cv_template: "",
  website: "",
  postcode: "",
  street: "",
  housenumber: "",
  city: "",
  einsatz_orte: "",
  aktive: 1,
  hourly_rate_on_site: 0.0,
  hourly_rate_remote: 0.0,
  daily_rate_on_site: 0.0,
  daily_rate_remote: 0.0,
  hourly_rate_on_site_public: 0,
  hourly_rate_remote_public: 0,
  daily_rate_on_site_public: 0,
  daily_rate_remote_public: 0,
  skills: [],
  _searching: [],
  qualifications: [],
  languages: [],
};

export default Profile;