import FreelancerserplaceNavbar from "../../signup/SubComponents/FreelancerserplaceNavbar";
import Footer from "../../signup/SubComponents/Footer";
import { Row } from "react-bootstrap";
import blogclient from "@serie3/common/api/blogClient";
import { useEffect, useState } from "react";
import BloglistItem from "./Subcomponents/BloglistItem";

const BlogIndex = ({ setToken }) => {
  const [blogPosts, setBlogPosts] = useState([]);
  useEffect(() => {
    const loadData = async () => {
      const response = await blogclient.get();
      if (response.status === 200) {
        setBlogPosts([...response.data.subpages]);
      }
    };
    loadData();
  }, []);

  return (
    <>
      <FreelancerserplaceNavbar setToken={setToken} />
      <br />
      <Row className="justify-content-md-center" id="blog">
        <Row>
          {blogPosts.map((post, index) => {
            return <BloglistItem post={post} key={index} />;
          })}
        </Row>
      </Row>
      <Footer />
    </>
  );
};

export default BlogIndex;
