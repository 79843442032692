import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TabGroupLeftVerticalNavlink = ({
  verticalPrefix,
  name,
  title,
  icon,
  show,
}) => {
  const href = "#" + verticalPrefix + "-" + name;
  const aria_controls = verticalPrefix + "-" + name;
  const id = verticalPrefix + "-" + name + "-tab";
  const className = show ? "nav-link active" : "nav-link";

  return (
    <a
      className={className}
      id={id}
      data-toggle="pill"
      href={href}
      role="tab"
      aria-controls={aria_controls}
      aria-selected="true"
    >
      {icon ? <FontAwesomeIcon icon={icon} /> : null} &nbsp; {title}
    </a>
  );
};

export default TabGroupLeftVerticalNavlink;
