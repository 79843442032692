import Position from "../../Entity/Billing/Position";
import * as Yup from "yup";
import PositionsApi from "../../api/finance/positions";
import ListHandler from "@serie3/common/Domain/listHandler";
import PositionDomain from "../../Domain/Billing/Outgoing/Position";

const createPositionForm = (
  dataList,
  setDataFunction,
  showErrorFunction,
  setShowRowEdit
) => {
  const validationSchema = Yup.object({
    titel: Yup.string().required("Required"),
    preis: Yup.number()
      .typeError(
        'Must be a number. Plz use "."  instead of "," for decimal numbers'
      )
      .required("Required"),
    multiplikator: Yup.number().required("Required"),
    steuersatz: Yup.number().required("Required"),
    taxabsolute: Yup.number().required("Required"),
    gesamt_preis: Yup.number().required("Required"),
    finanzVorgang: Yup.string().required("Required"),
  });

  const onSubmit = async (values, actions) => {
    let payload = JSON.stringify(values, null, 2);
    if (values.id > 0) {
      const response = await PositionsApi.edit(payload);
      ListHandler.updateList(
        response,
        payload,
        dataList,
        setDataFunction,
        showErrorFunction
      );
    } else {
      const response = await PositionsApi.createNew(payload);
      ListHandler.newToList(
        response,
        dataList,
        setDataFunction,
        showErrorFunction
      );
    }
    setShowRowEdit(false);
    actions.resetForm(Position);
  };

  return {
    initialValues: Position,
    //TODO add validation Schema
    onSubmit: (values, actions) => {onSubmit(values, actions, showErrorFunction)}
  };
};

const setEditData = (editData) => {
  let Position = {
    id: editData.id,
    titel: editData.titel,
    beschreibung: editData.beschreibung,
    preis: editData.preis,
    multiplikator: editData.multiplikator,
    steuersatz: editData.steuersatz,
    taxabsolute: PositionDomain.calculatePositionTax(editData),
    gesamt_preis: editData.gesamt_preis,
    gross_price: PositionDomain.calculatePositionGross(editData),
  };

  return Position;
};

export default { createPositionForm, setEditData };
