const Education = {
  name: '',
  subject_area: '',
  graduation: '',
  start: '',
  ende: '',
  description: '',
  profile: { id: '' },
};

export default Education;
