import {
  faEye,
  faPen,
  faTrash,
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import downloadHandler from "@serie3/freelancesplaceapi/Domain/Shared/downloadHandler";
import { useState } from "react";
import PDFModal from "../Popups/PDFModal";

const EditCardButtons = ({
  index,
  data,
  setShowDetails,
  setViewData,
  deleteData,
  editUrl,
  downloadUrl,
  downloadname,
}) => {
  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(false);

  return (
    <>
      <PDFModal showSpinner={showSpinner} setShowSpinner={setShowSpinner} />
      {downloadUrl ? (
        <Button
          variant="primary"
          size="sm"
          className="mr-1"
          onClick={() => {
            setShowSpinner(true);
            downloadHandler(downloadUrl, downloadname, null, setShowSpinner);
          }}
        >
          <FontAwesomeIcon icon={faDownload} />
        </Button>
      ) : null}
      {setShowDetails ? (
        <Button
          variant="primary"
          size="sm"
          className="mr-1"
          onClick={() => {
            setViewData(data);
            setShowDetails(true);
          }}
        >
          <FontAwesomeIcon icon={faEye} />
        </Button>
      ) : null}
      {editUrl ? (
        <Button
          variant="primary"
          size="sm"
          className="mr-1"
          onClick={() => {
            navigate(editUrl + data.id);
          }}
        >
          <FontAwesomeIcon icon={faPen} />
        </Button>
      ) : null}
      {deleteData ? (
        <Button
          variant="danger"
          size="sm"
          onClick={() => deleteData(index, data)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      ) : null}
    </>
  );
};

export default EditCardButtons;
