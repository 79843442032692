const ProjectConfig = {
  endpoints: {
    getAll: "/project/",
    getOngoing: "/project/ongoing/",
    getDone: "/project/done/",
    quickLinks: "/project/quicklinks/",
    showindashboard:"/project/showindashboard/",
    new: "/project/",
    edit: "/project/edit",
    show: "/project/show/",
    delete: "/project/delete/",
    getTotalProjectHours: "/project/getprojecthours/",
    publicDashboard: "/projectdashboard/",
    share: "/project/share/",
    comments: {
      getAll: "/project/comment/",
      new: "/project/comment/",
      edit: "/project/comment/edit",
      show: "/project/comment/show/",
      delete: "/project/comment/delete/",
    },
    actions: {
      getAll: "/project/actions/",
      new: "/project/actions/",
      edit: "/project/actions/edit",
      show: "/project/actions/show/",
      delete: "/project/actions/delete/",
    },
    tasks: {
      getAll: "/project/tasks/",
      new: "/project/tasks/",
      edit: "/project/tasks/edit",
      show: "/project/tasks/show/",
      delete: "/project/tasks/delete/",
      addDefault: "/project/tasks/addprojectdefault/",
      getRunning: "/project/tasks/getrunning/",
      start: "/project/tasks/start/",
      print: "/project/tasks/printprojecttimetracking/"
    },
    people: {
      getAll: "/project/person/",
      new: "/project/person/",
      edit: "/project/person/edit",
      show: "/project/person/show/",
      delete: "/project/person/delete/",
    },
  },
};
export default ProjectConfig;