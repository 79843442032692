import { Form, Row, Col, Button, Table } from "react-bootstrap";
import TextField from "../../../../layout/Form/Fields/TextField";
import KeyValueTableRow from "../../../../layout/Tables/KeyValueTableRow";
import ProfileConfig from "@serie3/freelancesplaceapi/Config/Profiles/ProfileConfig";
import KeyValueFormView from "../../../../layout/Form/KeyValueFormView";
import { useState } from "react";
import Card from "../../../../layout/Cards/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";

const ContactData = ({ formik, profile, SaveButton }) => {
  const [showSocialMediaForm, setShowSocialMediaForm] = useState(false);
  const [socialMediaList, setSocialMediaList] = useState(profile.socialmedias);
  const [editSocialMedia, setEditSocialMedia] = useState();
  const [showError, setShowError] = useState(false);

  const onShow = (formik) => {
    formik.setFieldValue("profile.id", profile.id);
  };

  const SocialMediaButton = () => {
    return (
      <div className="card-tools ml-auto">
        <Button
          className="success"
          onClick={() => {
            setShowSocialMediaForm(true);
          }}
        >
          Add &nbsp;
          <FontAwesomeIcon icon={faAdd} />
        </Button>
      </div>
    );
  };

  return (
    <>
      <KeyValueFormView
        showEdit={showSocialMediaForm}
        setShowEdit={setShowSocialMediaForm}
        editKeyValueData={editSocialMedia}
        dataList={socialMediaList}
        setDataList={setEditSocialMedia}
        onShow={onShow}
        newEndpoint={ProfileConfig.endpoints.socialMedia.new}
        editEndpoint={ProfileConfig.endpoints.socialMedia.edit}
      />
      <Form>
        <Card title={"Contact Data"} CardTools={SaveButton}>
          <br />
          <Row>
            <Col>
              <TextField
                formik={formik}
                label={"Mobile"}
                valueName={"mobile"}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <TextField formik={formik} label={"E-Mail"} valueName={"email"} />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <TextField
                formik={formik}
                label={"Website"}
                valueName={"website"}
              />
            </Col>
          </Row>

          <br />
          <Row>
            <Col>
              <TextField
                formik={formik}
                label={"telefon"}
                valueName={"telefon"}
              />
            </Col>
          </Row>
        </Card>
        <br />
        <Card title={"Social Media"} CardTools={SocialMediaButton}>
          <br />
          <Row>
            <Col>
              <Table className="table table-striped projects">
                <tbody>
                  {profile.socialmedias
                    ? profile.socialmedias.map((socialMedia, index) => {
                        return (
                          <KeyValueTableRow
                            index={index}
                            key={index}
                            keyValueObject={socialMedia}
                            setShowEdit={setShowSocialMediaForm}
                            setEditFunction={setEditSocialMedia}
                            setKeyValueData={setSocialMediaList}
                            keyValueDataList={socialMediaList}
                            showErrorFunction={setShowError}
                            deleteUrl={
                              ProfileConfig.endpoints.socialMedia.delete
                            }
                          />
                        );
                      })
                    : null}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Card>
      </Form>
    </>
  );
};

export default ContactData;
