import React from "react";
import { Dropdown } from "react-bootstrap";
import EllipsisVerticalToggle from "../../../../layout/Dropdowns/EllipsisVerticalToggle";
import { faTrash, faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import listHandler from "@serie3/common/Domain/listHandler";

const Skillswrapper = ({
  skillList,
  setEditFunction,
  setShowEdit,
  dataList,
  setDataList,
  showErrorFunction,
  formik,
}) => {
  const deleteFromList = (editedItem, e) => {
    const itemIndex = dataList.findIndex((obj) => obj.id === editedItem.id);
    listHandler.deleteFromList(
      itemIndex,
      null,
      setDataList,
      dataList,
      showErrorFunction
    );
    formik.setFieldValue("skills", dataList);
    formik.handleSubmit(e);
    e.preventDefault();
  };

  return skillList ? (
    <>
      {Object.keys(skillList).map((category, index) => {
        return (
          <React.Fragment key={index}>
            <thead>
              <tr>
                <th colSpan={3}>{category}</th>
              </tr>
            </thead>
            <tbody>
              {skillList[category].map((skill, index) => {
                return (
                  <tr key={skill.id}>
                    <td>{skill.name}</td>
                    <td>{skill.percentage} %</td>
                    <td>
                      <Dropdown
                        style={{
                          float: "right",
                          maxHeight: "16px",
                          marginLeft: "5px",
                        }}
                      >
                        <EllipsisVerticalToggle />
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              setEditFunction(skill);
                              setShowEdit(true);
                            }}
                          >
                            <small>
                              <FontAwesomeIcon icon={faPen} />
                              &nbsp; Edit
                            </small>
                          </Dropdown.Item>
                          <div className="dropdown-divider" />
                          <Dropdown.Item
                            onClick={(e) => {
                              deleteFromList(skill, e);
                            }}
                          >
                            <small>
                              <FontAwesomeIcon icon={faTrash} />
                              &nbsp; Delete
                            </small>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </React.Fragment>
        );
      })}
    </>
  ): null;
};

export default Skillswrapper;
