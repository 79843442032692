import ContentHeader from "../../../../layout/ContenteHeader";
import { Col, Row } from "react-bootstrap";

const JobHeader  = () => {
return (
    <ContentHeader>
  </ContentHeader>
)
}

export default JobHeader;