import crudApi from "@serie3/common/api/crudApi";
import { Row, Col, Form, Button } from "react-bootstrap";
import React, { useState, useRef, Fragment } from "react";
import Card from "../layout/Cards/Card";
import NewModal from "../layout/Popups/NewModal";
import ReferencedInputAndSubmit from "../layout/Form/Fields/ReferencedInputAndSubmit";
import { useParams } from "react-router";
import ProjectConfig from "@serie3/freelancesplaceapi/Config/Projects/ProjectConfig";
import Timeline from "../layout/Timeline/Timeline";
import Timelinelabel from "../layout/Timeline/Timelinelabel";
import ProjectCommentTimelineItem from "../LoggedIn/modules/projects/Projects/content/ProjectCommentTimelineItem";
import InfoBox from "../layout/Boxes/InfoBox";
import {
  faTasks,
  faSun,
  faHourglassStart,
  faHourglassEnd,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tag from "../layout/Buttons/Tag";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import PieChart from "../layout/Charts/Pie";
import BarChart from "../layout/Charts/Bar";
import SmallBox from "../layout/Boxes/SmallBox";
import FreelancerserplaceNavbar from "../signup/SubComponents/FreelancerserplaceNavbar";

const CustomerDashboard = ({setToken}) => {
  const [showPasswordRequest, setShowPasswordRequest] = useState(true);
  const [onHold, setOnHold] = useState([]);
  const [inProgress, setInProgress] = useState([]);
  const [todo, setTodo] = useState([]);
  const [done, setDone] = useState([]);
  const [timePercentage, setTimePercentage] = useState([]);
  const [canceled, setCanceled] = useState([]);
  const [dashboardData, setDashboardData] = useState();
  const [showError, setShowError] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [dataSet, setDataSet] = useState([]);
  const [workingHours, setWorkingHours] = useState([]);
  const [donePercentage, setDonePercentage] = useState(0);
  const passwordref = useRef(null);
  const { publicId } = useParams();

  const submitPassword = async () => {
    const body = { password: passwordref.current.value };
    const response = await crudApi.search(
      ProjectConfig.endpoints.publicDashboard + publicId,
      body,
      true
    );
    if (response.status === 200) {
      const tmpTimePercentage = (response.data.total_time / response.data.max_hours) * 100
      setShowError(false);
      setShowPasswordRequest(false);
      setDataLoaded(true);
      setDashboardData(response.data);
      sortTickets(response.data.actions);
      setWorkingHours(response.data.tasks);
      if(tmpTimePercentage !== Infinity){
        setTimePercentage(tmpTimePercentage);
      }else{
        setTimePercentage(0);
      }
    } else {
      setShowError(true);
    }
  };

  const options = {
    scales: {
      y: {
        min: 0,
        max: 10,
      },
    },
  };

  const sortTickets = (actions = []) => {
    console.log(actions);
    const tmpTodo = [];
    const tmpInProgress = [];
    const tmpDone = [];
    const tmpOnHold = [];
    const tmpCanceled = [];

    let todoCount = 0;
    let inProgressCount = 0;
    let doneCount = 0;
    let onHoldCount = 0;
    let canceledCount = 0;

    actions.map((action, index) => {
      switch (action.status) {
        case "Created":
          tmpTodo.push(action);
          todoCount++;
          break;
        case "In_Progress":
          tmpInProgress.push(action);
          inProgressCount++;
          break;
        case "Done":
          tmpDone.push(action);
          doneCount++;
          break;
        case "Canceled":
          tmpCanceled.push(action);
          canceledCount++;
          break;
        case "On_Hold":
          tmpOnHold.push(action);
          onHoldCount++;
          break;
        default:
          tmpTodo.push(action);
          todoCount++;
          break;
      }
    });

    setTodo(tmpTodo);
    setInProgress(tmpInProgress);
    setDone(tmpDone);
    setOnHold(tmpOnHold);
    setCanceled(tmpCanceled);
    setDonePercentage(
      parseInt(((doneCount + canceledCount) / actions.length) * 100)
    );
    setDataSet([
      {
        label: "To do",
        number: todoCount,
      },
      {
        label: "In Progress",
        number: inProgressCount,
      },
      {
        label: "On Hold",
        number: onHoldCount,
      },
      {
        label: "Done",
        number: doneCount,
      },

      {
        label: "Canceled",
        number: canceledCount,
      },
    ]);
  };

  const PasswordModal = () => {
    return (
      <NewModal
        setShow={setShowPasswordRequest}
        show={showPasswordRequest}
        title={"Password:"}
      >
        <Form>
          <ReferencedInputAndSubmit
            namedReference={passwordref}
            label={"Submit"}
            type="password"
            onClick={() => {
              submitPassword(passwordref);
            }}
          />
        </Form>
        {showError ? (
          <b style={{ color: "darkred" }}>
            Project not found or password wrong
          </b>
        ) : null}
      </NewModal>
    );
  };

  const holdTip = (
    <Tooltip id="draft">
      <small>
        You might want to follow up on these. Something or somebody is holding
        up your freelancer from finishing these tasks.
      </small>
    </Tooltip>
  );

  const CancelCardInfo = () => {
    return (
      <OverlayTrigger placement="left" overlay={holdTip}>
        <FontAwesomeIcon icon={faQuestion} />
      </OverlayTrigger>
    );
  };

  return (
    <>
      <PasswordModal />
      <FreelancerserplaceNavbar setToken={setToken} />
      <br />
      {dataLoaded ? (
        <div
          style={{
            marginLeft: "0px",
            paddingRight: "1%",
            paddingLeft: "1%",
            maxWidth: "99%",
          }}
        >
          <Row>
            <Col>
              <h2>{dashboardData.title}</h2>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <InfoBox
                color={"bg-success"}
                icon={faHourglassStart}
                title={"Start"}
                message={dashboardData.start}
              />
            </Col>
            <Col>
              <InfoBox
                color={"bg-warning"}
                icon={faHourglassEnd}
                title={"End"}
                message={dashboardData.end}
              />
            </Col>
            <Col>
              <InfoBox
                color={"bg-info"}
                icon={faSun}
                title={"Planned Working Hours"}
                message={dashboardData.max_hours + " h"}
              />
            </Col>
            <Col>
              <InfoBox
                color={"bg-success"}
                icon={faTasks}
                title={"Done in %"}
                message={donePercentage + " %"}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Card title={"Comments"}>
                <Timeline showAddButton={false}>
                  {dashboardData.comments &&
                    dashboardData.comments.map((comment, index) => {
                      return (
                        <Fragment key={index}>
                          <Timelinelabel labeltext={comment.date} />
                          <ProjectCommentTimelineItem
                            comment={comment}
                            showDropdown={false}
                          />
                        </Fragment>
                      );
                    })}
                </Timeline>
              </Card>
            </Col>
            <Col md={6}>
              <Card title={"Tags"}>
                {dashboardData.tags &&
                  dashboardData.tags.map((tag, index) => {
                    return <Tag key={index} content={tag} />;
                  })}
              </Card>
              <Card title={"Process overview"}>
                {dataSet ? <PieChart Data={dataSet} /> : null}
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Card title={"Tasks"}>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: "space-between",
                  }}
                >
                  <Card
                    title={"Todo"}
                    className={"card-primary"}
                    style={{ flex: "1" }}
                  >
                    {todo.map((action, index) => {
                      return (
                        <>
                          {action.title}
                          <hr />
                        </>
                      );
                    })}
                  </Card>

                  <Card
                    title={"In Progress"}
                    className={"card-info"}
                    style={{ flex: "1" }}
                  >
                    {inProgress.map((action, index) => {
                      return (
                        <>
                          {action.title}
                          <hr />
                        </>
                      );
                    })}
                  </Card>
                  <OverlayTrigger placement="bottom" overlay={holdTip}>
                    <Card
                      title={"On Hold"}
                      className={"card-secondary"}
                      style={{ flex: "1" }}
                      CardTools={CancelCardInfo}
                    >
                      {onHold.map((action, index) => {
                        return (
                          <>
                            {action.title}
                            <hr />
                          </>
                        );
                      })}
                    </Card>
                  </OverlayTrigger>
                  <Card
                    title={"Canceled"}
                    className={"card-danger"}
                    style={{ flex: "1" }}
                  >
                    {canceled.map((action, index) => {
                      return (
                        <>
                          {action.title}
                          <hr />
                        </>
                      );
                    })}
                  </Card>
                  <Card
                    title={"Done"}
                    className={"card-success"}
                    style={{ flex: "1" }}
                  >
                    {done.map((action, index) => {
                      return (
                        <>
                          {action.title}
                          <hr />
                        </>
                      );
                    })}
                  </Card>
                </div>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <Card title={"Working Hours"}>
                {workingHours ? (
                  <BarChart
                    Data={workingHours}
                    options={options}
                    label={"Working Hours: h"}
                  />
                ) : null}
              </Card>
            </Col>
            <Col md={4}>
              <SmallBox
                title={dashboardData.total_time + " h"}
                message={"Project time left"}
                color={
                  donePercentage >= timePercentage ? "bg-success" : "bg-danger"
                }
                icon={"ion-time"}
              />
              <SmallBox
                title={timePercentage + " %"}
                message={"Project time used up"}
                color={
                  donePercentage >= timePercentage ? "bg-success" : "bg-danger"
                }
                icon={"ion-time"}
              />

              <SmallBox
                title={
                  donePercentage >= timePercentage
                    ? "Project on track"
                    : "Project is behind"
                }
                message={
                  donePercentage +
                  " % done  | " +
                  timePercentage +
                  " % time used"
                }
                color={
                  donePercentage >= timePercentage ? "bg-success" : "bg-danger"
                }
                icon={"ion-time"}
              />
            </Col>
          </Row>
        </div>
      ) : (
        <Row className="justify-content-md-center">
          <Col style={{ textAlign: "center" }}>
            <Button
              onClick={() => {
                setShowPasswordRequest(true);
              }}
            >
              Load Data
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
};

export default CustomerDashboard;
