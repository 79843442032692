import { Row, Col, Button, Modal } from "react-bootstrap";
import Position from "@serie3/freelancesplaceapi/Entity/Billing/Position";
import PoistionDomain from "@serie3/freelancesplaceapi/Domain/Billing/Outgoing/Position";
const PositionView = ({ show, setShow, position }) => {

  !position && (position = Position);

  let nett = PoistionDomain.calculatePositionNett(position);
  let taxtotal = PoistionDomain.calculatePositionTax(position);
  let subtotal = PoistionDomain.calculatePositionGross(position);
  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => setShow(false)}
      dialogClassName="modal-90w"
    >
      <Modal.Header closeButton={true}>
        <Modal.Title id="Billview">{position.titel}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <p>{position.beschreibung}</p>
          </Col>
        </Row>
        <Row className="mt-2 mr-1">
          <Col xs={3} sm={5} md={2} lg={2} style={{ fontWeight: "700" }}>
            Price (€):
          </Col>
          <Col xs={8} sm={5} md={2} lg={2} >{position.preis}</Col>
          <Col xs={3} sm={5} md={2} lg={2}  style={{ fontWeight: "700" }}>Amount:</Col>
          <Col xs={8} sm={5} md={2} lg={2} >{position.multiplikator}</Col>
          <Col xs={3} sm={5} md={2} lg={2} style={{ fontWeight: "700" }}>Tax (%):</Col>
          <Col xs={8} sm={5} md={2} lg={2} >{position.steuersatz}</Col>
        </Row>
        <Row className="mt-2 mr-1">
          <Col
            style={{ fontWeight: "700" }}
            md={{ offset: 8 }}
            className="pt-1 pb-1"
          >
            Nett total:
          </Col>
          <Col style={{ backgroundColor: "#e9ecef" }} className="pt-1 pb-1">
            {nett}
          </Col>
        </Row>
        <Row className="mt-2 mr-1">
          <Col
            style={{ fontWeight: "700" }}
            md={{ offset: 8 }}
            className="pt-1 pb-1"
          >
            Tax total:
          </Col>
          <Col style={{ backgroundColor: "#e9ecef" }} className="pt-1 pb-1">
            {taxtotal}
          </Col>
        </Row>
        <Row className="mt-2 mr-1">
          <Col
            style={{ fontWeight: "700" }}
            md={{ offset: 8 }}
            className="pt-1 pb-1"
          >
            Sub total:
          </Col>
          <Col style={{ backgroundColor: "#e9ecef" }} className="pt-1 pb-1">
            {subtotal}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShow(false)}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PositionView;
