import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPen, faEye } from "@fortawesome/free-solid-svg-icons";
import EllipsisVerticalToggle from "./EllipsisVerticalToggle";

const CompleteDropdown = ({ deleteAction, editAction, viewAction }) => {
  return (
    <Dropdown style={{ float: "right", maxHeight: "16px", marginLeft: "5px" }}>
      <EllipsisVerticalToggle />
      <Dropdown.Menu>
        {viewAction ? (
          <>
            <Dropdown.Item onClick={viewAction}>
              <small>
                <FontAwesomeIcon icon={faEye} />
                &nbsp; View
              </small>
            </Dropdown.Item>
            <div className="dropdown-divider" />
          </>
        ) : null}
        {editAction ? (
          <>
            <Dropdown.Item onClick={editAction}>
              <small>
                <FontAwesomeIcon icon={faPen} />
                &nbsp; Edit
              </small>
            </Dropdown.Item>
            <div className="dropdown-divider" />
          </>
        ) : null}
        {deleteAction ? (
          <>
            <Dropdown.Item onClick={deleteAction}>
              <small>
                <FontAwesomeIcon icon={faTrash} />
                &nbsp; Delete
              </small>
            </Dropdown.Item>
          </>
        ) : null}
      </Dropdown.Menu>
    </Dropdown>
  );
};
export default CompleteDropdown;
