import ContentHeader from "../../../../layout/ContenteHeader";
import { Row, Col } from "react-bootstrap";

const TimetrackingHeader = () => {
  return (
    <ContentHeader>
    </ContentHeader>
  );
};
export default TimetrackingHeader;
