import ProfileLeft from "../profiles/ProfileLeft";
import ProfileBasics from "../profiles/Tabs/ProfileBasics";
import crudApi from "@serie3/common/api/crudApi";
import ProfileForm from "@serie3/freelancesplaceapi/Form/Profile/ProfileForm";
import ProfileConfig from "@serie3/freelancesplaceapi/Config/Profiles/ProfileConfig";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";

const SystemProfile = () => {
  const [showError, setShowError] = useState(false);
  const [profile, setProfile] = useState();

  const editProfileForm = ProfileForm.createProfileForm(setShowError);
  const formik = useFormik(editProfileForm);


  const SaveButton = () => {
    return (
      <Button
        onClick={(e) => {
          formik.handleSubmit(e);
          e.preventDefault();
        }}
      >
        Save
      </Button>
    );
  };

  useEffect(() => {
    const loadData = async () => {
      const response = await crudApi.getAll(
        ProfileConfig.endpoints.systemProfile,
        setProfile
      );
      setProfile(response.data);
      formik.setValues(ProfileForm.setEditData(response.data));
    };
    loadData();
  }, []);

  return profile ? (
    <Row>
      <ProfileLeft formik={formik} profile={profile} />
      <Col md={9}>
        <ProfileBasics formik={formik} profile={profile} SaveButton={SaveButton}/>
      </Col>
    </Row>
  ) : null;
};

export default SystemProfile;
