const CrmConfig = {
  people: {
    index: {
      endpoint: "/crm/person/",
      datafield: "personen",
    },
  },
  company: {
    index: {
      endpoint: "/crm/company/",
      datafield: "kunden",
    },
  },
  contactData: {
    endpoints: {
      new: "/crm/contactdata/",
      edit: "/crm/contactdata/edit",
      show: "/crm/contactdata/show/",
      delete: "/crm/contactdata/delete/",
    },
  },
  socialMedia: {
    endpoints: {
      new: "/crm/socialmedia/",
      edit: "/crm/socialmedia/edit",
      show: "/crm/socialmedia/show/",
      delete: "/crm/socialmedia/delete/",
    },
  },
  address: {
    endpoints: {
      new: "/crm/address/",
      edit: "/crm/address/edit",
      show: "/crm/address/show/",
      delete: "/crm/address/delete/",
    },
  },
  comments: {
    endpoints: {
      new: "/crm/comments/",
      edit: "/crm/comments/edit",
      show: "/crm/comments/show/",
      delete: "/crm/comments/delete/",
    },
  },
  tickets: {
    endpoints: {
      getAll: "/crm/tickets/",
      new: "/crm/tickets/",
      edit: "/crm/tickets/edit",
      show: "/crm/tickets/show/",
      delete: "/crm/tickets/delete/",
      comments: {
        new: "/crm/tickets/comments/",
        delete: "/crm/tickets/comments/delete/",
      },
      files: {
        getAll: "/document/action/",
        new: "/document/action/",
        delete: "/document/action/",
      },
    },
  },
};

export default CrmConfig;
