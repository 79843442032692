import Card from "../../../../../../layout/Cards/Card";
import { Col, Row } from "react-bootstrap";
import Timelinelabel from "../../../../../../layout/Timeline/Timelinelabel";
import ProjectCommentTimelineItem from "../ProjectCommentTimelineItem";
import Timeline from "../../../../../../layout/Timeline/Timeline";
import InfoBox from "../../../../../../layout/Boxes/InfoBox";
import {
  faFile,
  faComment,
  faMoneyBill,
  faListCheck,
  faHourglass
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import TicketList from "../../Charts/TicketList";
import TicketChart from "../../Charts/TicketChart";
import HourChart from "../../Charts/HourChart";
import { calculateDoneTicketPercentage } from "@serie3/freelancesplaceapi/Domain/Projects/Calculations";
import ProjectConfig from "@serie3/freelancesplaceapi/Config/Projects/ProjectConfig";
import crudApi from "@serie3/common/api/crudApi";


const getTotalProjectHours = async (setTotalHours, projectId) => {
  const response = await crudApi.justGet(ProjectConfig.endpoints.getTotalProjectHours + projectId);
  if(response.status === 200){
    setTotalHours(response.data);
  }
  
}

const Dashboard = ({ project, comments }) => {
  const [lastComment, setLastComment] = useState(null);
  const [ticketsPercentage, setTicketsPercentage] = useState(null);
  const [totalProjectHours, setTotalProjectHours] = useState(0);
  const [hydratedTasks, setHydratedTasks] = useState([]);
  const tickets = project.actions;

  useEffect(() => {
    const doneTickets = calculateDoneTicketPercentage(project.actions);
    setTicketsPercentage(doneTickets);
    setLastComment(comments[0]);
    getTotalProjectHours(setTotalProjectHours, project.id);
    const tmpHydratedTasks = [];
    project.tasks.map((task) => {
      tmpHydratedTasks.push(task.task);
    });
    setHydratedTasks([...tmpHydratedTasks]);
  }, [project, comments]);

  return (
    <>
      <Row>
        <Col className="col-sm-6 col-md-3 col-12">
          <InfoBox
            color={"bg-info"}
            icon={faHourglass}
            title={"Hours"}
            message={totalProjectHours + " h"}
          />
        </Col>
        <Col className="col-sm-6 col-md-3 col-12">
          <InfoBox
            color={"bg-success"}
            icon={faListCheck}
            title={"Done"}
            message={ticketsPercentage + " %"}
          />
        </Col>
        <Col className="col-sm-6 col-md-3 col-12">
          <InfoBox
            color={"bg-warning"}
            icon={faFile}
            title={"Files"}
            message={project.documents.length}
          />
        </Col>
        <Col className="col-sm-6 col-md-3 col-12">
          <InfoBox
            color={"bg-secondary"}
            icon={faComment}
            title={"Comments"}
            message={project.comments.length}
          />
        </Col>
      </Row>
      <Row>
        <Col className="col-sm-6 col-md-6 col-lg-6">
          <Card title={"Last comment"} style={{ height: "450px" }}>
            <Timeline showAddButton={false}>
              {lastComment ? (
                <>
                  <Timelinelabel labeltext={lastComment.date} />
                  <ProjectCommentTimelineItem comment={lastComment} />
                </>
              ) : null}
            </Timeline>
          </Card>
        </Col>
        <Col className="col-sm-6 col-md-6 col-lg-6" style={{ height: "450px" }}>
          <Card title={"Tickets done"} style={{ height: "450px" }}>
            <TicketList tickets={tickets} />
          </Card>
        </Col>
      </Row>
      <Row>
        <Col className="col-sm-6 col-md-6 col-lg-6" style={{ height: "450px" }}>
          <Card title={"Hours"} style={{ height: "450px" }}>
            <HourChart tasks={hydratedTasks} style={{ height: "450px" }} />
          </Card>
        </Col>
        <Col className="col-sm-6 col-md-6 col-lg-6" style={{ height: "450px" }}>
          <Card title={"Tickets overview"} style={{ height: "450px" }}>
            <TicketChart tickets={tickets} />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
