import ContentHeader from "../../../../layout/ContenteHeader";
import Content from "../../../../layout/Content";
import KanbanBoard from "../../../../layout/Kanban/KanbanBoard";
import NewModal from "../../documents/modals/NewModal";
import ActionsForm from "./Form/ActionsForm";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import SaveButton from "../../../../layout/Buttons/SaveButton";
import ActionForm from "@serie3/freelancesplaceapi/Form/Crm/ActionForm";
import crudApi from "@serie3/common/api/crudApi";
import Action from "@serie3/freelancesplaceapi/Entity/Crm/Action";
import TicketView from "../../../../layout/Kanban/TicketView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import CrmConfig from "@serie3/freelancesplaceapi/Config/Crm/CrmConfig";
import getSettings from "@serie3/common/config/settings";
import PaymentModal from "../../../../layout/Payment/PaymentModal";

const Index = () => {
  const [showForm, setShowForm] = useState(false);
  const [showView, setShowView] = useState(false);
  const [showError, setShowError] = useState(false);
  const [actions, setActions] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [editAction, setEditAction] = useState();
  const [viewAction, setViewAction] = useState(false);
  const [showPayModal, setShowPayModal] = useState(false);
  const [settings, setSettings] = useState();


  const editActionForm = ActionForm.createActionForm(
    actions,
    setActions,
    setShowError,
    setShowPayModal,
    setEditAction
  );
  const formik = useFormik(editActionForm);


  const SaveActionButton = () => {
    return (
      <>
      <SaveButton
          handleClick={(e) => {
            formik.handleSubmit(e);
            e.preventDefault();
          }}
        />
      </>
    );
  };

  const title = () => {
    return (
      <>
        <b>CRM: &nbsp; </b> Actions
      </>
    );
  };

  useEffect(() => {
    const loadData = async () => {
      const actions = await crudApi.getAll(CrmConfig.tickets.endpoints.getAll);
      setSettings(getSettings());
      setActions(actions.data.aktionen);
      setIsLoaded(true);
    };

    loadData();
  }, []);

  const showAction = (action) => {
    setViewAction(action);
    setShowView(true);
  };

  const AddTicket = () => {
    return (
      <Button
        className="success"
        onClick={() => {
          setEditAction(null);
          setShowForm(true);
        }}
      >
        New <FontAwesomeIcon icon={faPlus} />
      </Button>
    );
  };

  const editTitle = editAction ? editAction.title : "New";
  const viewTitle = viewAction ? viewAction.title : "";

  const setFormikNewValues = () => {
    formik.setValues(Action);
    formik.setFieldValue("tags", []);
  };

  return isLoaded ? (
    <>
      <NewModal
        show={showForm}
        setShow={setShowForm}
        ExtraButton={SaveActionButton}
        title={editTitle}
        onShow={() => {
          editAction
            ? formik.setValues(ActionForm.setEditData(editAction))
            : setFormikNewValues();
        }}
      >
        <ActionsForm formik={formik}  editAction={editAction} setShowPayModal={setShowPayModal}/>
      </NewModal>

      <NewModal show={showView} setShow={setShowView} title={viewTitle}>
        <TicketView action={viewAction} />
      </NewModal>

      {settings && <PaymentModal show={showPayModal} setShow={setShowPayModal} priceTableId={settings.pricingTables.crm}/>}

      <ContentHeader />
      <Content Title={title} AdditionalButtons={AddTicket}>
        <KanbanBoard
          showForm={showForm}
          setShowForm={setShowForm}
          actions={actions}
          setActions={setActions}
          editAction={editAction}
          setEditAction={setEditAction}
          setViewAction={showAction}
          showError={setShowError}
        />
      </Content>
    </>
  ) : null;
};

export default Index;
