import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";

const Tag = ({ item, content, deleteFunction }) => {

  const padding = "12px 16px 12px 16px";
  const paddingDelete = "12px 0px 12px 16px"
  return (
    <Button
      className="btn-sm btn-outline-primary btn-light"
      style={{
        marginRight: "5px",
        marginBottom: "5px",
        float: "left",
        verticalAlign: "middle",
        padding: "0",
      }}
    >
      <span
        style={{ float: "left", padding: deleteFunction ? paddingDelete : padding }}
      >
        {content}
      </span>
      {deleteFunction  ?
      <FontAwesomeIcon
        icon={faTrash}
        style={{
          float: "right",
          paddingLeft: "15px",
          paddingRight: "10px",
          marginTop: "15px",
          color: "red",
        }}
        onClick={() => {
          deleteFunction(item);
        }}
      /> : null }
    </Button>
  );
};

export default Tag;
