import NewModal from "../../LoggedIn/modules/documents/modals/NewModal";
import SaveButton from "../Buttons/SaveButton";
import TextField from "./Fields/TextField";
import { Row, Col } from "react-bootstrap";

const LanguageForm = ({
  showEdit,
  setShowEdit,
  dataList,
  editLanguage,
  onShow,
  formik,
  setDataList,
}) => {
  let itemIndex = -1;
  if (editLanguage) {
    itemIndex = dataList.findIndex((obj) => obj.id === editLanguage.id);
  } else {
    itemIndex = dataList.length - 1;
  }

  const saveNew = (e) => {
    formik.handleSubmit(e);
    setShowEdit(false);
    e.preventDefault();
  };

  const saveEdit = (e) => {
    const language = formik.values.languages[itemIndex];
    dataList.splice(itemIndex, 1, language);
    let tmpList = [...dataList];
    setShowEdit(false);
    setDataList(tmpList);
    formik.handleSubmit(e);
  };

  const Save = () => {
    return (
      <SaveButton
        handleClick={(e) => {
          editLanguage ? saveEdit(e) : saveNew(e);
        }}
      />
    );
  };

  return itemIndex >= 0 ? (
    <NewModal show={showEdit} setShow={setShowEdit} ExtraButton={Save}>
      <Row>
        <Col>
          <TextField
            label={"Name"}
            valueName={"languages[" + itemIndex + "].name"}
            formik={formik}
            value={formik.values.languages[itemIndex].name}
          />
        </Col>
        <Col>
          <TextField
            label={"level"}
            valueName={"languages[" + itemIndex + "].level"}
            formik={formik}
            value={formik.values.languages[itemIndex].level}
          />
        </Col>
      </Row>
    </NewModal>
  ) : null;
};

export default LanguageForm;
