import { Col, Row } from "react-bootstrap";
const TicketView = ({ action }) => {
  const ShowCompany = ({ action }) => {
    if (typeof action.kunde !== "undefined") {
      const company = action.kunde;
      return (
        <>
          <Row>
            <Col className="col-sm-12">
              <b>{company.firmenname}</b>
            </Col>
          </Row>
          <Row>
            <Col className="col-sm-2">
              <b>Desc:</b>
            </Col>
            <Col>{company.bemerkung}</Col>
          </Row>
          <Row>
            <Col className="col-sm-2">
              <b>Class:</b>
            </Col>
            <Col>{company.kundenklasse}</Col>
          </Row>
        </>
      );
    }
    return null;
  };

  const ShowPerson = ({ action }) => {
    if (typeof action.person !== "undefined") {
      const person = action.person;
      return (
        <>
          <Row>
            <Col>
              <b>
                {person.vorname} {person.name}
              </b>
            </Col>
          </Row>
        </>
      );
    }
    return null;
  };

  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col className="col-sm-3">
              <b>Start:</b>
            </Col>
            <Col>{action.start}</Col>
          </Row>
          <Row>
            <Col className="col-sm-3">
              <b>End:</b>
            </Col>
            <Col>{action.end}</Col>
          </Row>
          <Row>
            <Col className="col-sm-3">
              <b>Deadline:</b>
            </Col>
            <Col>{action.deadline}</Col>
          </Row>
          <Row>
            <Col className="col-sm-3">
              <b>Priority:</b>
            </Col>
            <Col>{action.priority}</Col>
          </Row>
          <Row>
            <Col className="col-sm-3">
              <b>Status:</b>
            </Col>
            <Col>{action.status}</Col>
          </Row>
        </Col>
        <Col>
          <ShowCompany action={action} />
          <ShowPerson action={action} />
        </Col>
      </Row>
      <Row>
        <Col>
          <p>{action.beschreibung}</p>
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            {action.tags
              ? action.tags.map((tag) => {
                  return (
                    <span
                      key={tag.id}
                      className="badge badge-success"
                      style={{ marginRight: "5px" }}
                    >
                      {tag.name}
                    </span>
                  );
                })
              : null}
          </p>
        </Col>
      </Row>
    </>
  );
};

export default TicketView;
