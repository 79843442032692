const Job =  {
    key_skills: [],
    other_skills: [],
    to_do: [],
    we_offer: [],
    jobtitle: '',
    contract_type: '',
    languages: [],
    commisionfree: true,
    draft: true,
    max_hourly_rate: 0.0,
    max_daily_rate: 0.0,
    max_hours: 0.0,
    max_hours_per_week: 0.0,
    on_site: false,
    description: '',
    start: '',
    end: '',
    publish_from: '',
    publish_to: '',
    public: false,
    start_now: false,
    allow_share_project: false,
    send_matches_per_mail: false,
    postalcode: 0,
}

export default Job;