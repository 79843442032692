import Job from "../../../Entity/Job/Job";
import * as Yup from "yup";
import CrudApi from "@serie3/common/api/crudApi";
import JobConfig from "../../../Config/Projects/JobConfig";

const createJobForm = (setLanguages) => {
  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    vorname: Yup.string().required("Required"),
    sperrgrund: Yup.string().when("gesperrt", {
      is: true,
      then: Yup.string().required(
        "You must add a reason why this company is blocked"
      ),
    }),
  });

  const onSubmit = async (values) => {
    let payload = JSON.stringify(values, null, 2);
    const response = await CrudApi.edit(JobConfig.endpoints.edit, payload);
    if(response.status === 200){
      setLanguages([...response.data.languages]);
    }
  };

  return {
    initialValues: Job,
    onSubmit: (values, actions) => {
      onSubmit(values, actions);
    },
  };
};

const setEditData = (editData) => {
  const date = new Date();
  let Job = {
    id: editData.id,
    key_skills: editData.key_skills,
    other_skills: editData.other_skills,
    to_do: editData.to_do,
    we_offer: editData.we_offer,
    jobtitle: editData.jobtitle,
    contract_type: editData.contract_type,
    languages: editData.languages,
    commisionfree: editData.commisionfree,
    draft: editData.draft,
    max_hourly_rate: editData.max_hourly_rate,
    max_daily_rate: editData.max_daily_rate,
    max_hours_per_week: editData.max_hours_per_week,
    max_hours: editData.max_hours,
    on_site: editData.on_site,
    description: editData.description,
    start: editData.start,
    end: editData.end,
    publish_from: editData.publish_from,
    publish_to: editData.publish_to,
    public: editData.public,
    start_now: editData.start_now,
    allow_share_project: editData.allow_share_project,
    send_matches_per_mail: editData.send_matches_per_mail,
    postalcode: editData.postalcode,
    deleted: editData.deleted,
  };

  return Job;
};

export default { createJobForm, setEditData };
