import BarChart from "../../../../../layout/Charts/Bar";
import { useEffect, useState } from "react";
import timeingStuff from "@serie3/freelancesplaceapi/Domain/Shared/timeingStuff";

const HourChart = ({ tasks, totalHours }) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    const tmpData = [];
    if (typeof tasks !== "undefined" && tasks.length > 0) {
      tasks.map((task) => {
        let hydratedStartDate;
        let taskTime = 0;
        if (typeof task.start !== "undefined") {
          taskTime = timeingStuff.calculateTimeDifference(task.start, task.ende);
          const startDate = new Date(task.date);
          hydratedStartDate = startDate.toISOString().substring(0, 10);
        }else{
          const startDate = new Date(task.date);
          hydratedStartDate = startDate.toISOString().substring(0, 10);
        }
        tmpData.push({
          label: hydratedStartDate,
          number: taskTime,
        });
      });
      setData([...tmpData]);
    }
  }, [tasks]);

  const options = {
    scales: {
      y: {
        min: 0,
        max: 10,
      },
    },
  };

  return data ? <BarChart Data={data} options={options} label={"Working Hours: "+ totalHours + " h"}/> : null;
};


export default HourChart;
