import React from "react";
import { Button, Modal } from "react-bootstrap";

const NewModal = ({
  show,
  setShow,
  children,
  ExtraButton,
  onShow,
  onHide = null,
  title,
  size = "lg",
  modalType = "modal-90w"
}) => {
  return (
    <Modal
      size={size}
      show={show}
      onHide={() => {
        onHide && onHide();
        setShow(false);
      }}
      dialogClassName={modalType}
      onShow={() => {
        onShow && onShow();
      }}
    >
      <Modal.Header closeButton={true}>
        <Modal.Title id="Billview">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        {ExtraButton ? <ExtraButton /> : null}
        <Button onClick={() => { onHide && onHide(); setShow(false) }}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewModal;
