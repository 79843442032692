import * as Yup from "yup";
import CrudApi from "@serie3/common/api/crudApi";
import ListHandler from "@serie3/common/Domain/listHandler";
import GeneralSearch from "../../Entity/Search/GeneralSearch";

const createGeneralSearchForm = (dataList, setDataFunction, endpoint) => {
  const onSubmit = async (values) => {
    if (values.keywords.length > 0) {
      let payload = JSON.stringify(values, null, 2);
      if (payload.length > 0) {
        const response = await CrudApi.createNew(endpoint, payload);
        const data = response.data.hits.hits;
        const tmpDataList = [...data];
        setDataFunction(tmpDataList);
      }
    }
  };

  return {
    initialValues: GeneralSearch,
    onSubmit: (values, actions) => {
      onSubmit(values, actions);
    },
  };
};

export default { createGeneralSearchForm };
