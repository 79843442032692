import React from "react";
import { Row, Col } from "react-bootstrap";
import StatusIcons from "./content/outgoing/SubComponents/StatusIcons";
import PositionRow from "./content/outgoing/PositionRow";
import PositionRowHeader from "./content/outgoing/RowHeader";
import BillSummary from "./content/outgoing/BillSummary";
import Adress from "./content/outgoing/SubComponents/Address";

const BillOut = ({ bill }) => {
  let address;
  if (typeof bill.fixed_address !== "undefined") {
    address = JSON.parse(bill.fixed_address);
  }
  return (
    <>
      <Row className="mb-3">
        <Col>
          <Adress addressJson={bill.fixed_address} />
        </Col>
        <Col>
          <Row>
            <Col>
              <StatusIcons
                payed={bill.geldeingang}
                draft={bill.draft}
                subscription={bill.aborechnung}
                privateButton={bill.privat}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              Billing date: {bill.rechungsdatum}
            </Col>
          </Row>
          <Row>
            <Col>
              Will be debited: {bill.rechungsdatum}
            </Col>
          </Row>
          <Row>
            <Col>
              Follow up: {bill.rechungsdatum}
            </Col>
          </Row>
          <Row>
            <Col>
              Aborechnung: {bill.rechungsdatum}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="mb-2">
          <h5>{bill.betreff}</h5>
        </Col>
      </Row>
      <Row>
        <Col>{bill.beschreibung}</Col>
      </Row>
      <Row>
        <Col className="mt-3">
          <table className="table table-striped">
            <PositionRowHeader showDropdowns={false} />
            <tbody>
              {bill.positionen.map((position, index) => (
                <PositionRow
                  key={index}
                  index={index}
                  position={position}
                  showDropdowns={false}
                />
              ))}
            </tbody>
          </table>
        </Col>
      </Row>
      <Row>
        <Col>{bill.post_text}</Col>
        <Col>
          <BillSummary positionen={bill.positionen} />
        </Col>
      </Row>
    </>
  );
};

export default BillOut;
