import Card from "../../../../layout/Cards/Card";
import { useState, useEffect } from "react";
import JobConfig from "@serie3/freelancesplaceapi/Config/Projects/JobConfig";
import crudApi from "@serie3/common/api/crudApi";
import FreelancerCard from "./FreelancerCard";
import PublicProfileView from "../../profiles/PublicProfileView";

const Freelancers = ({ jobId, updateFreelancers }) => {
  const [viewProfile, setViewProfile] = useState();
  const [showView, setShowView] = useState(false);
  const [profiles, setProfiles] = useState();


  const FreelancerDetails = () => {
    return viewProfile ? <PublicProfileView profileId={viewProfile._source.id} showView={showView} setShowView={setShowView} /> : null
  }

  useEffect(() => {
    const loadData = async () => {
      const profileData = await crudApi.show(
        JobConfig.endpoints.matchingProfiles,
        jobId
      );
      if (
        profileData !== null &&
        typeof profileData.hits.hits !== "undefined"
      ) {
        setProfiles(profileData.hits.hits);
      } else {
        setProfiles([]);
      }
    };
    loadData();
  }, [updateFreelancers]);

  const showProfileDetails = (profile) => {
    setViewProfile(profile);
    setShowView(true);
  }

  return profiles ? (
    <>
    <FreelancerDetails />
    <Card title={"Matching Freelancers"}>
      {profiles.map((profile) => {
        return (<FreelancerCard profile={profile} showProfileDetails={showProfileDetails}/>);
      })}
    </Card>
    </>
  ) : null;
};

export default Freelancers;
