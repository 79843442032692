import { Form } from "react-bootstrap";

const Checkbox = ({ formik, formClass, checkboxClass, label,  valueName, style  }) => {
    return (
      <Form.Group className={checkboxClass ? formClass : null} controlId={valueName}>
        <Form.Check
          className={checkboxClass ? checkboxClass : null}
          type="checkbox"
          name={valueName}
          label={label}
          checked={formik.values[valueName]}
          onChange={(e) => {
            formik.handleChange(e)
            formik.setFieldValue(valueName, e.target.checked);
          }}
        />
      </Form.Group>
    );
  };

export default Checkbox;