import Container from "react-bootstrap/Container";
import { useEffect, useState } from "react";
import KanbanColumn from "./KanbanColumn";
import crudApi from "@serie3/common/api/crudApi";
import listHandler from "@serie3/common/Domain/listHandler";

const KanbanBoard = ({
  setEditAction,
  actions,
  setShowForm,
  setViewAction,
  setActions,
  showError,
  containerStyle,
  deleteUrl,
}) => {
  const [onHold, setOnHold] = useState([]);
  const [inProgress, setInProgress] = useState([]);
  const [todo, setTodo] = useState([]);
  const [done, setDone] = useState([]);

  const deleteData = async (id, index, setDataList, dataList) => {

    let deleteEndpoint = "/crm/tickets/delete/";
    if (typeof deleteUrl !== "undefined") {
      deleteEndpoint = deleteUrl;
    }
    listHandler.deleteFromList(
      index,
      crudApi.del(deleteEndpoint, id),
      setDataList,
      dataList,
      showError
    );
  };

  useEffect(() => {
    const tmpTodo = [];
    const tmpInProgress = [];
    const tmpDone = [];
    const tmpOnHold = [];

    actions.map((action, index) => {
      switch (action.status) {
        case "Created":
          tmpTodo.push(action);
          break;
        case "In_Progress":
          tmpInProgress.push(action);
          break;
        case "Done":
        case "Canceled":
          tmpDone.push(action);
          break;
        case "On_Hold":
          tmpOnHold.push(action);
          break;
        default:
          tmpTodo.push(action);
          break;
      }
    });

    setTodo(tmpTodo);
    setInProgress(tmpInProgress);
    setDone(tmpDone);
    setOnHold(tmpOnHold);
  }, [actions]);

  return (
    <Container fluid style={{ ...containerStyle }}>
      <KanbanColumn
        title={"Todo"}
        type={"card-row card-primary"}
        dataList={todo}
        setEditAction={setEditAction}
        setShowForm={setShowForm}
        setViewAction={setViewAction}
        deleteData={deleteData}
        setDataList={setTodo}
      />

      <KanbanColumn
        title={"In Progress"}
        type={"card-row card-info"}
        dataList={inProgress}
        setEditAction={setEditAction}
        setShowForm={setShowForm}
        setViewAction={setViewAction}
        deleteData={deleteData}
        setDataList={setInProgress}
      />

      <KanbanColumn
        title={"On Hold"}
        type={"card-row card-secondary"}
        dataList={onHold}
        setEditAction={setEditAction}
        setShowForm={setShowForm}
        setViewAction={setViewAction}
        deleteData={deleteData}
        setDataList={setOnHold}
      />

      <KanbanColumn
        title={"Done"}
        type={"card-row card-success"}
        dataList={done}
        setEditAction={setEditAction}
        setShowForm={setShowForm}
        setViewAction={setViewAction}
        deleteData={deleteData}
        setDataList={setDone}
      />
    </Container>
  );
};

export default KanbanBoard;
