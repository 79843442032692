import { Routes, Route } from "react-router-dom";
import Index from "./content/Index";
import Edit from "./content/Edit";

const ProjectRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path="/edit/:id" element={<Edit />} />
    </Routes>
  );
};

export default ProjectRoutes;
