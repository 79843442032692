import { Row, Col } from "react-bootstrap";

const Address = ({ contact, showStreet = true, style }) => {
  const address = contact.adresse[0] ? contact.adresse[0] : null;
  const PersonAddress = () => {
    return Object.keys(address).length !== 0 ? (
      <>
        {contact.anrede ? contact.anrede + " " : ""}
        {contact.vorname ? contact.vorname + " " : ""}
        {contact.name ? contact.name : ""}
      </>
    ) : null;
  };

  const CompanyAddress = () => {
    return Object.keys(address).length !== 0 ? (
      (contact.firmenname ? contact.firmenname : "") +
        " " +
        (contact.rechtsform ? contact.rechtsform : "")
    ) : (
      <br />
    );
  };

  return (
    address && (
      <>
      <address style={{ ...style }}>

        <strong>
          {contact.hasOwnProperty("anrede") ? (
            <PersonAddress />
          ) : (
            <CompanyAddress />
          )}
        </strong>
        {showStreet ? (
          <Row>
            <Col>
              {address.strasse} &nbsp;{address.hausnummer}
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col>
            {address.plz}&nbsp;{address.ort}
          </Col>
        </Row>
      </address>
      </>
    )
  );
};

export default Address;
