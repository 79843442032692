const Timelinelabel = ({ labeltext, color }) => {
  const className = color ? "bg-" +  color  : "bg-success";
  return (
    <div className="time-label">
      <span className={className}>{labeltext}</span>
    </div>
  );
};

export default Timelinelabel;
