const Action = {
  start: null,
  ende: null,
  wiedervorlage: null,
  deadline: null,
  title: '',
  beschreibung: '',
  abgeschlossen: 0,
  kunde: { id: '' },
  person: { id: '' },
  priority: '',
  status: '',
  project_pos: 0.0,
  total_pos: 0.0,
  company_pos: 0.0,
  person_pos: 0.0,
  tags:[],
};
export default Action;
