import { Row, Col, Form, Button, Container } from "react-bootstrap";
import FreelancerserplaceNavbar from "../signup/SubComponents/FreelancerserplaceNavbar";
import createRequestValidationForm from "@serie3/common/Form/Register/RequestValidationForm";
import { useFormik } from "formik";
import { useState } from "react";
import TextField from "../layout/Form/Fields/TextField";
import Footer from "../signup/SubComponents/Footer";

const RequestValidationLink = ({ setToken }) => {
  const [sucess, setSuccess] = useState();
  const requestValidationForm = createRequestValidationForm(setSuccess);
  const formik = useFormik(requestValidationForm);

  return (
    <Container>
      <FreelancerserplaceNavbar
        setToken={setToken}
        showButtons={false}
        showLinks={false}
      />
      <br />
      <Row className="justify-content-md-center">
        <Col>
          Your Email has not been validated, plz check your Email account and
          validate it, or enter your mail and request a new link
        </Col>
      </Row>
      <br />
      <br />
      <Form onSubmit={formik.handleSubmit}>
        <Row>
          <Col>
            <TextField formik={formik} label={"E-Mail"} valueName={"email"} />
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col>
            <Button type="submit"> Request new link </Button>
          </Col>
        </Row>
      </Form>
      <br />
      <br />
      {sucess && (
        <Row>
          <Col>
            <b>
              If we know your email a new link has been sent to you. It is valid
              for 1 hour
            </b>
          </Col>
        </Row>
      )}
      <Footer />
    </Container>
  );
};

export default RequestValidationLink;
