import TextField from "../../../../layout/Form/Fields/TextField";
import { Form } from "react-bootstrap";
import TextArea from "../../../../layout/Form/Fields/TextArea";
import Checkbox from "../../../../layout/Form/Fields/Checkbox";

const CompanyBasicsForm = ({ formik }) => {
  return (
    <Form>
      <TextField
        formik={formik}
        label={"Company Name"}
        valueName={"firmenname"}
        style={{ marginBottom: "10px" }}
      />
      <br />
      <TextField
        formik={formik}
        label={"Type"}
        valueName={"kundenklasse"}
        style={{ marginBottom: "10px" }}
      />
      <br />
      <TextArea
        formik={formik}
        label={"Note"}
        valueName={"bemerkung"}
        style={{ marginBottom: "10px" }}
      />
      <br />
      <TextField
        formik={formik}
        label={"Tax Id"}
        valueName={"tax_id"}
        style={{ marginBottom: "10px" }}
      />
      <br />
      <TextField
        formik={formik}
        label={"Taxnumber"}
        valueName={"tax_number"}
        style={{ marginBottom: "10px" }}
      />
      <br />
      <TextField
        formik={formik}
        label={"Legal form"}
        valueName={"rechtsform"}
        style={{ marginBottom: "10px" }}
      />
      <br />
      <Checkbox
        formik={formik}
        label={"Blocked "}
        valueName={"gesperrt"}
        style={{ marginBottom: "10px" }}
      />
      <br />
      <TextField
        formik={formik}
        label={"Reason for blocking "}
        valueName={"sperrgrund"}
        style={{ marginBottom: "10px" }}
      />
    </Form>
  );
};

export default CompanyBasicsForm;
