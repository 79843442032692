import { Form, Row, Col } from "react-bootstrap";
import TextField from "../../../../layout/Form/Fields/TextField";
import Checkbox from "../../../../layout/Form/Fields/Checkbox";
import DateField from "../../../../layout/Form/Fields/DateField";
import Card from "../../../../layout/Cards/Card";
import NumberField from "../../../../layout/Form/Fields/NumberField";

const PricesAndAvailability = ({ formik, SaveButton }) => {
  return (
    <Form>
      <Card title={"Availability"} CardTools={SaveButton}>
        <Row>
          <Col>
            <DateField
              formik={formik}
              label={"Available from"}
              valueName={"available_from"}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <DateField
              formik={formik}
              label={"Available to"}
              valueName={"available_to"}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <NumberField
              formik={formik}
              label={"Max Weekly hours"}
              valueName={"max_hours"}
            />
          </Col>
          <Col>
            <Checkbox
              formik={formik}
              label={"Fulltime only"}
              valueName={"fulltime_only"}
            />
          </Col>
        </Row>
      </Card>
      <Card title={"Rates"} CardTools={SaveButton}>
        <Row>
          <Col>
            <TextField
              formik={formik}
              label={"Hourly rate on site"}
              valueName={"hourly_rate_on_site"}
            />
          </Col>
          <Col>
            <Checkbox
              formik={formik}
              label={"Publish on site rate"}
              valueName={"hourly_rate_on_site_public"}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <TextField
              formik={formik}
              label={"Hourly rate remote"}
              valueName={"hourly_rate_remote"}
            />
          </Col>
          <Col>
            <Checkbox
              formik={formik}
              label={"Publish remote rate"}
              valueName={"hourly_rate_remote_public"}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <TextField
              formik={formik}
              label={"Daily rate on site"}
              valueName={"daily_rate_on_site"}
            />
          </Col>
          <Col>
            <Checkbox
              formik={formik}
              label={"Publish daily on site rate"}
              valueName={"daily_rate_on_site_public"}
            />
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col>
            <TextField
              formik={formik}
              label={"Daily remote rate"}
              valueName={"daily_rate_remote"}
            />
          </Col>
          <Col>
            <Checkbox
              formik={formik}
              label={"Publish daily remote rate"}
              valueName={"daily_rate_remote_public"}
            />
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

export default PricesAndAvailability;
