const JobCard = ({ job, showJobDetails }) => {
  return (
    <div
      onClick={() => {
        showJobDetails(job);
      }}
    >
      <strong>
        <i className="fas fa-book mr-1"></i> {job._source.jobtitle}
      </strong>
      <p>Fit: {job._score}</p>
      <p className="text-muted">{job._source.description}</p>
      <hr />
    </div>
  );
};

export default JobCard;
