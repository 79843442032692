import crudApi from "@serie3/common/api/crudApi";
import { Row, Col, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import PublicProfilesConfig from "@serie3/freelancesplaceapi/Config/Public/Profiles/PublicProfilesConfig";
import loadPublicProfileDetails from "@serie3/freelancesplaceapi/Domain/Profile/LoadPublicProfileDetails";

import Search from "./Components/Search";
import ProfileDetails from "./Components/ProfileDetails";
import Card from "../layout/Cards/Card";
import FreelancerserplaceNavbar from "../signup/SubComponents/FreelancerserplaceNavbar";
import Footer from "../signup/SubComponents/Footer";

const PublicProfiles = ({setToken}) => {
  const [publicProfiles, setPublicProfiles] = useState();
  const [profileDetails, setProfileDetails] = useState();
  const [count, setCount] = useState(0);

  const loadData = async () => {
    const response = await crudApi.search(
      PublicProfilesConfig.Profiles.endpoints.publicProfiles,
      {},
      true
    );

    if (response.status === 200) {
      setCount(response.data.hits.total.value);
      setPublicProfiles(response.data.hits.hits);
    }
  };

  const CardTools = ({ profileId }) => {
    return (
      <Button
        onClick={() => {
          loadPublicProfileDetails(setProfileDetails, profileId);
        }}
      >
        More
      </Button>
    );
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <FreelancerserplaceNavbar setToken={setToken} />
      <br />
      <Row className="justify-content-md-center">
        <br />
        <Col md={8}>
          <Search
            title={"Find your next Freelancer here"}
            endpoint={PublicProfilesConfig.Profiles.endpoints.search}
            resultData={publicProfiles}
            setResultData={setPublicProfiles}
            loadData={loadData}
          />
        </Col>
      </Row>
      <br />
      <div
        style={{
          marginLeft: "0px",
          paddingRight: "1%",
          paddingLeft: "1%",
          maxWidth: "99%",
        }}
      >
        <Row className="justify-content-md-center">
          <Col md={8}>
            <h3 style={{ width: "100%" }}>
              We have {count} Freelancers listed
            </h3>
          </Col>
        </Row>
        <br />
        <Row>
          <Col md={7}>
            {publicProfiles
              ? publicProfiles.map((profile, index) => {
                  return (
                    <Card
                      title={profile._source.title}
                      key={index}
                      CardTools={() => {
                        return <CardTools profileId={profile._source.id} />;
                      }}
                    >
                      {profile._source.mantra}
                      <hr />
                      <b> Key Skills: </b>
                      {profile._source.skills && profile._source.skills.length > 0
                        ? profile._source.skills.map((skill, skillIndex) => {
                            return skill.name + ", ";
                          })
                        : null}
                    </Card>
                  );
                })
              : "loading Jobs"}
          </Col>
          <Col md={5}>
            {profileDetails ? (
              <Card title={profileDetails.title}>
                <ProfileDetails profile={profileDetails} />
              </Card>
            ) : null}
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default PublicProfiles;
