import DocumentCard from "../../../../documents/DocumentCard";
import { Row, Col, Button } from "react-bootstrap";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faUnlink } from "@fortawesome/free-solid-svg-icons";
import DocumentsOverview from "./SubTabs/Documents/DocumentsOverview";
import listHandler from "@serie3/common/Domain/listHandler";

const Files = ({ documents, projectFormik }) => {
  const [showForm, setShowForm] = useState(false);

  const AddButton = () => {
    return (
      <Button
        className="success"
        onClick={() => {
          setShowForm(true);
        }}
      >
        Add &nbsp;
        <FontAwesomeIcon icon={faAdd} />
      </Button>
    );
  };

  const CardButtons = ({document}) => {
    return (
      <Button variant="danger" size="sm" className="mr-1" onClick={() => {
        listHandler.deleteWithoutCall(document, projectFormik.values["documents"])
        projectFormik.handleSubmit();
      }}>
        <FontAwesomeIcon icon={faUnlink} />
      </Button>
    );
  };

  return (
    <>
      <DocumentsOverview
        show={showForm}
        setShow={setShowForm}
        projectFormik={projectFormik}
      />
      <Row>
        <Col className="text-end">
          <AddButton />
        </Col>
      </Row>
      <br />
      <Row>
        {documents
          ? documents.map((document) => {
              return (
                <DocumentCard
                  key={document.id}
                  document={document}
                  CardButtons={() => { return <CardButtons document={document} />}}
                />
              );
            })
          : null}
      </Row>
    </>
  );
};
export default Files;
