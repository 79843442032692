import { Container} from "react-bootstrap";

const Video = ({title, mainTitle, mainText, videoLink}) => {
  return (
    <Container className="my-5 text-center">
      <h4 className="landingpage">{title}</h4>
      <h2 className="landingpage">{mainTitle}</h2>
      <p>
        {mainText}
      </p>
      <div className="ratio ratio-16x9">
        <iframe
          style={{ display: "block", margin: "0 auto", maxWidth: "100%" }}
          src={videoLink}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
        ></iframe>
      </div>
    </Container>
  );
};

export default Video;
