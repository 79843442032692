const calculateDoneTicketPercentage = (tickets = []) => {
  let done = 0;
  let undone = 0;
  let percentage = 0;
  tickets.map((ticket) => {
    if (ticket.action.status === "Done") {
      done++;
    } else {
      undone++;
    }
  });

  percentage = (done / tickets.length) * 100;
  return Math.round(percentage);
};

export { calculateDoneTicketPercentage };
