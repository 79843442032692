import { useLocation } from "react-router-dom";

const ContentWrapper = ({ children, style }) => {
  const url = useLocation();

  return url.pathname === "/crm/actions" ||
    url.pathname.includes("/project/edit/") ? (
    <div className="content-wrapper kanban">{children}</div>
  ) : (
    <div className="content-wrapper" style={{...style}}>{children}</div>
  );
};

export default ContentWrapper;
