import timeingStuff from "@serie3/freelancesplaceapi/Domain/Shared/timeingStuff";
import AgeInDaysBatch from "../../../layout/Common/AgeInDaysBatch";
import { useEffect, useState } from "react";
import EllipsisVerticalToggle from "../../../layout/Dropdowns/EllipsisVerticalToggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPen } from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "react-bootstrap";
import StatusDropdown from "./Components/StatusDropdown";
import crudApi from "@serie3/common/api/crudApi";
import ApplicationConfig from "@serie3/freelancesplaceapi/Config/Profiles/ApplicationConfig";


const ApplicationRow = ({ application, editAction, deleteAction }) => {
  const link = decodeURIComponent(application.link);
  const [ageInDays, setAgeInDays] = useState(0);
  const [applicationStatus, setApplicationStatus] = useState(
    application.status ? application.status : "ToDo"
  );

  useEffect(() => {
    setAgeInDays(timeingStuff.calculateAge(application.date));
  }, [application]);

  const updateStatus = async (status) => {
    application["status"] = status;
    //Date is set in the backend and not via frontend
    delete application.date;
    const payload = JSON.stringify(application);
  
    const response = await crudApi.edit(ApplicationConfig.endpoints.edit, payload);
    if(response.status === 200){
      switch (status) {
        case "todo":
          setApplicationStatus("ToDo");
          break;
        case "applied":
          setApplicationStatus("Applied");
          break;
        case "waiting":
          setApplicationStatus("waiting");
          break;
        case "canceled":
          setApplicationStatus("Canceled");
          break;
        case "appointment":
          setApplicationStatus("Appointement");
          break;
        case "rejected":
          setApplicationStatus("Rejected");
          break;
        case "accepted":
          setApplicationStatus("Accepted");
          break;
      }
    }
  };

  return (
    <tr>
      <td>
        <AgeInDaysBatch ageInDays={ageInDays} showZero={true} />
        <span style={{ fontSize: "smaller", display: "block" }}>
          {application.date}
        </span>
      </td>
      <td>
        <span style={{ display: "block", textTransform: "capitalize" }}>
          {application.job_title}
        </span>
        <span style={{ fontSize: "smaller", display: "block" }}>
          <b>@</b>
          {application.hireing_company} | {application.location}
        </span>
      </td>
      <td>
        <a href={link} target="_blank" rel="noreferrer">
          {application.page}
        </a>
      </td>
      <td>
        <Dropdown style={{ float: "left", marginLeft: "5px" }}>
          <Dropdown.Toggle as="statustoggle">
            <b style={{ textTransform: "capitalize" }}>
              {applicationStatus === "waiting"
                ? "Waiting for response"
                : applicationStatus}
            </b>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <StatusDropdown
              text={"ToDo"}
              action={() => {
                updateStatus("todo");
              }}
            />
            <div className="dropdown-divider" />
            <StatusDropdown
              text={"Applied"}
              action={() => {
                updateStatus("applied");
              }}
            />
            <div className="dropdown-divider" />
            <StatusDropdown
              text={"Waiting for Response"}
              action={() => {
                updateStatus("waiting");
              }}
            />
            <div className="dropdown-divider" />
            <StatusDropdown
              text={"Canceled"}
              action={() => {
                updateStatus("canceled");
              }}
            />
            <div className="dropdown-divider" />
            <StatusDropdown
              text={"Appointment"}
              action={() => {
                updateStatus("appointment");
              }}
            />
            <div className="dropdown-divider" />
            <StatusDropdown
              text={"Rejected"}
              action={() => {
                updateStatus("rejected");
              }}
            />
            <div className="dropdown-divider" />
            <StatusDropdown
              text={"Accepted"}
              action={() => {
                updateStatus("accepted");
              }}
            />
          </Dropdown.Menu>
        </Dropdown>
      </td>
      <td>
        <Dropdown
          style={{ float: "right", maxHeight: "16px", marginLeft: "5px" }}
        >
          <EllipsisVerticalToggle />
          <Dropdown.Menu>
            {editAction ? (
              <>
                <Dropdown.Item
                  onClick={() => {
                    editAction(application);
                  }}
                >
                  <small>
                    <FontAwesomeIcon icon={faPen} />
                    &nbsp; Edit
                  </small>
                </Dropdown.Item>
                <div className="dropdown-divider" />
              </>
            ) : null}
            {deleteAction ? (
              <>
                <Dropdown.Item onClick={deleteAction}>
                  <small>
                    <FontAwesomeIcon icon={faTrash} />
                    &nbsp; Delete
                  </small>
                </Dropdown.Item>
              </>
            ) : null}
          </Dropdown.Menu>
        </Dropdown>
      </td>
    </tr>
  );
};

export default ApplicationRow;
