import Address from "../../Entity/Crm/Address";
import * as Yup from "yup";
import CrudApi from "@serie3/common/api/crudApi";
import ListHandler from "@serie3/common/Domain/listHandler";

const createKeyValueForm = (
  dataList,
  setDataFunction,
  showErrorFunction,
  setShowEdit,
  newEndpoint,
  editEndpoint
) => {
  const onSubmit = async (values, actions) => {
    if (values.id > 0) {
      let payload = JSON.stringify(values, null, 2);
      const response = await CrudApi.edit(editEndpoint, payload);
      ListHandler.updateList(
        response,
        payload,
        dataList,
        setDataFunction,
        showErrorFunction
      );
    } else {
      //values.date = new Date().toISOString().split("T")[0];
      let payload = JSON.stringify(values, null, 2);
      const response = await CrudApi.createNew(newEndpoint, payload);
      ListHandler.newToList(
        response,
        dataList,
        setDataFunction,
        showErrorFunction
      );
    }

    setShowEdit(false);
    actions.resetForm(Address);
  };

  return {
    initialValues: Address,
    onSubmit: (values, actions) => {
      console.log(values);
      onSubmit(values, actions);
    },
  };
};

const setEditData = (editData) => {
  let Address = {
    id: editData.id,
    hausnummer: editData.hausnummer,
    ort: editData.ort,
    plz: editData.plz,
    strasse: editData.strasse,    
  };
  return Address;
};

export default { createKeyValueForm, setEditData };
