import { Container, Row, Col } from "react-bootstrap";

const Join = () => {
  return (
    <section
      className="text-center py-4"
      style={{ backgroundColor: "#f5f5f5" }}
    >
      <Container>
        <Row>
          <Col>
            <h4 className="landingpage blueColorText">
              We want you to succeed!
            </h4>
            <h2 className="landingpage">Join! It's FREE!</h2>
            <ul className="list-unstyled">
              <li>No strings attached</li>
              <li>No credit card required</li>
              <li>Upgrade anytime</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Join;
