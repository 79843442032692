import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";

const SaveButton = ({ handleClick, text }) => {
  return (
    <Button type="submit" onClick={handleClick}>
      <FontAwesomeIcon icon={faFloppyDisk} />
      &nbsp; {text ? text: "Save"}
    </Button>
  );
};

export default SaveButton;
