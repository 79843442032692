const Workexperience = {
  position_name: "",
  position_type: "",
  company: "",
  description: "",
  business: "",
  type_of_business: "",
  start: new Date(),
  ende: new Date(),
  current: false,
  use_for_card: "",
  headcount: 0,
  profile: { id: "" },
};

export default Workexperience;
