import { Row, Col, Container } from "react-bootstrap";
import CustomModal from "../../../layout/CustomModal";
import { useState } from "react";

const TimetrackingLandingpage = () => {
  const [showImage, setShowImage] = useState(false);
  const imagePath = "/dist/img/timetracking.png";

  return (
    <>
      <CustomModal show={showImage} setShow={setShowImage} size="xl" title={'Easily track your hours'}>
        <img src={imagePath} className="landingpageImageModal" />
      </CustomModal>
      <Container>
        <Row
          style={{
            position: "relative",
            border: "0px solid #000000",
            paddingTop: "4.5rem",
            paddingBottom: "5.5rem",
          }}
        >
          <Col className="yellowdotWelcome" />
          <Col className="greendotWelcome" />
          <Row>
            <Col md={7} sm={12} style={{ marginBottom: "0.5rem" }}>
              <div className="blueSubheaderTop">
                Master Your Productivity with Time Tracking
              </div>
              <div
                className="landingpageHeader"
                style={{
                  fontWeight: "500",
                  fontStyle: "normal",
                  lineHeight: "140%",
                }}
              >
                Be transparent with your customer
              </div>
              <span className="landingpageDescriptions">
                Produce standardized timesheets ready to sign off for your
                clients. Coming up: the capability to start separate
                time-tracking for each ticket and direct customer timesheet
                approval!
              </span>
            </Col>

            <Col md={5} sm={12}>
              <Col className="reddotWelcome" />
              <div className="landingpageImageWrapper">
              <img
                  src={imagePath}
                  className="landingpageImage"
                  onClick={() => {setShowImage(true)}}
                />
              </div>
            </Col>
          </Row>
        </Row>
      </Container>
    </>
  );
};

export default TimetrackingLandingpage;
