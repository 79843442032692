const StatusTag = ({start, end, done}) => {

    let type = <span className="badge badge-primary" >Ongoing</span>;

    const startDate = new Date(start).getTime();
    const endDate = new Date(end).getTime();
    const today = new Date().getTime(); 

    if(startDate > today){
        type = <span className="badge badge-warning" >Waiting</span>;
    }


    if(done){
        type = <span className="badge badge-success" >Done</span>;
    }

    if((typeof done === "undefined" || done === false) && (typeof end !== undefined && endDate < today )){
        type = <span className="badge badge-danger" >Not Done</span>;
    }

    return type;

}

export default StatusTag