import { useState } from 'react';


export default function useToken() {
  const getToken = () => {
    const tokenString = sessionStorage.getItem('token');
    const localId = sessionStorage.getItem('localId');
    const userToken = JSON.parse(tokenString);
    const uuid = JSON.parse(localId);
    
    return userToken;
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken, localId) => {
    sessionStorage.setItem('token', JSON.stringify(userToken));
    sessionStorage.setItem('localId', JSON.stringify(localId));
    sessionStorage.setItem('loginTime', Date.now());
    setToken(userToken);
  };
  
  if(token && ((Math.abs(Date.now() - sessionStorage.getItem('loginTime'))/ 60000) >= 59)){
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('loginTime');
    window.location.replace("/");
  }

  return {
    setToken: saveToken,
    token
  }
}
 
