import { Routes, Route } from "react-router-dom";
import CompaniesRoutes from "./Companies/CompaniesRoutes";
import PeoplesRoutes from "./People/PeoplesRoutes";
import ActionRoutes from "./Actions/ActionRoutes";
import CrmSettings from "./CrmSettings";

const CrmRoutes = () => {
  return (
    <Routes>
         <Route path="/settings" element={<CrmSettings />} />
         <Route path="/companies/*" element={<CompaniesRoutes />} />
         <Route path="/people/*" element={<PeoplesRoutes />} />
         <Route path="/actions/*" element={<ActionRoutes />} />
    </Routes>
  );
};

export default CrmRoutes;
