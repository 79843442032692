import React from "react";
import ListElement from "../../navbars/elements/ListElement";
import { faFile } from "@fortawesome/free-solid-svg-icons";

const DocumentsSideNav = () => {
  return (
    <>
      <li className="nav-header">Your Documents</li>
      <ListElement
        name="Documents"
        targetUrl="/documents"
        icon={faFile}
      />
    </>
  );
};

export default DocumentsSideNav;
