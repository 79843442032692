import { Bar } from "react-chartjs-2";
import { useState, useEffect } from "react";

const BarChart = ({ Data, options, label }) => {
  const [chartData, setChartData] = useState(null);
  useEffect(() => {
    const tmpChartData = {
      labels: Data.map((Data) => Data.label),
      datasets: [
        {
          label: label,
          data: Data.map((Data) => Data.number),
          backgroundColor: ["#007bff"],
          borderColor: "black",
          borderWidth: 1.5,
        },
      ],
    };
    setChartData(tmpChartData);
  }, [Data]);

  return chartData ? (
    <div className="chart-container">
      <Bar
        data={chartData}
        options={options}
        style={{ minHeight: "355px", maxHeight: "355px" }}
      />
    </div>
  ): null;
};

export default BarChart;
