import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Backend from "./components/LoggedIn/Backend";
import NotloggedinNew from "./components/signup/NotloggedinNew";
import useToken from "./business/security/useToken";
import useAccount from "./business/security/useAccount";
import AuthContext from "@serie3/common/auth/AuthContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PublicJobs from "./components/Public/PublicJobs";
import PublicProfiles from "./components/Public/PublicProfiles";
import VerifyEmail from "./components/signup/VerifyEmail";
import GeneralServerError from "./components/LoggedIn/modules/Errors/GeneralServerError";
import Datareset from "./components/LoggedIn/modules/Errors/DataReset";
import CustomerDashboard from "./components/Public/CustomerDashboard";
import Privacy from "./components/Public/Privacy";
import Imprint from "./components/Public/Imprint";
import RequestValidationLink from "./components/Public/RequestValidationLink";
import BlogIndex from "./components/Public/Blog/BlogIndex";
import BlogPage from "./components/Public/Blog/BlogPage";
import { useEffect } from "react";
import ApplicationTracker from "./components/Public/Landingpages/ Applicationtracker";
import WhySignUp from "./components/Public/Landingpages/WhySignUp";

function App() {
  const { token, setToken } = useToken();
  const { account, setAccount } = useAccount();

  useEffect(() => {
    if (window.location.hash === "#pricing") {
      const element = document.getElementById("pricing");
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [window.location.pathname]);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <AuthContext.Provider value={{ token, setToken }}>
        <Router>
          <Routes>
            {!token && (
              <Route
                path="/*"
                element={
                  <NotloggedinNew setToken={setToken} setAccount={setAccount} />
                }
              />
            )}

            <Route
              exact
              path="/applicationtracker"
              element={<ApplicationTracker />}
            />
            <Route exact path="/whysignup" element={<WhySignUp />} />
            <Route
              exact
              path="/freelancer"
              element={
                <PublicProfiles setToken={setToken} setAccount={setAccount} />
              }
            />
            <Route
              exact
              path="/requestemailvalidation"
              element={
                <RequestValidationLink
                  setToken={setToken}
                  setAccount={setAccount}
                />
              }
            />
            <Route
              exact
              path="/freelance/jobs"
              element={
                <PublicJobs setToken={setToken} setAccount={setAccount} />
              }
            />
            <Route
              exact
              path="/verifyemail"
              element={
                <VerifyEmail setToken={setToken} setAccount={setAccount} />
              }
            />
            <Route exact path="/uuuups" element={<GeneralServerError />} />
            <Route exact path="/datareset" element={<Datareset />} />
            <Route
              exact
              path="/blog/"
              element={
                <BlogIndex setToken={setToken} setAccount={setAccount} />
              }
            />
            <Route
              path="/blog/:splat"
              element={<BlogPage setToken={setToken} setAccount={setAccount} />}
            />
            <Route exact path="/*" element={<Backend />} />
            <Route
              exact
              path="/publicdashboard/:publicId"
              element={
                <CustomerDashboard
                  setToken={setToken}
                  setAccount={setAccount}
                />
              }
            />
            <Route
              exact
              path="/privacy"
              element={<Privacy setToken={setToken} setAccount={setAccount} />}
            />
            <Route
              exact
              path="/imprint"
              element={<Imprint setToken={setToken} setAccount={setAccount} />}
            />
          </Routes>
        </Router>
      </AuthContext.Provider>
    </>
  );
}

export default App;
