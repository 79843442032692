import { Row, Col, Button } from "react-bootstrap";
import TicketChart from "../projects/Projects/Charts/TicketChart";
import Card from "../../../layout/Cards/Card";
import { useContext, useEffect, useState } from "react";
import HourChart from "../projects/Projects/Charts/HourChart";
import crudApi from "@serie3/common/api/crudApi";
import ProjectConfig from "@serie3/freelancesplaceapi/Config/Projects/ProjectConfig";
import SettingsContext from "@serie3/common/auth/SettingsContext";
import TicketList from "../projects/Projects/Charts/TicketList";
import timeingStuff from "@serie3/freelancesplaceapi/Domain/Shared/timeingStuff";
import { Link } from "react-router-dom";

const ProjectDashboard = ({ setDoneTickets, projectId }) => {
  const { settings, setSettings } = useContext(SettingsContext);
  const [project, setProject] = useState();
  const [hydratedTasks, setHydratedTasks] = useState([]);
  const [totalHours, setTotalHours] = useState(0);

  useEffect(() => {
    const tmpHydratedTasks = [];
    if (
      settings.project_quicklink_id &&
      typeof settings.project_quicklink_id.id === "number"
    ) {
      async function fetchProject() {
        const response = await crudApi.justGet(
          ProjectConfig.endpoints.show + projectId
        );
        if (response.status === 200) {
          const tmpProject = response.data;
          let tmpTotalHours = 0;
          setProject(tmpProject);
          tmpProject.tasks.map((task) => {
            tmpTotalHours += task.task.total_time;
            tmpHydratedTasks.push(task.task);
          });
          setHydratedTasks([...tmpHydratedTasks]);
          setTotalHours(timeingStuff.convertToHHmm(tmpTotalHours / 60));
        }
      }
      fetchProject();
    }
  }, []);

  return project ? (
    <Card title={project.title}>
      <Row>
        <Col>
          <HourChart
            tasks={hydratedTasks}
            style={{ height: "450px" }}
            totalHours={totalHours}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col sm={6} md={6}>
          <Card title={"Tickets"}>
            <TicketList tickets={project.actions} />
          </Card>
        </Col>
        <Col sm={6} md={6}>
          <Card title={"Tickets"}>
            <TicketChart
              tickets={project.actions}
              setDoneTickets={setDoneTickets}
            />
          </Card>
        </Col>
      </Row>
    </Card>
  ) : (
    <Card title={"Create a new Project"}>
      <b>Create your first Project or set one your projects as default</b>
      <br />
      <br />
      <Link to="/project/">
        <Button>Projects</Button>
      </Link>
    </Card>
  );
};

export default ProjectDashboard;
