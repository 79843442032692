import NewModal from "../../documents/modals/NewModal";
import TimetrackingForm from "@serie3/freelancesplaceapi/Form/Timetracking/TimetrackingForm";
import { Col, Row } from "react-bootstrap";
import TextField from "../../../../layout/Form/Fields/TextField";
import TextArea from "../../../../layout/Form/Fields/TextArea";
import Task from "@serie3/freelancesplaceapi/Entity/Timetracking/Task";
import DateField from "../../../../layout/Form/Fields/DateField";
import TimeField from "../../../../layout/Form/Fields/TimeField";


const TimetrackingFormView = ({
  showEdit,
  setShowEdit,
  editTask,
  Save,
  formik,
}) => {
  return (
    <NewModal
      show={showEdit}
      setShow={setShowEdit}
      ExtraButton={Save}
      onShow={() => {
        editTask
          ? formik.setValues(TimetrackingForm.setEditData(editTask))
          : formik.setValues(Task);
      }}
    >
      <Row>
        <Col>
          <TextField
            formik={formik}
            label={"Title"}
            valueName={"title"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <DateField
            formik={formik}
            label={"Date"}
            valueName={"date"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
        <Col>
          <TimeField
            formik={formik}
            label={"Start"}
            valueName={"start"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
        <Col>
          <TimeField
            formik={formik}
            label={"End"}
            valueName={"ende"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
        <Col>
          <TimeField
            formik={formik}
            label={"Break"}
            valueName={"pause_time"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextArea
            formik={formik}
            label={"Description"}
            valueName={"beschreibung"}
            style={{ marginBottom: "10px" }}
            rows={8}
          />
        </Col>
      </Row>
    </NewModal>
  );
};

export default TimetrackingFormView;
