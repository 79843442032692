import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPen } from "@fortawesome/free-solid-svg-icons";

const StatusDropdown = ({ text, action }) => {
  return (
    <>
      <Dropdown.Item
        onClick={() => {
          action();
        }}
      >
        <small>{text}</small>
      </Dropdown.Item>
    </>
  );
};

export default StatusDropdown;
