import { React } from "react";
import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useFormik } from "formik";
import PoistionDomain from "@serie3/freelancesplaceapi/Domain/Billing/Outgoing/Position";
import PositionForm from "@serie3/freelancesplaceapi/Form/Billing/PositionForm";
import Position from "@serie3/freelancesplaceapi/Entity/Billing/Position";

const PositionModal = ({
  show,
  setShow,
  financeId,
  position,
  dataList,
  setDataFunction,
  showErrorFunction,
}) => {
  const editPositionForm = PositionForm.createPositionForm(
    dataList,
    setDataFunction,
    showErrorFunction,
    setShow
  );
  const formik = useFormik(editPositionForm);

  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => setShow(false)}
      dialogClassName="modal-90w"
      onShow={() => {
        position
          ? formik.setValues(PositionForm.setEditData(position))
          : formik.setValues(Position);
        formik.setFieldValue("finanz_vorgang", {id: financeId });
      }}
    >
      <Form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton={true}>
          <Modal.Title id="Billview">
            {typeof position === "undefined" || position === null
              ? "Add "
              : "Edit "}
            Position
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group controlId="titel">
                <Form.Label>Title:</Form.Label>
                <Form.Control
                  type="text"
                  name="titel"
                  placeholder="Title"
                  onChange={formik.handleChange}
                  value={formik.values.titel}
                  isInvalid={!!formik.errors.titel}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.titel}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <Form.Label>Description:</Form.Label>
              <Form.Control
                as="textarea"
                name="beschreibung"
                rows={3}
                style={{ width: "100%" }}
                onChange={formik.handleChange}
                value={formik.values.beschreibung}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col className="col-sm-4">
              <Form.Group controlId="title">
                <Form.Label>Price:</Form.Label>
                <Form.Control
                  type="text"
                  name="preis"
                  placeholder="price"
                  value={formik.values.preis}
                  onChange={(e) => {
                    formik.handleChange(e);
                    PoistionDomain.handlePositionFormChange(e, formik);
                  }}
                  isInvalid={!!formik.errors.preis}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.preis}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col className="col-sm-4">
              <Form.Group controlId="amount">
                <Form.Label>Amount:</Form.Label>
                <Form.Control
                  type="number"
                  name="multiplikator"
                  placeholder="1"
                  value={formik.values.multiplikator}
                  onChange={(e) => {
                    formik.handleChange(e);
                    PoistionDomain.handlePositionFormChange(e, formik);
                  }}
                  isInvalid={!!formik.errors.multiplikator}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.amount}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col className="col-sm-4">
              <Form.Group controlId="tax">
                <Form.Label>Tax (%):</Form.Label>
                <Form.Control
                  type="number"
                  name="steuersatz"
                  placeholder="1"
                  value={formik.values.steuersatz}
                  onChange={(e) => {
                    formik.handleChange(e);
                    PoistionDomain.handlePositionFormChange(e, formik);
                  }}
                  isInvalid={!!formik.errors.steuersatz}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.steuersatz}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <br />
          <Row>
            <Col className="col-sm-6 ml-auto">
            <Form.Group controlId="gesamt_preis" className="form-inline mb-2">
                <Col className="col-sm-4 ml-auto">
                  <Form.Label className="rightText">Nett:</Form.Label>
                </Col>
                <Col className="col-sm-6">
                  <Form.Control
                    type="number"
                    name="gesamt_preis"
                    style={{
                      maxWidth: "100%",
                      textAlign: "end",
                      fontWeight: "bolder",
                    }}
                    value={formik.values.gesamt_preis}
                    readOnly
                  />
                </Col>
              </Form.Group>
              <Form.Group controlId="taxabsolute" className="form-inline mb-2">
                <Col className="col-sm-4 ml-auto">
                  <Form.Label className="rightText">Tax:</Form.Label>
                </Col>
                <Col className="col-sm-6">
                  <Form.Control
                    type="number"
                    name="taxabsolute"
                    style={{
                      maxWidth: "100%",
                      textAlign: "end",
                      fontWeight: "bolder",
                    }}
                    value={formik.values.taxabsolute}
                    readOnly
                  />
                </Col>
              </Form.Group>
              <Form.Group controlId="gross_price" className="form-inline">
                <Col className="col-sm-4 ml-auto">
                  <Form.Label className="rightText">Gross:</Form.Label>
                </Col>
                <Col className="col-sm-6">
                  <Form.Control
                    type="number"
                    name="gross_price"
                    style={{
                      maxWidth: "100%",
                      textAlign: "end",
                      fontWeight: "bolder",
                    }}
                    value={formik.values.gross_price}
                    readOnly
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit">
            <FontAwesomeIcon icon={faSave} />
            &nbsp; Save
          </Button>
          <Button onClick={() => setShow(false)}>Close</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default PositionModal;
