import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import Language from "@serie3/freelancesplaceapi/Domain/Profile/Language";

const AddLanguageButton = ({formik, setEditLanguage, setShowLanguageForm}) => {
    return (
      <div className="card-tools ml-auto">
        <Button
          className="success"
          onClick={() => {
            const arrayLength = formik.values.languages.length;
            if (arrayLength > 0) {
              const lastLanguage = formik.values.languages[arrayLength - 1];
              if (
                typeof lastLanguage !== "undefined" &&
                (typeof lastLanguage.name !== "undefined" ||
                  typeof lastLanguage.level !== "undefined")
              ) {
                formik.values.languages.push(Language);
              }
            } else {
              formik.values.languages.push(Language);
            }
            setEditLanguage(null);
            setShowLanguageForm(true);
          }}
        >
          Add &nbsp;
          <FontAwesomeIcon icon={faAdd} />
        </Button>
      </div>
    );
  };

  export default AddLanguageButton;