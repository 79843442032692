import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";

const ReferencedInputAndSubmit = ({
  namedReference,
  onClick,
  label,
  style,
  type = "text",
}) => {
  const labelWithButton = label ? (
    label
  ) : (
    <>
      Add <FontAwesomeIcon icon={faAdd} />
    </>
  );
  return (
    <div className="input-group mb-3" style={{ ...style }}>
      <input
        ref={namedReference}
        type={type}
        className="form-control"
        style={{
          border: "1px solid #ced4da",
          borderRight: "0px",
          borderTopRightRadius: "0",
          borderBottomRightRadius: "0",
          borderRadius: "0.25rem",
        }}
      />
      <div className="input-group-append">
        <Button className="btn btn-primary" onClick={onClick}>
          {labelWithButton}
        </Button>
      </div>
    </div>
  );
};

export default ReferencedInputAndSubmit;
