import { React, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CrudApi from "@serie3/common/api/crudApi";
import { faLinkSlash } from "@fortawesome/free-solid-svg-icons";
import downloadHandler from "@serie3/freelancesplaceapi/Domain/Shared/downloadHandler";
import DocumentsConfig from "@serie3/freelancesplaceapi/Config/Documents/DocumentsConfig";

const ConnectedFiles = ({ entity, show, endpointAll, endpointUnlink }) => {
  const [attachments, setAttachments] = useState([]);

  const loadData = async () => {
    let url = endpointAll + entity.id;
    const response = await CrudApi.getAll(url);
    setAttachments(response.data);
  };

  useEffect(() => {
    if (show === false) return;
    loadData();
  }, [show]);

  const handleUnlink = async (attachmentId) => {
    try {
      let response = await CrudApi.del(endpointUnlink, attachmentId);
      if (response.status === 200) {
        const newAttachments = attachments.filter(
          (attachment) => attachment.id !== attachmentId
        );
        setAttachments(newAttachments);
      }
    } catch (e) {}
  };


  const AttachementWrapper = ({ attachments }) => {
    return (
      <div
        style={{
          backgroundColor: "#d9d9d9",
          width: "100%",
          padding: "10px",
          borderRadius: "5px",
          border: "1px solid #dfdfdf",
          float: "left",
          marginTop: "10px",
        }}
      >
        {attachments.map((attachment, index) => (
          <span
            key={attachment.id}
            style={{
              marginRight: "5px",
              borderRadius: "5px",
              border: "1px solid #bababa",
              padding: "5px",
              backgroundColor: "#ededed",
              display: "inline-block",
              cursor: "pointer",
            }}
          >
            <span
              style={{
                borderBottom: "1px solid #007bff",
                color: "#007bff",
                marginRight: "15px",
              }}
              onClick={() => {
                const downloadUrl = DocumentsConfig.endpoints.preview + attachment.fileId;
                downloadHandler(downloadUrl, attachment.originalName);
              }}
            >
              {attachment.originalName}
            </span>
        
            <FontAwesomeIcon
              onClick={() => handleUnlink(attachment.id)}
              style={{
                color: "#dc3545",
              }}
              icon={faLinkSlash}
            />
          </span>
        ))}
      </div>
    );
  };

  if (attachments.length > 0) {
    return <AttachementWrapper attachments={attachments} />;
  }

  return null;
};

export default ConnectedFiles;
