import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faUpload, faFile } from "@fortawesome/free-solid-svg-icons";

const DocumentConnectionButtons = ({ showUpload, showLink, style }) => {
  const localStyle = style ? style : {float: "left"}
  return (
    <Dropdown style={localStyle}>
      <Dropdown.Toggle id="dropdown-basic">
        <FontAwesomeIcon icon={faFile} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => showUpload(true)}>
          <small>
            <FontAwesomeIcon icon={faUpload} />
            &nbsp; Upload
          </small>
        </Dropdown.Item>
        {typeof showLink !== "undefined" ? (
          <>
            <div className="dropdown-divider"></div>
            <Dropdown.Item onClick={() => showLink(true)}>
              <small>
                <FontAwesomeIcon icon={faLink} />
                &nbsp; Link
              </small>
            </Dropdown.Item>
          </>
        ) : null}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DocumentConnectionButtons;
