import Content from "../../../../layout/Content";
import TimetrackingHeader from "./TimetrackingHeader";
import { useEffect, useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import TimetrackingConfig from "@serie3/freelancesplaceapi/Config/Timetracking/TimetrackingConfig";
import crudApi from "@serie3/common/api/crudApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import TimetrackingFormView from "./TimetrackingFormView";
import listHandler from "@serie3/common/Domain/listHandler";
import TimetrackingForm from "@serie3/freelancesplaceapi/Form/Timetracking/TimetrackingForm";
import { useFormik } from "formik";
import SaveButton from "../../../../layout/Buttons/SaveButton";
import TasksTable from "./TasksTable";
import getSettings from "@serie3/common/config/settings";
import PaymentModal from "../../../../layout/Payment/PaymentModal";
import {
  StartSelectionButton,
  PrintButton,
} from "../Shared/TimetrackingButtons";

const Index = () => {
  const [showError, setShowError] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [editTask, setEditTask] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [showPayModal, setShowPayModal] = useState(false);
  const [settings, setSettings] = useState();
  const [selected, setSelected] = useState([]);
  const [startSelection, setStartSelection] = useState(false);

  useEffect(() => {
    const endpoint = TimetrackingConfig.endpoints.getAll;
    crudApi.getAll(endpoint, setTasks, "tasks");
    setSettings(getSettings());
  }, []);

  const createNew = () => {
    setEditTask(null);
    setShowForm(true);
  };

  const deleteTaskFunction = async (task) => {
    const itemIndex = tasks.findIndex((obj) => obj.id === task.id);
    listHandler.deleteFromList(
      itemIndex,
      crudApi.del(TimetrackingConfig.endpoints.delete, task.id),
      setTasks,
      tasks,
      setShowError
    );
  };

  const editTaskFunction = (task) => {
    setEditTask(task);
    setShowForm(true);
  };

  const NewButton = () => {
    return (
      <>
        <Button className="success" onClick={createNew}>
          New <FontAwesomeIcon icon={faPlus} />
        </Button>
        &nbsp;
        <StartSelectionButton
          startSelection={startSelection}
          setStartSelection={setStartSelection}
        />
      </>
    );
  };

  const timetrackingForm = TimetrackingForm.createTimetrackingForm(
    tasks,
    setTasks,
    setShowError,
    setShowForm,
    setShowPayModal
  );

  const formik = useFormik(timetrackingForm);

  const Save = () => {
    return (
      <SaveButton
        handleClick={(e) => {
          formik.handleSubmit(e);
          e.preventDefault();
        }}
      />
    );
  };

  return (
    <>
      {settings && (
        <PaymentModal
          show={showPayModal}
          setShow={setShowPayModal}
          priceTableId={settings.pricingTables.timetracking}
        />
      )}
      <TimetrackingFormView
        showEdit={showForm}
        setShowEdit={setShowForm}
        dataList={tasks}
        editTask={editTask}
        setDataList={setTasks}
        Save={Save}
        formik={formik}
      />

      <TimetrackingHeader />
      <Content AdditionalButtons={NewButton}>
        { selected.length > 0 &&
        <Row>
          <Col>
            <PrintButton endpoint={TimetrackingConfig.endpoints.print} selected={selected} setSelected={setSelected} />
            <br />
            <br />
          </Col>
        </Row>
        } 
        <TasksTable
          tasks={tasks}
          deleteTaskFunction={deleteTaskFunction}
          editTaskFunction={editTaskFunction}
          selected={selected}
          setSelected={setSelected}
          startSelection={startSelection}
        />
      </Content>
    </>
  );
};

export default Index;
