import React from "react";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      style={{
        color: "black",
        width: "20px",
        height: "40px",
        display: "flex",
        alignItems: "middle",
      }}
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  ));

  export default CustomToggle;