import FreelancerserplaceNavbar from "../../signup/SubComponents/FreelancerserplaceNavbar";
import Footer from "../../signup/SubComponents/Footer";
import { Row, Col, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import blogclient from "@serie3/common/api/blogClient";

import getSettings from "@serie3/common/config/settings";

const BlogPage = ({ setToken }) => {
  const { splat } = useParams();
  const [blogContent, setBlogContent] = useState("");
  const [header, setHeader] = useState("");
  const [headerImage, setHeaderImage] = useState("");
  const settings = getSettings();
  const blogUrl =
    window.location.protocol + "//" + window.location.host + "/blog/";

  useEffect(() => {
    const loadData = async () => {
      const response = await blogclient.get(splat);
      if (response.status === 200) {
        let content = response.data.content.colPos0[0].content.bodytext;
        let publicUrl =
          response.data.content.colPos0[0].content.gallery.rows[1].columns[1]
            .publicUrl;
        let parsedContent = content.replace(
          'src="/fileadmin/',
          'src="' + settings.baseUrl + "/fileadmin/"
        );
        setHeader(response.data.meta.title);
        setBlogContent(parsedContent);
        setHeaderImage(publicUrl);
      }
    };
    loadData();
  }, []);

  return (
    <>
      <FreelancerserplaceNavbar setToken={setToken} />
      <br />
      <br />
      <Row className="justify-content-md-center" id="blog" style={{padding: "10px"}}>
        <Col md={9}>
          <h2 style={{ marginBottom: "-0.25rem" }}>{header}</h2>
          <small style={{ color: "gray" }}>
            published by:{" "}
            <a href="https://felix-dziekan.de/" target="_blank">
              Felix Dziekan
            </a>
            in <a href={blogUrl}>Blog</a>
          </small>
          <br />
          <img src={headerImage} style={{ marginTop: "1.5rem" }} />
          <br />
          <br />
          <div dangerouslySetInnerHTML={{ __html: blogContent }} />
        </Col>
      </Row>
      <Footer />
    </>
  );
};

export default BlogPage;
