const firebaseConfig = {
    apiKey: "AIzaSyBRj1nzuWEhjUaUpl-B-T9j6ahWqQPzC_8",
    authDomain: "freelancersplace-demo.firebaseapp.com",
    projectId: "freelancersplace-demo",
    storageBucket: "freelancersplace-demo.appspot.com",
    messagingSenderId: "258579062263",
    appId: "1:258579062263:web:1267b879107bebd50e62f2"
  };

  
export default firebaseConfig;