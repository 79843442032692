import { Col } from "react-bootstrap";


const JobDetails = ({ job }) => {
  return Object.keys(job).length > 0 ? (
    <>
      <small>Created at {job.created}</small>
      <br />
      <table>
        <tbody>
          <tr>
            <th>Start</th>
            <td></td>
          </tr>
          <tr>
            <th>End</th>
            <td></td>
          </tr>
          <tr>
            <th>Start</th>
            <td></td>
          </tr>
        </tbody>
      </table>
      <b>Description</b>
      <p>{job.description}</p>
      <b>Your Job</b>
      <ul>
        {job.to_do
          ? job.to_do.map((todo, todoIndex) => {
              return <li key={todoIndex}>{todo.name}</li>;
            })
          : null}
      </ul>
      <b>Must have skills</b>
      <ul>
        {job.key_skills
          ? job.key_skills.map((keySkill, keySkillIndex) => {
              return <li key={keySkillIndex}>{keySkill.name}</li>;
            })
          : null}
      </ul>
      <b>Must have skills</b>
      <ul>
        {job.key_skills
          ? job.key_skills.map((keySkill, keySkillIndex) => {
              return <li key={keySkillIndex}>{keySkill.name}</li>;
            })
          : null}
      </ul>
      <b>Languages needed</b>
      <ul>
        {job.languages
          ? job.languages.map((language, languagesIndex) => {
              return <li key={languagesIndex}>{language.name}</li>;
            })
          : null}
      </ul>
    </>
  ) : (
    <Col
      className="bg-orange color-palette"
      style={{ borderRadius: "5px", padding: "15px", fontWeight: "bold" }}
    >
      You must login to see details!
    </Col>
  );
};
export default JobDetails;
