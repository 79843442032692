import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faMoneyBill,
  faDraftingCompass,
  faUserSecret,
} from "@fortawesome/free-solid-svg-icons";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const StatusIcons = ({ payed, draft, subscription, privateButton }) => {
  const draftTip = (
    <Tooltip id="draft">
      <strong>Draft</strong>
    </Tooltip>
  );
  const payedTip = (
    <Tooltip id="draft">
      <strong>Payed</strong>
    </Tooltip>
  );

  const notPayedTip = (
    <Tooltip id="draft">
      <strong>Not Payed</strong>
    </Tooltip>
  );

  const privateTip = (
    <Tooltip id="draft">
      <strong>Private</strong>
    </Tooltip>
  );

  const subscriptionTip = (
    <Tooltip id="draft">
      <strong>This is a subscription</strong>
    </Tooltip>
  );

  const PayedButton = (payed) => {
    return (
      <>
        {payed.payed === true ? (
          <OverlayTrigger placement="left" overlay={payedTip}>
            <span className="badge badge-success mr-1">
              <FontAwesomeIcon icon={faMoneyBill} />
            </span>
          </OverlayTrigger>
        ) : (
          <OverlayTrigger placement="left" overlay={notPayedTip}>
            <span className="badge badge-danger mr-1">
              <FontAwesomeIcon icon={faMoneyBill} />
            </span>
          </OverlayTrigger>
        )}
      </>
    );
  };

  return (
    <>
      {typeof payed !== "undefined" && draft !== true ? (
        <PayedButton payed={payed} />
      ) : null}
      {draft ? (
        <OverlayTrigger placement="left" overlay={draftTip}>
          <span className="badge badge-warning mr-1">
            <FontAwesomeIcon icon={faDraftingCompass} />
          </span>
        </OverlayTrigger>
      ) : null}
      {subscription === true ? (
        <OverlayTrigger placement="left" overlay={subscriptionTip}>
          <span className="badge badge-primary mr-1">
            <FontAwesomeIcon icon={faClock} />
          </span>
        </OverlayTrigger>
      ) : null}
      {privateButton === true ? (
        <OverlayTrigger placement="left" overlay={privateTip}>
          <span className="badge badge-secondary">
            <FontAwesomeIcon icon={faUserSecret} />
          </span>
        </OverlayTrigger>
      ) : null}
    </>
  );
};

export default StatusIcons;
