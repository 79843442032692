import Table from "../../../../../layout/Tables/Table";
import KeyValueTableRow from "../../../../../layout/Tables/KeyValueTableRow";
import { useState } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ContactForm from "../Form/ContactForm";
import ErrorPopup from "../../../../../layout/ErrorPopup";
import CrmConfig from "@serie3/freelancesplaceapi/Config/Crm/CrmConfig";

const Contacts = ({ contactData, companyId, setContactData, personId }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [editContact, setEditContact] = useState(null);
  const [showError, setShowError] = useState(false);

  const showErrorFunction = () => {
    setShowError(true);
  }

  return (
    <>
      <ErrorPopup setShowError={setShowError} showError={showError}>
        ... Sorry! Something went wrong
        <br />
        <br />
        <strong>
          If you think this is a bug and we screwed up plz contact support!
        </strong>
      </ErrorPopup>
      <Row>
        <Col>
          <Form>
            <ContactForm
              showEdit={showEdit}
              setShowEdit={setShowEdit}
              editContact={editContact}
              companyId={companyId}
              personId={personId}
              dataList={contactData}
              setContactData={setContactData}
              showErrorFunction={showErrorFunction}
            />
          </Form>

          <Table>
            <tbody>
              {contactData
                ? contactData.map((contact, index) => {
                    return (
                      <KeyValueTableRow
                        index={index}
                        key={index}
                        keyValueObject={contact}
                        setShowEdit={setShowEdit}
                        setEditFunction={setEditContact}
                        setKeyValueData={setContactData}
                        keyValueDataList={contactData}
                        showErrorFunction={showErrorFunction}
                        deleteUrl={CrmConfig.contactData.endpoints.delete}
                      />
                    );
                  })
                : null}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col className="col-sm-12 text-end mb-4 mt-2">
          <Button
            onClick={() => {
              setEditContact(null);
              setShowEdit(true);
            }}
          >
            Add Position &nbsp;
            <FontAwesomeIcon icon={faAdd} />
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Contacts;
