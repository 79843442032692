import { Button, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import TimetrackingFormView from "../../../../timetracking/content/TimetrackingFormView";
import SaveButton from "../../../../../../layout/Buttons/SaveButton";
import { useFormik } from "formik";
import listHandler from "@serie3/common/Domain/listHandler";
import TimetrackingForm from "@serie3/freelancesplaceapi/Form/Timetracking/TimetrackingForm";
import TasksTable from "../../../../timetracking/content/TasksTable";
import TaskView from "../../../../timetracking/content/TaskView";
import ProjectTaskForm from "@serie3/freelancesplaceapi/Form/Project/Project/ProjectTaskForm";
import crudApi from "@serie3/common/api/crudApi";
import ProjectConfig from "@serie3/freelancesplaceapi/Config/Projects/ProjectConfig";
import getSettings from "@serie3/common/config/settings";
import PaymentModal from "../../../../../../layout/Payment/PaymentModal";
import handleResponse from "@serie3/freelancesplaceapi/Domain/Projects/HandleTimetracking";
import {
  PrintButton,
  StartSelectionButton,
} from "../../../../timetracking/Shared/TimetrackingButtons";

const Timetracking = ({ tasks, setTasks, projectId, addTimetracking, projectName }) => {
  const [showView, setShowView] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showError, setShowError] = useState(false);
  const [editTask, setEditTask] = useState(null);
  const [viewTask, setViewTask] = useState();
  const [showPayModal, setShowPayModal] = useState(false);
  const [settings, setSettings] = useState();
  const [selected, setSelected] = useState([]);
  const [startSelection, setStartSelection] = useState(false);

  const { id } = useParams();
  const projectTaskForm = ProjectTaskForm.createProjectTaskForm(
    addTimetracking,
    projectId,
    setShowPayModal
  );
  const projectTaskFormik = useFormik(projectTaskForm);

  const timetrackingForm = TimetrackingForm.createTimetrackingForm(
    tasks,
    setTasks,
    setShowError,
    setShowForm,
    setShowPayModal
  );

  const formik = useFormik(timetrackingForm);

  const AddButton = () => {
    return (
      <Button
        className="success"
        onClick={() => {
          setEditTask(null);
          setShowForm(true);
        }}
      >
        Add &nbsp;
        <FontAwesomeIcon icon={faAdd} />
      </Button>
    );
  };

  const Save = () => {
    return (
      <SaveButton
        handleClick={(e) => {
          if (editTask === null) {
            projectTaskFormik.values.task = formik.values;
            projectTaskFormik.handleSubmit();
          } else {
            formik.handleSubmit();
          }
          e.preventDefault();
          setShowForm(false);
        }}
      />
    );
  };

  useEffect(() => {
    setSettings(getSettings());
  }, []);

  useEffect(() => {
    setSelected([]);
    setStartSelection(false);
  }, [id]);

  const unlinkElement = async (item) => {
    const response = await crudApi.del(
      ProjectConfig.endpoints.tasks.delete,
      item.id
    );
    if (response.status === 200) {
      const task = response.data.task;
      const tmpList = listHandler.deleteWithoutCall(task, tasks);
      setTasks([...tmpList]);
    }
  };
  const editTaskFunction = (task) => {
    setEditTask(task);
    setShowForm(true);
  };

  const viewTaskFunction = (task) => {
    setViewTask(task);
    setShowView(true);
  };

  const addDefaultTask = async () => {
    const response = await crudApi.justGet(
      ProjectConfig.endpoints.tasks.addDefault + projectId
    );
    handleResponse(response, tasks, setTasks);
  };

  return (
    <>
      {settings && (
        <PaymentModal
          show={showPayModal}
          setShow={setShowPayModal}
          priceTableId={settings.pricingTables.projects}
        />
      )}
      <TaskView show={showView} setShow={setShowView} task={viewTask} />
      <TimetrackingFormView
        showEdit={showForm}
        setShowEdit={setShowForm}
        dataList={tasks}
        editTask={editTask}
        setDataList={setTasks}
        Save={Save}
        formik={formik}
      />
      <Row>
        <Col>
          {selected.length > 0 ? (
            <PrintButton
              endpoint={ProjectConfig.endpoints.tasks.print + projectId}
              selected={selected}
              setSelected={setSelected}
              projectName={projectName}
            />
          ) : null}
        </Col>
        <Col className="text-end">
          <AddButton />
          &nbsp;
          <StartSelectionButton
            startSelection={startSelection}
            setStartSelection={setStartSelection}
            setSelected={setSelected}
          />
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <TasksTable
            tasks={tasks}
            deleteTaskFunction={unlinkElement}
            viewTaskFunction={viewTaskFunction}
            editTaskFunction={editTaskFunction}
            addDefault={addDefaultTask}
            setShowPayModal={setShowPayModal}
            selected={selected}
            setSelected={setSelected}
            startSelection={startSelection}
          />
        </Col>
      </Row>
    </>
  );
};
export default Timetracking;
