import { useState, useEffect } from "react";
import MainSection from "./Components/MainSection";
import Header from "./Components/Header";
import Features from "./Components/Features";
import icon1 from "@serie3/freelancesplaceapi/Assets/img/Landingpages/icon1.png";
import icon2 from "@serie3/freelancesplaceapi/Assets/img/Landingpages/icon2.png";
import icon3 from "@serie3/freelancesplaceapi/Assets/img/Landingpages/icon3.png";
import icon4 from "@serie3/freelancesplaceapi/Assets/img/Landingpages/icon4.png";
import Feature from "./Components/Feature";
import { Row } from "react-bootstrap";
import Join from "./Components/Join";
import Video from "./Components/Video";
import Signup from "./Components/Signup";
import LandingpageFooter from "./Components/LandingpageFooter";
import RegisterModal from "../../signup/RegisterModal";

const ApplicationTracker = () => {
  const [showRegister, setShowRegister] = useState(false);

  useEffect(() => {
    const location = window.location.hostname;
    if (location === "demo.freelancersplace.net") {
      window.location.href = "https://www.javascripttutorial.net/";
    }
  }, []);

  const headerTitle =
    "Make your project applications and job hunt a lot smoother with our application tracker";
  const headerText =
    "The freelancersplace application tracker lets you keep an eye on your current project applications and not lose track.";
  const headerInsurance =
    "The application tracker is free and we never share your data with anybody!";

  const mainTitle = "Every Freelancer should use this";

  const MainFirstfeature = () => {
    return (
      <>
        <h4 className="landingpage blueColorText">Chrome Plugin</h4>
        <p>
          Found a project online? Bookmark it with our <a href="https://chromewebstore.google.com/detail/freelancersplace-applicat/hbjjmgpmklhbkamejejbobggmbineadh?hl=de&utm_source=ext_sidebar">
            Chrome plugin
          </a>{" "}
          and start applying.
        </p>
      </>
    );
  };

  const MainSecondFeature = () => {
    return (
      <>
        <h4 className="landingpage blueColorText">Quick overview</h4>
        <p>Never lose track, all your project applications in one spot.</p>
      </>
    );
  };

  const videoTitle = "Screencast";
  const videoMainTitle = "See the application tracker in action";
  const videoText =
    "Discover how our Application Tracker can streamline your workflow. Watch our detailed screencast for an in-depth look";
  const videoLink =
    "https://www.youtube.com/embed/kK_zTULDhgQ?si=RIpy47t8z8gyLW0-";

  return (
    <>
      <RegisterModal
        show={showRegister}
        setShow={setShowRegister}
        showLoginForm={false}
      />
      <Header
        setShowRegister={setShowRegister}
        title={headerTitle}
        text={headerText}
        insurance={headerInsurance}
      />
      <MainSection
        title={mainTitle}
        FirstFeature={MainFirstfeature}
        SecondFeature={MainSecondFeature}
      />
      <Features>
        <Row>
          <Feature
            icon={icon1}
            title={"Different application states"}
            text={
              "Always know what the next step is or whose turn it is in the recruiting process. Get reminded when it's time to act (coming soon)"
            }
          />
          <Feature
            icon={icon2}
            title={"Import different types (coming soon)"}
            text={
              "Import from different sources like E-Mails or create your own complete custom application "
            }
          />
        </Row>
        <Row>
          <Feature
            icon={icon3}
            title={"Manage CV & Job opportunities (coming soon)"}
            text={
              "Apply with job specified CVs to maximize your chances, track applications and get reminders if needed."
            }
          />
          <Feature
            icon={icon4}
            title={"Convert applications into projects (coming soon)"}
            text={
              "Once you have been accepted for a job, you can easily convert it into a project and manage it"
            }
          />
        </Row>
      </Features>
      <Join />
      <Video
        title={videoTitle}
        mainTitle={videoMainTitle}
        mainText={videoText}
        videoLink={videoLink}
      />
      <Signup />
      <LandingpageFooter />
    </>
  );
};

export default ApplicationTracker;
