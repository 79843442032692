import { Row, Col, Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyBill,
  faUsers,
  faUser,
  faClock,
  faTasks,
  faBullseye,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";

const Features = () => {
  const [location, setLocation] = useState();
  useEffect(() => {
    const tmpLocation = window.location.hostname;
    setLocation(tmpLocation);
  }, []);

  return location !== "demo.freelancersplace.net" ? (
    <Container fluid>
      <Row className="opacity-bg" style={{ backgroundImage: "none" }}>
        <Row>
          <Col sm={12}>
            <h4> </h4>
          </Col>
        </Row>
        <Row className="opacity-text">
          <Col md={2} sm={12} style={{ marginBottom: "0.5rem" }}>
            <FontAwesomeIcon
              icon={faMoneyBill}
              style={{ marginBottom: "1rem" }}
            />
            <br />
            &nbsp; Billing
          </Col>
          <Col md={2} sm={12} style={{ marginBottom: "0.5rem" }}>
            <FontAwesomeIcon icon={faUsers} style={{ marginBottom: "1rem" }} />
            <br />
            CRM
          </Col>
          <Col md={2} sm={12} style={{ marginBottom: "0.5rem" }}>
            <FontAwesomeIcon icon={faUser} style={{ marginBottom: "1rem" }} />
            <br />
            CVs
          </Col>
          <Col md={2} sm={12} style={{ marginBottom: "0.5rem" }}>
            <FontAwesomeIcon icon={faClock} style={{ marginBottom: "1rem" }} />
            <br />
            Timetracking
          </Col>
          <Col md={2} sm={12} style={{ marginBottom: "0.5rem" }}>
            <FontAwesomeIcon icon={faTasks} style={{ marginBottom: "1rem" }} />
            <br />
            Projects
          </Col>
          <Col md={2} sm={12} style={{ marginBottom: "0.5rem" }}>
            <a href="/applicationtracker" style={{color: "white", textDecoration: "none"}}>
              <FontAwesomeIcon icon={faBullseye} style={{ marginBottom: "1rem" }} />
              <br />
              Applications
            </a>
          </Col>
        </Row>
      </Row>
    </Container>
  ) : (
    <Container fluid>
      <Row className="opacity-bg">
        <Row>
          <Col>
            <h4>Demo System Users </h4>
          </Col>
        </Row>
        <Row style={{}}>
          <Col>
            <span style={{ marginRight: "1.5rem" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                style={{ marginRight: "0.5rem" }}
              >
                <circle cx="7" cy="7.5" r="7" fill="#FFFFFF" />
              </svg>
              <span
                style={{
                  fontFamily: "Sofia Sans",
                  fontSize: "1.125rem",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "100%",
                }}
              >
                demouser_mueller@freelancersplace.net
              </span>
            </span>
            <span style={{ marginRight: "1.5rem" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                style={{ marginRight: "0.5rem" }}
              >
                <circle cx="7" cy="7.5" r="7" fill="#FFFFFF" />
              </svg>
              <span
                style={{
                  fontFamily: "Sofia Sans",
                  fontSize: "1.125rem",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "100%",
                }}
              >
                leon_schmidt@freelancersplace.net
              </span>
            </span>
            <br />
            <br />
            <span
              style={{
                fontFamily: "Sofia Sans",
                fontSize: "1.125rem",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "100%",
              }}
            >
              Password: password
            </span>
          </Col>
        </Row>
        <Row style={{ color: "red" }}>
          <Col>
            Data in this system is reset every 30 minutes! File upload is
            disabled on the demo system
          </Col>
        </Row>
      </Row>
    </Container>
  );
};

export default Features;
