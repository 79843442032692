import { useState, useEffect } from "react";
import TicketTable from "../../../crm/Shared/Components/TicketTable";

const TicketList = ({ tickets = [] }) => {
  const [onHold, setOnHold] = useState([]);
  const [inProgress, setInProgress] = useState([]);
  useEffect(() => {
    const tmpInProgress = new Array();
    const tmpOnHold = new Array();
    if (tickets.length > 0) {
      tickets.map((ticket) => {
        switch (ticket.action.status) {
          case "In_Progress":
            tmpInProgress.push(ticket);
            break;
          case "On_Hold":
            tmpOnHold.push(ticket);
            break;
          default:
            break;
        }
      });
      setInProgress(tmpInProgress);
      setOnHold(tmpOnHold);
    }
  }, [tickets]);

  return (
    <>
      <TicketTable label={"In Progress"} tickets={inProgress} />
      <br />
      <TicketTable label={"On Hold"} tickets={onHold} />
    </>
  );
};

export default TicketList;
