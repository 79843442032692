import TextArea from "../../../../../../layout/Form/Fields/TextArea";
import { Col, Row } from "react-bootstrap";
import KeyValueForm from "@serie3/freelancesplaceapi/Form/shared/KeyValueForm";
import KeyValue from "@serie3/freelancesplaceapi/Entity/shared/KeyValue";
import { useFormik } from "formik";
import SaveButton from "../../../../../../layout/Buttons/SaveButton";
import NewModal from "../../../../documents/modals/NewModal";
import { useState } from "react";
import BigErrorPopup from "../../../../../../layout/Popups/BigErrorPopup";

const CommentsFormView = ({
  showEdit,
  setShowEdit,
  editKeyValueData,
  dataList,
  setDataList,
  onShow,
  newEndpoint,
  editEndpoint,
}) => {
  const [showError, setShowError] = useState(false);

  const editKeyValueForm = KeyValueForm.createKeyValueForm(
    dataList,
    setDataList,
    setShowError,
    setShowEdit,
    newEndpoint,
    editEndpoint
  );
  const formik = useFormik(editKeyValueForm);

  const Save = () => {
    return (
      <SaveButton
        handleClick={(e) => {
          formik.handleSubmit(e);
          e.preventDefault();
        }}
      />
    );
  };

  return (
    <>
    <NewModal
        show={showEdit}
        setShow={setShowEdit}
        ExtraButton={Save}
        modalType="modal-dialog-scrollable"
        onShow={() => {
          editKeyValueData
            ? formik.setValues(KeyValueForm.setEditData(editKeyValueData))
            : formik.setValues(KeyValue);
          formik.setFieldValue("key_name", "comment");
          onShow(formik);
        }}
      >
        <Row>
          <Col>
            <TextArea
              formik={formik}
              label={"Comment"}
              valueName={"value"}
              style={{ marginBottom: "10px" }}
              rows={25}
            />
          </Col>
        </Row>
      </NewModal>
    </>
  );
};

export default CommentsFormView;
