import Card from "../Cards/Card";
import EllipsisVerticalToggle from "../Dropdowns/EllipsisVerticalToggle";
import { Dropdown } from "react-bootstrap";
import { faTrash, faPen, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const KanbanCard = ({
  index,
  action,
  setEditAction,
  setShowForm,
  setViewAction,
  deleteData,
  setDataList,
  dataList
}) => {
  let priority;
  switch (action.priority) {
    case "Highest":
      priority = "danger";
      break;
    case "High":
      priority = "warning";
      break;
    case "Normal":
      priority = "sucess";
      break;
    case "Low":
      priority = "info";
      break;
    case "Nicetohave":
    default:
      priority = "secondary";
  }

  const cardType = "card-" + priority + " card-outline";

  const CardTools = () => {
    return (
      <Dropdown
        style={{ float: "right", maxHeight: "16px", marginLeft: "5px" }}
      >
        <EllipsisVerticalToggle />
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              setViewAction(action);
            }}
          >
            <small>
              <FontAwesomeIcon icon={faEye} />
              &nbsp; View
            </small>
          </Dropdown.Item>
          <div className="dropdown-divider" />
          <Dropdown.Item
            onClick={() => {
              setEditAction(action);
              setShowForm(true);
            }}
          >
            <small>
              <FontAwesomeIcon icon={faPen} />
              &nbsp; Edit
            </small>
          </Dropdown.Item>
          <div className="dropdown-divider" />
          <Dropdown.Item onClick={() => {deleteData(action.id, index, setDataList, dataList )}}>
            <small>
              <FontAwesomeIcon icon={faTrash} />
              &nbsp; Delete
            </small>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <Card title={action.title} className={cardType} CardTools={CardTools}>
      <p> {action.beschreibung}</p>
      <p> {action.tags ? action.tags.map((
        tag, index) => {
           return (<span  key={tag.id} className="badge badge-success" style={{marginRight: "5px"}}>{tag.name}</span>)
        }): null }</p>
    </Card>
  );
};

export default KanbanCard;
