import TabGroupLeftVerticalBody from "../../../../../../layout/Tabs/LeftVertical/TabGroupLeftVerticalBody";
import TabGroupLeftVerticalHeader from "../../../../../../layout/Tabs/LeftVertical/TabGroupLeftVerticalHeader";
import TabGroupLeftVerticalNavlink from "../../../../../../layout/Tabs/LeftVertical/TabGroupLeftVerticalNavlink";
import TabGroupLeftTab from "../../../../../../layout/Tabs/LeftVertical/TabGroupLeftTab";
import { Row } from "react-bootstrap";
import {
  faCircleArrowRight,
  faCircleArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import ProjectFinanceWrapper from "./SubTabs/Finance/ProjectFinanceWrapper";
import { useEffect, useState } from "react";

const Finance = ({ formik, finance }) => {
  const [financeOut, setFinanceOut] = useState([]);
  const [financeIn, setFinanceIn] = useState([]);

  const verticalPrefix = "project-finances";
  
  useEffect(() => {
    function sortObjectsByTypAndDirection(objects) {
      const sortedObjects = [...objects].sort((a, b) => {
        if (a.typ < b.typ) return -1;
        if (a.typ > b.typ) return 1;
        if (a.eingang === false) return -1;
        if (a.eingang === true) return 1;
        return 0;
      });

      const result = { in: [], out: [] };

      for (const obj of sortedObjects) {
        if (obj.eingang === true) {
          const inArray = result["in"].find((arr) => arr.typ === obj.typ);
          if (inArray) {
            inArray.data.push(obj);
          } else {
            result["in"].push({ typ: obj.typ, data: [obj] });
          }
        } else {
          const outArray = result["out"].find((arr) => arr.typ === obj.typ);
          if (outArray) {
            outArray.data.push(obj);
          } else {
            result["out"].push({ typ: obj.typ, data: [obj] });
          }
        }
      }
      if (result.out.length > 0 && result.out[0].data.length > 0) {
        setFinanceOut(result.out);
        setFinanceIn(result.in);
      }
    }

    sortObjectsByTypAndDirection(finance);
  }, [finance]);

  return (
    <Row>
      <TabGroupLeftVerticalHeader>
        <TabGroupLeftVerticalNavlink
          verticalPrefix={verticalPrefix}
          name={"outgoing"}
          title={"Outgoing"}
          icon={faCircleArrowRight}
          show={true}
        />
        <TabGroupLeftVerticalNavlink
          verticalPrefix={verticalPrefix}
          name={"incoming"}
          title={"Incoming"}
          icon={faCircleArrowLeft}
        />
      </TabGroupLeftVerticalHeader>
      <TabGroupLeftVerticalBody>
        <TabGroupLeftTab
          verticalPrefix={verticalPrefix}
          name={"outgoing"}
          show={true}
        >
          <ProjectFinanceWrapper formik={formik} finance={financeOut} direction={"out"}/>
        </TabGroupLeftTab>
        <TabGroupLeftTab
          verticalPrefix={verticalPrefix}
          name={"incoming"}
        >
          <ProjectFinanceWrapper formik={formik} finance={financeIn} direction={"in"} />
        </TabGroupLeftTab>
      </TabGroupLeftVerticalBody>
    </Row>
  );
};

export default Finance;
