const errorPageStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    margin: 0,
    backgroundColor: '#f95b5b',
  };
  
  const errorContainerStyles = {
    textAlign: 'center',
  };
  
  const headingStyles = {
    color: '#fff',
    fontSize: '4em',
    marginBottom: '20px',
  };
  
  const paragraphStyles = {
    color: '#fff',
    fontSize: '1.5em',
  };

const GeneralServerError = () => {

    return (
        <div style={errorPageStyles}>
          <div style={errorContainerStyles}>
            <h1 style={headingStyles}>500 Error</h1>
            <p style={paragraphStyles}>We appologize! Something went terribly wrong here. </p>
            <p style={paragraphStyles}>If this error continues plz contact support! </p>
          </div>
        </div>
      );
};

export default GeneralServerError;
