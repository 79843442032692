import NewModal from "../../../../../../documents/modals/NewModal";
import crudApi from "@serie3/common/api/crudApi";
import { useState } from "react";
import DocumentCard from "../../../../../../documents/DocumentCard";
import { Row, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

/** THIS IS USED FOR THE MODAL POPUP TO DISPLAY ALL AVAILABLE FILES */
const DocumentsOverview = ({ projectFormik, show, setShow }) => {
  const [data, setData] = useState([]);

  const loadData = async () => {
    const response = await crudApi.getAll("/documents/");
    setData(response.data.documents);
  };

  const addDocument = (document) => {
    const newDocumentLink = { id: document.id };
    projectFormik.values["documents"].push(newDocumentLink);
    projectFormik.handleSubmit();
  };

  const Buttons = ({ document }) => {
    return (
      <Button
        variant="primary"
        size="sm"
        className="mr-1"
        onClick={() => addDocument(document)}
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>
    );
  };

  return (
    <NewModal show={show} setShow={setShow} onShow={loadData}>
      <Row>
        {data
          ? data.map((document, index) => {
              return (
                <DocumentCard
                  key={document.id}
                  document={document}
                  setShow={setShow}
                  CardButtons={() => {
                    return <Buttons document={document} />;
                  }}
                />
              );
            })
          : null}
      </Row>
    </NewModal>
  );
};

export default DocumentsOverview;
