import listHandler from "@serie3/common/Domain/listHandler";

const addElement = (formik, dataReference, valueName, dontSend = false) => {
  const namedElement = new Object();
  if (
    typeof dataReference !== "undefined" && 
    dataReference.current.value !== ""
  ) {
    namedElement.name = dataReference.current.value;
    formik.values[valueName].push(namedElement);
    if(dontSend !== true){
      formik.handleSubmit();
    }
    dataReference.current.value = "";
  }
};

const deleteElement = (namedElement, valueName, setDataList, formik, dontSend = false) => {
  const itemIndex = formik.values[valueName].findIndex(
    (obj) => obj.id === namedElement.id
  );
  listHandler.deleteFromList(
    itemIndex,
    undefined,
    setDataList,
    formik.values[valueName],
    null
  );

  if(dontSend !== true){
    formik.handleSubmit();
  }
};

export default { addElement, deleteElement };
