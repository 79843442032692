import { Row, Col, Table } from "react-bootstrap";
import CompleteDropdown from "../../../layout/Dropdowns/CompleteDropdown";

const WorkexperienceTimelineItem = ({
  workexperience,
  deleteAction,
  editAction,
}) => {
  return (
    <div>
      <i className="far fa-clock bg-primary"></i>
      <div className="timeline-item">
        <span className="time">
          <CompleteDropdown
            deleteAction={deleteAction}
            editAction={editAction}
          />
        </span>
        <h3 className="timeline-header">
          <span
            style={{
              color: "#0d6efd",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            {workexperience.position_name}
          </span>
          <b> &nbsp;&#124; &nbsp;@{workexperience.company}</b>
        </h3>
        <div className="timeline-body">
          <Table striped hover size="sm">
            <tbody>
              <tr>
                <td style={{ maxWidth: "20%" }}>
                  <b>Headcount:</b>
                </td>
                <td>{workexperience.headcount}</td>
              </tr>
              <tr>
                <td style={{ maxWidth: "20%" }}>
                  <b>Description:</b>
                </td>
                <td>{workexperience.description}</td>
              </tr>

              <tr>
                <td style={{ maxWidth: "20%" }}>
                  <b>Tools:</b>
                </td>
                <td>{workexperience.type_of_business}</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default WorkexperienceTimelineItem;
