import { React, useState } from "react";
import { Row, Col } from "react-bootstrap";
import BillingHeaderRight from "../../outgoing/SubComponents/BillHeaderRight";
import BillingHeaderLeft from "../../outgoing/SubComponents/BillHeaderLeft";
import Checkboxes from "../../shared/Form/FormFields/Checkboxes";
import Fields from "../../shared/Form/FormFields/Fields";
import SubmitButtons from "../../shared/Form/FormFields/Submitbuttons";
import DocumentConnectionButtons from "../../../../../../layout/Documents/DocumentConnectionButtons";

const BillingData = ({ formik, bill, setShowNew, address, setAddress }) => {
  const [openDebitDate, setOpenDebitDate] = useState(false);
  const [contact, setContact] = useState();
  return (
    <Col className="col-sm-5">
      <Row>
        <Col>
          <div className="card">
            <div
              className="card-header d-flex"
              style={{ alignItems: "end", flexDirection: "row" }}
            >
              <h2 className="card-title">General</h2>
              <div className="card-tools ml-auto">
                <DocumentConnectionButtons
                  showUpload={setShowNew}
                  showLink={setShowNew}
                />
              </div>
            </div>
            <div className="card-body">
              <BillingHeaderLeft
                bill={bill}
                contact={contact}
                setContact={setContact}
                setAddress={setAddress}
                addressJson={address}
                formik={formik}
              />
              <Row>
                <Col>
                  <Fields.Subject formik={formik} />
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Fields.Description formik={formik} />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="card">
            <div className="card-header">
              <h3 className="card-title"> Numbers </h3>
            </div>
            <div className="card-body">
              <Row>
                <Col>
                  <Fields.Net formik={formik} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Fields.Gross formik={formik} />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="card">
            <div className="card-header">
              <h3 className="card-title"> Dates </h3>
            </div>
            <div className="card-body">
              <BillingHeaderRight formik={formik} className={"rightText"} />
            </div>
          </div>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <div className="card">
            <div className="card-header">
              <h3 className="card-title"> Checks </h3>
            </div>
            <div className="card-body">
              <Row>
                <Col>
                  <Checkboxes.Draft formik={formik} formClass={"form-inline"} />
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Checkboxes.Payed formik={formik} formClass={"form-inline"} />
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Checkboxes.Private
                    formik={formik}
                    formClass={"form-inline"}
                  />
                </Col>
              </Row>
              <br />
              <Row>
                <Col>
                  <Checkboxes.Debited
                    formik={formik}
                    formClass={"form-inline"}
                    setOpenDebitDate={setOpenDebitDate}
                    openDebitDate={openDebitDate}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Fields.DebitedAt openDebitDate={openDebitDate} />
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      <SubmitButtons formik={formik} bill={bill} />
    </Col>
  );
};

export default BillingData;
