import HeaderNav from "./navbars/Header";
import SideBar from "./navbars/Sidebar";
import FinanceRoutes from "./modules/finance/FinanceRoutes";
import CrmRoutes from "./modules/crm/CrmRoutes";
import { Routes, Route } from "react-router-dom";
import ContentWrapper from "../layout/ContentWrapper";
import DocumentsRoutes from "./modules/documents/DocumentsRoutes";
import ProfilesRoutes from "./modules/profiles/ProfilesRoutes";
import ProjectRoutes from "./modules/projects/Projects/ProjectRoutes";
import JobRoutes from "./modules/projects/Jobs/JobRoutes";
import TimetrackingRoutes from "./modules/timetracking/TimetrackingRoutes";
import MainDashboard from "./modules/Dashboard/MainDashboard";
import crudApi from "@serie3/common/api/crudApi";
import Settingsconfig from "@serie3/freelancesplaceapi/Config/Settings/SettingsConfig";
import { useEffect, useState } from "react";
import SettingsContext from "@serie3/common/auth/SettingsContext";
import UserProfile from "./modules/user/UserProfile";
import SystemProfileContext from "@serie3/common/user/SystemProfileContext";
import ProfileConfig from "@serie3/freelancesplaceapi/Config/Profiles/ProfileConfig";

const Backend = () => {
  const [settings, setSettings] = useState(null);
  const [systemProfile, setSystemProfile] = useState(null);

  useEffect(() => {
    async function fetchSettings() {
      const response = await crudApi.getAll(Settingsconfig.endpoints.get);
      if (response.status === 200) {
        if (
          typeof response.data.Settings !== "undefined" &&
          response.data.Settings.length > 0
        ) {
          setSettings(response.data.Settings[0]);
        }
      }
    }
    async function fetchSystemProfile() {
      const response = await crudApi.getAll(
        ProfileConfig.endpoints.systemProfile
      );
      if (response.status === 200) {
        setSystemProfile(response.data);
      }
    }
    fetchSettings();
    fetchSystemProfile();

    const loadStripe = () => { 
      const script = document.createElement('script');

      script.src = "https://js.stripe.com/v3/pricing-table.js";
      script.async = true;
    
      document.body.appendChild(script);
    }

    loadStripe();
  }, []);

  return settings ? (
    <>
      <SettingsContext.Provider value={{ settings, setSettings }}>
        <SystemProfileContext.Provider
          value={{ systemProfile, setSystemProfile }}
        >
          <HeaderNav />
          <SideBar />
          <ContentWrapper>
            <Routes>
              <Route path="/" element={<MainDashboard />} />
              <Route path="/finance/*" element={<FinanceRoutes />} />
              <Route path="/userprofile/*" element={<UserProfile />} />
              <Route path="/crm/*" element={<CrmRoutes />} />
              <Route path="/profile/*" element={<ProfilesRoutes />} />
              <Route path="/documents/*" element={<DocumentsRoutes />} />
              <Route path="/timetracking/*" element={<TimetrackingRoutes />} />
              <Route path="/outsourcing/*" element={<JobRoutes />} />
              <Route path="/project/*" element={<ProjectRoutes />} />
            </Routes>
          </ContentWrapper>
        </SystemProfileContext.Provider>
      </SettingsContext.Provider>
    </>
  ) : null;
};

export default Backend;
