import ProjectAction from "../../../Entity/Project/ProjectAction";
import CrudApi from "@serie3/common/api/crudApi";
import ProjectConfig from "../../../Config/Projects/ProjectConfig";
import projectActionValidationSchema from "../../Crm/Yup/ActionValidation";

const createProjectActionForm = (addTickets, projectId, setShowPayModal, setEditAction) => {
  const onSubmit = async (values) => {
    values.project = { id: projectId };
    let payload = JSON.stringify(values, null, 2);
    let response = "";
    if (values.id > 0) {
      response = await CrudApi.edit(
        ProjectConfig.endpoints.actions.edit,
        payload
      );
    } else {
      response = await CrudApi.createNew(
        ProjectConfig.endpoints.actions.new,
        payload,
        setShowPayModal
      );
    }
    if (response.status !== 200) {
    } else {
      setEditAction(response.data.action);
      addTickets(response.data);
    }
  };

  return {
    initialValues: ProjectAction,
    //validationSchema: projectActionValidationSchema,
    onSubmit: (values, actions) => {
      onSubmit(values, actions);
    },
  };
};

const setEditData = (editData) => {
  const date = new Date();
  let Project = {
    id: editData.id,
    action: editData.action,
    project_pos: editData.project_pos,
  };

  return Project;
};

export default { createProjectActionForm, setEditData };
