import React, { useState } from "react";
import { Button, Modal, Collapse } from "react-bootstrap";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConnectedFiles from "../../../../layout/Documents/ConnectedFiles";

const BillView = ({ show, setShow, children, bill, direction }) => {
  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => setShow(false)}
      dialogClassName="modal-90w"
    >
      <Modal.Header closeButton={true}>
        <Modal.Title id="Billview">
          {typeof bill !== "undefined"
            ? bill.typ +
              " number: " +
              (typeof bill.rechnungs_nummer !== "undefined"
                ? bill.rechnungs_nummer
                : "")
            : null}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
        <ConnectedFiles entity={bill} show={true} endpointAll={"/document/finance/"} endpointUnlink={"/document/finance/"} />
      </Modal.Body>
      <Modal.Footer>
        <Button>
          <FontAwesomeIcon icon={faDownload} />
          &nbsp; Generate PDF
        </Button>
        <Button onClick={() => setShow(false)}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BillView;
