import { Row, Col, Button } from "react-bootstrap";
import {
  faCircleArrowDown,
  faCircleArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

import downloadHandler from "@serie3/freelancesplaceapi/Domain/Shared/downloadHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PDFModal from "../../../../../../../layout/Popups/PDFModal";

const SubmitButtons = ({ formik, bill }) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const setKundeOrPerson = () => {
    if (formik.values.hasOwnProperty("kunde")) {
      const customer = formik.getFieldProps("kunde").value;
      if (typeof customer !== "undefined" && customer !== null) {
        formik.setFieldValue("kunde", null);
        formik.setFieldValue("kunde", { id: customer.id });
        formik.setFieldValue("person", null);
      }
    }

    if (formik.values.hasOwnProperty("person")) {
      const person = formik.getFieldProps("person").value;
      if (typeof person !== "undefined" && person !== null) {
        formik.setFieldValue("person", null);
        formik.setFieldValue("person", { id: person.id });
        formik.setFieldValue("kunde", null);
      }
    }
  };

  return bill.draft ? (
    <>
      <PDFModal showSpinner={showSpinner} setShowSpinner={setShowSpinner} />
      <Row>
        <Col className="text-end">
          <Button
            type="submit"
            onClick={(e) => {
              formik.setFieldValue("draft", true);
              formik.handleSubmit(e);
              setKundeOrPerson(bill);
              e.preventDefault();
            }}
          >
            <FontAwesomeIcon icon={faCircleArrowDown} />
            &nbsp; Save as Draft
          </Button>
          &nbsp;
          <Button
            type="submit"
            onClick={(e) => {
              setShowSpinner(true);
              formik.setFieldValue("draft", false);
              formik.setFieldValue();
              setKundeOrPerson();
              formik.handleSubmit(e);
              e.preventDefault();
              downloadHandler(
                "finance/print/" + bill.id,
                "Bill-" + bill.id + ".pdf",
                null,
                setShowSpinner
              );
            }}
          >
            <FontAwesomeIcon icon={faCircleArrowRight} />
            &nbsp; Publish
          </Button>
        </Col>
      </Row>
    </>
  ) : (
    <></>
  );
};

export default SubmitButtons;
