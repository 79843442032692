import { Row, Col, Container } from "react-bootstrap";

const Freetrial = () => {
  return (
    <Container fluid>
      <Row className="opacity-bg">
        <Row>
          <Col>
            <h4>Free trial </h4>
          </Col>
        </Row>
        <br />
        <br />
        <Row style={{ marginTop: "-50px" }}>
          <Col>
            <h3>30 day free trial</h3>
          </Col>
        </Row>
        <Row style={{ marginTop: "-50px" }}>
          <Col>
            <span style={{marginRight: "1.5rem"}}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                style={{ marginRight: "0.5rem" }}
              >
                <circle cx="7" cy="7.5" r="7" fill="#FFFFFF" />
              </svg>
              <span
                style={{
                  fontFamily: "Sofia Sans",
                  fontSize: "1.125rem",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "100%",
                }}
              >
                No strings attached
              </span>
            </span>
            <span style={{marginRight: "1.5rem"}}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                style={{ marginRight: "0.5rem" }}
              >
                <circle cx="7" cy="7.5" r="7" fill="#FFFFFF" />
              </svg>
              <span
                style={{
                  fontFamily: "Sofia Sans",
                  fontSize: "1.125rem",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "100%",
                }}
              >
                No credit card required
              </span>
            </span>
            <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              style={{ marginRight: "0.5rem" }}
            >
              <circle cx="7" cy="7.5" r="7" fill="#FFFFFF" />
            </svg>
            <span
              style={{
                fontFamily: "Sofia Sans",
                fontSize: "1.125rem",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "100%",
              }}
            >
              All Features
            </span>
            </span>
          </Col>
        </Row>
      </Row>
    </Container>
  );
};

export default Freetrial;
