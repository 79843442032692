import FreelancerserplaceNavbar from "./SubComponents/FreelancerserplaceNavbar";
import Footer from "./SubComponents/Footer";
import Welcome from "./SubComponents/Heros/Welcome";
import Features from "./SubComponents/Seperator/Features";
import Billing from "./SubComponents/Heros/Billing";
import Profiles from "./SubComponents/Heros/Profiles";
import Freetrial from "./SubComponents/Seperator/FreeTrial";
import Demosystem from "./SubComponents/Seperator/Demosystem";
import Pricing from "./SubComponents/Heros/Pricing";
import TimetrackingLandingpage from "./SubComponents/Heros/TimetrackingLandingpage";
import Projects from "./SubComponents/Heros/Projects";
import RegisterModal from "./RegisterModal";
import { useState, useEffect } from "react";

const NotloggedinNew = ({ setToken }) => {
  const [showRegister, setShowRegister] = useState(false);

  const setShowLoginDummy = () => {};

  useEffect(() => {
    if (window.location.hash === "#signup") {
      setShowRegister(true);
    }
  }, [window.location.pathname]);

  return (
    <>
      <RegisterModal
        show={showRegister}
        setShow={setShowRegister}
        setShowLogin={setShowLoginDummy}
      />
      <FreelancerserplaceNavbar setToken={setToken} />
      <Welcome setShowRegister={setShowRegister} />
      <Features />
      <Billing />
      <Profiles />
      <Freetrial />
      <Pricing setShowRegister={setShowRegister} />
      <TimetrackingLandingpage />
      <Projects />
      <Demosystem />
      <Footer />
    </>
  );
};

export default NotloggedinNew;
