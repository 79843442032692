import { Document, Page, pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";

//TODO try to fix file.data problem with txt files(Might be executed) reove src={file.data} with preview image
const DocumentImage = ({ file, style }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
  let header = file.header["content-type"];

  //THIS URL can be used for the downloadlink. Needs to be modified for non PDFs to get the real document instead of the Preview
  let url = URL.createObjectURL(file.data);
  switch (header) {
    case "image/png":
    case "image/jpeg":
      return (
        <img
          src={url}
          className="img-fluid"
          type="image/jpg"
          style={style ? style : { width: "100%", maxHeight: "250px",  marginTop: "10px", marginBottom: "10px" }}
        />
      );

    case "application/pdf":
      return (
        <div style={style ? style : { width: "100%", maxHeight: "250px", marginTop: "10px", marginBottom: "10px"}}>
          <Document file={file.data}>
            <Page pageNumber={1} />
          </Document>
        </div>
      );

    default:
      return "";
  }
};

export default DocumentImage;
