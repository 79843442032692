import { Button, Row } from "react-bootstrap";
import {
  faBuilding,
  faPerson,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import ContactCard from "../ContactCard";
import CustomModal from "../../../../layout/CustomModal";
import TabContent from "../../../../layout/Tabs/TabContent";
import TabHeader from "../../../../layout/Tabs/TabHeader";
import TabBody from "../../../../layout/Tabs/TabBody";
import TabLink from "../../../../layout/Tabs/TabLink";
import crudApi from "@serie3/common/api/crudApi";
import CrmConfig from "@serie3/freelancesplaceapi/Config/Crm/CrmConfig";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CustomerModal = ({ show, setShow, updateContact }) => {
  const [allCompanies, setAllCompanies] = useState([]);
  const [allPeople, setAllPeople] = useState([]);

  const prefix = "finance-customer-picker";

  const CardButtons = ({contact}) => {
    return (
      <Button
        variant="primary"
        size="sm"
        className="mr-1"
        onClick={() => {
          updateContact(contact);
          setShow(0);
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>
    );
  };

  const loadDataFunction = (type) => {
    switch (type) {
      case "companies":
        crudApi.getAll(
          CrmConfig.company.index.endpoint,
          setAllCompanies,
          CrmConfig.company.index.datafield
        );
        break;
      case "people":
        crudApi.getAll(
          CrmConfig.people.index.endpoint,
          setAllPeople,
          CrmConfig.people.index.datafield
        );
        break;
    }
  };

  return (
    <CustomModal setShow={setShow} show={show} title={"Choose Customer"}>
      <TabHeader style={{ width: "100%" }}>
        <TabLink
          prefix={prefix}
          name={"Companies"}
          icon={faBuilding}
          ignoreWith={true}
          style={{ minWidth: "50%" }}
          onClick={() => {
            loadDataFunction("companies");
          }}
        />
        <TabLink
          prefix={prefix}
          name={"People"}
          icon={faPerson}
          ignoreWith={true}
          style={{ minWidth: "50%" }}
          onClick={() => {
            loadDataFunction("people");
          }}
        />
      </TabHeader>

      <Row className="mb-3">
        <TabBody>
          <TabContent prefix={prefix} name={"Companies"}>
            {allCompanies && allCompanies.length > 0
              ? allCompanies.map((company, index) => {
                  return (
                    <ContactCard
                      key={index}
                      contact={company}
                      CardButtons={() => <CardButtons contact={company} />}
                      className={"col-12 col-sm-12 col-md-12"}
                    />
                  );
                })
              : "Loading"}
          </TabContent>
          <TabContent prefix={prefix} name={"People"}>
            {allPeople && allPeople.length > 0
              ? allPeople.map((person, index) => {
                  return (
                    <ContactCard
                      key={index}
                      contact={person}
                      CardButtons={() => <CardButtons contact={person} />}
                      className={"col-12 col-sm-12 col-md-12"}
                    />
                  );
                })
              : "Loading"}
          </TabContent>
        </TabBody>
      </Row>
    </CustomModal>
  );
};

export default CustomerModal;
