import { Col, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import CrmConfig from "@serie3/freelancesplaceapi/Config/Crm/CrmConfig";

import ConnectedFiles from "../../../../../layout/Documents/ConnectedFiles";
import FileDropzone from "../../../documents/modals/FileDropzone";
import DocumentConnectionButtons from "../../../../../layout/Documents/DocumentConnectionButtons";
import NewModal from "../../../../../layout/Popups/NewModal";
import ActionBasics from "../Subcomponents/ActionBasics";
import ActionsComments from "./ActionsComments";


const ActionsForm = ({ formik, editAction, setShowPayModal }) => {
  const [allTags, setAllTags] = useState([]);
  const [allComments, setAllComments] = useState([]);
  const [showFileUpload, setShowFileUpload] = useState(false);
  const [reloadConnectedFileView, setReloadConnectedFileView] = useState(0);


  useEffect(() => {
    setAllTags([...formik.values.tags]);
    if (editAction && editAction.comments && editAction.comments.length > 0) {
      setAllComments(editAction.comments);
    } else {
      setAllComments([]);
    }
  }, [formik.values.tags]);

  useEffect(() => {
    editAction && formik.setFieldValue('id', editAction.id);
  }, [editAction]);

  const viewAction = () => {};

  return (
    <>
      {editAction && typeof editAction.id !== "undefined" && (
        <NewModal show={showFileUpload} setShow={setShowFileUpload}>
          <FileDropzone
            uploadUrl={CrmConfig.tickets.endpoints.files.new + editAction.id}
            setData={() => {setReloadConnectedFileView(Math.random())}}
            setShowPayModal={setShowPayModal}
          />
        </NewModal>
      )}
      <Form>
        <ActionBasics
          formik={formik}
          allTags={allTags}
          setAllTags={setAllTags}
        />
        <br />
        {editAction && (
          <>
            <Row>
              <Col style={{ textAlign: "right" }}>
                <DocumentConnectionButtons
                  showUpload={() => {
                    setShowFileUpload(true);
                  }}
                  style={{ float: "right" }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <ConnectedFiles
                  entity={editAction}
                  show={reloadConnectedFileView}
                  endpointAll={CrmConfig.tickets.endpoints.files.getAll}
                  endpointUnlink={CrmConfig.tickets.endpoints.files.delete}
                />
              </Col>
            </Row>
            <br />
            <ActionsComments
              editAction={editAction}
              allComments={allComments}
              setAllComments={setAllComments}
            />
          </>
        )}
      </Form>
    </>
  );
};

export default ActionsForm;
