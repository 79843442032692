import timeingStuff from "@serie3/freelancesplaceapi/Domain/Shared/timeingStuff";
import { useEffect, useState } from "react";
import TimetrackingDomain from "@serie3/freelancesplaceapi/Domain/Timetracking/TimetrackingDomain";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import listHandler from "@serie3/common/Domain/listHandler";

const TaskRow = ({
  task,
  deleteTaskFunction,
  editTaskFunction,
  startSelection = false,
  selected = [],
  setSelected,
}) => {
  const fullWorkDay = 8 * 60 * 60;
  const workingHours = fullWorkDay - task.total_time;
  const [totalTime, setTotalTime] = useState(0);
  const [missedTime, setMissedTime] = useState(0);
  const [isSelected, setIsSelected] = useState(false);
  const isWeekend = TimetrackingDomain.isWeekend(task.date);

  useEffect(() => {
    if (typeof task.ende !== "undefined") {
      setTotalTime(timeingStuff.secondsToTimeString(task.total_time));
      setMissedTime(timeingStuff.secondsToTimeString(workingHours, true));
    } else {
      setTotalTime(0);
      setMissedTime(0);
    }
  }, [task]);

  useEffect(() => {
    if (selected.length === 0) {
      setIsSelected(false);
    }
  }, [selected]);

  const handleRowClick = () => {
    editTaskFunction(task);
  };

  const addToSelection = () => {
    selected.push(task);
    setSelected([...selected]);
    setIsSelected(!isSelected);
  };

  const removeFromSelection = () => {
    const tmpSelected = listHandler.deleteWithoutCall(task, selected);
    setSelected([...tmpSelected]);
    setIsSelected(!isSelected);
  };

  const className = isWeekend ? "timelineRow weekendRow" : "timelineRow";
  return (
    <tr
      style={{
        cursor: "pointer",
      }}
      className={className}
    >
      {startSelection ? (
        <td>
          <Button
            style={{ padding: "1px 6px" }}
            onClick={!isSelected ? addToSelection : removeFromSelection}
            className={isSelected ? "btn-success" : "btn-blue"}
          >
            <FontAwesomeIcon icon={!isSelected ? faPlus : faCheck} />
          </Button>
        </td>
      ) : null}
      <td
        style={{ backgroundColor: isWeekend ? "#f1dada" : "#d8ebd8" }}
        onClick={handleRowClick}
      >
        {task.date}
      </td>
      <td style={{ textAlign: "center" }} onClick={handleRowClick}>
        {task.start}
      </td>
      <td style={{ textAlign: "center" }} onClick={handleRowClick}>
        {task.ende ? task.ende : null}
      </td>
      <td style={{ textAlign: "center" }} onClick={handleRowClick}>
        {timeingStuff.convertToHHmm(task.pause_time)}
      </td>
      <td
        style={{ textAlign: "center", fontWeight: "bold" }}
        onClick={handleRowClick}
      >
        {totalTime}
      </td>
      <td
        style={{
          textAlign: "center",
          color: workingHours > 0 ? "darkred" : "green",
          fontWeight: "bold",
        }}
        onClick={handleRowClick}
      >
        {missedTime}
      </td>
      <td
        style={{
          maxWidth: "30px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
        onClick={handleRowClick}
      >
        <b>{task.title}</b>
      </td>
      <td style={{ color: "red" }}>
        <FontAwesomeIcon
          icon={faTrash}
          onClick={() => {
            deleteTaskFunction(task);
          }}
        />
      </td>
    </tr>
  );
};

export default TaskRow;
