import TextField from "../../../../../layout/Form/Fields/TextField";
import { Col, Row } from "react-bootstrap";
import NewModal from "../../../documents/modals/NewModal";
import KeyValueForm from "@serie3/freelancesplaceapi/Form/shared/KeyValueForm";
import { useFormik } from "formik";
import Contact from "@serie3/freelancesplaceapi/Entity/Crm/Contact";
import SaveButton from "../../../../../layout/Buttons/SaveButton";
import CrmConfig from "@serie3/freelancesplaceapi/Config/Crm/CrmConfig";

const ContactForm = ({
  showEdit,
  setShowEdit,
  editContact,
  companyId,
  personId,
  dataList,
  setContactData,
  showErrorFunction,
}) => {
  const editContactForm = KeyValueForm.createKeyValueForm(
    dataList,
    setContactData,
    showErrorFunction,
    setShowEdit,
    CrmConfig.contactData.endpoints.new,
    CrmConfig.contactData.endpoints.edit,
  );
  const formik = useFormik(editContactForm);
  
  const Save = () => {
    return (
      <SaveButton handleClick={(e) => {
        formik.handleSubmit(e);
        e.preventDefault()}} />
    );
  };

  return (
    <NewModal
      show={showEdit}
      setShow={setShowEdit}
      ExtraButton={Save}
      onShow={() => {
        editContact
          ? formik.setValues(KeyValueForm.setEditData(editContact))
          : formik.setValues(Contact);
        (companyId) && formik.setFieldValue("kunde.id", companyId);
        (personId) && formik.setFieldValue("person.id", personId);
      }}
    >
      <Row>
        <Col>
          <TextField
            formik={formik}
            label={"Type"}
            valueName={"key_name"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
        <Col>
          <TextField
            formik={formik}
            label={"Value"}
            valueName={"value"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
      </Row>
    </NewModal>
  );
};

export default ContactForm;
