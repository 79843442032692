import React from "react";
import ListElement from "../../navbars/elements/ListElement";
import {
  faBriefcase,
  faTasks
} from "@fortawesome/free-solid-svg-icons";

const ProjectsSideNav = () => {
  return (
    <>
      <li className="nav-header">Your Projects</li>
      <ListElement
        name="Projects"
        targetUrl="/project/"
        linkStyle="far nav-icon"
        icon={faTasks}
      />
      <ListElement
        name="Outsourcing"
        targetUrl="/outsourcing/"
        linkStyle="far nav-icon"
        icon={faBriefcase}
      />
    </>
  );
};

export default ProjectsSideNav;
