import { Modal, Button } from "react-bootstrap";

const CustomModal = ({ show, setShow, title, children, onShow, size='lg' }) => {
  return (
    <Modal
      size={size}
      show={show}
      onHide={() => setShow(false)}
      dialogClassName="modal-90w"
      onShow={onShow}
    >
      <Modal.Header closeButton={true}>
        <Modal.Title id="Register">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {children}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setShow(false)}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};
export default CustomModal;
