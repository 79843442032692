import TextField from "../../../../layout/Form/Fields/TextField";
import { Col, Row } from "react-bootstrap";
import SaveButton from "../../../../layout/Buttons/SaveButton";
import NewModal from "../../documents/modals/NewModal";
import { useState } from "react";
import ErrorPopup from "../../../../layout/ErrorPopup";
import Skill from "@serie3/freelancesplaceapi/Domain/Profile/Skill";
import BigErrorPopup from "../../../../layout/Popups/BigErrorPopup";

const SkillsForm = ({
  showEdit,
  setShowEdit,
  dataList,
  editSkill,
  onShow,
  formik,
  setDataList,
}) => {
  const [showError, setShowError] = useState(false);
  const [newSkill, setNewSkill] = useState(Skill);

  let itemIndex = 0;
  if (editSkill) {
    itemIndex = dataList.findIndex((obj) => obj.id === editSkill.id);
  } else {
    itemIndex = formik.values.skills.length - 1;
  }

  const saveNew = (e) => {
    if (newSkill.name && newSkill.category) {
      dataList.push(newSkill);
    }
    formik.handleSubmit(e);
    setShowEdit(false);
    e.preventDefault();
  };

  const saveEdit = (e) => {
    const skill = formik.values.skills[itemIndex];
    dataList.splice(itemIndex, 1);
    dataList[itemIndex] = skill;
    let tmpList = [...dataList];
    setShowEdit(false);
    //setEditSkill(null);
    setDataList(tmpList);
    formik.handleSubmit(e);
  };

  const Save = () => {
    return (
      <SaveButton
        handleClick={(e) => {
          editSkill ? saveEdit(e) : saveNew(e);
        }}
      />
    );
  };

  return itemIndex >= 0 ? (
    <>
      <BigErrorPopup setShowError={setShowError} showError={showError} />
      <NewModal
        show={showEdit}
        setShow={setShowEdit}
        ExtraButton={Save}
        onShow={() => {
          setNewSkill(Skill);
          if (typeof onShow === "function") {
            onShow(formik);
          }
        }}
      >
        <Row>
          <Col>
            <TextField
              label={"Category"}
              valueName={"skills[" + itemIndex + "].category"}
              formik={formik}
              value={formik.values.skills[itemIndex].category}
            />
          </Col>
          <Col>
            <TextField
              label={"Skill"}
              valueName={"skills[" + itemIndex + "].name"}
              formik={formik}
              value={formik.values.skills[itemIndex].name}
            />
          </Col>
          <Col>
            <TextField
              label={"Percentage"}
              valueName={"skills[" + itemIndex + "].percentage"}
              formik={formik}
              value={formik.values.skills[itemIndex].percentage}
            />
          </Col>
        </Row>
      </NewModal>
    </>
  ) : null;
};

export default SkillsForm;
