const SmallBox = ({ color, icon, title, message, textStyle }) => {
  const classname = "small-box " + color;
  const iconClass = "ion " + icon;
  return(
  <div className={classname}>
    <div className="inner">
      <h3>
        {title}
      </h3>
      <p>{message}</p>
    </div>
    <div className="icon">
      <i className={iconClass}></i>
    </div>
  </div>);
};
export default SmallBox;
