import { Table, Row, Col } from "react-bootstrap";
import TaskRow from "./TaskRow";
import RoundNewButton from "../../../../layout/Buttons/RoundNewButton";
import { useEffect, useState, Fragment } from "react";
import timeingStuff from "@serie3/freelancesplaceapi/Domain/Shared/timeingStuff";

const TasksTable = ({
  tasks,
  deleteTaskFunction,
  editTaskFunction,
  createNewTask,
  addDefault,
  selected,
  setSelected,
  startSelection = false,
}) => {
  const fullWorkDay = 8 * 60 * 60;
  const [totalTime, setTotalTime] = useState(0);
  const [totalOvertime, setTotalOvertime] = useState(0);

  let tmpTotalTime = 0;
  let tmpTotalOvertime = 0;
  let currentmonth = -1;

  useEffect(() => {
    tasks.map((task, index) => {
      tmpTotalTime += task.total_time;
      tmpTotalOvertime += fullWorkDay - task.total_time;
      if (index === tasks.length - 1) {
        setTotalTime(tmpTotalTime);
        setTotalOvertime(tmpTotalOvertime);
      }
    });
  }, [tasks]);

  const HeaderRow = ({ task }) => {
    var monthChange = new Date(task.date);
    return (
      <tr>
        <td colSpan={8} style={{backgroundColor: "#dee2e6", fontWeight: "bolder"}}>
          {monthChange.toLocaleString('default', { month: 'long' })} {monthChange.getFullYear()}{" "}
        </td>
      </tr>
    );
  };

  return (
    <>
      <Table>
        <thead>
          <tr style={{ backgroundColor: "lightgray" }}>
            {startSelection ? <th></th> : null}
            <th style={{ textAlign: "left" }}>Date</th>
            <th style={{ textAlign: "center" }}>Start</th>
            <th style={{ textAlign: "center" }}>End</th>
            <th style={{ textAlign: "center" }}>Pause</th>
            <th style={{ textAlign: "center" }}>Total</th>
            <th style={{ textAlign: "center" }}>Missed</th>
            <th style={{ textAlign: "left" }}>Documentation</th>
            <th></th>
          </tr>
          <tr style={{ backgroundColor: "lightgray" }}>
            <th
              colSpan={startSelection ? 5 : 4}
              style={{ textAlign: "right" }}
            ></th>
            <th style={{ textAlign: "center" }}>
              {timeingStuff.secondsToTimeString(totalTime)}
            </th>
            <th
              style={{
                textAlign: "center",
                color: totalOvertime > 0 ? "darkred" : "green",
              }}
            >
              {timeingStuff.secondsToTimeString(totalOvertime, true)}
            </th>
            <th style={{ textAlign: "center" }}></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {tasks
            ? tasks.map((task, index) => {
                let taskMonth = new Date(task.date).getMonth() + 1;
                let showHeaderRow = false;
                if (taskMonth !== currentmonth) {
                  showHeaderRow = true;
                  currentmonth = taskMonth;
                }
                return (
                  <Fragment key={task.id}>
                    {showHeaderRow === true ? <HeaderRow task={task} /> : null}
                    <TaskRow
                      key={task.id}
                      task={task}
                      deleteTaskFunction={deleteTaskFunction}
                      editTaskFunction={editTaskFunction}
                      startSelection={startSelection}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  </Fragment>
                );
              })
            : null}
        </tbody>
      </Table>
      <br />
      {addDefault ? (
        <Row>
          <Col className="text-center">
            <RoundNewButton createNew={addDefault} />
          </Col>
        </Row>
      ) : null}
    </>
  );
};

export default TasksTable;
