import ContentHeader from "../../../layout/ContenteHeader";
import Content from "../../../layout/Content";
import ProfileCard from "./ProfileCard";
import { Row, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import crudApi from "@serie3/common/api/crudApi";
import ProfileConfig from "@serie3/freelancesplaceapi/Config/Profiles/ProfileConfig";
import listHandler from "@serie3/common/Domain/listHandler";
import PaymentModal from "../../../layout/Payment/PaymentModal";
import getSettings from "@serie3/common/config/settings";

const Index = () => {
  const [profiles, setProfiles] = useState([]);
  const [showRowError, setShowRowError] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [profile, setProfile] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [showPayModal, setShowPayModal] = useState(false);
  const [settings, setSettings] = useState();

  const navigate = useNavigate();

  const createNew = async () => {
    const response = await crudApi.createNew(
      ProfileConfig.endpoints.new,
      {},
      setShowPayModal
    );
    if (response.status === 200) {
      navigate(ProfileConfig.endpoints.edit + "/" + response.data.id);
    }
  };

  const NewButton = () => {
    return (
      <Button className="success" onClick={createNew}>
        <FontAwesomeIcon icon={faAdd} />
        &nbsp; New
      </Button>
    );
  };

  useEffect(() => {
    const endpoint = ProfileConfig.endpoints.getAll;
    crudApi.getAll(endpoint, setProfiles, "profile");
    setIsLoaded(true);
    setSettings(getSettings());
  }, []);

  const deleteData = async (index, profile) => {
    listHandler.deleteFromList(
      index,
      crudApi.del(ProfileConfig.endpoints.delete, profile.id),
      setProfiles,
      profiles,
      setShowRowError
    );
  };

  return isLoaded ? (
    <>
      {settings && (
        <PaymentModal
          show={showPayModal}
          setShow={setShowPayModal}
          priceTableId={settings.pricingTables.profile}
        />
      )}

      <ContentHeader />
      <Content AdditionalButtons={NewButton}>
        <Row>
          {profiles
            ? profiles.map((profile, index) => (
                <ProfileCard
                  key={index}
                  index={index}
                  profile={profile}
                  deleteData={deleteData}
                  setShowDetails={setShowProfile}
                  setProfile={setProfile}
                />
              ))
            : null}
        </Row>
      </Content>
    </>
  ) : null;
};

export default Index;
