const TimetrackingConfig = {
    endpoints: {
      getAll: "/timetracking/",
      new: "/timetracking/",
      edit: "/timetracking/edit",
      show: "/timetracking/show/",
      delete: "/timetracking/delete/",
      start: "/timetracking/start",
      pause: "/timetracking/pause/",
      stop: "/timetracking/stop/",
      resume: "/timetracking/resume/",
      print: "/timetracking/printtimesheet/",
      totalMonthlyHours: "/timetracking/getmonthlyhours/"
    },
  };
  export default TimetrackingConfig;