import binaryClient from "@serie3/common/api/binaryClient";
import blogclient from "@serie3/common/api/blogClient";
import Card from "../../../layout/Cards/Card";
import getSettings from "@serie3/common/config/settings";
import { Col } from "react-bootstrap";

const BloglistItem = ({ post }) => {
  const settings = getSettings();
  let imageUrl = settings.baseUrl + post.image;

  const slug = post.slug.replace("blogapi", "blog");

  return (
    <Col sm={12} md={6} style={{marginBottom: "50px"}}>
      <a
        href={slug}
        style={{
          color: "#333333",
          textDecoration: "none",
          marginBottom: "0.5rem",
        }}
      >
        <div
          style={{
            backgroundColor: "#f0f3f6",
            margin: "10px",
            borderRadius: "0.25rem",
            border: "0px solid #000",
            marginBottom: "0.5rem",
            background: "#f0f3f6",
            height:"100%"
            // maxHeight: "425px",
          }}
        >
          <img src={imageUrl} className="card-img-top" style={{minHeight: "350px", maxHeight: "350px"}}/>
          <br />
          <br />
          <div style={{ color: "#333333", padding: "10px" }}>
            <h3 style={{ marginBottom: "-0.1rem" }}>{post.title}</h3>
            <small style={{ color: "gray" }}>
              Date: {post.publishDate}
            </small>
            <div style={{ marginBottom: "0.8rem", marginTop: "0.5rem" }}>
              {post.description}
            </div>
            <span style={{ color: "#0d6efd", textDecoration: "underline" }}>
              Read more →
            </span>
          </div>
        </div>
      </a>
    </Col>
  );
};

export default BloglistItem;
