import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPen } from "@fortawesome/free-solid-svg-icons";
import EllipsisVerticalToggle from "../../../../../layout/Dropdowns/EllipsisVerticalToggle";
import crudApi from "@serie3/common/api/crudApi";
import listHandler from "@serie3/common/Domain/listHandler";

const AddressRow = ({
  index,
  addressObject,
  setShowEdit,
  setEditFunction,
  setAddressData,
  addressDataList,
  showErrorFunction,
  deleteUrl
}) => {
  const deleteData = async () => {
    listHandler.deleteFromList(
      index,
      crudApi.del(deleteUrl, addressObject.id),
      setAddressData,
      addressDataList,
      showErrorFunction,
    );
  };

  return (
    <tr>
      <td>{addressObject.strasse}</td>
      <td>{addressObject.hausnummer}</td>
      <td>{addressObject.plz}</td>
      <td>{addressObject.ort}</td>
      <td style={{verticalAlign: "middle"}}>
        <Dropdown style={{ float: "right", maxHeight: "16px", marginLeft: "5px" }}>
          <EllipsisVerticalToggle />
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => {
                setEditFunction(addressObject);
                setShowEdit(true);
              }}
            >
              <small>
                <FontAwesomeIcon icon={faPen} />
                &nbsp; Edit
              </small>
            </Dropdown.Item>
            <div className="dropdown-divider" />
            <Dropdown.Item onClick={deleteData}>
              <small>
                <FontAwesomeIcon icon={faTrash} />
                &nbsp; Delete
              </small>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>
    </tr>
  );
};

export default AddressRow;
