import downloadHandler from "@serie3/freelancesplaceapi/Domain/Shared/downloadHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faPrint, faHandPointUp } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { useState } from "react";
import PDFModal from "../../../../layout/Popups/PDFModal";

const PrintButton = ({ endpoint, selected, setSelected, projectName }) => {
  const [showSpinner, setShowSpinner] = useState(false);
  var currentDate = new Date();
  var year = currentDate.getFullYear();
  var month = currentDate.getMonth() + 1; // getMonth() returns 0-11
  var day = currentDate.getDate();

  var formattedDate = day + "-" + month + "-" + year;
  return (
    <>
      <PDFModal showSpinner={showSpinner} setShowSpinner={setShowSpinner} />
      <Button
        className="success"
        onClick={() => {
          const data = { tasks: selected };
          setShowSpinner(true);
          downloadHandler(
            endpoint,
            projectName + "_timesheet_" + formattedDate + ".pdf",
            data,
            setShowSpinner
          );
        }}
      >
        <FontAwesomeIcon icon={faPrint} />
      </Button>
      &nbsp;
      <Button
        className="success"
        onClick={() => {
          setSelected([]);
        }}
      >
        <FontAwesomeIcon icon={faX} />
      </Button>
    </>
  );
};

const StartSelectionButton = ({ startSelection, setStartSelection }) => {
  return (
    <Button
      className="success"
      onClick={() => {
        setStartSelection(!startSelection);
      }}
    >
      <FontAwesomeIcon icon={faHandPointUp} />
    </Button>
  );
};

export { StartSelectionButton, PrintButton };
