import { Row, Col, Container, Button, Collapse } from "react-bootstrap";
import CustomModal from "../../../layout/CustomModal";
import { useState } from "react";

const Pricing = ({ setShowRegister }) => {
  const [showImage, setShowImage] = useState(false);
  const imagePath = "/dist/img/Pricing.png";
  const [monthly, setMonthly] = useState(false);
  const [yearly, setYearly] = useState(true);

  const moduleText = (
    <>
      <p>
        Only want to manage your CV? Get the CV module only! Pay only for what
        you need!
      </p>
      <p>Choose from: Billing, CRM, CVs Time tracking or Project management</p>
    </>
  );
  const fullAppText = (
    <>
      You will not need to get another tool to organize your freelancing! If you
      are missing an essential feature,
      <a href="mailto:felix.dziekan@serie3.de"> contact us</a>. If we can
      implement it, you will get it for free!
    </>
  );

  const SignupButton = () => {
    return (
      <>
        <br />
        {window.location.hostname === "demo.freelancersplace.net" ? (
          <a
            href="https://freelancersplace.net/#signup"
            class="headerButtons btn btn-primary"
          >
            Sign up now
          </a>
        ) : (
          <button
            type="button"
            class="headerButtons btn btn-primary"
            onClick={() => setShowRegister(true)}
          >
            Sign up now
          </button>
        )}
      </>
    );
  };

  return (
    <>
      <CustomModal
        show={showImage}
        setShow={setShowImage}
        size="xl"
        title={"Keep it simple, one price fits all"}
      >
        <img src={imagePath} className="landingpageImageModal" />
      </CustomModal>
      <Container id="pricing">
        <Row
          style={{
            position: "relative",
            border: "0px solid #000000",
            paddingTop: "4.5rem",
            paddingBottom: "5.5rem",
          }}
        >
          <Col className="bluedotProfile">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="700"
              height="953"
              viewBox="0 0 700 953"
              fill="none"
            >
              <g opacity="0.8" filter="url(#filter0_f_254_9150)">
                <circle cx="224" cy="476.486" r="76" fill="#45C546" />
              </g>
              <defs>
                <filter
                  id="filter0_f_254_9150"
                  x="-252"
                  y="0.486084"
                  width="952"
                  height="952"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                  />
                  <feGaussianBlur
                    stdDeviation="200"
                    result="effect1_foregroundBlur_254_9150"
                  />
                </filter>
              </defs>
            </svg>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <span className="blueSubheaderTop" style={{ textAlign: "center" }}>
              Keep it simple
            </span>
            <br />
            <div
              className="landingpageHeader"
              style={{
                fontWeight: "400",
                fontStyle: "normal",
                lineHeight: "110%",
              }}
            >
              Pricing
            </div>
            <span className="landingpageDescriptions">
              <Button
                onClick={() => {
                  if (!monthly) {
                    setYearly(!yearly);
                    setMonthly(!monthly);
                  }
                }}
                aria-controls="example-collapse-text"
                aria-expanded={monthly}
                className={
                  monthly
                    ? "btn btn-primary btn-lg pricingButtons"
                    : "btn btn-secondary btn-lg pricingButtons"
                }
                style={{
                  borderTopRightRadius: "0",
                  borderBottomRightRadius: "0",
                  borderRight: "0px",
                  backgroundColor: monthly ? "#0d6efd" : "transparent",
                  color: monthly ? "#fff" : "#212529",
                  boxShadow: "none",
                }}
              >
                Monthly
              </Button>
              <Button
                onClick={() => {
                  if (!yearly) {
                    setYearly(!yearly);
                    setMonthly(!monthly);
                  }
                }}
                aria-controls="example-collapse-text"
                aria-expanded={yearly}
                className={
                  yearly
                    ? "btn btn-primary btn-lg pricingButtons"
                    : "btn btn-outline-dark btn-lg pricingButtons"
                }
                style={{
                  borderTopLeftRadius: "0",
                  borderBottomLeftRadius: "0",
                  borderLeft: "0px",
                  backgroundColor: yearly ? "#0d6efd" : "transparent",
                  color: yearly ? "#fff" : "#212529",
                  boxShadow: "none",
                }}
              >
                Yearly
              </Button>
              <br />
              <br />
              <Collapse in={monthly}>
                <Row>
                  <Col>
                    <h4>Cancel Your Monthly Plan Whenever You Wish</h4>
                  </Col>
                </Row>
              </Collapse>
              <Collapse in={yearly}>
                <Row>
                  <Col>
                    <h4>Yearly Plan: The Best Bang for Your Buck</h4>
                  </Col>
                </Row>
              </Collapse>
              <br />
              <Row
                className="pricingtable"
                style={{
                  borderRadius: "1rem",
                  padding: "35px",
                }}
              >
                <Col>
                  <Collapse in={monthly}>
                    <Row>
                      <Col
                        style={{
                          textAlign: "left",
                        }}
                        className="pe-5 mb-5 no-border-md"
                      >
                        <h2>Custom</h2>

                        <div className="pricingDescriptions">
                          <i>{moduleText}</i>
                        </div>
                        <div>
                          € 14.<span style={{ fontSize: "1rem" }}>99</span> per
                          month
                        </div>
                        <SignupButton />
                      </Col>
                      <Col style={{ textAlign: "left" }} className="ms-md-5">
                        <h2>Full experience</h2>
                        <div className="pricingDescriptions">
                          <i>{fullAppText}</i>
                        </div>
                        <div>
                          € 34.<span style={{ fontSize: "1rem" }}>99</span> per
                          month
                        </div>
                        <SignupButton />
                      </Col>
                    </Row>
                  </Collapse>
                  <Collapse in={yearly}>
                    <Row>
                      <Col
                        style={{
                          textAlign: "left",
                          marginBottom: "35px",
                        }}
                        className="pe-5 no-border-md"
                      >
                        <h2>Custom</h2>
                        <div className="pricingDescriptions">
                          <i>{moduleText}</i>
                        </div>
                        <div>
                          € 9.<span style={{ fontSize: "1rem" }}>99</span> per
                          month
                        </div>
                        <SignupButton />
                      </Col>
                      <Col style={{ textAlign: "left" }} className="ms-md-5">
                        <h2>Full experience</h2>
                        <div className="pricingDescriptions">
                          <i>{fullAppText}</i>
                        </div>
                        <div>
                          € 24.<span style={{ fontSize: "1rem" }}>99</span> per
                          month
                        </div>
                        <SignupButton />
                      </Col>
                    </Row>
                  </Collapse>
                </Col>
              </Row>
            </span>

            <span class="landingpageDescriptions">
              You can either purchase an individual module or get the entire
              software. For flexibility, choose our monthly subscription that
              can be canceled any time. If you're looking for value, our annual
              payment option offers a more affordable rate. (excluding VAT)
            </span>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Pricing;
