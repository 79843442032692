import { Form } from "react-bootstrap";

const TextField = ({
  formik,
  formClass,
  label,
  valueName,
  style,
  value,
}) => {

  return (
    <Form.Group
      className={formClass ? formClass : "form-inline"}
      controlId={valueName}
      style={style}
    >
      <Form.Label>{label}:</Form.Label>
      <Form.Control
        placeholder={label}
        type="text"
        name={valueName}
        value={value ? value : formik.values[valueName]}
        isInvalid={!!formik.errors[valueName]}
        onChange={formik.handleChange}
        style={{ width: "100%" }}
      />
    </Form.Group>
  );
};

export default TextField;
