
const TabGroupLeftVerticalHeader = ({ children }) => {
  return (
    <div className="col-5 col-sm-3">
    <div
      className="nav flex-column nav-tabs h-100"
      id="vert-tabs-tab"
      role="tablist"
      aria-orientation="vertical"
    >
      {children}
    
    </div>
    </div>
  );
};

export default TabGroupLeftVerticalHeader;
