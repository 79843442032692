import { Fragment } from "react";
import { useState, useEffect } from "react";
import Timeline from "../../../../layout/Timeline/Timeline";
import Timelinelabel from "../../../../layout/Timeline/Timelinelabel";
import listHandler from "@serie3/common/Domain/listHandler";
import crudApi from "@serie3/common/api/crudApi";
import ProfileConfig from "@serie3/freelancesplaceapi/Config/Profiles/ProfileConfig";
import WorkexperienceTimelineItem from "../WorkexperienceTimelineItem";
import WorkexperienceFormView from "../Forms/WorkexperienceFormView";
import BigErrorPopup from "../../../../layout/Popups/BigErrorPopup";

const Workexperience = ({ profile }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [workexperience, setWorkexperience] = useState([]);
  const [showError, setShowError] = useState(false);
  const [editWorkexperience, setEditWorkexperience] = useState();

  const addFunction = () => {
    setEditWorkexperience(null);
    setShowEdit(true);
  };

  const editAction = (education) => {
    setEditWorkexperience(education);
    setShowEdit(true);
  };

  const deleteAction = (index, educationId) => {
    const deleteUrl = ProfileConfig.endpoints.workexperience.delete;
    listHandler.deleteFromList(
      index,
      crudApi.del(deleteUrl, educationId),
      setWorkexperience,
      workexperience,
      setShowError
    );
  };

  useEffect(() => {
    setWorkexperience(profile.workexperience);
  }, [profile]);

  return (
    <>
      <BigErrorPopup setShowError={setShowError} showError={showError} />
      <WorkexperienceFormView
        showEdit={showEdit}
        setShowEdit={setShowEdit}
        profileId={profile.id}
        dataList={workexperience}
        setDataList={setEditWorkexperience}
        editData={editWorkexperience}
      />
      <Timeline addFunction={addFunction}>
        {workexperience
          ? workexperience.map((workexperience, index) => {
              const labelText =
                workexperience.start + " - " + workexperience.ende;
              return (
                <Fragment key={index}>
                  <Timelinelabel labeltext={labelText} />
                  <WorkexperienceTimelineItem
                    workexperience={workexperience}
                    editAction={() => {
                      editAction(workexperience);
                    }}
                    deleteAction={() => {
                      deleteAction(index, workexperience.id);
                    }}
                  />
                  <br />
                </Fragment>
              );
            })
          : null}
      </Timeline>
    </>
  );
};

export default Workexperience;
