import Card from "../../../../layout/Cards/Card";

const FreelancerCard = ({ profile, showProfileDetails }) => {
  return (
    <div
      onClick={() => {
        showProfileDetails(profile);
      }}
    >
      <strong>
        <i className="fas fa-book mr-1"></i> {profile._source.title}
      </strong>
      <p>Fit: {profile._score}</p>
      <p className="text-muted">{profile._source.mantra}</p>
      <hr />
    </div>
  );
};

export default FreelancerCard;
