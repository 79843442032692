import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash, faEye } from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "react-bootstrap";
import positions from "@serie3/freelancesplaceapi/api/finance/positions";
import EllipsisVerticalToggle from "../../../../../layout/Dropdowns/EllipsisVerticalToggle";
import PositionDomain from "@serie3/freelancesplaceapi/Domain/Billing/Outgoing/Position";

const PositionRow = ({
  index,
  position,
  setShowRowEdit,
  deleteFunction,
  setShowPosition,
  showAll = false,
  showDropdowns = true,
}) => {
  const Dropdowns = () => {
    return (
      <Dropdown>
        <EllipsisVerticalToggle />
        <Dropdown.Menu>
          <Dropdown.Item
            onClick={() => {
              setShowPosition(position);
            }}
          >
            <small>
              <FontAwesomeIcon icon={faEye} />
              &nbsp; View
            </small>
          </Dropdown.Item>
          <div className="dropdown-divider"></div>
          <Dropdown.Item
            onClick={() => {
              setShowRowEdit(position);
            }}
          >
            <small>
              <FontAwesomeIcon icon={faEdit} />
              &nbsp; Edit
            </small>
          </Dropdown.Item>
          <div className="dropdown-divider"></div>
          <Dropdown.Item
            onClick={() => {
              deleteFunction(index, positions.del(position.id));
            }}
          >
            <small>
              <FontAwesomeIcon icon={faTrash} />
              &nbsp; Delete
            </small>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <tr className="billingRow">
      <td style={{ paddingLeft: "5px", paddingRight: "5px" }}>{index + 1}</td>
      <td>
        {position.titel} <br />
        <small
          style={{
            maxHeight: "1.5em",
            overflow: "hidden",
            display: "inline-block",
          }}
        >
          {position.beschreibung}
        </small>
      </td>
      <td className="numberRow">{position.preis} €</td>
      <td className="numberRow">{position.multiplikator}</td>
      <td className="numberRow">{position.steuersatz}%</td>
      <td className="numberRow">
        {PositionDomain.calculatePositionNett(position)} €
      </td>
      <td className="numberRow">
        {PositionDomain.calculatePositionTax(position)} €
      </td>
      <td className="numberRow">
        {PositionDomain.calculatePositionGross(position)} €
      </td>
      {showDropdowns === true ? (
        <td style={{ verticalAlign: "middle" }}>
          <Dropdowns />
        </td>
      ) : null}
    </tr>
  );
};

export default PositionRow;
