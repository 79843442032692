import FinanceApi from "../../../api/finance/out";

const calculateSummary = (positionen) => {};

const calculatePaymentDeadline = (billingDate, payableDays) => {
  if (typeof payableDays === "undefined") {
    payableDays = 0;
  }

  let newDate = new Date(billingDate);
  newDate.setDate(newDate.getDate() + Number(payableDays));
  return newDate;
};

const updateContact = async (bill, contact, setContact) => {
  let payload = { id: bill.id };
  let direction = "out";
  let type = getType(bill.typ);
  if (
    contact.hasOwnProperty("anrede") &&
    contact.hasOwnProperty("geschlecht")
  ) {
    payload.person = {
      id: contact.id,
    };
    payload.kunde = null;
  } else {
    payload.kunde = {
      id: contact.id,
    };
    payload.person = null;
  }

  if (bill.eingang) {
    direction = "in";
  }

  payload = JSON.stringify(payload, null, 2);
  const response = await FinanceApi.edit(type, direction, payload);
  if (response.status === 200 && response.data.fixed_address !== "undefined") {
    setContact(response.data.fixed_address);
  }
  return response;
};

const getType = (type) => {
  switch (type) {
    case "Rechnung":
      return "bills";
    case "Angebot":
      return "offers";
    case "Auftrag":
      return "orders";
  }
};

export default { calculateSummary, calculatePaymentDeadline, updateContact };
