const UserConfig = {
    endpoints: {
        deleteAccount: "/account/requestdelete",
        confirmDeleteAccount: "/account/confirmdelete",
        cancelDelete: "/account/canceldelete",
        confirmCancelDelete: "/account/confirmcanceldelete",
        checkDeletion: "account/goingtobedeleted"
    }

}

export default UserConfig;