import { Container, Row, Col } from "react-bootstrap";
import signup from "@serie3/freelancesplaceapi/Assets/img/Landingpages/signup.png";
import Social from "../../Components/Social";

const Signup = () => {
  return (
    <section id="signup">
      <Container className="p-5 text-white">
        <Row className="align-items-center">
          <Col md={6} className="mb-3">
            <img src={signup} className="img-fluid" alt="signup" />
          </Col>
          <Col md={6}>
            <h2 className="landingpage text-white">
              Join freelancersplace.net
            </h2>
            <p>
              We at freelancersplace.net aim to be the only all-in-one tool you
              will ever need as a freelancer to organize your workload and
              communicate with your customers, check out our social media and get
              in contact:
              <br />
              <br />
              <Social size={"2x"} />
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Signup;
