import TextField from "../../../../../layout/Form/Fields/TextField";
import { Col, Row } from "react-bootstrap";
import NewModal from "../../../documents/modals/NewModal";
import KeyValueForm from "@serie3/freelancesplaceapi/Form/shared/KeyValueForm";
import KeyValue from "@serie3/freelancesplaceapi/Entity/shared/KeyValue";
import { useFormik } from "formik";
import SaveButton from "../../../../../layout/Buttons/SaveButton";
import CrmConfig from "@serie3/freelancesplaceapi/Config/Crm/CrmConfig";

const MediaForm = ({
  showEdit,
  setShowEdit,
  editSocialMedia,
  companyId,
  personId,
  dataList,
  setSocialMediaData,
  showErrorFunction,
}) => {
  const editMediaForm = KeyValueForm.createKeyValueForm(
    dataList,
    setSocialMediaData,
    showErrorFunction,
    setShowEdit,
    CrmConfig.socialMedia.endpoints.new,
    CrmConfig.socialMedia.endpoints.edit,
  );
  const formik = useFormik(editMediaForm);
  
  const Save = () => {
    return (
      <SaveButton handleClick={(e) => {
        formik.handleSubmit(e);
        e.preventDefault()}} />
    );
  };

  return (
    <NewModal
      show={showEdit}
      setShow={setShowEdit}
      ExtraButton={Save}
      onShow={() => {
        editSocialMedia
          ? formik.setValues(KeyValueForm.setEditData(editSocialMedia))
          : formik.setValues(KeyValue);
        (companyId) && formik.setFieldValue("kunde.id", companyId);
        (personId) && formik.setFieldValue("person.id", personId);
      }}
    >
      <Row>
        <Col>
          <TextField
            formik={formik}
            label={"Name"}
            valueName={"key_name"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
        <Col>
          <TextField
            formik={formik}
            label={"Url"}
            valueName={"value"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
      </Row>
    </NewModal>
  );
};

export default MediaForm;
