const Card = ({ children, title, className, CardTools,style ={} }) => {
  const fullClassname = className ? "card " + className : "card";

  return (
    <div className={fullClassname} style={{...style}}>
      <div
        className="card-header d-flex"
        style={{  flexDirection: "row" }}
      >
        <h3 className="card-title">{title}</h3>
        <div className="card-tools ml-auto">
          {CardTools ? <CardTools /> : null}
        </div>
      </div>
      <div className="card-body">{children}</div>
    </div>
  );
};

export default Card;
