import CompleteDropdown from "../../../../../layout/Dropdowns/CompleteDropdown";
import StatusTag from "./StatusTag";

const ProjectRow = ({ editAction, viewAction, deleteAction, project }) => {
  return (
    <tr>
      <td>#</td>
      <td>
        <a>{project.title}</a>
        <br />
        <small>{project.start} - {project.start} </small>
      </td>
      <td>
        Start: {project.start}
        <br />
        End: {project.end}
      </td>
      <td className="project-state">
        <StatusTag
         start={project.start}
         end={project.end}
         done={project.done} />
      </td>
      <td className="project-actions text-right">
        <CompleteDropdown
          editAction={editAction}
          deleteAction={deleteAction}
          viewAction={viewAction}
        />
      </td>
    </tr>
  );
};

export default ProjectRow;
