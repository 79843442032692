import crudApi from "@serie3/common/api/crudApi";
import ProjectConfig from "../../Config/Projects/ProjectConfig";

const updateContact = async (project, contact, setContact) => {
  let payload = { id: project["id"], title: project["title"] };

  if (
    contact.hasOwnProperty("anrede") &&
    contact.hasOwnProperty("geschlecht")
  ) {
    payload.person = {
      id: contact.id,
    };
    payload.customer = null;
  } else {
    payload.customer = {
      id: contact.id,
    };
    payload.person = null;
  }

  payload = JSON.stringify(payload, null, 2);
  return await crudApi.edit(ProjectConfig.endpoints.edit, payload);
};

export default { updateContact };
