import Company from "../../Entity/Crm/Company";

import * as Yup from "yup";
import CrudApi from "@serie3/common/api/crudApi";

const createCompanyForm = (showErrorFunction) => {
  const validationSchema = Yup.object({
    firmenname: Yup.string().required("Required"),
    sperrgrund: Yup.string().when(
      'gesperrt', {
        is: true,
        then: Yup.string().required('You must add a reason why this company is blocked')
      }
    )
  });

  const onSubmit = async (values) => {
    let payload = JSON.stringify(values, null, 2);
    const response = await CrudApi.edit("/crm/company/edit", payload);
  };

  return {
    initialValues: Company,
    onSubmit: (values, actions) => {
      onSubmit(values, actions);
    },
  };
};

const setEditData = (editData) => {
  let Company = {
    id: editData.id,
    firmenname: editData.firmenname,
    bemerkung: editData.bemerkung,
    rechtsform: editData.rechtsform,
    kundenklasse: editData.kundenklasse,
    gesperrt: editData.gesperrt,
    sperrgrund: editData.sperrgrund,
    tax_id: editData.tax_id,
    tax_number: editData.tax_number,
  };

  return Company;
};

export default { createCompanyForm, setEditData };
