import { React } from "react";

const Content = ({ Title, AdditionalButtons, FooterText, children }) => {
  return (
    <section className="content">
      <div className="card h-100">
        <div
          className="card-header d-flex"
          style={{ alignItems: "end", flexDirection: "row" }}
        >
          <h2 className="card-title">{Title ? <Title /> : null}</h2>
          <div className="card-tools ml-auto">
            {AdditionalButtons ? <AdditionalButtons /> : null}
          </div>
        </div>
        <div className="card-body h-100">{children}</div>
        <div className="card-footer">{FooterText}</div>
      </div>
    </section>
  );
};

export default Content;
