import NewModal from "../../../../../layout/Popups/NewModal";
import Dashboard from "./Tabs/Dashboard";
import crudApi from "@serie3/common/api/crudApi";
import ProjectConfig from "@serie3/freelancesplaceapi/Config/Projects/ProjectConfig";
import { useEffect, useState } from "react";

const ProjectView = ({ projectId, show, setShow }) => {
  const [project, setProject] = useState(null);
  const [comments, setComments] = useState(null);
  const [title, setTitle] = useState('');

  useEffect(() => {
    setProject(null);
    const loadData = async (id) => {
      const project = await crudApi.show(ProjectConfig.endpoints.show, projectId);
      if (project !== null) {
        setProject(project);
        setComments([...project.comments]);
        setTitle(project.title);
      }
    };
    loadData(projectId);
  }, [projectId]);

  const onHide = () => {
    setProject(null);
    setTitle('');
  }

  return (
      <NewModal
        title={title}
        show={show}
        setShow={setShow}
        onHide={onHide}
      >
        {project ? <Dashboard project={project} comments={comments} /> : "Loading..."}
      </NewModal>
  );
};

export default ProjectView;
