const Position = {
    titel: '',
    beschreibung: '',
    preis: 0,
    multiplikator: 0,
    steuersatz: 19,
    taxabsolute: 0,
    gross_price: 0,
    gesamt_preis: 0,
    finanz_vorgang:  { id: "" },
}

export default Position;