import NewModal from "../../documents/modals/NewModal";
import { Row, Col, Table } from "react-bootstrap";

const TaskView = ({ show, setShow, task }) => {
  return task ? (
    <NewModal show={show} setShow={setShow} title={task.title}>
      <Row>
        <Table>
            <thead>
                <tr>
                    <th>Start</th>
                    <th>End</th>
                    <th>Pause:</th>
                    <th>Total</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{task.start}</td>
                    <td>{task.ende}</td>
                    <td>{task.pause_time}</td>
                    <td>{task.total_time}</td>
                </tr>
            </tbody>
        </Table>
      </Row>
      <hr />
      <Row>
        <Col>{task.beschreibung}</Col>
      </Row>
    </NewModal>
  ) : null;
};

export default TaskView;
