import { useEffect, useState } from "react";
import Content from "../../../../../layout/Content";
import billingApi from "@serie3/freelancesplaceapi/api/finance/out";
import Table from "../../../../../layout/Tables/Table";
import TableHeader from "../../../../../layout/Tables/TableHeader";
import TableRowBills from "../../TableRowBills";
import BillView from "../../modals/BillView";
import Billout from "../../BillOut";
import BillIn from "../../BillIn";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import FinanceHeader from "../../FinanceHeader";
import listHandler from "@serie3/common/Domain/listHandler";
import getSettings from "@serie3/common/config/settings";
import PaymentModal from "../../../../../layout/Payment/PaymentModal";

const Index = ({ type, direction, titleText }) => {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [bill, setBill] = useState();
  const [showRowError, setShowRowError] = useState(false);
  const [showPayModal, setShowPayModal] = useState(false);
  const [settings, setSettings] = useState();

  const navigate = useNavigate();

  const loadData = async () => {
    const response = await billingApi.getAll(type, direction);
    setData(response.data.finanzvorgang);
  };

  const createNew = async () => {
    const id = await billingApi.createNew(type, direction, setShowPayModal);
    if (typeof id !== "undefined" && typeof id === "number") {
      navigate("/finance/" + direction + "/" + type + "/edit/" + id);
    }
  };

  useEffect(() => {
    setSettings(getSettings());
    loadData();
  }, [type, direction]);

  const NewButton = () => {
    return (
      <Button className="success" onClick={createNew}>
        <FontAwesomeIcon icon={faAdd} />
        &nbsp; New
      </Button>
    );
  };

  const Title = () => {
    return (
      <>
        <b>Finance: &nbsp; </b>
        {titleText}
      </>
    );
  };

  const setTheList = (entities) => {
    setData(entities);
  };

  const deleteFromList = async (index, apiCall) => {
    listHandler.deleteFromList(
      index,
      apiCall,
      setTheList,
      data,
      setShowRowError
    );
  };

  const ShowType = ({ bill, direction }) => {
    if (direction === "out") {
      return <Billout bill={bill} />;
    }
    if (direction === "in") {
      return <BillIn bill={bill} type={type} />;
    }
  };

  return (
    <>
      <BillView show={show} bill={bill} setShow={setShow}>
        <ShowType direction={direction} bill={bill} />
      </BillView>
      {settings && (
        <PaymentModal
          show={showPayModal}
          setShow={setShowPayModal}
          priceTableId={settings.pricingTables.billing}
        />
      )}
      <FinanceHeader />
      <Content Title={Title} AdditionalButtons={NewButton}>
        <div style={{ overflowX: "auto", minHeight: "70vh" }}>
          <Table>
            <TableHeader>
              <th></th>
              <th>Customer</th>
              <th>Status</th>
              <th>Date</th>
              <th>Nett</th>
              <th>Gross</th>
              <th></th>
            </TableHeader>
            <tbody>
              {data
                ? data.map((bill, index) => (
                    <TableRowBills
                      key={index}
                      index={index}
                      bill={bill}
                      setShow={setShow}
                      setBill={setBill}
                      direction={direction}
                      type={type}
                      deleteFunction={deleteFromList}
                    />
                  ))
                : null}
            </tbody>
          </Table>
        </div>
      </Content>
    </>
  );
};

export default Index;
