import React from "react";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Row, Col } from "react-bootstrap";
import EditCardButtons from "../../../layout/Buttons/EditCardButtons";
import { useLocation } from "react-router-dom";

const ContactCard = ({
  index,
  contact,
  setShowDetails,
  createNew,
  setViewData,
  deleteData,
  CardButtons,
  className
}) => {
  const url = useLocation();

  const Name = ({ contact }) => {
    return (
      <div
        className="card-header border-bottom-0"
        style={{
          minHeight: "64px",
          paddingTop: "21px",
          paddingBottom: "21px",
          paddingLeft: "17px",
          backgroundColor: "#007bff",
          color: "white",
        }}
      >
        <h5 style={{ fontWeight: "bold" }}>
          {contact.firmenname
            ? contact.firmenname
            : (contact.vorname ? contact.vorname : " ") +
              " " +
              (contact.name ? contact.name : " ")}
        </h5>
        <span style={{ fontSize: "smaller", fontWeight: "lighter" }}>
          {contact.position ? contact.position : null}
        </span>
      </div>
    );
  };

  const Address = ({ contact }) => {
    if (contact.adresse && contact.adresse.length > 0) {
      const address = contact.adresse[0];
      return (
        <div
          style={{
            paddingTop: "5px",
            paddingBottom: "11px",
          }}
        >
          <b>
            {address.strasse} {address.hausnummer}
          </b>
          <br />
          <small>
            {address.plz} {address.ort}
          </small>
        </div>
      );
    }
    return null;
  };

  const Contact = ({ contact }) => {
    if (contact.kontaktdaten && contact.kontaktdaten.length > 0) {
      const contactData = contact.kontaktdaten[0];
      return (
        <div
        style={{
          paddingTop: "5px",
          paddingBottom: "11px",
        }}
      >
          <b> {contactData.key_name}:</b> <small>{contactData.value} </small>
        </div>
      );
    }
    return null;
  };

  const Buttons = () => {
    return CardButtons ? (
      <CardButtons />
    ) : (
      <EditCardButtons
        index={index}
        data={contact}
        setShowDetails={setShowDetails}
        setViewData={setViewData}
        deleteData={deleteData}
        editUrl={url.pathname + "/edit/"}
      />
    );
  };

  const ContactCardContent = () => {
    if (typeof contact !== "undefined") {
      return (
        <>
          <b>
            <Name contact={contact} />
          </b>
          <div className="card-body container-fluid">
            <Row>
              <Col>
                <Address contact={contact} />
              </Col>
              <Col>
                <Contact contact={contact} />
              </Col>
            </Row>
          </div>
          <div className="card-footer">
            <div className="text-right">
              <Buttons />
            </div>
          </div>
        </>
      );
    }
    return (
      <div className="card-body centerButtonCard">
        <Button className="btn bg-gradient-success btn-circle">
          <FontAwesomeIcon icon={faAdd} onClick={createNew} />
        </Button>
      </div>
    );
  };

  return (
    <div className={className ? className + " d-flex align-items-stretch flex-column" : "col-12 col-sm-6 col-md-6 d-flex align-items-stretch flex-column"}>
      <div className="card bg-light d-flex flex-fill">
        <ContactCardContent />
      </div>
    </div>
  );
};

export default ContactCard;
