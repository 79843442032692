import Table from "../../../../../layout/Tables/Table";
import TableHeader from "../../../../../layout/Tables/TableHeader";
import { useState } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ErrorPopup from "../../../../../layout/ErrorPopup";
import CrmConfig from "@serie3/freelancesplaceapi/Config/Crm/CrmConfig";
import AddressRow from "./AddressRow";
import AddressesForm from "../Form/AddressesForm";

const Adresses = ({ addressData, companyId, setAddressData, personId }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [editAddressData, setEditAddressData] = useState(null);
  const [showError, setShowError] = useState(false);

  const showErrorFunction = () => {
    setShowError(true);
  }

  return (
    <>
      <ErrorPopup setShowError={setShowError} showError={showError}>
        ... Sorry! Something went wrong
        <br />
        <br />
        <strong>
          If you think this is a bug and we screwed up plz contact support!
        </strong>
      </ErrorPopup>
      <Row>
        <Col>
          <Form>
            <AddressesForm
              showEdit={showEdit}
              setShowEdit={setShowEdit}
              editAddressData={editAddressData}
              companyId={companyId}
              personId={personId}
              dataList={addressData}
              setAddressData={setAddressData}
              showErrorFunction={showErrorFunction}
            />
          </Form>

          <Table>
            <TableHeader>
              <th>Street</th>
              <th>Number</th>
              <th>Postal</th>
              <th>City</th>
              <th></th>
            </TableHeader>
            <tbody>
              {addressData
                ? addressData.map((contact, index) => {
                    return (
                      <AddressRow
                        index={index}
                        key={index}
                        addressObject={contact}
                        setShowEdit={setShowEdit}
                        setEditFunction={setEditAddressData}
                        setAddressData={setAddressData}
                        addressDataList={addressData}
                        showErrorFunction={showErrorFunction}
                        deleteUrl={CrmConfig.address.endpoints.delete}
                      />
                    );
                  })
                : null}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col className="col-sm-12 text-end mb-4 mt-2">
          <Button
            onClick={() => {
              setEditAddressData(null);
              setShowEdit(true);
            }}
          >
            Add Position &nbsp;
            <FontAwesomeIcon icon={faAdd} />
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Adresses;
