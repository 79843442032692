import { useEffect, useState } from "react";
import Content from "../../../../layout/Content";
import ContentHeader from "../../../../layout/ContenteHeader";
import { Row } from "react-bootstrap";
import ContactCard from "../ContactCard";
import ErrorPopup from "../../../../layout/ErrorPopup";
import NewModal from "../../documents/modals/NewModal";
import { useLocation, useNavigate } from "react-router-dom";
import CrmConfig from "@serie3/freelancesplaceapi/Config/Crm/CrmConfig";
import crudApi from "@serie3/common/api/crudApi";
import ViewWrapper from "../ViewWrapper";
import listHandler from "@serie3/common/Domain/listHandler";
import getSettings from "@serie3/common/config/settings";
import PaymentModal from "../../../../layout/Payment/PaymentModal";

const Index = () => {
  const [data, setData] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [showRowError, setShowRowError] = useState(false);
  const [viewData, setViewData] = useState();
  const [showPayModal, setShowPayModal] = useState(false);
  const [settings, setSettings] = useState();

  const url = useLocation();

  const navigate = useNavigate();

  useEffect(() => {

    const endpoint =
    url.pathname === "/crm/companies"
      ? CrmConfig.company.index.endpoint
      : CrmConfig.people.index.endpoint;

  const datafield =
    url.pathname === "/crm/companies"
      ? CrmConfig.company.index.datafield
      : CrmConfig.people.index.datafield;

    crudApi.getAll(endpoint, setData, datafield);
    setSettings(getSettings());
  }, [url.pathname]);

  const title = () => {
    return (
      <>
        <b>CRM: &nbsp; </b>
      </>
    );
  };

  const createNew = async () => {
    const newUrl =
      url.pathname === "/crm/companies"
        ? CrmConfig.company.index.endpoint
        : CrmConfig.people.index.endpoint;

    const payload =
      url.pathname === "/crm/companies" ? {} : { vorname: " ", name: " " };

    const response = await crudApi.createNew(newUrl, payload, setShowPayModal);
    if (response.status === 200) {
      navigate(url.pathname + "/edit/" + response.data.id);
    } else {
      setShowRowError(true);
    }
  };

  const deleteData = async (index, contact) => {
    const deleteUrl =
      url.pathname === "/crm/companies"
        ? CrmConfig.company.index.endpoint + "delete/"
        : CrmConfig.people.index.endpoint + "delete/";

    listHandler.deleteFromList(
      index,
      crudApi.del(deleteUrl, contact.id),
      setData,
      data,
      setShowRowError
    );
  };

  return (
    <>
      <NewModal show={showDetails} setShow={setShowDetails}>
        <ViewWrapper contact={viewData} />
      </NewModal>
      {settings && <PaymentModal show={showPayModal} setShow={setShowPayModal} priceTableId={settings.pricingTables.crm}/>}
      <ContentHeader />
      <Content Title={title}>
        <Row className="mb-3">
          <ContactCard createNew={createNew} />
          {data
            ? data.map((contact, index) => (
                <ContactCard
                  index={index}
                  key={index}
                  contact={contact}
                  setShowDetails={setShowDetails}
                  setViewData={setViewData}
                  deleteData={deleteData}
                />
              ))
            : null}
        </Row>
      </Content>
    </>
  );
};

export default Index;
