const TabGroupLeftTab = ({ children, verticalPrefix, name, show }) => {
  const className = show
    ? "tab-pane text-left fade active show"
    : "tab-pane text-left fade";

  const id = verticalPrefix  + "-" +  name ;
  const labeled = verticalPrefix  + "-" +  name  + "-tab";
  return (
    <div
      className={className}
      id={id}
      role="tabpanel"
      aria-labelledby={labeled}
    >
      {children}
    </div>
  );
};
export default TabGroupLeftTab;
