const Task = {
  date: "",
  start: "",
  ende: undefined,
  pause_start: undefined,
  pause_time: 0,
  title: "",
  beschreibung: "",
};

export default Task;
