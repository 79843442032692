import { Row } from "react-bootstrap";
import FinanceCard from "./FinanceCard";

const FinanceCardRow = ({financeArray = [], AddButton}) =>{
    return (
        <Row>
        {financeArray
          ? financeArray.map((bill) => {
              return (
                <FinanceCard
                  bill={bill}
                  key={bill.id}
                  AddButton={AddButton}
                />
              );
            })
          : null}
      </Row>
    );
}

export default FinanceCardRow;