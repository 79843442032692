import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCogs } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const ListHeader = ({ title, link }) => {
  return (
    <li
      className="nav-header"
      style={{ display: "flex", alignItems: "center" }}
    >
      {title}
      {link ? (
        <Link to={link} className="ml-auto">
          <FontAwesomeIcon
            icon={faCogs}
            style={{ opacity: "0.65", color: "#17a2b8" }}
          />
        </Link>
      ) : (
        <></>
      )}
    </li>
  );
};

export default ListHeader;
