import { Row, Col, Button, Container } from "react-bootstrap";
import CustomModal from "../../../layout/CustomModal";
import { useState } from "react";

const Projects = () => {
  const [showImage, setShowImage] = useState(false);
  const imagePath = "/dist/img/projects.png";

  return (
    <>
    <CustomModal show={showImage} setShow={setShowImage} size="xl" title={'Manage your projects with ease'}>
      <img src={imagePath} className="landingpageImageModal" />
    </CustomModal>
    <Container>
      <Row
        style={{
          position: "relative",
          border: "0px solid #000000",
          paddingTop: "4.5rem",
          paddingBottom: "5.5rem",
        }}
      >
        <Row>
          <Col md={6} sm={12} style={{marginBottom: "0.5rem"}}>
            <Col className="reddot1" />
            <div className="landingpageImageWrapper">
            <img
                  src={imagePath}
                  className="landingpageImage"
                  onClick={() => {setShowImage(true)}}
                />
            </div>
          </Col>
          <Col xs={1} />
          <Col md={5} sm={12}>
          <div
              className="landingpageHeader"
              style={{
                fontWeight: "500",
                fontStyle: "normal",
                lineHeight: "140%",
              }}
            >
              Easily manage your projects
            </div>
            Collect and organize all project-related data, from tasks and
            comments to planned hours and bills. Share real-time progress with
            your clients through a customer-facing dashboard, all via a
            shareable link.
            <Row style={{ marginBottom: "1rem" }}>
              <Col className="yellowdotWelcome" />
              <Col className="greendotWelcome" />
            </Row>
          </Col>
        </Row>
      </Row>
    </Container>
    </>
  );
};

export default Projects;
