import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuidditch } from "@fortawesome/free-solid-svg-icons";
import ProjectConfig from "@serie3/freelancesplaceapi/Config/Projects/ProjectConfig";
import crudApi from "@serie3/common/api/crudApi";
import { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="#"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </a>
));

const ProjectQuicklink = () => {
  const [quicklinks, setQuickLinks] = useState([]);
  useEffect(() => {
    const endpoint = ProjectConfig.endpoints.quickLinks;
    crudApi.getAll(endpoint, setQuickLinks, "project");
  }, []);
  return quicklinks.length > 0 ? (
    <Dropdown
      className="d-flex"
      style={{ flexDirection: "row", alignItems: "center" }}
    >
      <Dropdown.Toggle id="dropdown-basic" as={CustomToggle}>
        <Button style={{ marginRight: "0.75rem" }} className="btn btn-light">
          <FontAwesomeIcon icon={faQuidditch} />
        </Button>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {quicklinks.map((link, index) => {
          return (
            <>
              <Dropdown.Item as={Link} to={"/project/edit/" + link.id}>
                <small>{link.title}</small>
              </Dropdown.Item>
              <div className="dropdown-divider"></div>
            </>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  ) : null;
};

export default ProjectQuicklink;
