import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import StatusIcons from "./content/outgoing/SubComponents/StatusIcons";
import Adress from "./content/outgoing/SubComponents/Address";
import DocumentImage from "../documents/DocumentImage";
import CrudApi from "@serie3/common/api/crudApi";
import Spinner from "@serie3/freelancesplaceapi/Assets/img/spinner.gif";
import Card from "../../../layout/Cards/Card";

const BillIn = ({ bill, type }) => {
  const [file, setFile] = useState();
  let address;
  if (typeof bill.fixed_address !== "undefined") {
    address = JSON.parse(bill.fixed_address);
  }

  const loadData = async () => {
    let previewUrl = "/finance/in/" + type + "/filePreview/" + bill.id;
    const response = await CrudApi.getBinary(previewUrl);
    let file = {
      header: response.headers,
      data: response.data,
    };
    setFile(file);
  };

  useEffect(() => {
    loadData();
  }, [bill]);

  return (
    <Row>
      <Col className="col-sm-8" style={{ overflow: "auto" }}>
        {file ? (
          <DocumentImage
            file={file}
            style={{ maxWidth: "100%", maxHeight: "100%" }}
          />
        ) : (
          <img src={Spinner} />
        )}
      </Col>
      <Col className="col-sm-4" style={{ textAlign: "left" }}>
        <Card title={""}>
          <StatusIcons
            payed={bill.geldeingang}
            draft={bill.draft}
            subscription={bill.aborechnung}
            privateButton={bill.privat}
          />
        </Card>

        <Card title={"Address"}>
          <Adress addressJson={bill.fixed_address} />
        </Card>

        <Card title={"Dates"}>
          <Row>
            <Col>Billing date: {bill.datum}</Col>
          </Row>

          <Row>
            <Col>Aborechnung: {bill.rechungsdatum}</Col>
          </Row>
        </Card>

        <Card title={"Numbers"}>
          <Row>
            <Col>
              <b> Net:</b> {bill.netto}
            </Col>
          </Row>
          <Row>
            <Col>
              <b> Tax: </b>
              {bill.brutto - bill.netto}
            </Col>
          </Row>
          <Row>
            <Col>
              <b>Gross:</b> {bill.brutto}
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default BillIn;
