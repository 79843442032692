import { useEffect, useState } from "react";
import FinancePicker from "./FinancePicker";
import { Button, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import BigErrorPopup from "../../../../../../../../layout/Popups/BigErrorPopup";
import FinanceCard from "./FinanceCard";
import listHandler from "@serie3/common/Domain/listHandler";

const ProjectFinanceWrapper = ({ formik, finance = [], direction }) => {
  const [show, setShow] = useState(false);
  const [showError, setShowError] = useState(false);
  const [bills, setBills] = useState([]);
  const [offers, setOffers] = useState([]);
  const [orders, setOrders] = useState([]);

  const AddButton = () => {
    return (
      <Button
        className="success"
        onClick={() => {
          setShow(true);
        }}
      >
        Add &nbsp;
        <FontAwesomeIcon icon={faAdd} />
      </Button>
    );
  };

  useEffect(() => {
    finance.map((subarray, index) => {
      switch (subarray.typ) {
        case "Angebot":
          setOffers(subarray);
          break;
        case "Rechnung":
          setBills(subarray);
          break;
        case "Auftrag":
          setOrders(subarray);
          break;
      }
    });
  }, [finance]);

  const Translator = ({ germanWord }) => {
    switch (germanWord) {
      case "Rechnung":
        return "Bills";
      case "Auftrag":
        return "Orders";
      case "Angebot":
        return "Offers";
    }
  };

  const unlinkElement = (item) => {
    formik.values.finance = listHandler.deleteWithoutCall(
      item,
      formik.values.finance
    );
    formik.handleSubmit();
  };

  const ShowRow = ({ financeItems }) => {
    return financeItems && financeItems.data ? (
      <Row>
        <h2>
          <Translator germanWord={financeItems.typ} />
        </h2>
        <hr style={{ maxWidth: "95%" }} />
        {financeItems.data && financeItems.data.length > 0
          ? financeItems.data.map((item) => {
              return (
                <FinanceCard
                  key={item.id}
                  bill={item}
                  unlinkFunction={() => {
                    unlinkElement(item);
                  }}
                />
              );
            })
          : null}
      </Row>
    ) : null;
  };

  return (
    <>
      <BigErrorPopup showError={showError} setShowError={setShowError} />
      <FinancePicker show={show} setShow={setShow} formik={formik} direction={direction} />
      <Row>
        <Col className="text-end">
          <AddButton />
        </Col>
      </Row>
      <br />
      <ShowRow financeItems={bills} />
      <br />
      <ShowRow financeItems={orders} />
      <br />
      <ShowRow financeItems={offers} />
    </>
  );
};

export default ProjectFinanceWrapper;
