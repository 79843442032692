import { useState } from "react";
import Timeline from "../../../../../../layout/Timeline/Timeline";
import ProjectCommentTimelineItem from "../ProjectCommentTimelineItem";
import Timelinelabel from "../../../../../../layout/Timeline/Timelinelabel";
import CommentsFormView from "../Forms/CommentsFormView";
import ProjectConfig from "@serie3/freelancesplaceapi/Config/Projects/ProjectConfig";
import { Fragment } from "react";
import listHandler from "@serie3/common/Domain/listHandler";
import crudApi from "@serie3/common/api/crudApi";
import BigErrorPopup from "../../../../../../layout/Popups/BigErrorPopup";

const Comments = ({ project, comments, setComments }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [editComment, setEditComment] = useState();
  const [showError, setShowError] = useState();


  const addComment = () => {
    setEditComment(null);
    setShowEdit(true);
  };

  const onShow = (formik) => {
    formik.setFieldValue("project.id", project.id);
  };

  const editAction = (comment) => {
    setEditComment(comment);
    setShowEdit(true);
  };

  const deleteAction = (index, comment) => {
    const deleteUrl = ProjectConfig.endpoints.comments.delete;
    listHandler.deleteFromList(
      index,
      crudApi.del(deleteUrl, comment.id),
      setComments,
      comments,
      setShowError
    );
  };

  const viewAction = (comment) => {};

  return (
    <>
      <BigErrorPopup setShowError={setShowError} showError={showError} />
      <CommentsFormView
        showEdit={showEdit}
        setShowEdit={setShowEdit}
        editKeyValueData={editComment}
        dataList={comments}
        setDataList={setComments}
        onShow={onShow}
        newEndpoint={ProjectConfig.endpoints.comments.new}
        editEndpoint={ProjectConfig.endpoints.comments.edit}
      />
      <Timeline addFunction={addComment} style={{marginBottom: "150px"}}>
        {comments.map((comment, index) => {
          return (
            <Fragment key={index}>
              <Timelinelabel labeltext={comment.date} />
              <ProjectCommentTimelineItem
                comment={comment}
                editAction={() => {
                  editAction(comment);
                }}
                deleteAction={() => {
                  deleteAction(index, comment);
                }}
              />
            </Fragment>
          );
        })}
      </Timeline>
    </>
  );
};

export default Comments;
