import CardRow from "./CardRow";
import KanbanCard from "./KanbanCard";

const KanbanColumn = ({
  title,
  type,
  dataList,
  setEditAction,
  setShowForm,
  CardTools,
  setViewAction,
  deleteData,
  setDataList,
}) => {
  return (
    <CardRow
      title={title}
      type={type}
      CardTools={CardTools}
      count={dataList.length}
    >
      {dataList
        ? dataList.map((action, index) => {
            return (
              <KanbanCard
                index={index}
                key={index}
                action={action}
                setEditAction={setEditAction}
                setShowForm={setShowForm}
                setViewAction={setViewAction}
                deleteData={deleteData}
                setDataList={setDataList}
                dataList={dataList}
              />
            );
          })
        : null}
    </CardRow>
  );
};

export default KanbanColumn;
