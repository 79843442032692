import { Col, Row, Button, Navbar, Container } from "react-bootstrap";
import { useState, useEffect, useContext } from "react";
import RegisterModal from "../RegisterModal";
import LoginModal from "../LoginModal";
import Forgot from "../Forgot";
import AuthContext from "@serie3/common/auth/AuthContext";
import firebaseConfig from "@serie3/freelancesplaceapi/Config/Firebase/firebase";
import { initializeApp } from "firebase/app";
import { getAuth, signOut } from "firebase/auth";

const FreelancerserplaceNavbar = ({
  setToken,
  showLinks = true,
  showButtons = true,
}) => {
  const [showRegister, setShowRegister] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showForgot, setShowForgot] = useState(false);
  const [location, setLocation] = useState();

  const { token } = useContext(AuthContext);

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  const LoginButtons = () => {
    useEffect(() => {
      const tmpLocation = window.location.hostname;
      setLocation(tmpLocation);
    }, []);

    if (!token) {
      return (
        <Col
          className="justify-content-center"
          style={{
            display: "inline-flex",
            alignItems: "center",
            marginTop: "60px",
            gap: "1.5rem",
          }}
        >
          <Button
            className="headerButtons btn btn-block btn-outline-dark"
            style={{ backgroundColor: "transparent", color: "#212529" }}
            onClick={() => {
              setShowLogin(true);
            }}
          >
            Login
          </Button>
          {location !== "demo.freelancersplace.net" ? (
            <Button
              className="headerButtons"
              onClick={() => {
                setShowRegister(true);
              }}
            >
              Sign up
            </Button>
          ) : null}
        </Col>
      );
    } else {
      return (
        <Col
          className="justify-content-center"
          style={{
            display: "inline-flex",
            alignItems: "center",
            marginTop: "60px",
            gap: "1.5rem",
          }}
        >
          <Button
            className="headerButtons btn btn-block btn-outline-dark"
            style={{ backgroundColor: "transparent", color: "#212529" }}
            onClick={() => {
              setToken(null, null);
              window.location.reload();
              signOut(auth);
            }}
          >
            Logout
          </Button>
          <Button
            className="headerButtons "
            onClick={() => {
              window.location.href = "/";
            }}
          >
            Dashboard
          </Button>
        </Col>
      );
    }
  };

  return (
    <>
      <RegisterModal
        show={showRegister}
        setShow={setShowRegister}
        setShowLogin={setShowLogin}
      />
      <LoginModal
        show={showLogin}
        setShow={setShowLogin}
        setShowRegister={setShowRegister}
        setToken={setToken}
        setShowForgot={setShowForgot}
      />
      <Forgot show={showForgot} setShow={setShowForgot} />
      <Navbar style={{ backgroundColor: "white" }}>
        <Container>
          <Row className="justify-content-center col">
            <Col
              style={{
                display: "inline-flex",
                alignItems: "left",
                marginTop: "60px",
              }}
            >
              <a href="/">
                <img src="/dist/img/logo-1.jpg" />
              </a>
            </Col>
            <Col
              className="justify-content-center"
              style={{
                display: "inline-flex",
                alignItems: "center",
                marginTop: "60px",
                gap: "1rem",
              }}
            >
              {showLinks ? (
                <>
                {/*
                  <a href="/whysignup" className="headerLink">
                    Why sign up?
              </a>*/}
                  &nbsp;
                  &nbsp;
                  <a href="/#pricing" className="headerLink">
                    Pricing
                  </a>
                </>
              ) : null}
            </Col>
            {showButtons ? <LoginButtons /> : null}
          </Row>
        </Container>
      </Navbar>
    </>
  );
};

export default FreelancerserplaceNavbar;
