import { Col, Button } from "react-bootstrap";
import Jobs from "./Jobs";
import downloadHandler from "@serie3/freelancesplaceapi/Domain/Shared/downloadHandler";
import ProfileConfig from "@serie3/freelancesplaceapi/Config/Profiles/ProfileConfig";
import ProfilePicture from "./ProfilePicture";
import NewModal from "../documents/modals/NewModal";
import FileDropzone from "../documents/modals/FileDropzone";
import { useState } from "react";
import PDFModal from "../../../layout/Popups/PDFModal";

const ProfileLeft = ({ formik, profile, updateJobs }) => {
  const [showProfileUpload, setShowProfileUpload] = useState(false);
  const downloadUrl = ProfileConfig.endpoints.download + profile.id;
  const uploadUrl = ProfileConfig.endpoints.edit;
  const [triggerRerenderProfile, setTriggerRerenderProfile] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);


  const setData = (response) => {
    setTriggerRerenderProfile(Math.random());
  };

  return (
    <>
      <PDFModal showSpinner={showSpinner} setShowSpinner={setShowSpinner} />
      <NewModal show={showProfileUpload} setShow={setShowProfileUpload}>
        <FileDropzone
          uploadUrl={uploadUrl}
          additionalData={{ id: profile.id }}
          edit={true}
          setName={false}
          setData={setData}
        />
      </NewModal>
      <Col className="col-md-3">
        <div className="card card-primary card-outline">
          <div className="card-body box-profile">
            <div
              className="text-center"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                setShowProfileUpload(true);
              }}
            >
              <ProfilePicture
                profileId={profile.id}
                rerender={triggerRerenderProfile}
              />
            </div>
            <br />
            <h3 className="profile-username text-center">
              {formik.getFieldProps("vorname").value}{" "}
              {formik.getFieldProps("name").value}
            </h3>
            <p className="text-muted text-center">
              {formik.getFieldProps("profiletitle").value}
            </p>
            {!profile.system_profile ? (
              <ul className="list-group list-group-unbordered mb-3">
                <li className="list-group-item">
                  <Button
                    style={{ width: "100%" }}
                    onClick={() => {
                      setShowSpinner(true);
                      downloadHandler(downloadUrl, profile.title + ".pdf", null, setShowSpinner);
                    }}
                  >
                    Download CV
                  </Button>
                </li>
              </ul>
            ) : null}
          </div>
        </div>
        {!profile.system_profile ? (
          <Jobs updateJobs={updateJobs} profileId={profile.id} />
        ) : null}
      </Col>
    </>
  );
};

export default ProfileLeft;
