import ErrorPopup from "../../../../layout/ErrorPopup";
import NewModal from "../../documents/modals/NewModal";
import { Col, Row } from "react-bootstrap";
import TextField from "../../../../layout/Form/Fields/TextField";
import TextArea from "../../../../layout/Form/Fields/TextArea";
import EducationForm from "@serie3/freelancesplaceapi/Form/Profile/EducationForm";
import { useFormik } from "formik";
import { useState } from "react";
import Education from "@serie3/freelancesplaceapi/Entity/Profile/Education";
import DateField from "../../../../layout/Form/Fields/DateField";
import SaveButton from "../../../../layout/Buttons/SaveButton";

const EducationFormView = ({
  showEdit,
  setShowEdit,
  editEducation,
  profileId,
  dataList,
  setDataList,
  showErrorFunction,
}) => {
  const [showError, setShowError] = useState(false);

  const editEducationForm = EducationForm.createEducationForm(
    dataList,
    setDataList,
    showErrorFunction,
    setShowEdit
  );
  const formik = useFormik(editEducationForm);

  const Save = () => {
    return (
      <SaveButton
        handleClick={(e) => {
          formik.handleSubmit(e);
          e.preventDefault();
        }}
      />
    );
  };

  return (
    <>
      <NewModal
        show={showEdit}
        setShow={setShowEdit}
        ExtraButton={Save}
        onShow={() => {
          editEducation
            ? formik.setValues(EducationForm.setEditData(editEducation))
            : formik.setValues(Education);

          
          formik.setFieldValue("profile.id", profileId);
        }}
      >
        <Row>
          <Col>
            <TextField
              formik={formik}
              label={"Name"}
              valueName={"name"}
              style={{ marginBottom: "10px" }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              formik={formik}
              label={"Company/Institution"}
              valueName={"subject_area"}
              style={{ marginBottom: "10px" }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextField
              formik={formik}
              label={"graduation"}
              valueName={"graduation"}
              style={{ marginBottom: "10px" }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <DateField
              formik={formik}
              label={"start"}
              valueName={"start"}
              style={{ marginBottom: "10px" }}
            />
          </Col>
          <Col>
            <DateField
              formik={formik}
              label={"ende"}
              valueName={"ende"}
              style={{ marginBottom: "10px" }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextArea
              formik={formik}
              label={"Description"}
              valueName={"description"}
              style={{ marginBottom: "10px" }}
            />
          </Col>
        </Row>
      </NewModal>
    </>
  );
};

export default EducationFormView;
