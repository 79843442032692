import { useEffect, useState } from "react";
import crudApi from "@serie3/common/api/crudApi";
import ProfileConfig from "@serie3/freelancesplaceapi/Config/Profiles/ProfileConfig";
import JobCard from "./JobCard";
import PublicJobView from "./PublicJobView";

const Jobs = ({ updateJobs, profileId }) => {
  const [viewJob, setViewJob] = useState();
  const [showView, setShowView] = useState(false);
  const [jobs, setJobs] = useState();

  useEffect(() => {
    const loadData = async () => {
      const jobData = await crudApi.show(
        ProfileConfig.endpoints.matchingJobs,
        profileId
      );
      if (jobData !== null && typeof jobData.hits.hits !== "undefined") {
        setJobs(jobData.hits.hits);
      } else {
        setJobs([]);
      }
    };
    loadData();
  }, [updateJobs]);


  const showJobDetails = (job) => {
    setViewJob(job);
    setShowView(true);
  }

  const JobDetails = () => {
    return viewJob ? <PublicJobView jobId={viewJob._source.id} showView={showView} setShowView={setShowView} /> : null
  }

  return (profileId > 0 && jobs) ?(
    <>
      <JobDetails />
      <div className="card card-primary">
        <div className="card-header">
          <h3 className="card-title">Found Jobs</h3>
        </div>
        <div className="card-body">
          {jobs ? jobs.map((job) => {
                return (
                  <JobCard
                    job={job}
                    showJobDetails={showJobDetails}
                    key={job._source.id}
                  />
                );
              }): null}
        </div>
      </div>
    </>
  ): null;
};

export default Jobs;
