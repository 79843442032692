import { useEffect, useState } from "react";
import Content from "../../../../layout/Content";
import listHandler from "@serie3/common/Domain/listHandler";
import CrudApi from "@serie3/common/api/crudApi";
import { Button, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import DocumentsHeader from "../DocumentsHeader";
import NewModal from "../modals/NewModal";
import DocumentCard from "../DocumentCard";
import FileDropzone from "../modals/FileDropzone";
import DocumentsConfig from "@serie3/freelancesplaceapi/Config/Documents/DocumentsConfig";
import PaymentModal from "../../../../layout/Payment/PaymentModal";
import getSettings from "@serie3/common/config/settings";

const Index = () => {
  const [data, setData] = useState([]);
  const [showRowError, setShowRowError] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showPayModal, setShowPayModal] = useState(false);
  const [settings, setSettings] = useState();

  const createNew = async () => {
    setShowNew(true);
  };

  const loadData = async () => {
    const response = await CrudApi.getAll("/documents/");
    setData(response.data.documents);
    setSettings(getSettings());
  };

  useEffect(() => {
    loadData();
  }, []);

  const NewButton = () => {
    return (
      <Button className="success" onClick={createNew}>
        <FontAwesomeIcon icon={faAdd} />
        &nbsp; New
      </Button>
    );
  };

  const Title = () => {
    return <b>Documents</b>;
  };

  const deleteFromList = (document) => {
    const itemIndex = data.findIndex((obj) => obj.id === document.id);
    listHandler.deleteFromList(
      itemIndex,
      CrudApi.del(DocumentsConfig.endpoints.delete, document.id),
      setData,
      data,
      setShowRowError
    );
  };

  return (
    <>
      <NewModal show={showNew} setShow={setShowNew}>
        <FileDropzone data={data} setShowPayModal={setShowPayModal} />
      </NewModal>
      {settings && <PaymentModal show={showPayModal} setShow={setShowPayModal} priceTableId={settings.pricingTables.document}/>}
      <DocumentsHeader/>
      <Content Title={Title} AdditionalButtons={NewButton}>
        <Row className="mb-3">
          {data
            ? data.map((document, index) => (
                <DocumentCard
                  key={document.id}
                  document={document}
                  setShow={setShowDetails}
                  deleteFunction={deleteFromList}
                />
              ))
            : "Keine Daten"}
        </Row>
      </Content>
    </>
  );
};

export default Index;
