import * as yup from "yup";

const actionValidationSchema = yup.object().shape({
  title: yup.string().required("This field is required"),
  beschreibung: yup.string().required("This field is required"),
  start: yup.date().required("This field is required"),
  priority: yup.string().required("This field is required"),
  status: yup.string().required("This field is required"),
});

export default actionValidationSchema;
