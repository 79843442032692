const TabContent = ({ prefix, name, children, show, style }) => {
  let showing = show ? "tab-pane fade show active" : "tab-pane fade";
  return (
      <div
        className={showing}
        id={prefix + "-" + name}
        role="tabpanel"
        aria-labelledby={prefix + "-" + name + "-tab"}
        style={{...style}}
      >
        {children}
      </div>
  );
};

export default TabContent;
